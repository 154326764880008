<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row mb-5">
        <div class="col-4">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Variation
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button type="primary" @click="createvariationmodal"><i class="fas fa-plus"></i> Create</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="variationData">
                <template slot="columns">
                  <th>Variation</th>
                  <!-- <th>Banner Image</th> -->
                  <!-- <th>Status</th> -->
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                  <td class="budget" >
                    <!-- <p class="mb-0 d-flex"><span>{{index+1 }}<img alt="Image placeholder" class="rounded mx-2" v-if="row.variation_icon" :src="row.variation_icon" width="50"></span><span><span> {{row.variation_name}}</span></span> <span class="mb-0 small d-block">{{row.status}}</span></p> -->

                    <p class="mb-0 d-flex">
                      <span>{{index+1 }}<img alt="Image placeholder" class="rounded mx-2" v-if="row.variation_img" :src="row.variation_img" width="50"></span> 
                      <span>
                        <span>{{row.variation_name}}</span> 
                        <span class="mb-0 small d-block">{{row.status}}</span>
                      </span>
                    </p>
                  </td>
                  <!-- <td class="budget">
                    <img alt="Image placeholder" class="rounded" :src="row.inventory_category_img" width="100">
                  </td> -->
                  <td class="text-right">
                    <!-- <a class="btn btn-primary btn-sm" @click="viewsubcategoryproduct(row)" icon="fa fa-info">View</a> -->
                    <base-dropdown class="" menuOnRight>
                      <base-button slot="title-container" size="sm" type="transparent" class="shadow-none">
                        <i class="fas fa-ellipsis-v"></i>
                      </base-button>
                          <a class="dropdown-item" @click="openbanner(row)">Banner</a>
                          <a class="dropdown-item" @click="openeditsubcategory(row)">Edit</a>
                          <a class="dropdown-item" @click="deletesubcategory(row)">Delete</a>
                    </base-dropdown>
                  </td>
                </template>
              </base-table>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Products
                  </h3>
                </div>
                <div class="col text-right">
                  <select class="form-control" v-model="filter" @change="getitems()">
                    <option>Filter by variation</option>
                    <option v-for="variation in variationData" :key="variation._id" :value="variation._id">{{ variation.variation_name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="subcategoryproductData">
                <template slot="columns">
                  <th>SKU</th>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget pr-2">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget px-2">
                      <p class="mb-0 d-flex" style="white-space: normal;"><img alt="Image placeholder" class="rounded avatar mr-2" :src="row.item_main_img" width="50"> {{row.item_name}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">{{row.item_mp}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.status}}</p>
                    </td>
                    <td class="text-right">
                      <base-dropdown class="" menuOnRight>
                        <base-button slot="title-container" size="sm" type="transparent" class="shadow-none">
                          <i class="fas fa-ellipsis-v"></i>
                        </base-button>
                             <a class="dropdown-item" :href="'#/products/editproduct/'+row._id">Edit</a>
                            <a class="dropdown-item" @click="deleteproduct(row)">Delete</a>
                      </base-dropdown>
                    </td>
                </template>
              </base-table>
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- create variation -->
    <modal :show.sync="createvariation">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create variation</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="Variation Name" placeholder="Variation Name" v-model="categorymodal.variation_name" input-classes="" />
                <div
                    v-if="$v.categorymodal.variation_name.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Variation Ranking</label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="categorymodal.sequenced_at"> 
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
              </select>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Make variation as featured</label>
              <br>
              <base-switch v-model="categorymodal.is_featured"></base-switch>
              {{categorymodal.is_featured}}
            </div>
            <!-- <div class="col-lg-6">
                <label class="form-control-label">Variation icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'icon')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="categorymodal.variation_icon" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div> -->
          </div>
          <div class="row mt-1" id="optionsid">
            <div class="col-lg-6">
                <label class="form-control-label">Variation image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'image')"
                >
                <div
                    v-if="$v.categorymodal.variation_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="categorymodal.variation_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for Web</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for Web"   id="file" ref="myFiles" name="img" @change="">
            </div> -->
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " v-model="categorymodal.status" autocomplete="off">
                    <option>approved</option>
                    <option>pending</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.categorymodal.$error" @click="$v.categorymodal.$touch();savevariation()"
          id="savevariationbtn"
          type="success"
          class="float-center"
        >
          <span>Save Variation</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="createvariation = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit variation -->
    <modal :show.sync="editvariation">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Variation</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="variation Name" placeholder="variation Name" v-model="editcategorymodal.variation_name" input-classes="" />
                 <div
                    v-if="$v.editcategorymodal.variation_name.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Variation Ranking</label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editcategorymodal.sequenced_at"> 
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
              </select>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Make variation as featured</label>
              <br>
              <base-switch v-model="editcategorymodal.is_featured"></base-switch>
              {{editcategorymodal.is_featured}}
            </div>
            <!-- <div class="col-lg-6">
                <label class="form-control-label">variation icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'web')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editcategorymodal.variation_icon" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div> -->
          </div>
          <div class="row mt-1" id="optionsid">
            <div class="col-lg-6">
                <label class="form-control-label">variation image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'web')"
                >
                <div
                    v-if="$v.editcategorymodal.variation_name.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editcategorymodal.variation_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editcategorymodal.status">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editcategorymodal.$error" @click="$v.editcategorymodal.$touch();updatevariation()"
          id="updatevariationbtn"
          type="success"
          class="float-center"
        >
          <span>Update Variation</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editvariation = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <modal :show.sync="editproduct">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Product</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="Item Name" placeholder="Item Name" v-model="editproductmodal.item_name" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_tagline" placeholder="item_tagline" v-model="editproductmodal.item_tagline" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="Short description" placeholder="Short description" v-model="editproductmodal.item_short_description" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_long_description" placeholder="item_long_description" v-model="editproductmodal.item_long_description" input-classes="" />
            </div>
            <!-- <div class="col-lg-6">
               <label class="form-control-label">item_category</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.item_category">
                    <option v-for="category in categoryData" :value="category._id">{{category.inventory_category_name}}</option>
               </select>
            </div> -->
            <div class="col-lg-6">
               <label class="form-control-label">item_subcategory</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.item_subcategory" @change="getvariation()">
                    <option v-for="subcategory in subcategoryData" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">item_variation</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.item_variation">
                    <option v-for="subcategory in variationData" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
            </div>
            <div class="col-lg-6">
                <base-input label="item_sp" placeholder="item_sp" v-model="editproductmodal.item_sp" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_mp" placeholder="item_mp" v-model="editproductmodal.item_mp" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_discount" placeholder="item_discount" v-model="editproductmodal.item_discount" input-classes="" />
            </div>
            
            <div class="col-lg-6">
                <label class="form-control-label">item_main_img</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'mainimage')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editproductmodal.item_main_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
          </div>
          <div class="row mt-1" id="optionsid">
            <div class="col-lg-6">
                <label class="form-control-label">item_secondary_img</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'secondary')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editproductmodal.item_secondary_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">item_thumbnail</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'thumbnail')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editproductmodal.item_thumbnail" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Store</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.store">
                     <option v-for="store in storeData" :value="store._id">{{store.store_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.status">
                    <option>approved</option>
                    <option>pending</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updateproduct()"
          id="updateproductbtn"
          type="success"
          class="float-center"
        >
          <span>Update product</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editproduct = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit banner -->
    <modal :show.sync="editbanner" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Banners</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="row" v-for="(banner_primary,index) in editcategorymodal.banner_primary">
                <div class="col-lg-4">
                  <label class="form-control-label">Primary Banner Link To</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="banner_primary.todo" @change="getchange_div('primary',index)">
                        <option value="openCategory">Category</option>
                        <option value="openProductFeed">Sub-Category</option>
                        <option value="openProduct">Product</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'pcategory'+index">
                  <label class="form-control-label">Category</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target" @change="getsubcategory()">
                        <option v-for="category in categoryData" :key="category._id">{{category.inventory_category_name}}</option>
                        
                        <!-- <option>Category 3</option> -->
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'psubcategory'+index">
                  <label class="form-control-label">Subcategory</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target">
                        <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                        <!-- <option>Sub-category 2</option>
                        <option>Sub-category 3</option> -->
                  </select>
                </div>
                <div class="col-lg-8 d-none" :id="'pproduct'+index">
                  <base-input label="Product oid" placeholder="Product oid" v-model="banner_primary.target" input-classes="" />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label mt-2">Upload Image</label>
                  <input
                    id="file"
                    ref="myFiles"
                    type="file"
                    aria-describedby="addon-right addon-left"
                    alternative=""
                    class="form-control form-control-alternative"
                    name="img"
                    @change="bannerhadleimage($event,'primary',index)"
                  >
                  <base-progress type="success" label="File uploaded"></base-progress>
                  <div>
                    <img :src="banner_primary.url" class="img-fluid rounded shadow mt-2 p-2">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <hr>
            </div>
            <div class="col-lg-12 mt-2">
              <div class="row" v-for="(banner_secondary,index) in editcategorymodal.banner_secondary">
                <div class="col-lg-4">
                  <label class="form-control-label">Secondary Banner Link To</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.todo" @change="getchange_div('secondary',index)">
                        <option value="openCategory">Category</option>
                        <option value="openProductFeed">Sub-Category</option>
                        <option value="openProduct">Product</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'scategory'+index">
                  <label class="form-control-label">Category</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                        <option v-for="category in categoryData" :key="category._id">{{category.inventory_category_name}}</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'ssubcategory'+index">
                  <label class="form-control-label">Subcategory</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                        <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                  </select>
                </div>
                <div class="col-lg-8 d-none" :id="'sproduct'+index">
                  <base-input label="Product SKU" placeholder="Product SKU" v-model="banner_secondary.target" input-classes="" />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label mt-2">Upload Image</label>
                  <input
                    id="file"
                    ref="myFiles"
                    type="file"
                    aria-describedby="addon-right addon-left"
                    alternative=""
                    class="form-control form-control-alternative"
                    name="img"
                    @change="bannerhadleimage($event,'secondary',index)"
                  >
                  <div>
                    <img :src="banner_secondary.url" class="img-fluid rounded shadow mt-2 p-2">
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                  <base-button
                    id="updatebtn1"
                    type="success"
                    outline
                    @click="addmore()"
                    class="float-center border-dashed"
                    block
                  >
                    <i class="fas fa-plus"></i><span>Add more banners</span>
                  </base-button>
                </div>
            </div>

          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatebanner()"
          id="updatebtn1"
          type="success"
          class="float-center"
        >
          <span>Update Banners</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editbanner = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import StatsCard from '@/components/Cards/StatsCard';
   import VueSwal from 'vue-swal';
  import axios from "axios";
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        inputs: {
          file: []
        },
        subcategoryData:[],
        categoryData:[],
        variationData:[],
        subcategoryproductData:[],
        banneruploadPercentage:0,
        imagearr:[],
        filter:'',
        storeData:[],
        editbanner:false,
        createvariation:false,
        editvariation:false,
        editproduct:false,
        uploadPercentage:0,
        categorymodal:{
            variation_name:'',
            variation_subcategory_name:this.$route.params.name,
            variation_subcategory_oid:this.$route.params.id,
            variation_category_oid:'',
            variation_category_name:'Dairy Products',
            variation_icon:'',
            variation_type:'New',
            variation_img:'',
            is_featured:true,
            variation_theme_color:'#d3d3d3',
            store:'4uw81toukjwkdqaj',
            banner_primary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404864493banner-1%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404896351banner-1-top%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}],
            banner_secondary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}],
            created_by : localStorage.getItem('_id'),
            status : '',
        },
        editcategorymodal:{
            variation_id:'',
            variation_name:'',
            variation_subcategory_name:'',
            variation_subcategory_oid:'',
            variation_category_oid:'',
            variation_category_name:'Dairy Products',
            variation_icon:'',
            variation_type:'New',
            variation_img:'',
            variation_theme_color:'#d3d3d3',
            store:'',
            is_featured:true,
            banner_primary:[{url:'',todo:'',target:'',sequenced_at:0}],
            banner_secondary:[{url:'',todo:'',target:'',sequenced_at:0}],
            created_by : localStorage.getItem('_id'),
            status : '',
        },
        editproductmodal:{
          item_id : '',
          item_name : '',
          item_tagline : '',
          item_short_description : '',
          item_long_description : '',
          item_type : '',
          is_featured :'',
          sequenced_at :'',
          status:'',
          visibility :'', 
          item_category :this.$route.params.id,
          item_subcategory : '',
          item_variation : '',
          item_sp : '',
          item_mp : '',
          item_discount : '',
          minimum_quantity : '',
          item_ratings : '',
          item_theme_color : '',
          item_tags : [],
          item_main_img : '',
          item_secondary_img : '',
          item_thumbnail : '',
          store : '',
          number_available :'',
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted () {

      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{_id:this.$route.params.id}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryData=response.data.details[0];
        this.categorymodal.variation_category_oid=this.subcategoryData.inventory_category_oid;
        this.categorymodal.variation_category_name=this.subcategoryData.inventory_category_name;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_variation',{ "details" :{variation_subcategory_oid:this.$route.params.id}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.variationData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        }
            
      })


      //inventory_category_oid:this.$route.params.id

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',{ "details" :{item_subcategory:this.$route.params.id},"offset" : "0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryproductData=response.data.details;
        }
            
      })


    },
    validations: {
      categorymodal: {
        variation_name:{
          required
        },
        variation_img:{
          required
        },
      },
      editcategorymodal: {
        variation_name:{
          required
        },
        variation_img:{
          required
        },
      },

    },
    methods: {
          createvariationmodal(){
            this.createvariation=true;
          },
          bannerhadleimage: function(event,type,index) {
                // Reference to the DOM input element
                    this.uploadPercentage =0;
                    var input = event.target;
                    // Ensure that you have a file before attempting to read it
                    if(input.files[0].size < 5000000){

                    const fd= new FormData();
                    fd.append('image',input.files[0],input.files[0].name);
                    axios
                    .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
                    .then(response => {
                      console.log(response);
                      if(type=='primary'){
                        this.editcategorymodal.banner_primary[index].url=response.data.imageUrl;
                        console.log(this.editcategorymodal.banner_primary[index].url);
                      }else if(type=='secondary'){
                        this.editcategorymodal.banner_secondary[index].url=response.data.imageUrl;
                        console.log(this.editcategorymodal.banner_secondary[index].url);
                      }
                        
                    })

                    }else{

                    swal({
                      title:'File too large!',
                      text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                      icon:'warning',
                     }).then((result) => {
                       
                    })

                  }

            },
            getchange_div(type,index){

                  if(this.editcategorymodal.banner_primary[index].todo=='openCategory' && type=='primary'){
                     document.getElementById("pcategory"+index).classList.remove('d-none');
                     document.getElementById("psubcategory"+index).classList.add('d-none');
                     document.getElementById("pproduct"+index).classList.add('d-none');
                  }
                  if(this.editcategorymodal.banner_primary[index].todo=='openProductFeed' && type=='primary'){
                     document.getElementById("pcategory"+index).classList.add('d-none');
                     document.getElementById("psubcategory"+index).classList.remove('d-none');
                     document.getElementById("pproduct"+index).classList.add('d-none');

                  }
                  if(this.editcategorymodal.banner_primary[index].todo=='openProduct' && type=='primary'){
                     document.getElementById("pcategory"+index).classList.add('d-none');
                     document.getElementById("psubcategory"+index).classList.add('d-none');
                     document.getElementById("pproduct"+index).classList.remove('d-none');

                  }

                  if(this.editcategorymodal.banner_secondary[index].todo=='openCategory' && type=='secondary'){
                     document.getElementById("scategory"+index).classList.remove('d-none');
                     document.getElementById("ssubcategory"+index).classList.add('d-none');
                     document.getElementById("sproduct"+index).classList.add('d-none');
                  }
                  if(this.editcategorymodal.banner_secondary[index].todo=='openProductFeed' && type=='secondary'){
                     document.getElementById("scategory"+index).classList.add('d-none');
                     document.getElementById("ssubcategory"+index).classList.remove('d-none');
                     document.getElementById("sproduct"+index).classList.add('d-none');

                  }
                  if(this.editcategorymodal.banner_secondary[index].todo=='openProduct' && type=='secondary'){
                     document.getElementById("scategory"+index).classList.add('d-none');
                     document.getElementById("ssubcategory"+index).classList.add('d-none');
                     document.getElementById("sproduct"+index).classList.remove('d-none');

                  }
                  
            },
            addmore(){
            this.editcategorymodal.banner_secondary.push({url:'',todo:'',target:'',sequenced_at:0})
            },
            openbanner(row){
            this.editbanner=true;
            this.editcategorymodal.variation_id =row.variation_id;
            this.editcategorymodal.variation_name =row.variation_name;
            this.editcategorymodal.variation_subcategory_name =row.variation_subcategory_name;
            this.editcategorymodal.variation_subcategory_oid =row.variation_subcategory_oid;
            this.editcategorymodal.variation_category_oid =row.variation_category_oid;
            this.editcategorymodal.variation_category_name =row.variation_category_name;
            this.editcategorymodal.variation_icon =row.variation_icon;
            this.editcategorymodal.variation_type =row.variation_type;
            this.editcategorymodal.variation_img =row.variation_img;
            this.editcategorymodal.variation_theme_color =row.variation_theme_color;
            this.editcategorymodal.store =row.store;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.banner_secondary =row.banner_secondary;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.status =row.status;
          },
          updatebanner() {
                const querystring = require('querystring');
                 axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_subcategory',{ inventory_subcategory_id:this.editcategorymodal.inventory_subcategory_id,created_by:this.editcategorymodal.created_by,banner_primary:this.editcategorymodal.banner_primary,banner_secondary:this.editcategorymodal.banner_secondary},this.auth1)
                .then(response => {
                  console.log(response);
                  this.message=response.data.message;
                  document.getElementById("updatebtn1").classList.remove('btn-primary');
                  document.getElementById("updatebtn1").classList.add('btn-success');
                  document.getElementById("updatebtn1").innerHTML='Updated';
                  setTimeout(function(){
                  document.getElementById("updatebtn1").classList.remove('btn-success');
                  document.getElementById("updatebtn1").classList.add('btn-primary');
                  document.getElementById("updatebtn1").innerHTML="Update";
                }, 2000);

                 // window.location.reload()

                })

           },
           hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                if(type=='icon'){
                  this.categorymodal.variation_icon=response.data.imageUrl;
                  console.log(this.categorymodal.variation_icon);
                }else if(type=='image'){
                  this.categorymodal.variation_img=response.data.imageUrl;
                  console.log(this.categorymodal.variation_img);
                }else if(type=='editicon'){
                  this.editcategorymodal.inventory_subcategory_icon=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_icon);
                }else if(type=='editimage'){
                  this.editcategorymodal.inventory_subcategory_img=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_img);
                }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

        },
        async bannerupload(event,type,uploatype) {
              // Reference to the DOM input element
                //console.log(event);
                // document.getElementById("editassignmentbtn").classList.add('d-none');
                // document.getElementById("editaddassignmentinvalid").classList.remove('d-none');
               this.banneruploadPercentage =0;
                if(uploatype=='file1'){
                  for( var i = 0; i < this.$refs.file1.files.length; i++ ){
                      let file = this.$refs.file1.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }
                if(uploatype=='file3'){
                  for( var i = 0; i < this.$refs.file3.files.length; i++ ){
                      let file = this.$refs.file3.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }

        },
        upload(file,type){
          //console.log(file);
          let fd = new FormData();
          fd.append('image',file,file.name);
          axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.banneruploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.banneruploadPercentage)}.bind(this)}).then(response => {
            //console.log(response);
            this.imagearr.push({"url":response.data.imageUrl,"sequenced_at":1});
            //console.log(this.imagearr);
            if(type=='add'){
              this.categorymodal.banner_primary=this.imagearr;
              //console.log(this.assignmentmodel.assignment_file);
            }
            else{
              this.categorymodal.banner_secondary=this.imagearr;
            }
            //console.log();
         })

        },
        savevariation() {
          if(this.$v.categorymodal.$error===false){
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_variation',{variation_name : this.categorymodal.variation_name,variation_subcategory_name:this.categorymodal.variation_subcategory_name,variation_subcategory_oid:this.categorymodal.variation_subcategory_oid,sequenced_at:this.categorymodal.sequenced_at,variation_category_oid:this.categorymodal.variation_category_oid,variation_category_name:this.categorymodal.variation_category_name,variation_icon:this.categorymodal.variation_icon,variation_type:this.categorymodal.variation_type,variation_img:this.categorymodal.variation_img,variation_theme_color:this.categorymodal.variation_theme_color,store:this.categorymodal.store,banner_primary:this.categorymodal.banner_primary,banner_secondary:this.categorymodal.banner_secondary,is_featured:this.categorymodal.is_featured,created_by:this.categorymodal.created_by,status:this.categorymodal.status},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savevariationbtn").classList.remove('btn-primary');
            document.getElementById("savevariationbtn").classList.add('btn-success');
            document.getElementById("savevariationbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savevariationbtn").classList.remove('btn-success');
            document.getElementById("savevariationbtn").classList.add('btn-primary');
            document.getElementById("savevariationbtn").innerHTML="Update";
             window.location.reload()
          }, 2000);

           

          })

        }

        },
        openeditsubcategory(row){
            this.editvariation=true;
            this.editcategorymodal.variation_id =row.variation_id;
            this.editcategorymodal.variation_name =row.variation_name;
            this.editcategorymodal.variation_subcategory_name =row.variation_subcategory_name;
            this.editcategorymodal.variation_subcategory_oid =row.variation_subcategory_oid;
            this.editcategorymodal.variation_category_oid =row.variation_category_oid;
            this.editcategorymodal.variation_category_name =row.variation_category_name;
            this.editcategorymodal.variation_icon =row.variation_icon;
            this.editcategorymodal.variation_type =row.variation_type;
            this.editcategorymodal.variation_img =row.variation_img;
            this.editcategorymodal.variation_theme_color =row.variation_theme_color;
            this.editcategorymodal.store =row.store;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.banner_secondary =row.banner_secondary;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.status =row.status;
          },

        updatevariation() {
          if(this.$v.categorymodal.$error===false){
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_variation',{variation_id:this.editcategorymodal.variation_id,variation_name:this.editcategorymodal.variation_name,variation_subcategory_name:this.editcategorymodal.variation_subcategory_name,variation_subcategory_oid:this.editcategorymodal.variation_subcategory_oid,variation_category_oid:this.editcategorymodal.variation_category_oid,variation_category_name:this.editcategorymodal.variation_category_name,variation_icon:this.editcategorymodal.variation_icon,variation_type:this.editcategorymodal.variation_type,variation_img:this.editcategorymodal.variation_img,variation_theme_color:this.editcategorymodal.variation_theme_color,store:this.editcategorymodal.store,created_by:this.editcategorymodal.created_by,is_featured:this.editcategorymodal.is_featured,sequenced_at:this.categorymodal.sequenced_at,status:this.editcategorymodal.status},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatevariationbtn").classList.remove('btn-primary');
            document.getElementById("updatevariationbtn").classList.add('btn-success');
            document.getElementById("updatevariationbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatevariationbtn").classList.remove('btn-success');
            document.getElementById("updatevariationbtn").classList.add('btn-primary');
            document.getElementById("updatevariationbtn").innerHTML="Update";
             window.location.reload()
          }, 2000);

            //window.location.reload()

          })
        }

        },
      deletesubcategory(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_inventory_subcategory',querystring.stringify({inventory_subcategory_id :row.inventory_subcategory_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your subcategory has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getvariation(){
       axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_variation',{ "details" :{inventory_subcategory_oid:this.editproductmodal.item_subcategory}},this.auth1)
      .then(response => {
        console.log(response);
          if(response.data.details){
          this.variationData=response.data.details;
          }   
        })
      },
      openeditproduct(row){
            this.editproduct=true;
            this.editproductmodal.item_id =row.item_id;
            this.editproductmodal.inventory_subcategory_name =row.inventory_subcategory_name;
            this.editproductmodal.item_name =row.item_name;
            this.editproductmodal.item_tagline =row.item_tagline;
            this.editproductmodal.item_short_description =row.item_short_description;
            this.editproductmodal.item_long_description =row.item_long_description;
            this.editproductmodal.item_type =row.item_type;
            this.editproductmodal.is_featured =row.is_featured;
            this.editproductmodal.sequenced_at =row.sequenced_at;
            this.editproductmodal.status =row.status;
            this.editproductmodal.visibility =row.visibility;
            this.editproductmodal.item_category =row.item_category;
            this.editproductmodal.item_subcategory =row.item_subcategory;
            this.editproductmodal.item_variation =row.item_variation;
            this.editproductmodal.item_sp =row.item_sp;
            this.editproductmodal.item_mp =row.item_mp;
            this.editproductmodal.item_discount =row.item_discount;
            this.editproductmodal.minimum_quantity =row.minimum_quantity;
            this.editproductmodal.item_theme_color =row.item_theme_color;
            this.editproductmodal.item_tags =row.item_tags;
            this.editproductmodal.item_main_img =row.item_main_img;
            this.editproductmodal.item_secondary_img =row.item_secondary_img;
            this.editproductmodal.item_thumbnail =row.item_thumbnail;
            this.editproductmodal.store =row.store;
            this.editproductmodal.number_available =row.number_available;
        },
        updateproduct() {
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_item',{ item_id:this.editproductmodal.item_id,item_name : this.editproductmodal.item_name,item_tagline:this.editproductmodal.item_tagline,item_short_description:this.editproductmodal.item_short_description,item_long_description:this.editproductmodal.item_long_description,item_subcategory:this.editproductmodal.item_subcategory,item_variation:this.editproductmodal.item_variation,item_sp:this.editproductmodal.item_sp,item_mp:this.editproductmodal.item_mp,item_discount:this.editproductmodal.item_discount,item_main_img:this.editproductmodal.item_main_img,item_secondary_img:this.editproductmodal.item_secondary_img,item_thumbnail:this.editproductmodal.item_thumbnail,store:this.editproductmodal.store,is_featured:true,status:this.editproductmodal.status},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateproductbtn").classList.remove('btn-primary');
            document.getElementById("updateproductbtn").classList.add('btn-success');
            document.getElementById("updateproductbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateproductbtn").classList.remove('btn-success');
            document.getElementById("updateproductbtn").classList.add('btn-primary');
            document.getElementById("updateproductbtn").innerHTML="Update";
          }, 2000);

            //window.location.reload()

          })

        },
      deleteproduct(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_item',querystring.stringify({item_id :row.item_id,product_oid:row._id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your product has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getitems(){

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',{ "details" :{item_variation:this.filter},"offset" : "0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryproductData=response.data.details;
        }else{
          this.subcategoryproductData=[];
        }
            
      })

      }

    },
  };
</script>
<style></style>
