<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    General Queries
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <base-input class="mb-0 search-input mr-2" input-group-classes="input-group-merge" placeholder="Search User" v-model="search_term" @keyup.enter="searchuser()">
                      <template slot="append"><a><i class="fas fa-search" @click="searchuser()"></i></a></template>
                  </base-input> -->
                  <!-- <select class="form-control d-inline w-auto mr-2">
                    <option>Status</option>
                    <option>Complete</option>
                    <option>On Hold</option>
                    <option>Cancelled</option>
                    <option>Suspended</option>
                  </select> -->
                </div>
              </div>
            </div>
            <div class="table-responsive d-block">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="queryData">
                <template slot="columns"> 
                  <th>Customer</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Message</th>
                  <th>Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget" >
                      <span class="mb-0 d-block"><span class="pr-2" v-if="!pagination_load">{{ (offset*30)+(index+1) }}.</span>{{row.name}}</span>
                      <span class="mb-0 ml-3">{{row.mobile}}</span>
                    </td>
                    <td class="budget">
                      <span class="mb-0">{{row.email}}</span>
                    </td>
                    <td class="budget">
                      <span class="mb-0">{{format_datetime(row.created_at)}}</span>
                    </td>
                    <td class="budget" style="white-space: normal;">
                      <span class="mb-0">{{row.query}}</span>
                    </td>
                    <td class="text-right">
                      <base-dropdown menuOnRight>
                        <base-button slot="title-container" size="sm" type="white" class="shadow-none">
                          <i class="fas fa-ellipsis-v"></i>
                        </base-button>
                            <!-- <a :href="'#/users/viewuser/'+row.user_oid" class="dropdown-item">User Profile</a> -->
                            <a :href="'mailto:'+row.email" v-if="row.email" class="dropdown-item">Email</a>
                            <a :href="'tel:'+row.mobile" v-if="row.mobile" class="dropdown-item">Call</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" v-if="pagepermission.delete" @click="deletequery(row)">Delete</a>
                      </base-dropdown>
                    </td>
                </template>
              </base-table>
              <!-- no result found -->
              <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No queries found.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="queryData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
              <!-- <div class="d-block mb-5 mt-4">
                <base-button
                  type="success"
                  class="float-center m-auto d-block"
                  v-if="loadmorefound" 
                  @click="loadmore()"
                >
                  <span>Load More</span>
                </base-button>
              </div> -->
            </div>
            <div class="card-footer d-flex justify-content-end" v-if="!datanotfound">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <!-- <li class="page-item d-flex mr-2">
                      <p class="mb-0 px-1 mt-1">
                        Page
                      </p>
                      <input
                        id="page_no"
                        size="4"
                        class="mx-1 p-1 text-center"
                        style="border: 1px solid #e9ecff;border-radius: 5px !important;background-color: #e9ecff;width: 4em;"
                        type="number"
                        name="page_no"
                      >
                      <base-button
                        block
                        type="primary"
                        class="px-3 mx-1"
                        title="GO"
                        size="sm"
                        @click="gotopage()"
                      >
                        GO
                      </base-button>
                    </li> -->
                    <li class="page-item">
                      <a
                        class="page-link"
                        :disable="pagination_load"
                        @click="previouspage()"
                      > <span aria-hidden="true"><i
                        aria-hidden="true"
                        class="fa fa-angle-left"
                      /></span> </a>
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        :disable="pagination_load"
                        @click="nextpage()"
                      > <span aria-hidden="true"><i
                        aria-hidden="true"
                        class="fa fa-angle-right"
                      /></span> </a>
                    </li>
                  </ul>
                </nav> 
              </div>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="resetpassword">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Reset password</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-12">
                <base-input label="User Name" placeholder="User Name" v-model="model.user_name" input-classes="" />

            </div>
            <div class="col-lg-12">
                <base-input label="Password" placeholder="Password" v-model="model.user_password" input-classes="" />
            </div>
            <div class="col-lg-12">
                <base-input label="Confirm Password" placeholder="Confirm Password" v-model="model.cuser_password" input-classes="" />
            </div>
            <div class="col-lg-12">
                <span v-if="passworderror" style="color: red">{{passworderror}}</span>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="savepassword()"
          id="updatebtn1"
          type="success"
          class="float-center"
        >
          <span>Change Password</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="resetpassword = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
   import VueSwal from 'vue-swal';
  import axios from "axios";
  import moment from 'moment';

  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },

    data() {
      return {
        inputs: {
          file: []
        },
        queryData:[],
        search_term:'',
        passworderror:'',
        pagepermission:{},
        offset:0,
        loadmorefound:1,
        pagination_load:false,
        user_type:localStorage.getItem('user_type'),
        datanotfound:false,
        resetpassword:false,
        model:{
          user_id : '',
          user_type : '',
          user_name : '',
          user_email : '',
          user_fcm : '',
          user_gid : '',
          user_fid : '',
          user_dp : '',
          user_password : '',
          cuser_password : '',
          user_permissions: '',
          user_phone : '',
          user_language : '',
          user_country : '',
          user_status : 'approved',
        },
        checkboxes: {
          unchecked: false,
          checked: true,
          uncheckedDisabled: false,
          checkedDisabled: true
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'bakeneto#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'bakeneto#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted () {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_contact',{ "details" :{},"offset":"0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.datanotfound=false;
        this.queryData=response.data.details;
        
        }else{
            this.queryData=[]; 
            this.datanotfound=true;
        }
            
      })

    },
    methods: {
      openuser(row){
        console.log(row);
            this.resetpassword=true;
            this.model.user_id =row.user_id;
            this.model.user_name =row.user_name;
            this.model.user_email =row.user_email;
            this.model.user_status =row.user_status;
          },
      loadmore(){
          this.offset=this.offset+1;
          const querystring = require('querystring');
          console.log(this.auth);
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'get_user_details',{ "details" :{},"offset":this.offset.toString()},this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            //this.orderData=response.data.details;
             for (var i = 0; i < response.data.details.length; i++) {
                    this.queryData.push(response.data.details[i]);
                }
            // this.productData.push(response.data.details);
                if(response.data.details.length < 30){
                   this.loadmorefound=0; 
                }
            }else{
                this.loadmorefound=0;

            } 
                
          })
      },
      searchuser(){
        axios
        .post(process.env.VUE_APP_API_ENDPOINT+'search_user',{ "search_term" :this.search_term},this.auth1)
        .then(response => {
          console.log(response);
          if(response.data.details){
          this.datanotfound=false;
          this.queryData=response.data.details;
          }else{
            this.datanotfound=true;
            this.queryData=[];
          }
              
         })
        },
      savepassword() {
        if(this.model.user_password==this.model.cuser_password){
          this.passworderror='';
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_user_details',{user_id : this.model.user_id,user_email:this.model.user_email,user_name : this.model.user_name,user_password:this.model.user_password},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
            window.location.reload()
          }, 2000);

           // window.location.reload()

          })
        }else{
          this.passworderror="Confirm Password not match";
        }

        },
      changestatus(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to suspend",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
          if (result) {
            let status='';
            console.log('result cloud',result)
            if(row.user_status=='approved'){
               status='suspended';
            }else{
               status='approved';
            }
            axios.post(process.env.VUE_APP_API_ENDPOINT+'update_user_details',querystring.stringify({user_email:row.user_email,user_status : status}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Suspended!',
                text: 'User has been suspended',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
            })
          }
        });
      },
      changestatusapproved(row) {
        //referral_code:row.user_id.substr(1, 6)
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to unsuspend",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              let status='';
              console.log('result cloud',result)
              if(row.user_status=='approved'){
                 status='suspended';
              }else{
                 status='approved';
              }
              axios.post(process.env.VUE_APP_API_ENDPOINT+'update_user_details',querystring.stringify({user_email:row.user_email,user_status : status}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Unsuspended!',
                text: 'User has been unsuspended',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      previouspage(){
        const querystring = require('querystring');
        //console.log(this.auth);
        if(this.offset>0){
          this.pagination_load=true;
        --this.offset;

       axios
        .post(process.env.VUE_APP_API_ENDPOINT+'read_contact',{ "details" :{},"offset":this.offset.toString()},this.auth1)
        .then(response => {
          //console.log(response);
          if(response.data.details){
          this.datanotfound=false;
          this.queryData=response.data.details;
          this.pagination_load=false;

          }else{
            this.queryData=[];
            this.datanotfound=true;
            this.pagination_load=false;

          }
              
        })
        }
      },
      gotopage(){
        const querystring = require('querystring');
        //console.log(this.auth);
        if(document.getElementById("page_no").value){
        this.offset=document.getElementById("page_no").value;
       axios
        .post(process.env.VUE_APP_API_ENDPOINT+'read_contact',{ "details" :{},"offset":this.offset.toString()},this.auth1)
        .then(response => {
          //console.log(response);
          if(response.data.details){
          this.queryData=response.data.details;
          this.datanotfound=false;
          }else{
            this.queryData=[];
            this.datanotfound=true;
          }
              
        })

        }
      },
      nextpage(){
        const querystring = require('querystring');
        this.pagination_load=true;
        this.offset=this.offset+1;
        //console.log(this.auth);
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_contact',{ "details" :{},"offset":this.offset.toString()},this.auth1)
          .then(response => {
            //console.log(response);
            if(response.data.details){
            
            this.queryData=response.data.details;
            this.datanotfound=false;
            this.pagination_load=false;

            }else{
              this.offset=this.offset-1;
              this.queryData=[];
              this.datanotfound=true;
              this.pagination_load=false;

            }
                
          })
      },
      // deletequery(row){
      //   const querystring = require('querystring');
      //   axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_contact',querystring.stringify({contact_id : row.contact_id}),this.auth).then(response => {
      //     console.log(response);
      //     this.message=response.data.message; 
      //     // document.getElementById("send_message").classList.remove('btn-primary');
      //     // document.getElementById("send_message").classList.add('btn-success');
      //     // document.getElementById("send_message").innerHTML='Message Sent';
      //     // setTimeout(function(){
      //     // document.getElementById("send_message").classList.remove('btn-success');
      //     // document.getElementById("send_message").classList.add('btn-primary');
      //     // document.getElementById("send_message").innerHTML="Saved";
      //     window.location.reload()
      //     // }, 2000);
      //   })
      // },
      deletequery(row) {
        const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_contact',querystring.stringify({contact_id : row.contact_id}),this.auth).then(response => {
              console.log(response);
              this.message=response.data.message; 
              swal({
                title:'Deleted!',
                text: 'Your order has been deleted.',
                icon:'success',
               }).then((result) => {
                // console.log(result)
                // _this.getalldata();
                window.location.reload()
              })
            })
           }
        });
      },
      format_datetime(value){
        if(value) {
          return moment(new Date(value)).format("LLL")
        }
      },
   },
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
