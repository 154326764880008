<template>
  <nav :class="classes" class="navbar">
    <div :class="containerClasses">
      <slot name="brand"></slot>

      <slot name="toggle-button">
        <button
          class="navbar-toggler collapsed"
          v-if="hasMenu"
          type="button"
          @click="toggleMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
      </slot>

      <button class="navbar-toggler" @click.stop="toggleMenu">
        <span class="navbar-toggler-icon"></span>
      </button>

        <div
          class="navbar-collapse navbar-custom-collapse collapse show"
          :class="menuClasses"
          v-show="show"
          v-click-outside="closeMenu">
          <slot :close-menu="closeMenu"></slot>
        </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'base-nav',
  props: {
    show: {
      type: Boolean,
      default: false,
      description:
        'Whether navbar menu is shown (valid for viewports < specified by `expand` prop)'
    },
    transparent: {
      type: Boolean,
      default: false,
      description: 'Whether navbar is transparent'
    },
    expand: {
      type: String,
      default: 'lg',
      description: 'Breakpoint where nav should expand'
    },
    menuClasses: {
      type: [String, Object, Array],
      default: '',
      description:
        'Navbar menu (items) classes. Can be used to align menu items to the right/left'
    },
    containerClasses: {
      type: [String, Object, Array],
      default: 'container',
      description:
        'Container classes. Can be used to control container classes (contains both navbar brand and menu items)'
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        return [
          '',
          'dark',
          'success',
          'danger',
          'warning',
          'white',
          'primary',
          'light',
          'info',
          'vue'
        ].includes(value);
      },
      description: 'Navbar color type'
    }
  },
  model: {
    prop: 'show',
    event: 'change'
  },
  computed: {
    classes() {
      let color = `bg-${this.type}`;
      let classes = [
        { 'navbar-transparent': this.transparent },
        { [`navbar-expand-${this.expand}`]: this.expand }
      ];
      if (this.position) {
        classes.push(`navbar-${this.position}`);
      }
      if (!this.transparent) {
        classes.push(color);
      }
      return classes;
    },
    hasMenu() {
      return this.$slots.default;
    }
  },
  methods: {
    toggleMenu() {
      this.$emit('change', !this.show);
    },
    closeMenu() {
      this.$emit('change', false);
    }
  }
};
</script>
<style>
.cursor-drag {
  cursor: all-scroll;
}
.link-text {
  color: #555555;
}
.link-text:hover {
  color: #ef613e;
}
.meta_tags .tags-input__wrapper {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}
.meta_tags .el-tag.el-tag--primary {
  margin-top: auto;
  margin-bottom: auto;
}
.el-select .el-input .el-input__inner {
    height: calc(1.5em + 1.25rem + 5px) !important;
}
.bg-lightest {
    background-color: #f3f3f3 !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.ui.multiple.dropdown {
    padding: .5em 2.1em .5em .35714286em !important;
}
</style>
