<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-3" :class="''">
            <div class="p-3 border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Roles
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button v-if="pagepermission.create" type="primary" @click="addpopuprole()"><i class="fas fa-user-plus"></i> Create new role</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4" v-for="roledata, index in role" :key="roledata.role_id">
          <card
            header-classes="bg-transparent"
            body-classes="px-lg-4"
            class="card-pricing border-0 text-center mb-4">
            <div class="display-4">{{roledata.role_name}}</div>
            <span class=" text-muted">(permissions)</span>
            <ul class="list-unstyled my-3">
              <li v-for="permission in roledata.permissions" :key="permission._id">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-terminal"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">{{permission.permission_name}}</span>
                  </div>
                </div>
              </li>
            </ul>
            <div slot="footer" class="row">
              <div class="col-md-6 border-right">
                <a v-if="pagepermission.update" class="cursor-pointer text-muted" @click="editrolepopup(roledata)">Edit</a>
              </div>
              <div class="col-md-6">
                <a v-if="pagepermission.delete" @click="deleterole(roledata)" class="cursor-pointer text-muted">Delete</a>
              </div>
            </div>
          </card>
        </div>

        <!-- shimmer starts here -->
        <div class="col-md-12" v-if="role.length==0 && !datanotfound">
          <div class="row justify-content-center">
            <div class="col-lg-12 mt-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- shimmer ends here -->
        <!-- no result found -->
        <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
          <div class="col-md-3 mx-auto mt-4 text-center">
              <img src="img/theme/no-result.png" class="img-fluid">
              <p class="mt-4">No role found.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- create team popup -->
    <modal :show.sync="addrole" size="sm" body-classes="py-0">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create new role</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-12">
              <base-input label="Role name" placeholder="e.g, Manager" v-model="rolemodal.role_name" input-classes="" />
            </div>
            <div class="col-md-12">
              <label class="form-control-label mt-0 pt-0">Permissions</label>
            </div>
            <div class="col-md-12" v-for="permission in permissionData" :key="permission._id">
              <base-checkbox v-model="rolemodal.permissions" :name="permission._id" :value="permission._id" class="mb-1">
                {{permission.permission_name}}
              </base-checkbox>
            </div>
      <!-- <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div> -->
            <!-- <div class="col-lg-12 mt-2">
              <label class="form-control-label">OG Image</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'addrole')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="metamodal.og_image">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteogimage(metamodal)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="metamodal.og_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createrole()"
          type="success"
          id="createrolebtn"
          class="float-center"
        >
          <span>Create Role</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addrole = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit meta popup -->
    <modal :show.sync="editrole" size="sm" body-classes="py-0">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Role</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-12">
              <base-input label="Role name" placeholder="e.g, Manager" v-model="editrolemodal.role_name" input-classes="" />
            </div>
            <div class="col-md-12">
              <label class="form-control-label mt-0 pt-0">Permissions</label>
            </div>
            <div class="col-md-12" v-for="permission in permissionData" :key="permission._id">
              <base-checkbox v-model="editrolemodal.permissions" :name="permission._id" :value="permission._id" class="mb-1">
                {{permission.permission_name}}
              </base-checkbox>
            </div>
            <!-- <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div>
            <div class="col-md-12">
              <base-checkbox v-model="checkboxes.unchecked" class="mb-1">
                Unchecked
              </base-checkbox>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updaterole()"
          type="success"
          id="updaterolebtn"
          class="float-center"
        >
          <span>Save Details</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editrole = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import moment from 'moment';
  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import _ from 'lodash';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addrole: false,
        datanotfound:false,
        editrole: false,
        uploadPercentage:0,
        pagepermission:{},
        role:[],
        permissionData:[],
        regionData:[],
        rolemodal:{
          role_id:'',
          role_name:'',
          role_description:'',
          role_image:'',
          sequence_at:'',
          permissions:[],
          status:'approved',
        },
        editrolemodal:{
          role_id:'',
          role_name:'',
          role_description:'',
          role_image:'',
          sequence_at:'',
          permissions:[],
          status:'approved',
        },
        checkboxes: {
          unchecked: false,
          checked: true,
          uncheckedDisabled: false,
          checkedDisabled: true
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    // validations: {
    //   metamodal: {
    //     offer_code:{
    //       required
    //     },
    //     offer_type:{
    //       required
    //     },
    //     min_amount:{
    //       required
    //     },
    //     expires_on:{
    //       required
    //     },
    //     offer_value:{
    //       required
    //     },
    //   },
    //   editrolemodal: {
    //     offer_code:{
    //       required
    //     },
    //     offer_type:{
    //       required
    //     },
    //     min_amount:{
    //       required
    //     },
    //     expires_on:{
    //       required
    //     },
    //     offer_value:{
    //       required
    //     },
    //   },
    // },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_role',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.role=response.data.details;
        }else{
            this.role=[];
            this.datanotfound=true;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_permission',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.permissionData=response.data.details;
        }
            
      })
    },
    methods: {

      addpopuprole(){
        this.addrole=true;
      },
      editrolepopup(row){
        this.editrolemodal.role_id=row.role_id;
        this.editrolemodal.role_name=row.role_name; 
        this.editrolemodal.role_description=row.role_description; 
        this.editrolemodal.role_image=row.role_image; 
        this.editrolemodal.sequence_at=row.sequence_at;
        this.editrolemodal.status=row.status;
        this.editrolemodal.permissions =  _.map(row.permissions, users => (users._id));
        // row.permissions
        console.log(this.editrolemodal);
        this.editrole=true;
        // }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addrole'){
                this.metamodal.og_image=response.data.imageUrl;
                console.log(this.metamodal.og_image);
              } else if(type=='editrole'){
                this.editrolemodal.og_image=response.data.imageUrl;
                console.log(this.editrolemodal.og_image);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
              }).then((result) => {
               
              })
            }
      },
      createrole(){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_role',{role_name : this.rolemodal.role_name,role_description : this.rolemodal.role_description,role_image : this.rolemodal.role_image,sequence_at : this.rolemodal.sequence_at,permissions : this.rolemodal.permissions,status : this.rolemodal.status},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createrolebtn").classList.remove('btn-primary');
            document.getElementById("createrolebtn").classList.add('btn-success');
            document.getElementById("createrolebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createrolebtn").classList.remove('btn-success');
            document.getElementById("createrolebtn").classList.add('btn-primary');
            document.getElementById("createrolebtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

            //

          })

      },
      updaterole(){
        const querystring = require('querystring');
        axios.post(process.env.VUE_APP_API_ENDPOINT+'update_role',{role_id : this.editrolemodal.role_id,role_name : this.editrolemodal.role_name,role_description : this.editrolemodal.role_description,role_image : this.editrolemodal.role_image,sequence_at : this.editrolemodal.sequence_at,permissions : this.editrolemodal.permissions,status : this.editrolemodal.status},this.auth1)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("updaterolebtn").classList.remove('btn-primary');
          document.getElementById("updaterolebtn").classList.add('btn-success');
          document.getElementById("updaterolebtn").innerHTML='Updated';
          setTimeout(function(){
          document.getElementById("updaterolebtn").classList.remove('btn-success');
          document.getElementById("updaterolebtn").classList.add('btn-primary');
          document.getElementById("updaterolebtn").innerHTML="Update";
          window.location.reload()
        }, 2000);
          //window.location.reload()
        })
      },
      deleterole(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_role',querystring.stringify({role_id :row.role_id}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your file has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      format_datetime(value){
        if(value) {
          return moment.utc(new Date(value)).format("LLL")
        }
      },
      change_offer_type(type){
          if(this.metamodal.offer_type=='FlatOnCart' && type=='add'){
            document.getElementById("max_amount").classList.add('d-none');
          }
          if(this.metamodal.offer_type=='PercentageOnCart' && type=='add'){
            document.getElementById("max_amount").classList.remove('d-none');
          }

          if(this.editrolemodal.offer_type=='FlatOnCart' && type=='edit'){
            document.getElementById("edit_max_amount").classList.add('d-none');
          }
          if(this.editrolemodal.offer_type=='PercentageOnCart' && type=='edit'){
            document.getElementById("edit_max_amount").classList.add('d-none');
          }
      },
      deleteogimage(index){
        this.metamodal.og_image='';
        this.uploadPercentage =0;
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
