import Vue from 'vue';
import Vuex from 'vuex';
import router from './routes/router';
import axios from 'axios';
import _ from 'lodash';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    loggingIn: false,
    loginError: null
  },
  mutations: {
    loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    }
  },
  actions: {
    doLogin({ commit }, loginData) {
      commit('loginStart');

      axios.post(process.env.VUE_APP_API_ENDPOINT+'login_admin',{
        ...loginData
      })
      .then(response => {
        console.log(response);
        if(response.data.success==true){
          if(response.data.user_details[0].user_status=='approved'){
            localStorage.setItem('accessToken',response.data.user_token);
            localStorage.setItem('admin_name', response.data.user_details[0].user_name);
            localStorage.setItem('admin_email', response.data.user_details[0].user_email);
            localStorage.setItem('admin_mobile', response.data.user_details[0].user_mobile);
            localStorage.setItem('admin_img', response.data.user_details[0].user_dp);
            localStorage.setItem('admin_address', response.data.user_details[0].user_address);
            localStorage.setItem('admin_id', response.data.user_details[0].user_id);
            localStorage.setItem('_id', response.data.user_details[0]._id);
            localStorage.setItem('user_role', response.data.user_details[0].user_role);
            // localStorage.setItem('bell_notification', response.data.user_details[0].bell_notification);
            // localStorage.setItem('store_id', response.data.user_details[0].store_id);
             axios.post(process.env.VUE_APP_API_ENDPOINT+'read_role',{ "details" :{"_id":response.data.user_details[0].user_role}})
              .then(response1 => {
                  console.log(response1);
                  let roueterpath =  _.map(response1.data.details[0].permissions, permissions => (permissions.path));
                  console.log(roueterpath);
                  var permissions = JSON.stringify(response1.data.details[0].permissions);
                  localStorage.setItem('role', response1.data.details[0].role_name);
                  localStorage.setItem('permissions',permissions);
                  localStorage.setItem('roueterpath',JSON.stringify(roueterpath));
                  commit('loginStop', null);
                  commit('updateAccessToken',response.data.user_token);
                  router.push('/dashboard');
              })

          }else{
            commit('loginStop','Invalid user');
            commit('updateAccessToken', null);
          }
        }else{
          commit('loginStop','Invalid login details');
          commit('updateAccessToken', null);
        }
        
      })
      .catch(error => {
        commit('loginStop', error.response.data.errorMessage);
        commit('updateAccessToken', null);
      })
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'));
    },
    fetchUserdata({ commit }) {
      commit('UserDetails', localStorage.getItem('UserDetails'));
    },
    logout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('permissions');
      localStorage.removeItem('role');
      localStorage.removeItem('roueterpath');
      localStorage.removeItem('admin_email');
      localStorage.removeItem('admin_name');
      localStorage.removeItem('admin_mobile');
      localStorage.removeItem('admin_img');
      localStorage.removeItem('_id');
      localStorage.removeItem('user_role');
      localStorage.removeItem('admin_address');
      localStorage.removeItem('admin_id');
      localStorage.removeItem('store_id');
      localStorage.removeItem('bell_notification');
      router.push('/login');
    }
  }
})