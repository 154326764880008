<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Students master table
                  </h3>
                </div>
                <div class="col text-right">
                  <base-input class="mb-0 search-input mr-2" input-group-classes="input-group-merge" placeholder="Search User" v-model="search_term" @keyup.enter="searchuser()">
                      <template slot="append"><a><i class="fas fa-search" @click="searchuser()"></i></a></template>
                  </base-input>
                  <base-button type="primary" @click="cleardata()">Clear</base-button>
                  <base-button type="primary" @click="addstudentpop()">Add student</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="userData">
                <template slot="columns"> 
                  <th>S. No.</th>
                  <th>User</th>
                  <th>Admission No</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{ (offset*30)+(index+1) }}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.user_name}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.admission_no}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0"><a :href="'tel:+91'+row.user_phone" class="link-text">{{row.user_phone}}</a></p>
                    </td>
                    <td class="budget">
                      <p class="mb-0"><a :href="'mailto:'+row.user_email" target="_blank" class="link-text">{{row.user_email}}</a></p>
                    </td>
                    <td class="budget">
                      <span v-if="row.user_type=='guestlogin'" class="badge badge-dot badge-success">
                        <i class="bg-success"></i>
                        <span class="status">Guest</span>
                      </span>
                      <span v-if="row.user_type=='login'" class="badge badge-dot badge-primary">
                        <i class="bg-primary"></i>
                        <span class="status">Regular</span>
                      </span>
                    </td>
                    <td class="text-right">
                      <base-dropdown menuOnRight>
                        <base-button slot="title-container" size="sm" type="white" class="shadow-none">
                          <i class="fas fa-ellipsis-v"></i>
                        </base-button>
                            <router-link :to="'/students/viewstudent/'+row._id" class="dropdown-item">Student Details</router-link>
                            <!-- <a :href="'/users/viewuser/'+row._id" class="dropdown-item">View</a> -->
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" v-if="pagepermission.update" @click="openuser(row)">Edit</a>
                            <a class="dropdown-item" v-if="pagepermission.delete" @click="deletestudent(row)">Delete</a>
                      </base-dropdown>
                    </td>
                </template>
              </base-table>
              <!-- no result found -->
              <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No Customer Found.</p>
                    <base-button type="primary" @click="cleardata()" outline>Fetch all</base-button>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="userData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
              <!-- <div class="d-block mb-5 mt-4">
                <base-button
                  type="success"
                  class="float-center m-auto d-block"
                  v-if="loadmorefound" 
                  @click="loadmore()"
                >
                  <span>Load More</span>
                </base-button>
              </div> -->
            </div>
            <div class="card-footer d-flex justify-content-end" v-if="!datanotfound">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <!-- <li class="page-item d-flex mr-2">
                      <p class="mb-0 px-1 mt-1">
                        Page
                      </p>
                      <input
                        id="page_no"
                        size="4"
                        class="mx-1 p-1 text-center"
                        style="border: 1px solid #e9ecff;border-radius: 5px !important;background-color: #e9ecff;width: 4em;"
                        type="number"
                        name="page_no"
                      >
                      <base-button
                        block
                        type="primary"
                        class="px-3 mx-1"
                        title="GO"
                        size="sm"
                        @click="gotopage()"
                      >
                        GO
                      </base-button>
                    </li> -->
                    <li class="page-item">
                      <a
                        class="page-link"
                        @click="previouspage()"
                      > <span aria-hidden="true"><i
                        aria-hidden="true"
                        class="fa fa-angle-left"
                      /></span> </a>
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        @click="nextpage()"
                      > <span aria-hidden="true"><i
                        aria-hidden="true"
                        class="fa fa-angle-right"
                      /></span> </a>
                    </li>
                  </ul>
                </nav> 
              </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="addstudent">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Add student</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-12">
                <base-input label="User Name" placeholder="User Name" v-model="model.user_name" input-classes="" />
                <div
                  v-if="$v.model.user_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
                <base-input label="Admission number" placeholder="Admission number" v-model="model.admission_no" input-classes="" />
                  <div
                  v-if="$v.model.admission_no.$error"
                  class="error h5 text-danger mt--4 pl-1">
                  Field is required.
                 </div>
            </div>
            <div class="col-lg-12">
                <base-input label="User Email" placeholder="User Email" v-model="model.user_email" input-classes="" />
                <div
                  v-if="$v.model.user_email.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input label="User Phone" placeholder="User Phone" v-model="model.user_phone" input-classes="" />
                <div
                  v-if="$v.model.user_phone.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input type="date" label="DOB" placeholder="DOB" v-model="model.user_dob" input-classes="" />
                <div
                  v-if="$v.model.user_dob.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input label="Father's Name" placeholder="Father's Name" v-model="model.father_name" input-classes="" />
            </div>
            <div class="col-lg-12">
                <base-input label="Mother's Name" placeholder="Mother's Name" v-model="model.mother_name" input-classes="" />
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Profile Picture</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'add')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="model.user_dp">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteogimage(model)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="model.user_dp" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">User Gender</label>
                <select v-model="model.user_gender"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>

               </select>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">User Class</label>
              <select class="form-control" v-model="model.user_class">
                <option value="">User Class</option>
                <option v-for="classes in classesData" :key="classes._id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.model.user_class.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">User section</label>
              <select class="form-control" v-model="model.user_section">
                <option value="">User section</option>
                <option v-for="section in sectionData" :key="section._id" :value="section._id">{{section.section_name}}</option>
              </select>
            </div>
            <!-- <div class="col-lg-12">
                <base-input label="Password" placeholder="Password" v-model="model.user_password" input-section="" />
            </div>
            <div class="col-lg-12">
                <base-input label="Confirm Password" placeholder="Confirm Password" v-model="model.cuser_password" input-classes="" />
            </div> -->
            <div class="col-lg-12">
               <label class="form-control-label">Status</label>
                <select v-model="model.user_status"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="approved">active</option>
                    <option value="disapproved">inactive</option>
               </select>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">Result</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="model.result_allowed">
                    <option>enable</option>
                    <option>disable</option>
               </select>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">Tc</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="model.tc_allowed">
                    <option>enable</option>
                    <option>disable</option>
               </select>
            </div>
            <!-- <div class="col-lg-12">
                <span v-if="passworderror" style="color: red">{{passworderror}}</span>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.model.$error" @click="$v.model.$touch();createstudent()"
          id="createstudentbtn"
          type="success"
          class="float-center"
        >
          <span>Save Student</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addstudent = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <modal :show.sync="editstudent">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit student</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-12">
                <base-input label="User Name" placeholder="User Name" v-model="editmodel.user_name" input-classes="" />
                <div
                  v-if="$v.editmodel.user_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
                <base-input label="Admission number" placeholder="Admission number" v-model="editmodel.admission_no" input-classes="" />
                 <div
                  v-if="$v.editmodel.admission_no.$error"
                  class="error h5 text-danger mt--4 pl-1">
                  Field is required.
                 </div>
            </div>
            <div class="col-lg-12">
                <base-input label="User Email" placeholder="User Email" v-model="editmodel.user_email" input-classes="" />
                <div
                  v-if="$v.editmodel.user_email.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input label="User Phone" placeholder="User Phone" v-model="editmodel.user_phone" input-classes="" />
                <div
                  v-if="$v.editmodel.user_phone.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input type="date" label="DOB" placeholder="DOB" v-model="editmodel.user_dob" input-classes="" />
                <div
                  v-if="$v.editmodel.user_dob.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input label="Father's Name" placeholder="Father's Name" v-model="editmodel.father_name" input-classes="" />
            </div>
            <div class="col-lg-12">
                <base-input label="Mother's Name" placeholder="Mother's Name" v-model="editmodel.mother_name" input-classes="" />
            </div>
            
            <div class="col-lg-12">
              <label class="form-control-label">Profile Picture</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'add')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="editmodel.user_dp">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteogimage(editmodel)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="editmodel.user_dp" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">User Gender</label>
                <select v-model="editmodel.user_gender"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
               </select>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">User Class</label>
              <select class="form-control" v-model="editmodel.user_class">
                <option value="">User Class</option>
                <option v-for="classes in classesData" :key="classes._id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.editmodel.user_class.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">User section</label>
              <select class="form-control" v-model="editmodel.user_section">
                <option value="">User section</option>
                <option v-for="section in sectionData" :key="section._id" :value="section._id">{{section.section_name}}</option>
              </select>
            </div>
            <!-- <div class="col-lg-12">
                <base-input label="Password" placeholder="Password" v-model="editmodel.user_password" input-classes="" />
            </div> -->
            <!-- <div class="col-lg-12">
                <base-input label="Confirm Password" placeholder="Confirm Password" v-model="editmodel.cuser_password" input-classes="" />
            </div> -->
            <div class="col-lg-12">
               <label class="form-control-label">Status</label>
                <select v-model="editmodel.user_status"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="approved">active</option>
                    <option value="disapproved">inactive</option>
               </select>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">Result</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editmodel.result_allowed">
                    <option>enable</option>
                    <option>disable</option>
               </select>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">Tc</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editmodel.tc_allowed">
                    <option>enable</option>
                    <option>disable</option>
               </select>
            </div>
            <!-- <div class="col-lg-12">
                <span v-if="passworderror" style="color: red">{{passworderror}}</span>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editmodel.$error" @click="$v.editmodel.$touch();updatestudent()"
          id="updatestudentbtn"
          type="success"
          class="float-center"
        >
          <span>Update Student</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editstudent = false"
        >
          <span>close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import { required, minLength, between } from 'vuelidate/lib/validators';

  export default {
    components: {
      // ActivityFeed,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },

    data() {
      return {
        inputs: {
          file: []
        },
        userData:[],
        classesData:[],
        sectionData:[],
        search_term:'',
        passworderror:'',
        offset:0,
        pagepermission:{},
        loadmorefound:1,
        user_type:localStorage.getItem('user_type'),
        datanotfound:false,
        uploadPercentage:0,
        addstudent:false,
        editstudent:false,
        resetpassword:false,
        model:{
          user_name:'',
          admission_no:'',
          user_dob:'',
          father_name:'',
          mother_name:'',
          result_allowed:'',
          tc_allowed:'',
          user_email:'',
          user_password :'',
          user_dp:'',
          user_status:'approved',
          user_phone:'',
          user_gender:'male',
          user_class :'',
          user_section :'',
          verified :true,
        },
        editmodel:{
          user_id:'',
          user_name:'',
          admission_no:'',
          user_dob:'',
          father_name:'',
          mother_name:'',
          result_allowed:'',
          tc_allowed:'',
          user_email:'',
          user_password :'',
          user_dp:'',
          user_status:'approved',
          user_phone:'',
          user_gender:'male',
          user_class :'',
          user_section :'',
          verified :true,
        },
        checkboxes: {
          unchecked: false,
          checked: true,
          uncheckedDisabled: false,
          checkedDisabled: true
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    validations: {
      model: {
        user_name:{
          required
        },
        admission_no:{
          required
        },
        user_dob:{
          required
        },
        user_email:{
          required
        },
        user_phone:{
          required
        },
        user_class:{
          required
        },
        
      },
      editmodel: {
        user_name:{
          required
        },
        admission_no:{
          required
        },
        user_dob:{
          required
        },
        user_email:{
          required
        },
        user_phone:{
          required
        },
        user_class:{
          required
        },
      },

    },
    mounted () {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{},"offset":"0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
         this.classesData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_section',{ "details" :{},"offset":"0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
         this.sectionData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_student',{ "details" :{"user_type":'student'},"offset":"0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.datanotfound=false;
        this.userData=response.data.details;
        
        }else{
            this.userData=[]; 
            this.datanotfound=true;
        }
            
      })

    },
    methods: {
        openuser(row){
          console.log(row);
          this.editstudent=true;
          this.editmodel =row;
          if(row.user_dob){
          let date=row.user_dob.split('T');
          this.editmodel.user_dob=date[0];
          }
          if(row.user_class){
          this.editmodel.user_class=row.user_class._id;
          }
        },
        addstudentpop(){
          this.addstudent=true;
        },
        hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='add'){
                this.model.user_dp=response.data.imageUrl;
                console.log(this.model.user_dp);
              } else if(type=='edit'){
                this.editmodel.user_dp=response.data.imageUrl;
                console.log(this.editmodel.user_dp);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
              }).then((result) => {
               
              })
            }
      },
       loadmore(){
          this.offset=this.offset+1;
          const querystring = require('querystring');
          console.log(this.auth);
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'get_user_details',{ "details" :{},"offset":this.offset.toString()},this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            //this.orderData=response.data.details;
             for (var i = 0; i < response.data.details.length; i++) {
                    this.userData.push(response.data.details[i]);
                }
            // this.productData.push(response.data.details);
                if(response.data.details.length < 30){
                   this.loadmorefound=0; 
                }
            }else{
                this.loadmorefound=0;

            } 
                
          })
        },
        searchuser(){
        axios
        .post(process.env.VUE_APP_API_ENDPOINT+'search_user',{ "search_term" :this.search_term},this.auth1)
        .then(response => {
          console.log(response);
          if(response.data.details){
          this.datanotfound=false;
          this.userData=response.data.details;
          }else{
            this.datanotfound=true;
            this.userData=[];
          }
              
         })
        },
        createstudent(){
           const querystring = require('querystring');
           if(this.$v.model.$error===false){
           // if(this.model.user_password==this.model.cuser_password){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_student',querystring.stringify({user_name : this.model.user_name,user_phone : this.model.user_phone,user_email : this.model.user_email,admission_no : this.model.admission_no,user_gender : this.model.user_gender,user_class : this.model.user_class,user_dp : this.model.user_dp,user_dob : this.model.user_dob,father_name : this.model.father_name,mother_name : this.model.mother_name,result_allowed : this.model.result_allowed,tc_allowed : this.model.tc_allowed,user_status : this.model.user_status,user_section:this.model.user_section}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstudentbtn").classList.remove('btn-primary');
            document.getElementById("createstudentbtn").classList.add('btn-success');
            document.getElementById("createstudentbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstudentbtn").classList.remove('btn-success');
            document.getElementById("createstudentbtn").classList.add('btn-primary');
            document.getElementById("createstudentbtn").innerHTML="Update";
            //window.location.reload()
          }, 2000);

          })
        }
        // }else{
        //   this.passworderror="Confirm Password not match";
        // }
      },
      updatestudent(){
        const querystring = require('querystring');
        if(this.$v.editmodel.$error===false){
        axios.post(process.env.VUE_APP_API_ENDPOINT+'update_student',querystring.stringify({user_id:this.editmodel.user_id,user_name : this.editmodel.user_name,user_phone : this.editmodel.user_phone,user_email : this.editmodel.user_email,admission_no : this.editmodel.admission_no,user_gender : this.editmodel.user_gender,user_class : this.editmodel.user_class,user_dp : this.editmodel.user_dp,user_dob : this.editmodel.user_dob,father_name : this.editmodel.father_name,mother_name : this.editmodel.mother_name,result_allowed : this.editmodel.result_allowed,tc_allowed : this.editmodel.tc_allowed,user_status : this.editmodel.user_status,user_section:this.editmodel.user_section}),this.auth)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("updatestudentbtn").classList.remove('btn-primary');
          document.getElementById("updatestudentbtn").classList.add('btn-success');
          document.getElementById("updatestudentbtn").innerHTML='Updated';
          setTimeout(function(){
          document.getElementById("updatestudentbtn").classList.remove('btn-success');
          document.getElementById("updatestudentbtn").classList.add('btn-primary');
          document.getElementById("updatestudentbtn").innerHTML="Update";
          window.location.reload()
        }, 2000);
        })
       }
       // }else{
       //    this.passworderror="Confirm Password not match";
       // }
      },
      deletestudent(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_student',querystring.stringify({user_id :row.user_id}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your file has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      savepassword() {
        if(this.model.user_password==this.model.cuser_password){
          this.passworderror='';
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_user_details',{user_id : this.model.user_id,user_phone:this.model.user_phone,user_name : this.model.user_name,user_password:this.model.user_password,flag:1},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
            window.location.reload()
          }, 2000);

           // window.location.reload()

          })
        }else{
          this.passworderror="Confirm Password not match";
        }

        },
      changestatus(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to suspend",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            let status='';
            console.log('result cloud',result)
            if(row.user_status=='approved'){
               status='suspended';
            }else{
               status='approved';
            }
            axios.post(process.env.VUE_APP_API_ENDPOINT+'update_user_details',querystring.stringify({user_email:row.user_email,user_status : status}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Suspended!',
              text: 'User has been suspended',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
    },
    changestatusapproved(row) {
      //referral_code:row.user_id.substr(1, 6)
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to unsuspend",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            let status='';
            console.log('result cloud',result)
            if(row.user_status=='approved'){
               status='suspended';
            }else{
               status='approved';
            }
            axios.post(process.env.VUE_APP_API_ENDPOINT+'update_user_details',querystring.stringify({user_email:row.user_email,user_status : status}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Unsuspended!',
              text: 'User has been unsuspended',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
     },
     previouspage(){
      const querystring = require('querystring');
      //console.log(this.auth);
      if(this.offset>0){
      --this.offset;

     axios
      .post(process.env.VUE_APP_API_ENDPOINT+'get_user_details',{ "details" :{},"offset":this.offset.toString()},this.auth1)
      .then(response => {
        //console.log(response);
        if(response.data.details){
        this.datanotfound=false;
        this.userData=response.data.details;
        }else{
          this.userData=[];
          this.datanotfound=true;
        }
            
      })
    }
    },
    gotopage(){
      const querystring = require('querystring');
      //console.log(this.auth);
      if(document.getElementById("page_no").value){
      this.offset=document.getElementById("page_no").value-1;
     axios
      .post(process.env.VUE_APP_API_ENDPOINT+'get_user_details',{ "details" :{},"offset":this.offset.toString()},this.auth1)
      .then(response => {
        //console.log(response);
        if(response.data.details){
        this.userData=response.data.details;
        this.datanotfound=false;
        }else{
          this.userData=[];
          this.datanotfound=true;
        }
            
      })

      }

    },
    nextpage(){
        this.offset=this.offset+1;
        const querystring = require('querystring');
        //console.log(this.auth);
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'get_user_details',{ "details" :{},"offset":this.offset.toString()},this.auth1)
          .then(response => {
            //console.log(response);
            if(response.data.details){
            this.userData=response.data.details;
            this.datanotfound=false;
            }else{
              this.offset=this.offset-1;
              this.userData=[];
              this.datanotfound=true;
            }
                
          })

      },
      cleardata(){
        this.offset=0;
        this.search_term='';
        this.status='';
         axios
           .post(process.env.VUE_APP_API_ENDPOINT+'get_user_details',{ "details" :{},"offset":"0"},this.auth1).then(response => {
            console.log(response);
            if(response.data.details){
            this.userData=response.data.details;
            this.datanotfound=false;
            }else{
              this.userData=[];
              this.datanotfound=true;
            }
       })  
      },
   },
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
