<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <!-- <div class="col-lg-6">

              <card gradient="info" class="border-0">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0 text-white">Total traffic</h5>
                    <span class="h2 font-weight-bold mb-0 text-white">350,897</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="ni ni-active-40"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                  <span class="text-white mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                  <span class="text-nowrap text-light">Since last month</span>
                </p>
              </card>
            </div>
            <div class="col-lg-6">

              <card gradient="danger" class="border-0">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0 text-white">Performance</h5>
                    <span class="h2 font-weight-bold mb-0 text-white">49,65%</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="ni ni-spaceship"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                  <span class="text-white mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                  <span class="text-nowrap text-light">Since last month</span>
                </p>
              </card>
            </div> -->
          </div>
          <card class="">
            <div slot="header" class="row align-items-center">
              <div class="col-12">
                <h3 class="mb-0">Admin Details</h3>
              </div>
              <div class="col-4 text-right">
                <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
              </div>
            </div>
            <form @submit.prevent="updateProfile">
              <div class="">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="time"
                      label="Opening time"
                      v-model="model.opening_time"
                      placeholder=""
                    >
                    </base-input>
                  </div>
                  
                  <div class="col-lg-6">
                    <base-input
                      type="time"
                      label="Closing time"
                      v-model="model.closing_time"
                      placeholder="e.g, 5000"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <!-- <div class="col-lg-6">
                    <base-input
                      type="number"
                      label="COD min amount limit"
                      v-model="model.min_cod_amount"
                      placeholder="e.g,100"
                    >
                    </base-input>
                  </div> -->
                  
                  <div class="col-lg-6">
                    <base-input
                      type="number"
                      label="COD max amount limit"
                      v-model="model.max_cod_amount"
                      placeholder="e.g, 5000"
                    >
                    </base-input>
                  </div>

                  <div class="col-lg-6">
                    <base-input
                      type="number"
                      label="State code"
                      v-model="model.state_code"
                      placeholder="e.g, 09"
                    >
                    </base-input>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Invoice Address"
                      v-model="model.invoice_address"
                      placeholder="e.g, main road ,delhi"
                    >
                    </base-input>
                  </div>

                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Company Name"
                      v-model="model.company_name"
                      placeholder="e.g, Bakeneto"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Company Email"
                      v-model="model.admin_email"
                      placeholder="e.g, info@bakeneto.com"
                    >
                    </base-input>
                  </div>

                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Website rating"
                      v-model="model.website_rating"
                      placeholder="e.g, 4.5"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Website reviews"
                      v-model="model.website_reviews"
                      placeholder="e.g, Best site forever"
                    >
                    </base-input>
                  </div>

                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Satisfied Customers"
                      v-model="model.satisfied_customers"
                      placeholder="e.g, 99% Satisfied Customers"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Satisfied Customers Description"
                      v-model="model.satisfied_customers_description"
                      placeholder="e.g, Based upon the ratings on social media"
                    >
                    </base-input>
                  </div>

                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="invoice_contact"
                      v-model="model.invoice_contact"
                      placeholder="e.g, 9999999999"
                    >
                    </base-input>
                  </div>
                </div>
             

                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="GST Number"
                      v-model="model.gstin"
                      placeholder="e.g, 09...."
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">invoice_logo</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'invoice_logo')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div>
                        <img :src="model.invoice_logo" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-control-label">authorized_signature</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'authorized_signature')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div>
                        <img :src="model.authorized_signature" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">invoice_stamp</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'invoice_stamp')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div>
                        <img :src="model.invoice_stamp" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                  </div>
                  <!-- <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Password"
                      placeholder="e.g, aaa@89"
                      v-model="model.admin_password"
                    >
                    </base-input>
                  </div> -->
                  <!-- <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Last Name"
                      placeholder="Last Name"
                    >
                    </base-input>
                  </div> -->
                  <div class="col-xl-12">
                    <base-button type="primary" size="lg" id="saveuserbtn" @click="updateassets()">Save Details</base-button>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import FileInput from '@/components/Inputs/FileInput'
  import { Tabs } from '@/components';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  export default {
    name: 'form-elements',
    components: {
      FileInput,
      Tabs
    },
    data() {
      return {
        uploadPercentage:0,
        inputs: {
          file: []
        },
        model:{
          assets_id:"",
          invoice_address:"",
          authorized_signature:"",
          invoice_logo:"",
          invoice_contact:"",
          invoice_stamp:"",
          min_cod_amount:0,
          max_cod_amount:0,
          state_code :'',
          opening_time:'',
          closing_time:'',
          admin_email :'',
          company_name :'',
          website_rating :'',
          website_reviews :'',
          satisfied_customers:'',
          satisfied_customers_description:'',
          gstin :'',
          created_by:localStorage.getItem('_id'),
        },
        checkboxes: {
          unchecked: false,
          checked: true,
          uncheckedDisabled: false,
          checkedDisabled: true
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted () {
      const querystring = require('querystring');
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_assets',{ "details" :{"created_by":localStorage.getItem('_id')}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.model=response.data.details[0];
        }
            
      })
    },
    methods: {
      filesChange(files) {
        this.inputs.file = files
      },
      hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                 if(type=='invoice_logo'){
                  this.model.invoice_logo=response.data.imageUrl;
                  console.log(this.model.invoice_logo);
                }else if(type=='authorized_signature'){
                  this.model.authorized_signature=response.data.imageUrl;
                  console.log(this.model.authorized_signature);
                }else if(type=='invoice_stamp'){
                  this.model.invoice_stamp=response.data.imageUrl;
                  console.log(this.model.invoice_stamp);
                }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

        },
      updateassets(){
            document.getElementById("saveuserbtn").innerHTML='Loading...';
            const querystring = require('querystring');
            if(this.model.assets_id){
            axios.post(process.env.VUE_APP_API_ENDPOINT+'update_assets',querystring.stringify({assets_id:this.model.assets_id,opening_time: this.model.opening_time,closing_time: this.model.closing_time,min_cod_amount: this.model.min_cod_amount,max_cod_amount: this.model.max_cod_amount,state_code:this.model.state_code,invoice_address: this.model.invoice_address,authorized_signature: this.model.authorized_signature,invoice_logo: this.model.invoice_logo,invoice_contact: this.model.invoice_contact,invoice_stamp: this.model.invoice_stamp,created_by: this.model.created_by,admin_email: this.model.admin_email,company_name: this.model.company_name,gstin: this.model.gstin,website_rating : this.model.website_rating,website_reviews : this.model.website_reviews,satisfied_customers :this.model.satisfied_customers,satisfied_customers_description: this.model.satisfied_customers_description}),this.auth)
          .then(response => {
            
              document.getElementById("saveuserbtn").classList.remove('btn-primary');
              document.getElementById("saveuserbtn").classList.add('btn-success');
              document.getElementById("saveuserbtn").innerHTML='Updated';
              setTimeout(function(){
              document.getElementById("saveuserbtn").classList.remove('btn-success');
              document.getElementById("saveuserbtn").classList.add('btn-primary');
              document.getElementById("saveuserbtn").innerHTML="Update business";
              window.location.reload()
            }, 2000);
            }) 
        }else{

          axios.post(process.env.VUE_APP_API_ENDPOINT+'create_assets',querystring.stringify({opening_time: this.model.opening_time,closing_time: this.model.closing_time,min_cod_amount: this.model.min_cod_amount,max_cod_amount: this.model.max_cod_amount,state_code:this.model.state_code,invoice_address: this.model.invoice_address,authorized_signature: this.model.authorized_signature,invoice_logo: this.model.invoice_logo,invoice_contact: this.model.invoice_contact,invoice_stamp: this.model.invoice_stamp,created_by: this.model.created_by,user_oid:localStorage.getItem('_id'),admin_email: this.model.admin_email,company_name: this.model.company_name,gstin: this.model.gstin,website_rating : this.model.website_rating,website_reviews : this.model.website_reviews,satisfied_customers :this.model.satisfied_customers,satisfied_customers_description: this.model.satisfied_customers_description}),this.auth)
          .then(response => {
            
              document.getElementById("saveuserbtn").classList.remove('btn-primary');
              document.getElementById("saveuserbtn").classList.add('btn-success');
              document.getElementById("saveuserbtn").innerHTML='Updated';
              setTimeout(function(){
              document.getElementById("saveuserbtn").classList.remove('btn-success');
              document.getElementById("saveuserbtn").classList.add('btn-primary');
              document.getElementById("saveuserbtn").innerHTML="Update business";
              window.location.reload()
            }, 2000);
            }) 

        }
    },

  }
}
</script>
<style>
  .profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
  }
</style>
