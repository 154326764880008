<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Manage content of Beyond Academics Page
                  </h3>
                </div>
                <div class="col text-right">
                  
                </div>
              </div>
            </div>  
            <div class="card border shadow-sm">
              <div class="card-header bg-secondary">Sports Content</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <base-input
                      label="Sports Heading"
                      v-model="beyond_academic.heading"
                      placeholder="Appears in Sports section"
                      input-classes=""
                    />
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">Left description</label>
                    <textarea
                      class="form-control"
                      v-model="beyond_academic.left_description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">Right description</label>
                    <textarea
                      class="form-control"
                      v-model="beyond_academic.right_description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                  <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(sports, index) in beyond_academic.sports"
                    :key="index"
                  >
                    <base-input
                      :label="'sports ' + (index + 1) + ' name'"
                      v-model="sports.name"
                      placeholder="Appears in beyond academic section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >Sports {{ index + 1 }} image</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'Sports'+index)"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="sports.image">
                      <img
                        :src="sports.image"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                </div>
                <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(sportslider, index) in beyond_academic.sports_slider"
                    :key="index"
                  >
                    <base-input
                      :label="'sports slider ' + (index + 1) + ' name'"
                      v-model="sportslider.name"
                      placeholder="Appears in beyond academic section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >Sports slider {{ index + 1 }} image</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'sportslider'+index)"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="sportslider.image">
                      <img
                        :src="sportslider.image"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                    <label class="form-control-label"
                      >sports slider {{ index + 1 }} description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="sportslider.description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                </div>
                  
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >Creativity short description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="beyond_academic.creativity_short_description"
                      rows="2"
                      placeholder="Appears in about us section"
                    ></textarea>
                  </div>
                  
                  <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(creativity, index) in beyond_academic.creativities"
                    :key="index"
                  >
                    <base-input
                      :label="'creativities ' + (index + 1) + ' name'"
                      v-model="creativity.name"
                      placeholder="Appears in beyond academic section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >creativity {{ index + 1 }} image</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'creativity'+index)"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="creativity.image">
                      <img
                        :src="creativity.image"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                    <label class="form-control-label"
                      >Creativity {{ index + 1 }} description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="creativity.description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >Houses Short description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="beyond_academic.houses_short_decription"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                  <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(house, index) in beyond_academic.houses"
                    :key="index"
                  >
                    <base-input
                      :label="'house ' + (index + 1) + ' name'"
                      v-model="house.name"
                      placeholder="Appears in beyond academic section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >House {{ index + 1 }} image</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'house'+index)"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="house.image">
                      <img
                        :src="house.image"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                    <label class="form-control-label"
                      >House {{ index + 1 }} description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="house.description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">trips Short description</label>
                    <textarea
                      class="form-control"
                      v-model="beyond_academic.trips_short_description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">COVID Safety description</label>
                    <textarea
                      class="form-control"
                      v-model="beyond_academic.COVID_Safety_description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >COVID Safety image</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'COVID_Safety_image')"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="beyond_academic.COVID_Safety_image">
                      <img
                        :src="beyond_academic.COVID_Safety_image"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">Community Service description</label>
                    <textarea
                      class="form-control"
                      v-model="beyond_academic.community_service_description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >community service image 1</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'community_service_image1')"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="beyond_academic.community_service_images[0]">
                      <img
                        :src="beyond_academic.community_service_images[0]"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                   <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >community service image 2</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'community_service_image2')"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="beyond_academic.community_service_images[1]">
                      <img
                        :src="beyond_academic.community_service_images[1]"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                   <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >community service image 3</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'community_service_image3')"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="beyond_academic.community_service_images[2]">
                      <img
                        :src="beyond_academic.community_service_images[2]"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                   <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >community service image 4</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'community_service_image4')"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="beyond_academic.community_service_images[3]">
                      <img
                        :src="beyond_academic.community_service_images[3]"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                </div>
                <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(service, index) in beyond_academic.services"
                    :key="index"
                  >
                    <base-input
                      :label="'service ' + (index + 1) + ' name'"
                      v-model="service.name"
                      placeholder="Appears in beyond academic section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >service {{ index + 1 }} description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="service.description"
                      rows="2"
                      placeholder="Appears in beyond academic section"
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-12">
                    <base-button
                      @click="savebeyondacademic()"
                      id="savebeyondacademiccontent"
                      type="primary"
                      class="float-center"
                      block
                    >
                      <span>Save</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  <div class="row">
                    <div class="col align-self-center">
                      <span>Events</span>
                    </div>
                    <div class="col text-right">
                      <base-button
                        @click="addboardmemberpopup()"
                        id="addboardmemberpopup"
                        type="primary"
                        class="float-center"
                        outline
                      >
                        <i class="fas fa-plus mr-2"></i> Add new event
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card border" v-for="events in eventData" :key="events._id">
                        <div class="row">
                          <div class="col-lg-12">
                            <ul class="list-group list-group-flush px-2">
                              <li class="list-group-item h2">{{events.event_name}}</li>
                            </ul>
                            <div class="card-body pb-3 pt-0">
                              <p class="card-text mb-4">{{events.event_long_description}}</p>
                              <base-button  type="primary" size="sm" @click="editeventpopup(events)" >Edit</base-button>
                              <base-button type="danger" size="sm" @click="deleteeventpopup(events)">Delete</base-button>
                            </div>
                            <div class="card-footer">
                              <div class="row">
                                <div class="col-lg-12 col-12 mb-3 px-2">
                                  <template>
                                    <div :id="'app'">
                                      <!-- <CoolLightBox 
                                        :items="items" 
                                        :index="index"
                                        @close="index = null">
                                      </CoolLightBox> -->

                                      <div class="images-wrapper row mx-0">
                                        <div class="image-wrapper position-relative" v-for="(image, imageIndex) in events.images" :key="imageIndex">
                                          <div
                                            class="gallery_image"
                                            @click="index = imageIndex"
                                            :style="{ backgroundImage: 'url(' + image + ')' }"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <!-- <div class="col-md-12 px-4">
                                  <form>
                                    <div class="custom-file">
                                        <base-input type="file" inputClasses="custom-file-input" id="customFileLang" lang="en"></base-input>
                                        <label class="custom-file-label" for="customFileLang">Upload photos</label>
                                    </div>
                                  </form>
                                </div> -->
                              </div>
                              <div class="row">
                                <div class="col-lg-12 col-12 mb-3 px-2">
                                  <template>
                                    <div :id="'app'">
                                      <!-- <CoolLightBox 
                                        :items="items" 
                                        :index="index"
                                        @close="index = null">
                                      </CoolLightBox> -->

                                      <div class="row mx-0">
                                        <div class="image-wrapper col-md-4 position-relative" v-for="(video, imageIndex) in events.videos" :key="imageIndex">
                                          <video width="320" height="240" controls class="img-fluid">
                                            <source :src="video" type="video/mp4">
                                            <!-- <source src="movie.ogg" type="video/ogg"> -->
                                          </video>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <!-- <div class="col-md-12 px-4">
                                  <form>
                                    <div class="custom-file">
                                        <base-input type="file" inputClasses="custom-file-input" id="customFileLang" lang="en"></base-input>
                                        <label class="custom-file-label" for="customFileLang">Upload photos</label>
                                    </div>
                                  </form>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Traditional Touch
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card border" v-for="traditions in traditionData" :key="traditions._id">
                        <div class="row px-3">
                          <div class="col-lg-3 py-3">
                            <img alt="Image placeholder" class="img-fluid border p-1 rounded shadow-sm" :src="traditions.traditional_image">
                            <!-- <p class="mt-1 mb-0 font-weight-bold">{{traditions.traditional_name}}</p>
                            <p class="mb-0">({{board_of_governor.designation}})</p> -->
                          </div>
                          <div class="col-lg-9">
                            <div class="card-body p-3">
                              <p class="mt-1 mb-0 font-weight-bold">{{traditions.traditional_name}}</p>
                              <p class="card-text mb-4">{{traditions.traditional_description}}</p>
                              <base-button type="primary" size="sm" @click="edittraditional(traditions)" >Edit Details</base-button>
                              <base-button type="danger" size="sm" @click="deletetraditional(traditions)">Delete</base-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" id="edittraditionaltouch">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input label="Title" v-model="traditional.traditional_name" placeholder="Title" input-classes="" />
                      <textarea class="form-control mb-2" rows="5" placeholder="Short description" v-model="traditional.traditional_description"></textarea>
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">Image</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative cursor-pointer"
                        name="img"
                        @change="hadleimage($event,'traditional')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="traditional.traditional_image">
                        <img :src="traditional.traditional_image" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                    </div>
                  </div>
                  <div class="card border shadow-sm">
                    <div class="row">
                      <div class="col-lg-12">
                        <base-button
                          @click="savetraditionaltouch()"
                          id="savetraditional"
                          type="primary"
                          class="float-center"
                          block
                          size="lg"
                        >
                          <span>Save</span>
                        </base-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Gallery
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12 col-12 mb-3 px-2">
                      <template>
                        <div :id="'app'">
                          <!-- <CoolLightBox 
                            :items="items" 
                            :index="index"
                            @close="index = null">
                          </CoolLightBox> -->

                          <div class="images-wrapper row mx-0">
                            <div class="image-wrapper position-relative" v-for="(image, imageIndex) in gallary" :key="imageIndex">
                              <base-button type="danger" @click="deletefile(imageIndex,'gallery')" size="sm" class="position-absolute right-0 mr--2 mt--2 btn-round"><i class="fas fa-trash"></i></base-button>
                              <div
                                class="gallery_image"
                                @click="index = imageIndex"
                                :style="{ backgroundImage: 'url(' + image + ')' }"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="col-md-12 px-4">
                      <form>
                        <div class="custom-file">
                            <input type="file" ref="filec" inputClasses="custom-file-input" id="customFileLang" multiple="multiple" @change="handlemultipleimage($event,'gallery','filec')"></input>
                            <label class="custom-file-label" for="customFileLang">Upload photos</label>
                        </div>
                        <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
                      </form>
                    </div>
                    <div class="col-lg-12 col-12 mb-3 px-2">
                      <template>
                        <div :id="'app'">
                          <!-- <CoolLightBox 
                            :items="items" 
                            :index="index"
                            @close="index = null">
                          </CoolLightBox> -->

                          <div class="row mx-0">
                            <div class="image-wrapper position-relative col-md-4" v-for="(video,imageIndex) in videogallary" :key="imageIndex">
                              <base-button type="danger" @click="deletevideofile(imageIndex,'videogallery')" size="sm" class="position-absolute right-0 mr--2 mt--2 btn-round"><i class="fas fa-trash"></i></base-button>
                              <video width="320" height="240" controls class="img-fluid">
                                <source :src="video" type="video/mp4">
                                <!-- <source src="movie.ogg" type="video/ogg"> -->
                              </video>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="col-md-12 px-4">
                      <form>
                        <div class="custom-file">
                            <input type="file" ref="filed" accept="video/*" id="customFileLangvideo" multiple="multiple" @change="handlemultipleimage($event,'videogallery','filed')"></input>
                            <label class="custom-file-label" for="customFileLangvideo">Upload video</label>
                        </div>
                        <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
                      </form>
                    </div>
                    <div class="col-lg-12 mt-3">
                      <base-button
                        @click="savegallary()"
                        id="savegallary"
                        type="primary"
                        class="float-center"
                        block
                        size="lg"
                      >
                        <span>Save Gallery</span>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- create event popup -->
    <modal :show.sync="addboardmember" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create new event</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input label="Event name" v-model="eventmodel.event_name" placeholder="Event name" input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Event Short description</label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="10" v-model="eventmodel.event_short_description"></textarea>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Event long description</label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="10" v-model="eventmodel.event_long_description"></textarea>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Images</label>
              <input
                type="file"
                ref="file"
                accept="image/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'add','file')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-6 position-relative mt-3" v-for="(image,index) in eventmodel.images" :key="image">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletefile(index,'add')"><i class="fas fa-trash"></i></base-button>
                  <img :src="image" class="img-fluid rounded shadow p-1 border">
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Videos</label>
              <input
                type="file"
                ref="filev"
                accept="video/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'addvideo','filev')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-6 position-relative mt-3" v-for="(video,index) in eventmodel.videos" :key="video">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletevideofile(index,'addvideo')"><i class="fas fa-trash"></i></base-button>
                  <video width="320" height="240" controls class="img-fluid">
                    <source :src="video" type="video/mp4">
                    <!-- <source src="movie.ogg" type="video/ogg"> -->
                  </video>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createevent()"
          type="success"
          id="createeventbtn"
          class="float-center"
        >
          <span>Create Event</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addboardmember = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit event popup -->
    <modal :show.sync="editboardmember" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Event</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input label="Event name" v-model="editeventmodel.event_name" placeholder="Event name" input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Event Short description </label>
              <textarea class="form-control" rows="10" v-model="editeventmodel.event_short_description"></textarea>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Event long description</label>
              <textarea class="form-control" rows="10" v-model="editeventmodel.event_long_description"></textarea>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Images</label>
              <input
                type="file"
                ref="fileb"
                accept="image/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'edit','fileb')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-6 position-relative mt-3" v-for="(image,index) in editeventmodel.images" :key="image">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletefile(index,'edit')"><i class="fas fa-trash"></i></base-button>
                  <img :src="image" class="img-fluid rounded shadow p-1 border">
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Videos</label>
              <input
                type="file"
                ref="fileev"
                accept="video/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'editvideo','fileev')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-6 position-relative mt-3" v-for="(video,index) in editeventmodel.videos" :key="video">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletevideofile(index,'editvideo')"><i class="fas fa-trash"></i></base-button>
                  <video width="320" height="240" controls class="img-fluid">
                    <source :src="video" type="video/mp4">
                    <!-- <source src="movie.ogg" type="video/ogg"> -->
                  </video>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updateevent()"
          type="success"
          id="updateeventbtn"
          class="float-center"
        >
          <span>Save</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editboardmember = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  import CoolLightBox from 'vue-cool-lightbox';
  import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      CoolLightBox,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      VueEditor
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addboardmember: false,
        datanotfound:false,
        editboardmember: false,
        addstore: false,
        editstore: false,
        addregion: false,
        editregion: false,
        addarea: false,
        editarea: false,
        datanotfound:false,
        uploadPercentage:0,
        multipleuploadPercentage:0,
        pagepermission:{},
        eventData:[],
        traditionData:[],
        beyond_academic:[],
        gallary :[],
        videogallary:[],
        selectstore:'',
        selectpin:'',
        customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link", "image", "video"],
          ["clean"]],
        eventmodel:{
          event_id : '',
          event_name: '',
          event_short_description : '',
          event_long_description : '',
          images :[],
          videos :[],
          status :"approved",
        },
        model:{},
        editeventmodel:{
          event_id : '',
          event_name: '',
          event_short_description : '',
          event_long_description : '',
          images :[],
          videos :[],
          status :"approved",
        },
        traditional :{
          traditional_id : '',
          traditional_name: '',
          traditional_description : '',
          traditional_image : '',
          status : "approved",
        },

        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        items: [
        '/img/theme/team-1.jpg',
        '/img/theme/team-2.jpg',
        '/img/theme/team-3.jpg',
        '/img/theme/team-4.jpg',
        '/img/theme/team-5.jpg',
        '/img/theme/team-1.jpg',
        ],
      };
    },
    validations: {
      // model: {
      //   store_name:{
      //     required
      //   },
      //   store_img:{
      //     required
      //   },
      //   shipping_charge:{
      //     required
      //   },
      // }

    },
    mounted() {
      
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_event',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.eventData=response.data.details;
        }   
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_traditional_touch',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.traditionData=response.data.details;
        }
            
      })
      
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_beyond_academic',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.beyond_academic=response.data.details[0];
        this.gallary=this.beyond_academic.gallary;
        this.videogallary=this.beyond_academic.videogallary;
        }
            
      })
    },
    methods: {

      async handlemultipleimage(event,type,uploatype) {
            // Reference to the DOM input element
               console.log(type);
              // document.getElementById("assignmentbtn").classList.add('d-none');
              // document.getElementById("addassignmentinvalid").classList.remove('d-none');
              // console.log(event);
              this.multipleuploadPercentage=0;
              if(uploatype=='file'){
                for( var i = 0; i < this.$refs.file.files.length; i++ ){
                    let file = this.$refs.file.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='fileb'){
                for( var i = 0; i < this.$refs.fileb.files.length; i++ ){
                    let file = this.$refs.fileb.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='filec'){
                for( var i = 0; i < this.$refs.filec.files.length; i++ ){
                    let file = this.$refs.filec.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='filed'){
                for( var i = 0; i < this.$refs.filed.files.length; i++ ){
                    let file = this.$refs.filed.files[i];
                    console.log(type);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='filev'){
                for( var i = 0; i < this.$refs.filev.files.length; i++ ){
                    let file = this.$refs.filev.files[i];
                    console.log(type);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='fileev'){
                for( var i = 0; i < this.$refs.fileev.files.length; i++ ){
                    let file = this.$refs.fileev.files[i];
                    console.log(type);
                    await this.upload(file,type);
                    
                    
                }
              }

      },
      upload(file,type){
        // console.log(file);
        let fd = new FormData();
        fd.append('image',file,file.name);
        axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.multipleuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.multipleuploadPercentage)}.bind(this)}).then(response => {
          // console.log(response);
          if(type=='add'){
            this.eventmodel.images.push(response.data.imageUrl);
          }
          if(type=='edit'){
          this.editeventmodel.images.push(response.data.imageUrl);

          }
          if(type=='addvideo'){
            this.eventmodel.videos.push(response.data.imageUrl);
          }
          if(type=='editvideo'){
          this.editeventmodel.videos.push(response.data.imageUrl);

          }
          if(type=='gallery'){
          this.gallary.push(response.data.imageUrl);

          }
          if(type=='videogallery'){
          this.videogallary.push(response.data.imageUrl);
           console.log(this.videogallary);
          }
          // console.log(this.imagearr);
          
       })

      },
      deletefile(index,type){
          if(type=='add'){
            this.eventmodel.images.splice(index,1);
          }
          if(type=='edit'){
            this.editeventmodel.images.splice(index,1);
          }
          if(type=='gallery'){
            this.gallary.splice(index,1);
          }
      },
      deletevideofile(index,type){
          if(type=='addvideo'){
            this.eventmodel.videos.splice(index,1);
          }
          if(type=='editvideo'){
            this.editeventmodel.videos.splice(index,1);
          }
          if(type=='videogallery'){
            this.videogallary.splice(index,1);
          }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              this.uploadPercentage=0;
              if(type=='traditional'){
                this.traditional.traditional_image=response.data.imageUrl;
                console.log(this.traditional.traditional_image);
              }if(type=='image_1'){
                this.model.image_1=response.data.imageUrl;
                console.log(this.model.image_1);
              }if(type=='image_2'){
                this.model.image_2=response.data.imageUrl;
                console.log(this.model.image_2);
              }if(type=='block_1_icon'){
                this.model.block_1_icon=response.data.imageUrl;
                console.log(this.model.block_1_icon);
              }if(type=='block_2_icon'){
                this.model.block_2_icon=response.data.imageUrl;
                console.log(this.model.block_2_icon);
              }if(type=='block_3_icon'){
                this.model.block_3_icon=response.data.imageUrl;
                console.log(this.model.block_3_icon);
              }
              if(type=='Sports0'){
                this.beyond_academic.sports[0].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports[0].image);
              }
              if(type=='Sports1'){
                this.beyond_academic.sports[1].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports[1].image);
              }
              if(type=='Sports2'){
                this.beyond_academic.sports[2].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports[2].image);
              }
              if(type=='Sports3'){
                this.beyond_academic.sports[3].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports[3].image);
              }
              if(type=='Sports4'){
                this.beyond_academic.sports[4].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports[4].image);
              }
              if(type=='Sports5'){
                this.beyond_academic.sports[5].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports[5].image);
              }
              if(type=='sportslider0'){
                this.beyond_academic.sports_slider[0].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports_slider[0].image);
              }
              if(type=='sportslider1'){
                this.beyond_academic.sports_slider[1].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports_slider[1].image);
              }
              if(type=='sportslider2'){
                this.beyond_academic.sports_slider[2].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports_slider[2].image);
              }
              if(type=='sportslider3'){
                this.beyond_academic.sports_slider[3].image=response.data.imageUrl;
                console.log(this.beyond_academic.sports_slider[3].image);
              }
               if(type=='creativity0'){
                this.beyond_academic.creativities[0].image=response.data.imageUrl;
                console.log(this.beyond_academic.creativities[0].image);
              }
              if(type=='creativity1'){
                this.beyond_academic.creativities[1].image=response.data.imageUrl;
                console.log(this.beyond_academic.creativities[1].image);
              }
              if(type=='creativity2'){
                this.beyond_academic.creativities[2].image=response.data.imageUrl;
                console.log(this.beyond_academic.creativities[2].image);
              }
              if(type=='creativity3'){
                this.beyond_academic.creativities[3].image=response.data.imageUrl;
                console.log(this.beyond_academic.creativities[3].image);
              }
               if(type=='house0'){
                this.beyond_academic.houses[0].image=response.data.imageUrl;
                console.log(this.beyond_academic.houses[0].image);
              }
              if(type=='house1'){
                this.beyond_academic.houses[1].image=response.data.imageUrl;
                console.log(this.beyond_academic.houses[1].image);
              }
              if(type=='house2'){
                this.beyond_academic.houses[2].image=response.data.imageUrl;
                console.log(this.beyond_academic.houses[2].image);
              }
              if(type=='house3'){
                this.beyond_academic.houses[3].image=response.data.imageUrl;
                console.log(this.beyond_academic.houses[3].image);
              }
              if(type=='COVID_Safety_image'){
                this.beyond_academic.COVID_Safety_image=response.data.imageUrl;
                console.log(this.beyond_academic.COVID_Safety_image);
              }
              if(type=='community_service_image1'){
                this.beyond_academic.community_service_images[0]=response.data.imageUrl;
                console.log(this.beyond_academic.community_service_images[0]);
              }
              if(type=='community_service_image2'){
                this.beyond_academic.community_service_images[1]=response.data.imageUrl;
                console.log(this.beyond_academic.community_service_images[1]);
              }
              if(type=='community_service_image3'){
                this.beyond_academic.community_service_images[2]=response.data.imageUrl;
                console.log(this.beyond_academic.community_service_images[2]);
              }
              if(type=='community_service_image4'){
                this.beyond_academic.community_service_images[3]=response.data.imageUrl;
                console.log(this.beyond_academic.community_service_images[3]);
              }

              //else{
              //   this.storemodal.store_img=response.data.imageUrl;
              //   console.log(this.storemodal.store_img);
              // }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }
      },
      savetraditionaltouch(){
        const querystring = require('querystring');
           if(!this.traditional.traditional_id){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_traditional_touch',this.traditional,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savetraditional").classList.remove('btn-primary');
            document.getElementById("savetraditional").classList.add('btn-success');
            document.getElementById("savetraditional").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savetraditional").classList.remove('btn-success');
            document.getElementById("savetraditional").classList.add('btn-primary');
            document.getElementById("savetraditional").innerHTML="Update";
            window.location.reload()
          }, 2000);

            //

          })
        }else{

          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_traditional_touch',this.traditional,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savetraditional").classList.remove('btn-primary');
            document.getElementById("savetraditional").classList.add('btn-success');
            document.getElementById("savetraditional").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savetraditional").classList.remove('btn-success');
            document.getElementById("savetraditional").classList.add('btn-primary');
            document.getElementById("savetraditional").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })

        }
      },
      savegallary(){
        const querystring = require('querystring');
        if(!this.beyond_academic.beyond_academic_id){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_beyond_academic',{gallary:this.gallary,videogallary:this.videogallary},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savegallary").classList.remove('btn-primary');
            document.getElementById("savegallary").classList.add('btn-success');
            document.getElementById("savegallary").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savegallary").classList.remove('btn-success');
            document.getElementById("savegallary").classList.add('btn-primary');
            document.getElementById("savegallary").innerHTML="Update";
            window.location.reload()
          }, 2000);

            

          })
        }else{
          this.beyond_academic.gallery=this.gallary;
          this.beyond_academic.videogallary=this.videogallary;
          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_beyond_academic',this.beyond_academic,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savegallary").classList.remove('btn-primary');
            document.getElementById("savegallary").classList.add('btn-success');
            document.getElementById("savegallary").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savegallary").classList.remove('btn-success');
            document.getElementById("savegallary").classList.add('btn-primary');
            document.getElementById("savegallary").innerHTML="Update";
            window.location.reload()
          }, 2000);

            

          })

        }
      },
      savebeyondacademic(){
        const querystring = require('querystring');

          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_beyond_academic',this.beyond_academic,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savebeyondacademiccontent").classList.remove('btn-primary');
            document.getElementById("savebeyondacademiccontent").classList.add('btn-success');
            document.getElementById("savebeyondacademiccontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savebeyondacademiccontent").classList.remove('btn-success');
            document.getElementById("savebeyondacademiccontent").classList.add('btn-primary');
            document.getElementById("savebeyondacademiccontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

            

          })
      },
      createevent(){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_event',this.eventmodel,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createeventbtn").classList.remove('btn-primary');
            document.getElementById("createeventbtn").classList.add('btn-success');
            document.getElementById("createeventbtn").innerHTML='Created';
            setTimeout(function(){
            document.getElementById("createeventbtn").classList.remove('btn-success');
            document.getElementById("createeventbtn").classList.add('btn-primary');
            document.getElementById("createeventbtn").innerHTML="Save";
            window.location.reload()
          }, 2000);

          })
      },
      updateevent(){
        const querystring = require('querystring');
          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_event',this.editeventmodel,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateeventbtn").classList.remove('btn-primary');
            document.getElementById("updateeventbtn").classList.add('btn-success');
            document.getElementById("updateeventbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateeventbtn").classList.remove('btn-success');
            document.getElementById("updateeventbtn").classList.add('btn-primary');
            document.getElementById("updateeventbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
      },
      updateestore(){
        if(this.$v.editstoremodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_store_details',querystring.stringify({store_id : this.editstoremodal.store_id,store_name : this.editstoremodal.store_name,store_img:this.editstoremodal.store_img,store_status:this.editstoremodal.store_status,created_by:this.editstoremodal.created_by,shipping_charge:this.editstoremodal.shipping_charge,store_code: this.editstoremodal.store_code}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
            window.location.reload()
            
          }, 2000);


          })
        }
      },
      getstore_name(val){
      const search = this.storeData.find(element => element.store_id === val);
      if(search){
        return search.store_name;
      }
      },
      getfilterdata(){
          localStorage.setItem('selectstore',this.selectstore);
          let details={ "details" :{}};
          if(this.selectstore){
              details.details.region_store=this.selectstore;

            }

          axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_region',details,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.regionData=response.data.details;
              }else{
                this.regionData=[];
              }
                  
            })
            let adetails={ "details" :{}};
             if(this.selectstore){
               adetails.details.area_store=this.selectstore;

             }
             if(this.selectpin){
               adetails.details.area_region=this.selectpin;

             }
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_area',adetails,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.areaData=response.data.details;
              }else{
                this.areaData=[];
              }
                  
            }) 
      },
      addboardmemberpopup(row){
        // console.log(row);
        this.addboardmember=true;
      },
      editeventpopup(row){
        this.editeventmodel=row;
        console.log(this.editeventmodel);
        this.editboardmember=true;
        // }
      },
      edittraditional(row){
        this.traditional=row;
        console.log(this.traditional);
        document.getElementById("edittraditionaltouch").scrollIntoView({
          behavior: "smooth"
        });
        // }
      },
      deleteeventpopup(row){
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_event',querystring.stringify({event_id :row.event_id}),this.auth)
            .then(response => {
                let _this=this;
                console.log(response);
                swal({
                  title:'Deleted!',
                  text: 'Member has been deleted.',
                  icon:'success',
                 }).then((result) => {
                  console.log(result)
                  axios
                  .post(process.env.VUE_APP_API_ENDPOINT+'read_event',{ "details" :{}},this.auth1)
                  .then(response => {
                    console.log(response);
                    if(response.data.details){
                    this.eventData=response.data.details;
                    }   
                  })
                })
              })
             }
          });
      },
      deletetraditional(row){
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_traditional_touch',querystring.stringify({traditional_id :row.traditional_id}),this.auth)
            .then(response => {
                let _this=this;
                console.log(response);
                swal({
                  title:'Deleted!',
                  text: 'Member has been deleted.',
                  icon:'success',
                 }).then((result) => {
                  console.log(result)
                  axios
                  .post(process.env.VUE_APP_API_ENDPOINT+'read_traditional_touch',{ "details" :{}},this.auth1)
                  .then(response => {
                    console.log(response);
                    if(response.data.details){
                      this.traditionData=response.data.details;
                    } else {
                      this.traditionData=[];
                    }
                  })
                })
              })
             }
          });
      },
  },
    
};
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
