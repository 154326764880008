<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-xl-2 mb-2">
          <select v-if="role=='Super Admin'"  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectstore" @change="getfilterdata()">
            <option value="">Select Store</option>
            <option v-for="store in storeData" :key="store.store_id" :value="store.store_id">{{store.store_name}}</option>
          </select>
        </div>
        <div class="col-xl-10 mb-2 text-right">
          <base-input class="mb-0 search-input mr-2" input-group-classes="input-group-merge" v-model="search_term" placeholder="Search Product" @keyup.enter="searchProduct()">
              <template slot="append"><a ><i class="fas fa-search" @click="searchProduct()"></i></a></template>
          </base-input>
          <select class="form-control d-inline w-auto mr-2" v-model="category" @change="getsubcategory()">
            <option value="">All Category</option>
            <option v-for="category in categoryData" :key="category._id" :value="category._id">{{category.inventory_category_name}}</option>
            <!-- <option>Beverages</option>
            <option>Soaps</option> -->
          </select>
          <select class="form-control d-inline w-auto mr-2" v-model="subcategory" @change="getfilterdata()">
            <option value="">All Sub-category</option>
             <option v-for="subcategory in subcategoryData" :key="subcategory._id" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
            <!-- <option>Soyabeen Oil</option> -->
          </select>
          <base-button type="primary" @click="cleardata()">Clear</base-button>
          <router-link v-if="pagepermission.create" to="/products/addproduct" class="btn btn-primary">Add Product</router-link>
        </div>
        <div class="col-lg-15 col-md-3 col-6" v-for="product in productData" :key="product._id">
          <div class="card">
            <div class="card-body p-2 shadow--hover border" >
              <div class="d-block pb-2">
                <a :href="'/products/product/'+product.item_id" class="cursor-pointer"><img :src="product.item_main_img" class="rounded raised img-center img-fluid"></a>
              </div>
              <div class="pt-0">
                <div class="row">
                  <div class="col-md-12 mb-1">
                    <span>{{getstore_name(product.store)}}</span>
                  </div>
                  <div class="col-md-9">
                    <h5 class="h5 title mb-1">
                      <a :href="'/products/product/'+product.item_id"><span class="d-block">{{product.item_name}}</span></a>
                      <!-- <span class="h5 font-weight-400">SKU: EL3750</span> -->
                      <span class="font-weight-400 d-block small" v-if="product.item_price_type.length>0">Stock: {{product.item_price_type[0].availability_now}}</span>
                    </h5>
                  </div>
                  <div class="col-md-3 text-right">
                    <base-dropdown menuOnRight>
                        <base-button slot="title-container" size="sm" type="white" class="mr-0 px-0 shadow-none">
                          <i class="fas fa-ellipsis-v"></i>
                        </base-button>
                            <a v-if="pagepermission.update" class="dropdown-item" :href="'/products/editproduct/'+product._id">Edit Product</a>
                            <!-- <a class="dropdown-item" href="#">Make Product Hot</a> -->
                            <a class="dropdown-item" v-if="!product.is_featured"  @click="setfeatured(product)">Show Product on Home</a>
                            <a class="dropdown-item" v-if="product.is_featured"  @click="setunfeatured(product)">Remove Product from Home</a>
                            
                            <div class="dropdown-divider"></div>
                            <a v-if="pagepermission.delete" class="dropdown-item" @click="deleteproduct(product)">Delete Product</a>
                      </base-dropdown>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-6">
                    <!-- <p class="small mb-0 mt-3"><i class="fas fa-rupee-sign"></i> Price</p> -->
                        <select class="form-control form-control-sm d-inline w-auto mr-2" @change="getprice()">
                          <option v-for="(item_price_type,index) in product.item_price_type" :value="item_price_type">{{item_price_type.unit}}({{item_price_type.quantity}})</option>
                          <!-- <option>Pack of 10</option>
                          <option>1 Kg</option>
                          <option>2 Kg</option> -->
                        </select>
                        <!-- <span class="font-weight-bold h3">Rs. {{price}}</span> -->
                  </div>
                  <div class="col-md-6 text-right">
                    <h4 class="mb-0 text-success">Rs. <span v-if="product.item_price_type.length>0">{{product.item_price_type[0].price}} </span></h4>
                  </div>
                </div>
                <!-- <div class="mt-3">
                  <a href="#" class="btn btn-twitter btn-icon-only rounded-circle">
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a href="#" class="btn btn-facebook btn-icon-only rounded-circle">
                    <i class="fab fa-facebook"></i>
                  </a>
                  <a href="#" class="btn btn-dribbble btn-icon-only rounded-circle">
                    <i class="fab fa-dribbble"></i>
                  </a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- no result found -->
        <div class="col-md-12">
          <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
            <div class="col-md-3 mx-auto mt-4 text-center">
                <img src="/img/theme/no-result.png" class="img-fluid">
                <p class="mt-4">No product found.</p>
            </div>
          </div>
        </div>
        <!-- shimmer starts here -->
        <div class="col-md-12" v-if="productData.length==0 && !datanotfound">
          <div class="row justify-content-center">
            <div class="col-lg-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-3 pb-3">
                        <span class="photo-shimmer shine mb-3">
                            
                        </span>
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-3 pb-3">
                        <span class="photo-shimmer shine mb-3">
                            
                        </span>
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-3 pb-3">
                        <span class="photo-shimmer shine mb-3">
                            
                        </span>
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-3 pb-3">
                        <span class="photo-shimmer shine mb-3">
                            
                        </span>
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- shimmer ends here -->

        <div class="col-md-12" v-if="productData.length!==0">
          <div class="d-block mb-5 mt-2">
            <base-button
              type="success"
              class="float-center m-auto d-block"
              v-if="loadmorefound" 
              @click="loadmore()"
            >
              <span>Load More</span>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
   import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
    },
    data() {
      return {
        inputs: {
          file: []
        },
        subcategoryData:[],
        categoryData:[],
        vaiationData:[],
        selectstore:localStorage.getItem('selectstore'),
        role:localStorage.getItem('role'),
        pagepermission:{},
        category:'',
        datanotfound:false,
        subcategory:'',
        search_term:'',
        offset:0,
        loadmorefound:1,
        subcategoryproductData:[],
        storeData:[],
        productData:[],
        createproduct:false,
        createcategory:false,
        editcategory:false,
        editproduct:false,
        uploadPercentage:0,
        categorymodal:{
        inventory_subcategory_id : '',
        inventory_subcategory_name : '',
        inventory_category_name :this.$route.params.name,
        inventory_category_oid :this.$route.params.id,
        inventory_subcategory_type : '',
        inventory_subcategory_img : '',
        inventory_subcategory_icon : '',
        inventory_subcategory_theme_color : '',
        banner_primary : '',
        banner_secondary : '',
        is_featured : '',
        sequenced_at : '',
        created_by : localStorage.getItem('_id'),
        status : '',
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted () {
      if(this.selectstore==null || this.selectstore==undefined || this.selectstore=='undefined'){
        this.selectstore="";
      }
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      let details={ "details" :{},"offset" : "0"};
      if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
          details.details.store=localStorage.getItem('store_id')
      }
      if(this.selectstore){
              details.details.store=this.selectstore;

      }
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',details,this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.productData=response.data.details;
        this.datanotfound=false;
        if(response.data.details.length < 30){
               this.loadmorefound=0; 
            }
        }else{
            this.loadmorefound=0; 
            this.productData=[];
            this.datanotfound=true;
        }
        
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.categoryData=response.data.details;
        }
            
      })
      let storedetails={ "details" :{}};
      if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
          storedetails.details.store_id=localStorage.getItem('store_id')
      }
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',storedetails,this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        }
            
      })


      //inventory_category_oid:this.$route.params.id


    },
    methods: {
          createcategorymodal(){
            this.createcategory=true;
          },
          createproductpopup(){
            this.createproduct=true;
          },
          hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                if(type=='icon'){
                  this.categorymodal.inventory_subcategory_icon=response.data.imageUrl;
                  console.log(this.categorymodal.inventory_subcategory_icon);
                }else if(type=='image'){
                  this.categorymodal.inventory_subcategory_img=response.data.imageUrl;
                  console.log(this.categorymodal.inventory_subcategory_img);
                }else if(type=='editicon'){
                  this.editcategorymodal.inventory_subcategory_icon=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_icon);
                }else if(type=='editimage'){
                  this.editcategorymodal.inventory_subcategory_img=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_img);
                }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

        },
        loadmore(){
          this.offset=this.offset+1;
          const querystring = require('querystring');
          console.log(this.auth);
          let details={ "details" :{},"offset":this.offset.toString()};
          if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
          details.details.store=localStorage.getItem('store_id')
          }
          if(this.category){
            details.details.item_category= this.category;

          }
          if(this.subcategory){
            details.details.item_subcategory=this.subcategory;

          }
          if(this.selectstore){
            details.details.store=this.selectstore;
          }
          if(this.status){
            details.details.status=this.status;

          }
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_item',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.loadmorefound=1; 
              this.datanotfound=false;
            //this.orderData=response.data.details;
             for (var i = 0; i < response.data.details.length; i++) {
                    this.productData.push(response.data.details[i]);
                }
            // this.productData.push(response.data.details);
                if(response.data.details.length < 30){
                   this.loadmorefound=0; 
                }
            }else{
                this.loadmorefound=0;
            } 
                
          })
        },
        searchProduct(){
          let details={ "search_term" :this.search_term};
          if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
              details.store=localStorage.getItem('store_id')
          }
          if(this.category){
              details.item_category= this.category;

          }
          if(this.subcategory){
            details.item_subcategory=this.subcategory;

          }
          if(this.selectstore){
            details.store=this.selectstore;
          }
        axios
        .post(process.env.VUE_APP_API_ENDPOINT+'search_product',details,this.auth1)
        .then(response => {
          console.log(response);
          if(response.data.details){
          this.productData=response.data.details;
          this.datanotfound=false;
          this.loadmorefound=0; 
          }else{
            this.productData=[];
            this.datanotfound=true;
            this.loadmorefound=0; 
          }
              
         })
        },
        getstore_name(val){
              const search = this.storeData.find(element => element.store_id === val);
              if(search){
                return search.store_name;
              }
              
        },
        getsubcategory(){
          this.subcategory="";
          if(this.category!=""){
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{inventory_category_oid:this.category}},this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.subcategoryData=response.data.details;
            }
                
          })
           let details={ "details" :{"item_category":this.category},"offset" : "0"};
          if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
              details.details.store=localStorage.getItem('store_id')
          }
          if(this.category){
            details.details.item_category= this.category;

          }
          if(this.subcategory){
            details.details.item_subcategory=this.subcategory;

          }
          if(this.selectstore){
            details.details.store=this.selectstore;
          }
          if(this.status){
            details.details.status=this.status;

          }
          axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_item',details,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.productData=response.data.details;
              this.datanotfound=false;
              if(response.data.details.length < 30){
                     this.loadmorefound=0; 
                  }
              }else{
                  this.loadmorefound=0; 
                  this.productData=[];
                  this.datanotfound=true;
              }
              
                  
            })
          }else{
            let details={ "details" :{},"offset" : "0"};
            if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
                details.details.store=localStorage.getItem('store_id')
            }
            if(this.category){
              details.details.item_category= this.category;

            }
            if(this.subcategory){
              details.details.item_subcategory=this.subcategory;

            }
            if(this.selectstore){
              details.details.store=this.selectstore;
            }
            if(this.status){
              details.details.status=this.status;

            }
           axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_item',details,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.datanotfound=false;
              this.productData=response.data.details;
              if(response.data.details.length < 30){
                     this.loadmorefound=0; 
                  }
              }else{
                  this.loadmorefound=0; 
                  this.productData=[];
                  this.datanotfound=true;
              }
              
                  
            })

          }
      },
      getfilterdata(){
            localStorage.setItem('selectstore',this.selectstore)
            let details={ "details" :{},"offset" : "0"};
            if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
                details.details.store=localStorage.getItem('store_id')
            }
            if(this.category){
              details.details.item_category= this.category;

            }
            if(this.subcategory){
              details.details.item_subcategory=this.subcategory;

            }
            if(this.selectstore){
              details.details.store=this.selectstore;
            }
            if(this.status){
              details.details.status=this.status;

            }
            
            this.loadmorefound=1; 
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_item',details,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.datanotfound=false;
              this.productData=response.data.details;
              if(response.data.details.length < 30){
                     this.loadmorefound=0; 
                  }
              }else{
                  this.loadmorefound=0; 
                  this.productData=[];
                  this.datanotfound=true;
              }
              
                  
            })

            let cdetails={ "details" :{},"offset" : "0"};
            // if(this.selectstore){
            //   cdetails.details.store=this.selectstore;
            // }
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',cdetails,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.categoryData=response.data.details;
              }
                  
            })


        
        },
        cleardata(){
        this.search_term='';
        this.category='';
        this.subcategory='';
        this.loadmorefound=1; 
          let details={ "details" :{},"offset" : "0"};
          if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
              details.details.store=localStorage.getItem('store_id')
          }
          if(this.selectstore){
                 details.details.store=this.selectstore;

          }
         axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_item',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.datanotfound=false;
            this.productData=response.data.details;
            if(response.data.details.length < 30){
                   this.loadmorefound=0; 
                }
            }else{
                this.loadmorefound=0; 
                this.productData=[];
                this.datanotfound=true;
            }
            
                
          })
          
        },
        setfeatured(product){
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_item',querystring.stringify({ item_id:product.item_id,is_featured:true}),this.auth)
          .then(response => {
            console.log(response);
             window.location.reload()

          })

        },
        setunfeatured(product){
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_item',querystring.stringify({ item_id:product.item_id,is_featured:"false"}),this.auth)
          .then(response => {
            console.log(response);
             window.location.reload()

          })

        },
        savesubcategory() {
          //,banner_primary:this.categorymodal.banner_primary,banner_secondary:this.categorymodal.banner_secondary,is_featured:this.categorymodal.is_featured,
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_inventory_subcategory',querystring.stringify({inventory_subcategory_name : this.categorymodal.inventory_subcategory_name,inventory_category_name:this.categorymodal.inventory_category_name,inventory_category_oid:this.categorymodal.inventory_category_oid,inventory_subcategory_type:this.categorymodal.inventory_subcategory_type,status:this.categorymodal.status,inventory_subcategory_icon:this.categorymodal.inventory_subcategory_icon,inventory_subcategory_img:this.categorymodal.inventory_subcategory_img,sequenced_at:this.categorymodal.sequenced_at,inventory_subcategory_theme_color:this.categorymodal.inventory_subcategory_theme_color,created_by:this.categorymodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn").classList.remove('btn-primary');
            document.getElementById("updatebtn").classList.add('btn-success');
            document.getElementById("updatebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn").classList.remove('btn-success');
            document.getElementById("updatebtn").classList.add('btn-primary');
            document.getElementById("updatebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })

        },
        openeditsubcategory(row){
            this.editcategory=true;
            this.editcategorymodal.inventory_subcategory_id =row.inventory_subcategory_id;
            this.editcategorymodal.inventory_subcategory_name =row.inventory_subcategory_name;
            this.editcategorymodal.inventory_category_oid =row.inventory_category_oid;
            this.editcategorymodal.inventory_subcategory_type =row.inventory_subcategory_type;
            this.editcategorymodal.inventory_subcategory_img =row.inventory_subcategory_img;
            this.editcategorymodal.inventory_subcategory_icon =row.inventory_subcategory_icon;
            this.editcategorymodal.inventory_subcategory_theme_color =row.inventory_subcategory_theme_color;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.banner_secondary =row.banner_secondary;
            this.editcategorymodal.is_featured =row.is_featured;
            this.editcategorymodal.sequenced_at =row.sequenced_at;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
          },

        updatesubcategory() {
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_subcategory',{ inventory_subcategory_id:this.editcategorymodal.inventory_subcategory_id,inventory_subcategory_name : this.editcategorymodal.inventory_subcategory_name,inventory_category_name:this.editcategorymodal.inventory_category_name,inventory_category_oid:this.editcategorymodal.inventory_category_oid,inventory_subcategory_type:this.editcategorymodal.inventory_subcategory_type,status:this.editcategorymodal.status,inventory_subcategory_icon:this.editcategorymodal.inventory_subcategory_icon,inventory_subcategory_img:this.editcategorymodal.inventory_subcategory_img,banner_primary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404864493banner-1%20%281%29.jpg',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404896351banner-1-top%20%281%29.jpg',sequenced_at:1}],banner_secondary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',sequenced_at:1}],is_featured:true,sequenced_at:this.editcategorymodal.sequenced_at,inventory_subcategory_theme_color:this.editcategorymodal.inventory_subcategory_theme_color,created_by:this.editcategorymodal.created_by},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
          }, 2000);

            //window.location.reload()

          })

        },
      deletesubcategory(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_inventory_subcategory',querystring.stringify({inventory_subcategory_id :row.inventory_subcategory_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your subcategory has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getvariation(){
       axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_variation',{ "details" :{inventory_subcategory_oid:this.editproductmodal.item_subcategory}},this.auth1)
      .then(response => {
        console.log(response);
          if(response.data.details){
          this.variationData=response.data.details;
          }   
        })
      },
      overflow_change() {
        document.getElementsByClassName("overflow_visible")[0].classList.add('overflow-x-visible');
        //document.getElementById("updatebtn").classList.add('btn-success');
      },
      openeditproduct(row){
            this.editproduct=true;
            this.editproductmodal.item_id =row.item_id;
            this.editproductmodal.inventory_subcategory_name =row.inventory_subcategory_name;
            this.editproductmodal.item_name =row.item_name;
            this.editproductmodal.item_tagline =row.item_tagline;
            this.editproductmodal.item_short_description =row.item_short_description;
            this.editproductmodal.item_long_description =row.item_long_description;
            this.editproductmodal.item_type =row.item_type;
            this.editproductmodal.is_featured =row.is_featured;
            this.editproductmodal.sequenced_at =row.sequenced_at;
            this.editproductmodal.status =row.status;
            this.editproductmodal.visibility =row.visibility;
            this.editproductmodal.item_category =row.item_category;
            this.editproductmodal.item_subcategory =row.item_subcategory;
            this.editproductmodal.item_variation =row.item_variation;
            this.editproductmodal.item_sp =row.item_sp;
            this.editproductmodal.item_mp =row.item_mp;
            this.editproductmodal.item_discount =row.item_discount;
            this.editproductmodal.minimum_quantity =row.minimum_quantity;
            this.editproductmodal.item_theme_color =row.item_theme_color;
            this.editproductmodal.item_tags =row.item_tags;
            this.editproductmodal.item_main_img =row.item_main_img;
            this.editproductmodal.item_secondary_img =row.item_secondary_img;
            this.editproductmodal.item_thumbnail =row.item_thumbnail;
            this.editproductmodal.store =row.store;
            if(row.number_available){
               this.editproductmodal.number_available =row.number_available;
            }
        },
        updateproduct() {
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_item',querystring.stringify({ item_id:this.editproductmodal.item_id,item_name : this.editproductmodal.item_name,item_tagline:this.editproductmodal.item_tagline,item_short_description:this.editproductmodal.item_short_description,item_long_description:this.editproductmodal.item_long_description,item_subcategory:this.editproductmodal.item_subcategory,item_variation:this.editproductmodal.item_variation,item_sp:this.editproductmodal.item_sp,item_mp:this.editproductmodal.item_mp,item_discount:this.editproductmodal.item_discount,item_main_img:this.editproductmodal.item_main_img,item_secondary_img:this.editproductmodal.item_secondary_img,item_thumbnail:this.editproductmodal.item_thumbnail,store:this.editproductmodal.store,status:this.editproductmodal.status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateproductbtn").classList.remove('btn-primary');
            document.getElementById("updateproductbtn").classList.add('btn-success');
            document.getElementById("updateproductbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateproductbtn").classList.remove('btn-success');
            document.getElementById("updateproductbtn").classList.add('btn-primary');
            document.getElementById("updateproductbtn").innerHTML="Update";
          }, 2000);

            //window.location.reload()

          })

        },
      deleteproduct(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_item',querystring.stringify({item_id :row.item_id,product_oid:row._id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your product has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },

    },
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
  }
</style>
