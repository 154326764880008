<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Brands
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button type="primary" @click="addbrandpopup"><i class="fas fa-plus"></i> Create brand</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="brandData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Brand Name</th>
                  <th>brand icon</th>
                  <th>Brand image</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.brand_name}}</p>
                    </td>
                    <td class="budget" >
                     <img alt="Image placeholder" class="rounded" v-if="row.brand_icon" :src="row.brand_icon" >
                    </td>
                    <td class="budget" >
                     <img alt="Image placeholder" class="rounded" v-if="row.brand_img" :src="row.brand_img" width="100">
                    </td>
                    <!-- <td class="budget">
                      <img alt="Image placeholder" class="rounded" :src="row.store_img" width="100">
                    </td> -->
                    <td class="text-right">
                      <base-button type="primary" size="sm" @click="editbrandpopup(row)" >Edit</base-button>
                      <base-button type="danger" size="sm" @click="deletebrand(row)">Delete</base-button>
                      <!-- <a class="btn btn-primary btn-sm" href="#" icon="fa fa-info">View</a> -->
                    </td>
                </template>
              </base-table>
              <!-- no result found -->
              <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No brands created.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="brandData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>

            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- add store popup -->
    <modal :show.sync="addstore">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create brand</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="brand_name" placeholder="brand_name" v-model="brandmodal.brand_name" input-classes="" />
                <div
                    v-if="$v.brandmodal.brand_name.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">brand_icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'brand_icon')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="brandmodal.brand_icon" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">brand_img</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'brand_img')"
                >
                <div
                    v-if="$v.brandmodal.brand_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="brandmodal.brand_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Status</label>
              <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off"
                v-model="brandmodal.status">
                <option>approved</option>
                <option>disapprove</option>
              </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.brandmodal.$error" @click="$v.brandmodal.$touch();createbrand()"
          type="success"
          id="createstorebtn"
          class="float-center"
        >
          <span>Create brand</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addstore = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit store popup -->
    <modal :show.sync="editstore">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit brand</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="brand_name" placeholder="brand_name" v-model="editbrandmodal.brand_name" input-classes="" />
                 <div
                    v-if="$v.editbrandmodal.brand_name.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">brand_icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'brand_icon')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="editbrandmodal.brand_icon" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">brand_img</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'brand_img')"
                >
                <div
                    v-if="$v.editbrandmodal.brand_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="editbrandmodal.brand_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Status</label>
              <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off"
                v-model="editbrandmodal.status">
                <option>approved</option>
                <option>disapprove</option>
              </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editbrandmodal.$error" @click="$v.editbrandmodal.$touch();updatebrand()"
          type="success"
          id="updateestorebtn"
          class="float-center"
        >
          <span>Update brand</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editstore = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addstore: false,
        editstore: false,
        datanotfound:false,
        uploadPercentage:0,
        brandData:[],
        storeData:[],
        regionData:[],
        brandmodal:{
          brand_id : '',
          brand_name : '',
          status : 'approved',
          brand_icon : '',
          brand_img : '',
          store : '',
          region : '',
          is_featured :'',
          sequenced_at : '',
          brand_theme_color : '',
          created_by:localStorage.getItem('_id'),
        },
        editbrandmodal:{
          brand_id : '',
          brand_name : '',
          status : '',
          brand_icon : '',
          brand_img : '',
          store : '',
          region : '',
          is_featured :'',
          sequenced_at : '',
          brand_theme_color : '',
          created_by:localStorage.getItem('_id'),
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {

      brandmodal: {
        brand_name:{
          required
        },
        brand_img:{
          required
        },
      },
      editbrandmodal: {
        brand_name:{
          required
        },
        brand_img:{
          required
        },
      },

    },
    mounted() {
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_brand',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.brandData=response.data.details;
        }else{
            this.brandData=[];
            this.datanotfound=true;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        this.brandmodal.store=this.storeData[0].store_id;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_region',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.regionData=response.data.details;
        }
            
      })
    },
    methods: {

      addbrandpopup(row){
        console.log(row);
        this.addstore=true;
      },
      editbrandpopup(row){
        console.log(row);
        this.editbrandmodal=row;
        this.editstore=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='brand_icon'){
                this.brandmodal.brand_icon=response.data.imageUrl;
                console.log(this.brandmodal.brand_icon);
              }if(type=='brand_img'){
                this.brandmodal.brand_img=response.data.imageUrl;
                console.log(this.brandmodal.brand_img);
              }else if(type=='editbrand_icon'){
                this.editbrandmodal.brand_icon=response.data.imageUrl;
                console.log(this.editbrandmodal.brand_icon);
              }if(type=='editbrand_img'){
                this.editbrandmodal.brand_img=response.data.imageUrl;
                console.log(this.editbrandmodal.brand_img);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    createbrand(){
      if(this.$v.brandmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_brand',querystring.stringify({brand_name : this.brandmodal.brand_name,brand_icon : this.brandmodal.brand_icon,brand_img : this.brandmodal.brand_img,status : this.brandmodal.status,created_by:this.brandmodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })

        }
        
      },
      updatebrand(){
        if(this.$v.editbrandmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_brand',querystring.stringify({brand_id : this.editbrandmodal.brand_id,brand_name : this.editbrandmodal.brand_name,brand_icon : this.editbrandmodal.brand_icon,brand_img : this.editbrandmodal.brand_img,status : this.editbrandmodal.status,created_by:this.editbrandmodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })

        }
        
      },
      deletebrand(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_brand',querystring.stringify({brand_id :row.brand_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
    },

    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
