<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-3" :class="''">
            <div class="p-3 border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Team Management
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button v-if="pagepermission.create" type="primary" @click="addteampopup"><i class="fas fa-user-plus"></i> Add new member</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4" v-for="staff, index in admindata" :key="staff.user_id">
          <!-- Basic with list group -->
          <!-- Contact card -->
          <card>
            <!-- Card body -->
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <a href="#" class="avatar avatar-xl rounded-circle">
                  <img alt="Image placeholder" :src="staff.user_dp">
                </a>
              </div>
              <div class="col ml--2 pr-0">
                <h4 class="mb-0">
                  <a href="#!">{{staff.user_name}}</a>
                </h4>
                <p class="text-sm text-muted mb-0" v-if="staff.user_role">{{staff.user_role.role_name}}</p>
                <span class="text-success">● </span>
                <small v-if="staff.user_status =='approved'">Active</small>
                <small v-if="staff.user_status =='disapproved'">Inactive</small>
              </div>
              <div class="col-auto">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" size="sm" type="white" class="mr-0 px-0 shadow-none">
                    <i class="fas fa-ellipsis-v"></i>
                  </base-button>
                      <a class="dropdown-item" v-if="pagepermission.update" @click="editteampopup(staff)">Edit</a>
                      <div class="dropdown-divider"></div>
                      <a  class="dropdown-item" v-if="pagepermission.delete" @click="deletestaff(staff)">Delete</a>
                </base-dropdown>
              </div>
            </div>
          </card>
        </div>

        <!-- shimmer starts here -->
        <div class="col-md-12" v-if="admindata.length==0 && !datanotfound">
          <div class="row justify-content-center">
            <div class="col-lg-12 mt-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- shimmer ends here -->
        <!-- no result found -->
        <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
          <div class="col-md-3 mx-auto mt-4 text-center">
              <img src="img/theme/no-result.png" class="img-fluid">
              <p class="mt-4">No staff found.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- create team popup -->
    <modal :show.sync="addteam" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Add new member</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <label class="form-control-label">Select Role<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="teammodal.user_role">
                <option value="">Select role</option>
                <option v-for="role in roledata" :key="role._id" :value="role._id">{{role.role_name}}</option>
              </select>
              <div
                  v-if="$v.teammodal.user_role.$error"
                  class="error h5 text-danger mt-0 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Member Name<span class="text-danger">*</span></label>
              <base-input v-model="teammodal.user_name" placeholder="Member Name" input-classes="" />
              <div
                  v-if="$v.teammodal.user_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Phone Number<span class="text-danger">*</span></label>
              <base-input  
                v-model="teammodal.user_phone" 
                placeholder="Phone number" 
                oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Email<span class="text-danger">*</span></label>
              <base-input v-model="teammodal.user_email" placeholder="Email" input-classes="" />
              <div
                  v-if="$v.teammodal.user_email.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Password<span class="text-danger">*</span></label>
              <base-input v-model="teammodal.user_password" placeholder="minimum 6 characters" input-classes="" />
              <div
                  v-if="$v.teammodal.user_password.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Profile Picture</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'addteam')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="teammodal.user_dp">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteogimage(teammodal)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="teammodal.user_dp" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6 mt-2">
               <label class="form-control-label">Status</label>
                <select v-model="teammodal.user_status"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="approved">active</option>
                    <option value="disapproved">inactive</option>
               </select>
            </div>
            
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.teammodal.$error" 
          @click="$v.teammodal.$touch();createstaff()"
          type="success"
          id="createstaffbtn"
          class="float-center"
        >
          <span>Add member</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addteam = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit meta popup -->
    <modal :show.sync="editteam" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit member details</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <label class="form-control-label">Select Role<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="user_role">
                <option value="">Select role</option>
                <option v-for="role in roledata" :key="role._id" :value="role._id">{{role.role_name}}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Member Name<span class="text-danger">*</span></label>
              <base-input v-model="editteammodal.user_name" placeholder="Member Name" input-classes="" />
              <div
                  v-if="$v.editteammodal.user_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Phone Number<span class="text-danger">*</span></label>
              <base-input v-model="editteammodal.user_phone" oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');" placeholder="Member Number" input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Email<span class="text-danger">*</span></label>
              <base-input v-model="editteammodal.user_email" placeholder="Email" input-classes="" />
              <div
                  v-if="$v.editteammodal.user_email.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-md-6">
              <base-input label="Password" v-model="editteammodal.user_password" placeholder="minimum 6 characters" input-classes="" />
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Profile Picture</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editteam')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="editteammodal.user_dp">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteogimage(editteammodal)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="editteammodal.user_dp" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select v-model="editteammodal.user_status"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="approved">active</option>
                    <option value="disapproved">inactive</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatestaff()"
          type="success"
          id="updatestaffbtn"
          class="float-center"
        >
          <span>Update Staff</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editteam = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import moment from 'moment';
  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addteam: false,
        datanotfound:false,
        editteam: false,
        user_role:'',
        uploadPercentage:0,
        pagepermission:{},
        admindata:[],
        roledata:[],
        storeData:[],
        regionData:[],
        teammodal:{
          user_id :'',
          user_name :'',
          user_email :'',
          user_phone :'',
          user_role :'',
          user_dp :'',
          user_password :'',
          user_status:'approved'
        },
        editteammodal:{
          user_id :'',
          user_name :'',
          user_email :'',
          user_phone :'',
          user_role :'',
          user_dp :'',
          user_password :'',
          user_status:'approved'
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      teammodal: {
        user_role:{
          required
        },
        user_name:{
          required
        },
        user_email:{
          required
        },
        user_password:{
          required
        },
      },
      editteammodal: {
        user_role:{
          required
        },
        user_name:{
          required
        },
        user_email:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_admin',{ "details" :{"user_type":{$ne:'student'}}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.datanotfound=false;
        this.admindata=response.data.details;
        console.log(this.admindata)
        }else{
            this.admindata=[];
            this.datanotfound=true;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_role',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.roledata=response.data.details;
        }else{
            this.roledata=[];
            this.datanotfound=true;
        }
            
      })
      // axios
      // .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      // .then(response => {
      //   console.log(response);
      //   if(response.data.details){
      //   this.storeData=response.data.details;
      //   }
            
      // })
    },
    methods: {

      addteampopup(){
        // console.log(row);
        this.addteam=true;
      },
      editteampopup(row){
        this.editteammodal=row;
        console.log(this.editteammodal)
        this.editteammodal.user_password='';
        this.editteam=true;
        if(this.editteammodal.user_role){
          this.user_role=this.editteammodal.user_role._id;
        }
        
        console.log(this.editteammodal);
        
        // }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addteam'){
                this.teammodal.user_dp=response.data.imageUrl;
                console.log(this.teammodal.user_dp);
              } else if(type=='editteam'){
                this.editteammodal.user_dp=response.data.imageUrl;
                console.log(this.editteammodal.user_dp);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
              }).then((result) => {
               
              })
            }
      },
      createstaff(){
        if(this.$v.teammodal.$error===false){
          let user_type='admin';
          console.log(this.teammodal.user_role)
          const querystring = require('querystring');
          if(this.teammodal.user_role=='608198a9d41f070a82131bf6'){
               user_type='teacher';
          }
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_admin',querystring.stringify({user_name : this.teammodal.user_name,user_phone : this.teammodal.user_phone,user_email : this.teammodal.user_email,user_type:user_type,user_password : this.teammodal.user_password,user_role : this.teammodal.user_role,user_dp : this.teammodal.user_dp,user_status : this.teammodal.user_status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstaffbtn").classList.remove('btn-primary');
            document.getElementById("createstaffbtn").classList.add('btn-success');
            document.getElementById("createstaffbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstaffbtn").classList.remove('btn-success');
            document.getElementById("createstaffbtn").classList.add('btn-primary');
            document.getElementById("createstaffbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
        }
      },
      updatestaff(){
        const querystring = require('querystring');
        let user_type='admin';
          if(this.user_role=='608198a9d41f070a82131bf6'){
               user_type='teacher';
          }
          console.log(user_type);
        axios.post(process.env.VUE_APP_API_ENDPOINT+'update_admin',querystring.stringify({user_id:this.editteammodal.user_id,user_name : this.editteammodal.user_name,user_phone : this.editteammodal.user_phone,user_email : this.editteammodal.user_email,user_type:user_type,user_password : this.editteammodal.user_password,user_role : this.user_role,user_dp : this.editteammodal.user_dp,user_status : this.editteammodal.user_status}),this.auth)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("updatestaffbtn").classList.remove('btn-primary');
          document.getElementById("updatestaffbtn").classList.add('btn-success');
          document.getElementById("updatestaffbtn").innerHTML='Updated';
          setTimeout(function(){
          document.getElementById("updatestaffbtn").classList.remove('btn-success');
          document.getElementById("updatestaffbtn").classList.add('btn-primary');
          document.getElementById("updatestaffbtn").innerHTML="Update";
          window.location.reload()
        }, 2000);
        })
      },
      deletestaff(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_admin',querystring.stringify({user_id :row.user_id}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your file has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      format_datetime(value){
        if(value) {
          return moment.utc(new Date(value)).format("LLL")
        }
      },
      change_offer_type(type){
          if(this.metamodal.offer_type=='FlatOnCart' && type=='add'){
            document.getElementById("max_amount").classList.add('d-none');
          }
          if(this.metamodal.offer_type=='PercentageOnCart' && type=='add'){
            document.getElementById("max_amount").classList.remove('d-none');
          }

          if(this.editteammodal.offer_type=='FlatOnCart' && type=='edit'){
            document.getElementById("edit_max_amount").classList.add('d-none');
          }
          if(this.editteammodal.offer_type=='PercentageOnCart' && type=='edit'){
            document.getElementById("edit_max_amount").classList.add('d-none');
          }
      },
      deleteogimage(index){
        this.metamodal.og_image='';
        this.uploadPercentage =0;
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
