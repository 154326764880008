<template>
  <div>
    <!-- <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Default</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
    </base-header> -->

    <div class="container-fluid mt-4">
      <!--quick information-->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                 <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">ORDERS Today</h5>
                    <span class="h2 font-weight-bold mb-0">350,897</span>
                 </div>
                 <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-red"><i class="fas fa-shopping-cart"></i></div>
                 </div>
              </div>
              <p class="mt-3 mb-0 text-sm">14 today</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                 <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">ORDERS in Jan</h5>
                    <span class="h2 font-weight-bold mb-0">2,356</span>
                 </div>
                 <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-orange"><i class="fas fa-shopping-bag"></i></div>
                 </div>
              </div>
              <p class="mt-3 mb-0 text-sm">today orders</p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                 <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Sales Today</h5>
                    <span class="h2 font-weight-bold mb-0">924</span>
                 </div>
                 <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-green"><i class="ni ni-money-coins"></i></div>
                 </div>
              </div>
              <!-- <p class="mt-3 mb-0 text-sm"></p> -->
            </div>
          </div>

        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                 <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Sales in Jan</h5>
                    <span class="h2 font-weight-bold mb-0">49,65%</span>
                 </div>
                 <div class="col-auto">
                    <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-info"><i class="fas fa-rupee-sign"></i></div>
                 </div>
              </div>
              <!-- <p class="mt-3 mb-0 text-sm"></p> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xl-8">
          <card type="white" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-muted text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 mb-0">Sales value</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 0}"
                       @click.prevent="initBigChart(0)">
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: bigLineChart.activeIndex === 1}"
                       @click.prevent="initBigChart(1)">
                      <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="230"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>

          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total orders</h5>
              </div>
            </div>

            <bar-chart
              :height="230"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
          </card>
        </div>
      </div> -->
      <!--orders information-->
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Latest Orders
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list">
                <template slot="columns">
                  <th>Order ID</th>
                  <th>Customer</th>
                  <th>Product/s</th>
                  <th>Quantity</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </template>
                <template slot-scope="{row}">
                    <td class="budget" >
                      <p class="mb-0">#85457898</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">Ramesh Kumar</p>
                    </td>
                    <td class="budget">
                      <img alt="Image placeholder" class="rounded" src="img/theme/team-1.png" width="100">
                    </td>
                    <td class="budget">
                      <p class="mb-0">Mother Dairy Full Creame Milk</p>
                      <p class="mb-0">Rs. 150</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">5</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">12 Jan 2020, 3:45 pm</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">Under Process</p>
                    </td>
                    <td class="text-right">
                      <base-button type="primary" size="sm" @click="opencategory(row)" icon="fa fa-pencil">Edit</base-button>
                      <base-button type="danger" size="sm" v-if="verified" @click="deletecategory(row.cat_id)" icon="fa fa-pencil">Delete</base-button>
                      <a class="btn btn-primary btn-sm" :href="'#/captions/subcategories/'+row._id+'/'+row.cat_name" icon="fa fa-info">View</a>
                    </td>
                </template>
              </base-table>
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';

  // Lists
  import ActivityFeed from './ActivityFeed';
  import TaskList from './TaskList';
  import UserList from './UserList';
  import ProgressTrackList from './ProgressTrackList';

  // Tables
  import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      ActivityFeed,
      LineChart,
      BarChart,
      BaseProgress,
      RouteBreadCrumb,
      StatsCard,
      TaskList,
      PageVisitsTable,
      // SocialTrafficTable,
      LightTable,
      UserList,
      ProgressTrackList
    },
    data() {
      return {
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          },
          extraOptions: chartConfigs.blueChartOptions
        }
      };
    },
    methods: {
      initBigChart(index) {
        let chartData = {
          datasets: [
            {
              label: 'Performance',
              data: this.bigLineChart.allData[index]
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    mounted() {
      this.initBigChart(0);
    }
  };
</script>
<style></style>
