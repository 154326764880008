<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Tc
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectstore" @change="getfilterdata()">
                    <option value="">Select Store</option>
                    <option v-for="store in storeData" :key="store.store_id" :value="store.store_id">{{store.store_name}}</option>
                  </select> -->
                  <base-button type="primary" @click="addtcpopup"><i class="fas fa-plus"></i> Create tc</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="tcData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Student name</th>
                  <th>Tc Image</th>
                  <th>class</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget">
                      <p v-if="row.student_oid">{{row.student_oid.user_name}}</p>
                    </td>
                    <td class="budget">
                      <img :src="row.tc_link" width="100">
                    </td>
                    <td class="budget">
                      <p class="mb-0" v-if="row.class_oid">{{row.class_oid.class_name}}</p>
                    </td>
                    <td class="text-right">
                      <base-button type="primary" size="sm" @click="edittcpopup(row)" >Edit</base-button>
                      <base-button type="danger" size="sm" @click="deletetc(row)">Delete</base-button>
                      <!-- <router-link class="btn btn-primary btn-sm" to="/seo/meta" icon="fa fa-info">View</router-link> -->
                    </td>
                </template>
              </base-table>

              <!-- no data found here -->
              <div class="card-footer justify-content-center" v-if="!datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No TC created.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="tcData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- add baner -->
    <modal :show.sync="addtc">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create TC</span>
      </p>

      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">TC link<span class="text-danger">*</span></label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'add')"
                >
              <div
                  v-if="$v.tcmodal.tc_link.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
              <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
              <div>
                  <img :src="tcmodal.tc_link" class="img-fluid rounded shadow mt-2">
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="tcmodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.tcmodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
           
            <div class="col-lg-12">
              <label class="form-control-label">Student<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="tcmodal.student_oid">
                <option value="">Select student</option>
                <option v-for="student in studentData" :key="student.user_id" :value="student._id">{{student.user_name}} ({{student.admission_no}})</option>
              </select>
              <div
                  v-if="$v.tcmodal.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
         :disabled="$v.tcmodal.$error" @click="$v.tcmodal.$touch();createtc()"
          type="success"
          id="createtcbtn"
          class="float-center"
        >
          <span>Save tc</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addtc = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit banner popup -->
    <modal :show.sync="edittc">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit tc</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">Tc link<span class="text-danger">*</span></label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'edit')"
                >
              <div
                  v-if="$v.edittcmodal.tc_link.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
              <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
              <div>
                  <img :src="edittcmodal.tc_link" class="img-fluid rounded shadow mt-2">
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="edittcmodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.edittcmodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
           
            <div class="col-lg-12">
              <label class="form-control-label">Student<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="edittcmodal.student_oid">
                <option value="">Select student</option>
                <option v-for="student in studentData" :key="student.user_id" :value="student._id">{{student.user_name}}({{student.admission_no}})</option>
              </select>
              <div
                  v-if="$v.edittcmodal.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.edittcmodal.$error" @click="$v.edittcmodal.$touch();updatetc()"
          type="success"
          id="updatetcbtn"
          class="float-center"
        >
          <span>Update tc</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="edittc = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { ModelListSelect } from 'vue-search-select'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import 'vue-search-select/dist/VueSearchSelect.css'
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
// cayegory
// subcategory
// variation
// item
// offer
      DropzoneFileUpload,
      ModelListSelect,
    },
    data() {
      return {
        addtc: false,
        edittc: false,
        datanotfound:false,
        uploadPercentage:0,
        selectstore:'',
        pagepermission:{},
        tcData:[],
        studentData:[],
        classesData:[],
        subjectData:[],
        tcmodal:{
          tc_id : '',
          tc_link : '',
          student_oid : '',
          class_oid : '',
        },
        edittcmodal:{
          tc_id : '',
          tc_link : '',
          student_oid : '',
          class_oid : '',
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      tcmodal: {
        tc_link:{
          required
        },
        class_oid:{
          required
        },
        student_oid:{
          required
        },
        
      },
      edittcmodal: {
        tc_link:{
          required
        },
        class_oid:{
          required
        },
        student_oid:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_tc',{ "details" :{}},this.auth1)
      .then(tc => {
        console.log(tc);
        if(tc.data.details){
        this.tcData=tc.data.details;
        this.datanotfound=false;
        }else{
          this.tcData=[];
        } this.datanotfound=true;
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_student',{ "details" :{user_type:"student"}},this.auth1)
      .then(tc => {
        console.log(tc);
        if(tc.data.details){
        this.studentData=tc.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
      .then(tc => {
        console.log(tc);
        if(tc.data.details){
        this.classesData=tc.data.details;
        }
            
      })
      
    },
    methods: {

      addtcpopup(row){
        console.log(row);
        this.addtc=true;
      },
      edittcpopup(row){
        console.log(row);
        this.edittcmodal.tc_id=row.tc_id;
        this.edittcmodal.tc_link=row.tc_link;
        if(row.student_oid){
        this.edittcmodal.student_oid =row.student_oid._id;
        }
        if(row.class_oid){
        this.edittcmodal.class_oid =row.class_oid._id;
        }

        this.edittc=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(tc => {
              console.log(tc);
              if(type=='add'){
                this.tcmodal.tc_link=tc.data.imageUrl;
                console.log(this.tcmodal.tc_link);
              }if(type=='edit'){
                this.edittcmodal.tc_link=tc.data.imageUrl;
                console.log(this.tcmodal.tc_link);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    createtc(){
        if(this.$v.tcmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_tc',querystring.stringify({tc_link : this.tcmodal.tc_link,student_oid:this.tcmodal.student_oid,class_oid:this.tcmodal.class_oid}),this.auth)
          .then(tc => {
            console.log(tc);
            this.message=tc.data.message;
            document.getElementById("createtcbtn").classList.remove('btn-primary');
            document.getElementById("createtcbtn").classList.add('btn-success');
            document.getElementById("createtcbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createtcbtn").classList.remove('btn-success');
            document.getElementById("createtcbtn").classList.add('btn-primary');
            document.getElementById("createtcbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
        }
        
      },
      updatetc(){
        if(this.$v.edittcmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_tc',querystring.stringify({tc_id : this.edittcmodal.tc_id,tc_link : this.edittcmodal.tc_link,student_oid:this.edittcmodal.student_oid,class_oid:this.edittcmodal.class_oid}),this.auth)
          .then(tc => {
            console.log(tc);
            this.message=tc.data.message;
            document.getElementById("updatetcbtn").classList.remove('btn-primary');
            document.getElementById("updatetcbtn").classList.add('btn-success');
            document.getElementById("updatetcbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatetcbtn").classList.remove('btn-success');
            document.getElementById("updatetcbtn").classList.add('btn-primary');
            document.getElementById("updatetcbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
          })

        }
        
      },
      deletetc(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_tc',querystring.stringify({tc_id :row.tc_id}),this.auth)
          .then(tc => {
            console.log(tc);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getstore_name(val){
        const search = this.storeData.find(element => element.store_id === val);
        if(search){
          return search.store_name;
        }
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
