<template>
  <div>
    <div class="container-fluid mt-4">
      <tabs tabNavClasses="flex-column flex-sm-row nav-wrapper"
        tabContentClasses="">
        <tab-pane id="firstbanner">
          <span slot="title">
              First Banner
          </span>
            
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''" style="background-color: #efefef;">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        First Banner of {{categoryData.inventory_category_name}} category
                      </h3>
                    </div>
                    <div class="col text-right">
                      <!-- <base-button type="primary"><i class="fas fa-plus"></i> Create Banner</base-button> -->
                    </div>
                  </div>
                </div>
                <div class="container pb-5 pt-3">
                  <form @submit.prevent class="row">
                    <div class="col-lg-12 mt-2">
                      <div class="container">
                        <div class="row" v-for="(banner_primary,index) in categoryData.banner_primary">
                          <div class="col-md-1">
                            <label class="form-control-label text-white">.</label>
                            <base-button
                              id="deletebanner"
                              type="danger"
                              @click="deletefirstimage(index)"
                              class="float-center"
                              block
                            >
                              <i class="fas fa-trash"></i><span></span>
                            </base-button>
                          </div>
                          <div class="col-lg-3">
                            <label class="form-control-label">Primary Banner Link To</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="banner_primary.todo" @change="getchange_div('primary',index)">
                                  <option value="openCategory">Category</option>
                                  <option value="openProductFeed">Sub-Category</option>
                                  <option value="openProduct">Product</option>
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" :id="'pcategory'+index">
                            <label class="form-control-label">Category</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target" @change="getsubcategory()">
                                  <option v-for="category in allcategoryData" :key="category._id">{{category.inventory_category_name}}</option>
                                  
                                  <!-- <option>Category 3</option> -->
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" :id="'psubcategory'+index">
                            <label class="form-control-label">Subcategory</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target">
                                  <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                                  <!-- <option>Sub-category 2</option>
                                  <option>Sub-category 3</option> -->
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" :id="'pproduct'+index">
                             <label class="form-control-label">Product</label>
                            <model-list-select :list="productData"
                                 v-model="banner_primary.target"
                                 option-value="item_id"
                                 option-text="item_name"
                                 placeholder="Select product">
                            </model-list-select>
                            <!-- <base-input label="Product oid" placeholder="Product oid" v-model="banner_primary.target" input-classes="" /> -->
                          </div>
                          <div class="col-lg-4">
                            <label class="form-control-label mt-2">Upload Image</label>
                            <input
                              id="file"
                              ref="myFiles"
                              type="file"
                              aria-describedby="addon-right addon-left"
                              alternative=""
                              class="form-control form-control-alternative"
                              name="img"
                              @change="bannerhadleimage($event,'primary',index)"
                            >
                            <base-progress type="success" label="File uploaded"></base-progress>
                            <div>
                              <img :src="banner_primary.url" class="img-fluid rounded shadow mt-2 p-2">
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-center mt-4">
                          <div class="col-md-12 text-center">
                              <base-button
                                  v-if="pagepermission.update"
                                  @click="updatebanner()"
                                  type="primary"
                                  id="updatefirstbtn"
                                  class="float-center"
                                  block
                                  size="lg"
                                >
                                  <span>Update banner</span>
                                </base-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <!-- no result found -->
                  <!-- <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                    <div class="col-md-3 mx-auto mt-4 text-center">
                        <img src="img/theme/no-result.png" class="img-fluid">
                        <p class="mt-4">No stores created yet.</p>
                    </div>
                  </div> -->

                  <!-- shimmer starts here -->
                  <!-- <div class="col-md-12" v-if="storeData.length==0 && !datanotfound">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 mt-3">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- shimmer ends here -->
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane id="secondbanner">
          <span slot="title">
              Second banner
          </span>
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''" style="background-color: #efefef;">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Second Banner of {{categoryData.inventory_category_name}} category
                      </h3>
                    </div>
                    <div class="col text-right">
                      <!-- <base-button type="primary"><i class="fas fa-plus"></i> Add banner</base-button> -->
                    </div>
                  </div>
                </div>
                <div class="container pb-5 pt-3">
                  <form @submit.prevent class="row">
                    <div class="col-lg-12 mt-2">
                      <div class="container">
                        <div class="row" v-for="(banner_secondary,index) in categoryData.banner_secondary">
                          <div class="col-md-1">
                            <label class="form-control-label text-white">.</label>
                            <base-button
                              id="deletebanner"
                              type="danger"
                              @click="deletesecondimage(index)"
                              class="float-center"
                              block
                            >
                              <i class="fas fa-trash"></i><span></span>
                            </base-button>
                          </div>
                          <div class="col-lg-3">
                            <label class="form-control-label">Secondary Banner Link To</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.todo" @change="getchange_div('secondary',index)">
                                  <option value="openCategory">Category</option>
                                  <option value="openProductFeed">Sub-Category</option>
                                  <option value="openProduct">Product</option>
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" :id="'scategory'+index">
                            <label class="form-control-label">Category</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                                  <option v-for="category in allcategoryData" :key="category._id">{{category.inventory_category_name}}</option>
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" :id="'ssubcategory'+index">
                            <label class="form-control-label">Subcategory</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                                  <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" :id="'sproduct'+index">
                             <label class="form-control-label">Product</label>
                            <model-list-select :list="productData"
                                 v-model="banner_secondary.target"
                                 option-value="item_id"
                                 option-text="item_name"
                                 placeholder="Select product">
                            </model-list-select>
                            <!-- <base-input label="Product SKU" placeholder="Product SKU" v-model="banner_secondary.target" input-classes="" /> -->
                          </div>
                          <div class="col-lg-4">
                            <label class="form-control-label mt-2">Upload Image</label>
                            <input
                              id="file"
                              ref="myFiles"
                              type="file"
                              aria-describedby="addon-right addon-left"
                              alternative=""
                              class="form-control form-control-alternative"
                              name="img"
                              @change="bannerhadleimage($event,'secondary',index)"
                            >
                            <div>
                              <img :src="banner_secondary.url" class="img-fluid rounded shadow mt-2 p-2">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 mt-3">
                            <base-button
                              id="updatebtn1"
                              type="success"
                              outline
                              @click="addmore()"
                              class="float-center border-dashed"
                              block
                            >
                              <i class="fas fa-plus"></i><span>Add more banners</span>
                            </base-button>
                          </div>
                        </div>
                        <div class="row justify-content-center mt-4">
                          <div class="col-md-12">
                            <base-button
                                v-if="pagepermission.update"
                                @click="updatesecondbanner()"
                                type="primary"
                                id="updatesecondbtn"
                                class="float-center"
                                block
                                size="lg"
                              >
                                <span>Update banner</span>
                              </base-button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <!-- no result found -->
                  <!-- <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                    <div class="col-md-3 mx-auto mt-4 text-center">
                        <img src="img/theme/no-result.png" class="img-fluid">
                        <p class="mt-4">No stores created yet.</p>
                    </div>
                  </div> -->

                  <!-- shimmer starts here -->
                  <!-- <div class="col-md-12" v-if="storeData.length==0 && !datanotfound">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 mt-3">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- shimmer ends here -->
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane id="thirdbanner">
          <span slot="title">
              Third Banner
          </span>
            
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''" style="background-color: #efefef;">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Third Banner of {{categoryData.inventory_category_name}} category
                      </h3>
                    </div>
                    <div class="col text-right">
                      <!-- <base-button type="primary"><i class="fas fa-plus"></i> Create Banner</base-button> -->
                    </div>
                  </div>
                </div>
                <div class="container pb-5 pt-3">
                  <form @submit.prevent class="row">
                    <div class="col-lg-12 mt-2">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-1">
                            <label class="form-control-label text-white">.</label>
                            <base-button
                              id="deletebanner"
                              type="danger"
                              @click="deletethirdimage()"
                              class="float-center"
                              block
                             >
                              <i class="fas fa-trash"></i><span></span>
                            </base-button>
                          </div>
                          <div class="col-lg-3">
                            <label class="form-control-label">Primary Banner Link To</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="thirdbannermodal.todo" @change="getchange_bannerdiv('third')">
                                  <option value="openCategory">Category</option>
                                  <option value="openProductFeed">Sub-Category</option>
                                  <option value="openProduct">Product</option>
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" id="thirdcategory">
                            <label class="form-control-label">Category</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="thirdbannermodal.target" @change="getsubcategory()">
                                  <option v-for="category in allcategoryData" :key="category._id" :value="category._id">{{category.inventory_category_name}}</option>
                                  
                                  <!-- <option>Category 3</option> -->
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" id="thirdsubcategory">
                            <label class="form-control-label">Subcategory</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="thirdbannermodal.target">
                                  <option v-for="subcategory in subcategoryData" :key="subcategory._id" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                                  <!-- <option>Sub-category 2</option>
                                  <option>Sub-category 3</option> -->
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" id="thirdproduct">
                             <label class="form-control-label">Product</label>
                            <model-list-select :list="productData"
                                 v-model="thirdbannermodal.target"
                                 option-value="item_id"
                                 option-text="item_name"
                                 placeholder="Select product">
                            </model-list-select>
                            <!-- <base-input label="Product oid" placeholder="Product oid" v-model="thirdbannermodal.target" input-classes="" /> -->
                          </div>
                          <div class="col-lg-4">
                            <label class="form-control-label mt-2">Upload Image</label>
                            <input
                              id="file"
                              ref="myFiles"
                              type="file"
                              aria-describedby="addon-right addon-left"
                              alternative=""
                              class="form-control form-control-alternative"
                              name="img"
                              @change="bannerhadleimage($event,'third','')"
                            >
                            <base-progress type="success" label="File uploaded"></base-progress>
                            <div>
                              <img :src="thirdbannermodal.static_img" class="img-fluid rounded shadow mt-2 p-2">
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-center mt-4">
                          <div class="col-md-12">
                            <base-button
                                v-if="pagepermission.update"
                                @click="updatethirdbanner()"
                                type="primary"
                                id="updatethirdbtn"
                                class="float-center"
                                block
                                size="lg"
                              >
                                <span>Update banner</span>
                            </base-button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </form>

                  <!-- no result found -->
                  <!-- <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                    <div class="col-md-3 mx-auto mt-4 text-center">
                        <img src="img/theme/no-result.png" class="img-fluid">
                        <p class="mt-4">No stores created yet.</p>
                    </div>
                  </div> -->

                  <!-- shimmer starts here -->
                  <!-- <div class="col-md-12" v-if="storeData.length==0 && !datanotfound">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 mt-3">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- shimmer ends here -->
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane id="fourthbanner">
          <span slot="title">
              Fourth Banner
          </span>
            
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''" style="background-color: #efefef;">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Fourth Banner of {{categoryData.inventory_category_name}} category
                      </h3>
                    </div>
                    <div class="col text-right">
                      <!-- <base-button type="primary"><i class="fas fa-plus"></i> Create Banner</base-button> -->
                    </div>
                  </div>
                </div>
                <div class="container pb-5 pt-3">
                  <form @submit.prevent class="row">
                    <div class="col-lg-12 mt-2">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-1">
                            <label class="form-control-label text-white">.</label>
                            <base-button
                              id="deletebanner"
                              type="danger"
                              @click="deletefourthimage()"
                              class="float-center"
                              block
                            >
                              <i class="fas fa-trash"></i><span></span>
                            </base-button>
                          </div>
                          <div class="col-lg-3">
                            <label class="form-control-label">Primary Banner Link To</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="fourthbannermodal.todo" @change="getchange_bannerdiv('fourth')">
                                  <option value="openCategory">Category</option>
                                  <option value="openProductFeed">Sub-Category</option>
                                  <option value="openProduct">Product</option>
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" id="fourthcategory">
                            <label class="form-control-label">Category</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="fourthbannermodal.target" @change="getsubcategory()">
                                  <option v-for="category in allcategoryData" :key="category._id" :value="category._id">{{category.inventory_category_name}}</option>
                                  
                                  <!-- <option>Category 3</option> -->
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" id="fourthsubcategory">
                            <label class="form-control-label">Subcategory</label>
                            <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="fourthbannermodal.target">
                                  <option v-for="subcategory in subcategoryData" :key="subcategory._id" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                                  <!-- <option>Sub-category 2</option>
                                  <option>Sub-category 3</option> -->
                            </select>
                          </div>
                          <div class="col-lg-4 d-none" id="fourthproduct">
                             <label class="form-control-label">Product</label>
                            <model-list-select :list="productData"
                                 v-model="thirdbannermodal.target"
                                 option-value="item_id"
                                 option-text="item_name"
                                 placeholder="Select product">
                            </model-list-select>
                            <!-- <base-input label="Product oid" placeholder="Product oid" v-model="fourthbannermodal.target" input-classes="" /> -->
                          </div>
                          <div class="col-lg-4">
                            <label class="form-control-label mt-2">Upload Image</label>
                            <input
                              id="file"
                              ref="myFiles"
                              type="file"
                              aria-describedby="addon-right addon-left"
                              alternative=""
                              class="form-control form-control-alternative"
                              name="img"
                              @change="bannerhadleimage($event,'fourth','')"
                            >
                            <base-progress type="success" label="File uploaded"></base-progress>
                            <div>
                              <img :src="fourthbannermodal.static_img" class="img-fluid rounded shadow mt-2 p-2">
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-center mt-4">
                          <div class="col-md-12">
                            <base-button
                                v-if="pagepermission.update"
                                @click="updatefourthbanner()"
                                type="primary"
                                id="updatefourthbtn"
                                class="float-center"
                                block
                                size="lg"
                              >
                                <span>Update banner</span>
                            </base-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane id="bannerposition">
          <span slot="title">
              Banner Positions
          </span>
            
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''" style="background-color: #efefef;">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Where does the banners get visible in app
                      </h3>
                    </div>
                    <div class="col text-right">
                      <!-- <base-button type="primary"><i class="fas fa-plus"></i> Create Banner</base-button> -->
                    </div>
                  </div>
                </div>
                <div class="container pb-5 pt-3">
                  <form @submit.prevent class="row">
                    <div class="col-lg-12 mt-2">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-12">
                            <img src="img/theme/banner_showcase.jpg" class="img-fluid">
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import { Tabs } from '@/components';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import { ModelListSelect } from 'vue-search-select'
  import 'vue-search-select/dist/VueSearchSelect.css'
   import VueSwal from 'vue-swal';
  import axios from "axios";
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
      Tabs,
      DropzoneFileUpload,
      ModelListSelect
    },
    data() {
      return {
        inputs: {
          file: []
        },
        categoryData:[],
        allcategoryData:[],
        subcategoryData:[],
        thirdbannerData:[],
        fourthbannerData:[],
        storeData:[],
        pagepermission:{},
        productData:[],
        pagepermission:{},
        banneruploadPercentage:0,
        imagearr:[],
        createcategory:false,
        editcategory:false,
        editbanner:false,
        uploadPercentage:0,
        categorymodal:{
          inventory_category_id:'',
          inventory_category_name:'',
          inventory_category_type:'product',
          status:'',
          inventory_category_icon:'',
          inventory_category_img:'',
          store:'4uw81toukjwkdqaj',
          region:'',
          banner_primary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414002181banner-1700.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414092328toppng.com-grocery-png-1000x667.png',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0}],
          banner_secondary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414002181banner-1700.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414092328toppng.com-grocery-png-1000x667.png',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}],
          is_featured:false,
          sequenced_at:0,
          created_by:localStorage.getItem('_id'),
          inventory_category_theme_color:'#d3d3d3',
        },
        editcategorymodal:{
          inventory_category_id:'',
          inventory_category_name:'',
          inventory_category_type:'',
          status:'',
          inventory_category_icon:'',
          inventory_category_img:'',
          store:'',
          region:'',
          banner_primary:[{url:'',todo:'',target:'',sequenced_at:0}],
          banner_secondary:[{url:'',todo:'',target:'',sequenced_at:0}],
          is_featured:'',
          sequenced_at:'',
          created_by:localStorage.getItem('_id'),
          inventory_category_theme_color:'',
        },
        thirdbannermodal:{
          banner_id:'',
          banner_name:'',
          banner_type:'Fixed_Banner_Middle_Primary_Category',
          placement_type:'category',
          placement_oid:this.$route.params.id,
          static_img:'',
          todo:'',
          target:'',
          store:'',
          is_featured:false,
          sequenced_at:0,
          status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        fourthbannermodal:{
          banner_id:'',
          banner_name:'',
          banner_type:'Fixed_Banner_Bottom_Primary_Category',
          placement_type:'category',
          placement_oid:this.$route.params.id,
          static_img:'',
          todo:'',
          target:'',
          store:'',
          is_featured:false,
          sequenced_at:0,
          status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted () {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{"_id":this.$route.params.id}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.categoryData=response.data.details[0];
        if(this.categoryData.banner_primary.length==0){
          this.categoryData.banner_primary=[{url:'',todo:'',target:'',sequenced_at:0}];
        }
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.allcategoryData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{"placement_oid": this.$route.params.id,"banner_type":"Fixed_Banner_Middle_Primary_Category"}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.thirdbannermodal=response.data.details[0];
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{"placement_oid": this.$route.params.id,"banner_type":"Fixed_Banner_Bottom_Primary_Category"}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.fourthbannermodal=response.data.details[0];
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        }
            
      })
     axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',{ "details" :{},"offset" : "0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.productData=response.data.details;
        }else{
            this.productData=[];
        }
        
            
      })

    },
    methods: {
          getalldata(){
            axios
              .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
              .then(response => {
                console.log(response);
                if(response.data.details){
                this.categoryData=response.data.details;
                }else{
                  this.categoryData=[];
                }
                    
              })
          },
          createcategorymodal(){
            this.createcategory=true;
          },
          bannerhadleimage: function(event,type,index) {
                // Reference to the DOM input element
                this.uploadPercentage =0;
                var input = event.target;
                // Ensure that you have a file before attempting to read it
                if(input.files[0].size < 5000000){

                const fd= new FormData();
                fd.append('image',input.files[0],input.files[0].name);
                axios
                .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
                .then(response => {
                  console.log(response);
                  if(type=='primary'){
                    this.categoryData.banner_primary[index].url=response.data.imageUrl;
                    console.log(this.categoryData.banner_primary[index].url);
                  }else if(type=='secondary'){
                    this.categoryData.banner_secondary[index].url=response.data.imageUrl;
                    console.log(this.categoryData.banner_secondary[index].url);
                  }else if(type=='third'){
                    this.thirdbannermodal.static_img=response.data.imageUrl;
                    console.log(this.thirdbannermodal.static_img);
                  }else if(type=='fourth'){
                    this.fourthbannermodal.static_img=response.data.imageUrl;
                    console.log(this.fourthbannermodal.static_img);
                  }
                    
                })

                }else{

                swal({
                  title:'File too large!',
                  text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                  icon:'warning',
                 }).then((result) => {
                   
                })

              }

        },
        getchange_div(type,index){

          // let banner_secondary_todo=this.categoryData.banner_secondary[index].todo;
          // let banner_primary_todo=this.categoryData.banner_primary[index].todo;

          console.log(this.categoryData.banner_secondary[index].todo);
              if(type=='primary'){
                if(this.categoryData.banner_primary[index].todo=='openCategory' && type=='primary'){
                   document.getElementById("pcategory"+index).classList.remove('d-none');
                   document.getElementById("psubcategory"+index).classList.add('d-none');
                   document.getElementById("pproduct"+index).classList.add('d-none');
                }
                if(this.categoryData.banner_primary[index].todo=='openProductFeed' && type=='primary'){
                   document.getElementById("pcategory"+index).classList.add('d-none');
                   document.getElementById("psubcategory"+index).classList.remove('d-none');
                   document.getElementById("pproduct"+index).classList.add('d-none');

                }
                if(this.categoryData.banner_primary[index].todo=='openProduct' && type=='primary'){
                   document.getElementById("pcategory"+index).classList.add('d-none');
                   document.getElementById("psubcategory"+index).classList.add('d-none');
                   document.getElementById("pproduct"+index).classList.remove('d-none');

                }

              }else{

              if(this.categoryData.banner_secondary[index].todo=='openCategory' && type=='secondary'){
                 document.getElementById("scategory"+index).classList.remove('d-none');
                 document.getElementById("ssubcategory"+index).classList.add('d-none');
                 document.getElementById("sproduct"+index).classList.add('d-none');
              }
              if(this.categoryData.banner_secondary[index].todo=='openProductFeed' && type=='secondary'){
                 document.getElementById("scategory"+index).classList.add('d-none');
                 document.getElementById("ssubcategory"+index).classList.remove('d-none');
                 document.getElementById("sproduct"+index).classList.add('d-none');

              }
              if(this.categoryData.banner_secondary[index].todo=='openProduct' && type=='secondary'){
                 document.getElementById("scategory"+index).classList.add('d-none');
                 document.getElementById("ssubcategory"+index).classList.add('d-none');
                 document.getElementById("sproduct"+index).classList.remove('d-none');

              }
            }
              
        },
        getchange_bannerdiv(type){

            if(this.thirdbannermodal.todo=='openCategory' && type=='third'){
                 document.getElementById("thirdcategory").classList.remove('d-none');
                 document.getElementById("thirdsubcategory").classList.add('d-none');
                 document.getElementById("thirdproduct").classList.add('d-none');
              }
              if(this.thirdbannermodal.todo=='openProductFeed' && type=='third'){
                 document.getElementById("thirdcategory").classList.add('d-none');
                 document.getElementById("thirdsubcategory").classList.remove('d-none');
                 document.getElementById("thirdproduct").classList.add('d-none');

              }
              if(this.thirdbannermodal.todo=='openProduct' && type=='third'){
                 document.getElementById("thirdcategory").classList.add('d-none');
                 document.getElementById("thirdsubcategory").classList.add('d-none');
                 document.getElementById("thirdproduct").classList.remove('d-none');

              }

              if(this.fourthbannermodal.todo=='openCategory' && type=='fourth'){
                 document.getElementById("fourthcategory").classList.remove('d-none');
                 document.getElementById("fourthsubcategory").classList.add('d-none');
                 document.getElementById("fourthproduct").classList.add('d-none');
              }
              if(this.fourthbannermodal.todo=='openProductFeed' && type=='fourth'){
                 document.getElementById("fourthcategory").classList.add('d-none');
                 document.getElementById("fourthsubcategory").classList.remove('d-none');
                 document.getElementById("fourthproduct").classList.add('d-none');

              }
              if(this.fourthbannermodal.todo=='openProduct' && type=='fourth'){
                 document.getElementById("fourthcategory").classList.add('d-none');
                 document.getElementById("fourthsubcategory").classList.add('d-none');
                 document.getElementById("fourthproduct").classList.remove('d-none');

              }

        },
        hadleimage: function(event,type) {
                // Reference to the DOM input element
                this.uploadPercentage =0;
                var input = event.target;
                // Ensure that you have a file before attempting to read it
                if(input.files[0].size < 5000000){

                const fd= new FormData();
                fd.append('image',input.files[0],input.files[0].name);
                axios
                .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
                .then(response => {
                  console.log(response);
                  if(type=='icon'){
                    this.categorymodal.inventory_category_icon=response.data.imageUrl;
                    console.log(this.categorymodal.inventory_category_icon);
                  }else if(type=='image'){
                    this.categorymodal.inventory_category_img=response.data.imageUrl;
                    console.log(this.categorymodal.inventory_category_img);
                  }else if(type=='editicon'){
                    this.editcategorymodal.inventory_category_icon=response.data.imageUrl;
                    console.log(this.editcategorymodal.inventory_category_icon);
                  }else if(type=='editimage'){
                    this.editcategorymodal.inventory_category_img=response.data.imageUrl;
                    console.log(this.editcategorymodal.inventory_category_img);
                  }
                    
                })

                }else{

                swal({
                  title:'File too large!',
                  text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                  icon:'warning',
                 }).then((result) => {
                   
                })

              }

        },

        async bannerupload(event,type,uploatype) {
              // Reference to the DOM input element
                //console.log(event);
                // document.getElementById("editassignmentbtn").classList.add('d-none');
                // document.getElementById("editaddassignmentinvalid").classList.remove('d-none');
               this.banneruploadPercentage =0;
                if(uploatype=='file1'){
                  for( var i = 0; i < this.$refs.file1.files.length; i++ ){
                      let file = this.$refs.file1.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }
                if(uploatype=='file3'){
                  for( var i = 0; i < this.$refs.file3.files.length; i++ ){
                      let file = this.$refs.file3.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }

        },
        upload(file,type){
          //console.log(file);
          let fd = new FormData();
          fd.append('image',file,file.name);
          axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.banneruploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.banneruploadPercentage)}.bind(this)}).then(response => {
            //console.log(response);
            this.imagearr.push({"url":response.data.imageUrl,"sequenced_at":1});
            //console.log(this.imagearr);
            if(type=='add'){
              this.categorymodal.banner_primary=this.imagearr;
              //console.log(this.assignmentmodel.assignment_file);
            }
            else{
              this.categorymodal.banner_secondary=this.imagearr;
            }
            //console.log();
         })

        },
        addmore(){
        this.categoryData.banner_secondary.push({url:'',todo:'',target:'',sequenced_at:0})
        },
        deletethirdimage(){
            this.thirdbannermodal.static_img='';
            this.thirdbannermodal.todo='';
            this.thirdbannermodal.target='';
        },
        deletefourthimage(){
            this.fourthbannermodal.static_img='';
            this.fourthbannermodal.todo='';
            this.fourthbannermodal.target='';
        },
        deletefirstimage(index){
              this.categoryData.banner_primary.splice(index,1);
        },
        deletesecondimage(index){
              this.categoryData.banner_secondary.splice(index,1);
        },
        savecategory() {
          //region:this.categorymodal.region,banner_primary:this.categorymodal.banner_primary,banner_secondary:this.categorymodal.banner_secondary,
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_inventory_category',{inventory_category_name : this.categorymodal.inventory_category_name,inventory_category_type:this.categorymodal.inventory_category_type,status:this.categorymodal.status,inventory_category_icon:this.categorymodal.inventory_category_icon,inventory_category_img:this.categorymodal.inventory_category_img,store:this.categorymodal.store,is_featured:this.categorymodal.is_featured,sequenced_at:this.categorymodal.sequenced_at,created_by:this.categorymodal.created_by,inventory_category_theme_color:this.categorymodal.inventory_category_theme_color,banner_primary:this.categorymodal.banner_primary,banner_secondary:this.categorymodal.banner_secondary},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            let _this=this;
            document.getElementById("updatebtn").classList.remove('btn-primary');
            document.getElementById("updatebtn").classList.add('btn-success');
            document.getElementById("updatebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn").classList.remove('btn-success');
            document.getElementById("updatebtn").classList.add('btn- ');
            document.getElementById("updatebtn").innerHTML="Update";
            window.location.reload()
            }, 2000); 

          })

        },
        openeditcategory(row){
            this.editcategory=true;
            this.editcategorymodal.inventory_category_id =row.inventory_category_id;
            this.editcategorymodal.inventory_category_name =row.inventory_category_name;
            this.editcategorymodal.inventory_category_type =row.inventory_category_type;
            this.editcategorymodal.status =row.status;
            this.editcategorymodal.inventory_category_icon =row.inventory_category_icon;
            this.editcategorymodal.inventory_category_img =row.inventory_category_img;
            this.editcategorymodal.store =row.store;
            this.editcategorymodal.region =row.region;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.is_featured =row.is_featured;
            this.editcategorymodal.sequenced_at =row.sequenced_at;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
          },
          openbanner(row){
            this.editbanner=true;
            this.editcategorymodal.inventory_category_id =row.inventory_category_id;
            this.editcategorymodal.inventory_category_name =row.inventory_category_name;
            this.editcategorymodal.inventory_category_type =row.inventory_category_type;
            this.editcategorymodal.status =row.status;
            this.editcategorymodal.inventory_category_icon =row.inventory_category_icon;
            this.editcategorymodal.inventory_category_img =row.inventory_category_img;
            this.editcategorymodal.store =row.store;
            this.editcategorymodal.region =row.region;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.banner_secondary =row.banner_secondary;
            this.editcategorymodal.is_featured =row.is_featured;
            this.editcategorymodal.sequenced_at =row.sequenced_at;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
          },

        updatecategory() {
          //region:this.editcategorymodal.region,banner_primary:this.editcategorymodal.banner_primary,banner_secondary:this.editcategorymodal.banner_secondary,is_featured:this.editcategorymodal.is_featured
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_category',{inventory_category_id : this.editcategorymodal.inventory_category_id,inventory_category_name : this.editcategorymodal.inventory_category_name,inventory_category_type:this.editcategorymodal.inventory_category_type,status:this.editcategorymodal.status,inventory_category_icon:this.editcategorymodal.inventory_category_icon,inventory_category_img:this.editcategorymodal.inventory_category_img,store:this.editcategorymodal.store,sequenced_at:this.editcategorymodal.sequenced_at,created_by:this.editcategorymodal.created_by,banner_primary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404864493banner-1%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404896351banner-1-top%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}],banner_secondary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}],is_featured:this.editcategorymodal.is_featured},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
          }, 2000);

           // window.location.reload()

          })

        },
        updatebanner() {
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_category',{inventory_category_id : this.categoryData.inventory_category_id,created_by:this.categoryData.created_by,banner_primary:this.categoryData.banner_primary,banner_secondary:this.categoryData.banner_secondary},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatefirstbtn").classList.remove('btn-primary');
            document.getElementById("updatefirstbtn").classList.add('btn-success');
            document.getElementById("updatefirstbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatefirstbtn").classList.remove('btn-success');
            document.getElementById("updatefirstbtn").classList.add('btn-primary');
            document.getElementById("updatefirstbtn").innerHTML="Update";
          }, 2000);

           // window.location.reload()

          })

        },
        updatesecondbanner() {
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_category',{inventory_category_id : this.categoryData.inventory_category_id,created_by:this.categoryData.created_by,banner_primary:this.categoryData.banner_primary,banner_secondary:this.categoryData.banner_secondary},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatesecondbtn").classList.remove('btn-primary');
            document.getElementById("updatesecondbtn").classList.add('btn-success');
            document.getElementById("updatesecondbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatesecondbtn").classList.remove('btn-success');
            document.getElementById("updatesecondbtn").classList.add('btn-primary');
            document.getElementById("updatesecondbtn").innerHTML="Update";
          }, 2000);

           // window.location.reload()

          })

        },
        deletecategory(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_inventory_category',querystring.stringify({inventory_category_id :row.inventory_category_id,created_by:row.created_by}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your category has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      updatethirdbanner(){
        if(this.thirdbannermodal.banner_id){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_banner',querystring.stringify({banner_name : this.thirdbannermodal.banner_name,banner_id : this.thirdbannermodal.banner_id,banner_type:this.thirdbannermodal.banner_type,placement_type:this.thirdbannermodal.placement_type,placement_oid:this.thirdbannermodal.placement_oid,static_img:this.thirdbannermodal.static_img,todo:this.thirdbannermodal.todo,target:this.thirdbannermodal.target,store:this.thirdbannermodal.store,is_featured:this.thirdbannermodal.is_featured,sequenced_at:this.thirdbannermodal.sequenced_at,status:this.thirdbannermodal.status,created_by:this.thirdbannermodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatethirdbtn").classList.remove('btn-primary');
            document.getElementById("updatethirdbtn").classList.add('btn-success');
            document.getElementById("updatethirdbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatethirdbtn").classList.remove('btn-success');
            document.getElementById("updatethirdbtn").classList.add('btn-primary');
            document.getElementById("updatethirdbtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }else{
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_banner',querystring.stringify({banner_name : this.thirdbannermodal.banner_type,banner_type:this.thirdbannermodal.banner_type,placement_type:this.thirdbannermodal.placement_type,placement_oid:this.thirdbannermodal.placement_oid,static_img:this.thirdbannermodal.static_img,todo:this.thirdbannermodal.todo,target:this.thirdbannermodal.target,store:this.thirdbannermodal.store,is_featured:this.thirdbannermodal.is_featured,sequenced_at:this.thirdbannermodal.sequenced_at,status:this.thirdbannermodal.status,created_by:this.thirdbannermodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatethirdbtn").classList.remove('btn-primary');
            document.getElementById("updatethirdbtn").classList.add('btn-success');
            document.getElementById("updatethirdbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatethirdbtn").classList.remove('btn-success');
            document.getElementById("updatethirdbtn").classList.add('btn-primary');
            document.getElementById("updatethirdbtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
        
      },
      updatefourthbanner(){
        if(this.fourthbannermodal.banner_id){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_banner',querystring.stringify({banner_name : this.fourthbannermodal.banner_name,banner_id : this.fourthbannermodal.banner_id,banner_type:this.fourthbannermodal.banner_type,placement_type:this.fourthbannermodal.placement_type,placement_oid:this.fourthbannermodal.placement_oid,static_img:this.fourthbannermodal.static_img,todo:this.fourthbannermodal.todo,target:this.fourthbannermodal.target,store:this.fourthbannermodal.store,is_featured:this.fourthbannermodal.is_featured,sequenced_at:this.fourthbannermodal.sequenced_at,status:this.fourthbannermodal.status,created_by:this.fourthbannermodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatefourthbtn").classList.remove('btn-primary');
            document.getElementById("updatefourthbtn").classList.add('btn-success');
            document.getElementById("updatefourthbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatefourthbtn").classList.remove('btn-success');
            document.getElementById("updatefourthbtn").classList.add('btn-primary');
            document.getElementById("updatefourthbtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }else{
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_banner',querystring.stringify({banner_name : this.fourthbannermodal.banner_type,banner_type:this.fourthbannermodal.banner_type,placement_type:this.fourthbannermodal.placement_type,placement_oid:this.fourthbannermodal.placement_oid,static_img:this.fourthbannermodal.static_img,todo:this.fourthbannermodal.todo,target:this.fourthbannermodal.target,store:this.fourthbannermodal.store,is_featured:this.fourthbannermodal.is_featured,sequenced_at:this.fourthbannermodal.sequenced_at,status:this.fourthbannermodal.status,created_by:this.fourthbannermodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatefourthbtn").classList.remove('btn-primary');
            document.getElementById("updatefourthbtn").classList.add('btn-success');
            document.getElementById("updatefourthbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatefourthbtn").classList.remove('btn-success');
            document.getElementById("updatefourthbtn").classList.add('btn-primary');
            document.getElementById("updatefourthbtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
      },

    },
  };
</script>
<style></style>
