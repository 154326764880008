<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Notification History 
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button type="primary" @click="addstorepopup">Create notification <i class="fas fa-paper-plane"></i></base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="notificationData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Notification</th>
                  <th>Date & Time</th>
                  <th>Image</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0 font-weight-bold">{{row.notif_title}}</p>
                      <p class="mb-0">{{row.notif_message}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{format_datetime(row.created_at)}}</p>
                    </td>
                    <td class="budget">
                      <img alt="Image placeholder" class="rounded" :src="row.notif_img" height="80">
                    </td>
                    <td class="text-right">
                      <!-- <base-button type="primary" size="sm" @click="editstorepopup(row)" >Edit</base-button> -->
                      <base-button type="danger" size="sm" @click="deletestore(row)">Delete</base-button>
                      <!-- <base-button type="info" size="sm" @click="editstorepopup(row)">View</base-button> -->
                    </td>
                </template>
              </base-table>
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
            <!-- no result found -->
            <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
              <div class="col-md-3 mx-auto mt-4 text-center">
                  <img src="img/theme/no-result.png" class="img-fluid">
                  <p class="mt-4">No notifications created.</p>
              </div>
            </div>
            <!-- shimmer starts here -->
            <div class="col-md-12" v-if="notificationData.length==0 && !datanotfound">
              <div class="row justify-content-center">
                <div class="col-lg-12 mt-3">
                    <div class="card border-0 shadow">
                       <div class="card-body pt-2 pb-1">
                            <div class="row">
                                <div class="col-md-3 col-3 mt-1 mb-1">
                                    <span class="circle-shimmer shine"></span>
                                </div>
                                <div class="col-md-9 col-9 mt-1 mb-1">
                                    <div class="div-shimmer w-100 mt-0 pr-4">
                                      <span class="line-shimmer w-100 shine"></span>
                                      <span class="line-shimmer w-100 shine"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card border-0 shadow">
                       <div class="card-body pt-2 pb-1">
                            <div class="row">
                                <div class="col-md-3 col-3 mt-1 mb-1">
                                    <span class="circle-shimmer shine"></span>
                                </div>
                                <div class="col-md-9 col-9 mt-1 mb-1">
                                    <div class="div-shimmer w-100 mt-0 pr-4">
                                      <span class="line-shimmer w-100 shine"></span>
                                      <span class="line-shimmer w-100 shine"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card border-0 shadow">
                       <div class="card-body pt-2 pb-1">
                            <div class="row">
                                <div class="col-md-3 col-3 mt-1 mb-1">
                                    <span class="circle-shimmer shine"></span>
                                </div>
                                <div class="col-md-9 col-9 mt-1 mb-1">
                                    <div class="div-shimmer w-100 mt-0 pr-4">
                                      <span class="line-shimmer w-100 shine"></span>
                                      <span class="line-shimmer w-100 shine"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card border-0 shadow">
                       <div class="card-body pt-2 pb-1">
                            <div class="row">
                                <div class="col-md-3 col-3 mt-1 mb-1">
                                    <span class="circle-shimmer shine"></span>
                                </div>
                                <div class="col-md-9 col-9 mt-1 mb-1">
                                    <div class="div-shimmer w-100 mt-0 pr-4">
                                      <span class="line-shimmer w-100 shine"></span>
                                      <span class="line-shimmer w-100 shine"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <!-- shimmer ends here -->
          </div>
        </div>
      </div>
    </div>
    <!-- create notification popup -->
    <modal :show.sync="addstore">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create notification</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="Notification title" placeholder="e.g, New offer for you..." v-model="storemodal.notif_title" input-classes="" />
                <div
                    v-if="$v.storemodal.notif_title.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6">
                <base-input label="Notification description" placeholder="We have some awesome offers for you..." v-model="storemodal.notif_message" input-classes="" />
                <div
                    v-if="$v.storemodal.notif_message.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Target</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="storemodal.notif_todo" @change="getchange_bannerdiv('p')">
                    <option value="openCategory">Open Category</option>
                    <option value="openProductFeed">Open Sub-category</option>
                    <option value="openProduct">Open Product</option>
               </select>
               <div
                    v-if="$v.storemodal.notif_todo.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6 d-none" id="pcategory">
               <label class="form-control-label">Category</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="storemodal.notif_link">
                    <option v-for="category in categoryData" :key="category._id" :value="category._id">{{category.inventory_category_name}}</option>
                    <!-- <option>Category 2</option>
                    <option>Category 3</option> -->
               </select>
               <div
                    v-if="$v.storemodal.notif_link.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6 d-none" id="psubcategory">
               <label class="form-control-label">Sub-category</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="storemodal.notif_link">
                    <option v-for="subcategory in subcategoryData" :key="subcategory._id" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
               </select>
               <div
                    v-if="$v.storemodal.notif_link.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6 d-none" id="pproduct">
              <label class="form-control-label">Product</label>
                <model-list-select :list="productData"
                     v-model="storemodal.notif_link"
                     option-value="item_id"
                     option-text="item_name"
                     placeholder="Select product">
                </model-list-select>
                <div
                    v-if="$v.storemodal.notif_link.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">Notification image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'addstore')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="storemodal.notif_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.storemodal.$error" @click="$v.storemodal.$touch();createstore()"
          type="success"
          id="createstorebtn"
          class="float-center"
        >
          <span>Send notification</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addstore = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  var _ = require('lodash');
  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { ModelListSelect } from 'vue-search-select'
  import 'vue-search-select/dist/VueSearchSelect.css'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import moment from 'moment';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      ModelListSelect
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addstore: false,
        editstore: false,
        addregion: false,
        editregion: false,
        datanotfound:false,
        userData:[],
        productData:[],
        uploadPercentage:0,
        notificationData:[],
        categoryData:[],
        subcategoryData:[],
        regionData:[],
        storemodal:{
          notif_todo:'',
          notif_title:'',
          notif_message:'',
          notif_img:'',
          notif_link:'',
          created_by:localStorage.getItem('_id'),
        },
        editstoremodal:{
          store_name:'',
          store_img:'',
          store_status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        regionmodal:{
          region_name:'',
          region_img:'',
          region_store:'',
          region_type:'PostCode',
          region_status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        editregionmodal:{
          region_name:'',
          region_img:'',
          region_store:'',
          region_type:'PostCode',
          region_status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {

      storemodal: {
        notif_todo:{
          required
        },
        notif_title:{
          required
        },
        notif_message:{
          required
        },
        notif_link:{
          required
        },
      },
      

    },
    mounted() {
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_notification',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.notificationData=response.data.details;
        }else{
            this.notificationData=[];
            this.datanotfound=true;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.categoryData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',{ "details" :{},"offset" : "0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.productData=response.data.details;
        }else{
            this.productData=[];
        }
        
            
      })
    },
    methods: {
      getalldata(){

        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_notification',{ "details" :{}},this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.notificationData=response.data.details;
            }else{
                this.notificationData=[];
                this.datanotfound=true;
            }  
        })

      },
      addstorepopup(row){
        console.log(row);
        this.addstore=true;
      },
      editstorepopup(row){
        console.log(row);
        this.editstoremodal=row;
        this.editstore=true;
      },
      addregionpopup(){
        this.addregion=true;
      },
      editregionpopup(row){
        this.editregionmodal=row;
        this.editregion=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addstore'){
                this.storemodal.notif_img=response.data.imageUrl;
                console.log(this.storemodal.notif_img);
              }if(type=='editstore'){
                this.editstoremodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }else{
                this.storemodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    getchange_bannerdiv(type){

            if(this.storemodal.notif_todo=='openCategory' && type=='p'){
                 document.getElementById("pcategory").classList.remove('d-none');
                 document.getElementById("psubcategory").classList.add('d-none');
                 document.getElementById("pproduct").classList.add('d-none');
              }
              if(this.storemodal.notif_todo=='openProductFeed' && type=='p'){
                 document.getElementById("pcategory").classList.add('d-none');
                 document.getElementById("psubcategory").classList.remove('d-none');
                 document.getElementById("pproduct").classList.add('d-none');

              }
              if(this.storemodal.notif_todo=='openProduct' && type=='p'){
                 document.getElementById("pcategory").classList.add('d-none');
                 document.getElementById("psubcategory").classList.add('d-none');
                 document.getElementById("pproduct").classList.remove('d-none');

              }

              // if(this.fourthbannermodal.todo=='openCategory' && type=='fourth'){
              //    document.getElementById("fourthcategory").classList.remove('d-none');
              //    document.getElementById("fourthsubcategory").classList.add('d-none');
              //    document.getElementById("fourthproduct").classList.add('d-none');
              // }
              // if(this.fourthbannermodal.todo=='openProductFeed' && type=='fourth'){
              //    document.getElementById("fourthcategory").classList.add('d-none');
              //    document.getElementById("fourthsubcategory").classList.remove('d-none');
              //    document.getElementById("fourthproduct").classList.add('d-none');

              // }
              // if(this.fourthbannermodal.todo=='openProduct' && type=='fourth'){
              //    document.getElementById("fourthcategory").classList.add('d-none');
              //    document.getElementById("fourthsubcategory").classList.add('d-none');
              //    document.getElementById("fourthproduct").classList.remove('d-none');

              // }

        },
    createstore(){
        if(this.$v.storemodal.$error===false){
        const querystring = require('querystring');
               axios
                .post(process.env.VUE_APP_API_ENDPOINT+'get_user_details',{ "details":{},"offset":"0"},this.auth1)
                .then(userresponse => {
                  //console.log(response);
                  let datetime= new Date();
                  datetime=datetime.toString();
                  if(userresponse.data.details){
                  this.userData=userresponse.data.details;
                  let useroid =  _.map(this.userData, users => (users._id));
                  axios.post(process.env.VUE_APP_API_ENDPOINT+'create_notification',querystring.stringify({user_oid:useroid,notif_todo : this.storemodal.notif_todo,notif_title:this.storemodal.notif_title,notif_message:this.storemodal.notif_message,notif_img:this.storemodal.notif_img,notif_link:this.storemodal.notif_link,created_by:this.storemodal.created_by,notif_date:datetime}),this.auth)
                      .then(response => {

                     for(let x in this.userData){
                        if(this.userData[x].user_fcm){
                          axios
                           .post(process.env.VUE_APP_API_ENDPOINT+'push_notification',querystring.stringify({ fcm_token:this.userData[x].user_fcm,user_oid:this.userData[x]._id,notif_todo:this.storemodal.notif_todo,notif_title:this.storemodal.notif_title,notif_message:this.storemodal.notif_message,notif_img:this.storemodal.notif_img,notif_thumbnail:this.storemodal.notif_img,notif_link:this.storemodal.notif_link,sender_id:'Admin',notification_id:response.data.details.notification_id}),this.auth)
                          .then(response1 => {

                            // console.log(response);
                            
                            
                          })

                        }

                      }
                    })
                 
                  }
            console.log(response);
            this.message=response.data.message;
            let _this=this;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
            _this.addstore=false;
            _this.getalldata();
          }, 2000);

           // window.location.reload()

          })
        }
        
      },
      deletestore(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_notification',querystring.stringify({notification_id :row.notification_id,created_by:row.created_by}),this.auth)
          .then(response => {
            let _this=this;
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              _this.getalldata();
            })
            })
           }
        });
    },
    format_datetime(value){
           if(value) {
             return moment.utc(new Date(value)).format("LLL")
            }
      },

    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
