<template>
  <div>
    <div class="container-fluid my-4">
      <div class="row mb-0">
        <div class="col-12">
          <div class="card shadow mb-2" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Sub-categories
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button type="primary" @click="createsubcatmodal"><i class="fas fa-plus"></i> Create</base-button>
                </div>
              </div>
            </div>
            <!-- dynamic rendering of sub-categories -->
            <div class="card-body p-0">
              <div class="row p-2 mx-0 bg-table-header border-bottom border-top">
                <div class="col-md-1">
                  <span>S. No.</span>
                </div>
                <div class="col-md-6">
                  <span>Sub-category</span>
                </div>
                <div class="col-md-2">
                  <span>Thumnbail</span>
                </div>
                <!-- <div class="col-md-2">
                  <span>Banner Image</span>
                </div> -->
                <!-- <div class="col-md-2">
                  <span>Status</span>
                </div> -->
                <div class="col-md-3 text-right">
                  <span>Action</span>
                </div>
              </div>
              <draggable class="draggable-items" v-model="subcategoryData" group="people" @start="drag=true" @end="drag=false">
                <div v-for="subcategory, index in subcategoryData" :key="subcategory.id" class="row border-bottom p-2 mx-0 cursor-drag">
                  <div class="col-md-1 align-self-center">
                    <span>{{index+1}}</span>
                  </div>
                  <div class="col-md-6 align-self-center">
                    <span>{{subcategory.inventory_subcategory_name}}</span>
                  </div>
                  <!-- <div class="col-md-1 align-self-center">
                    <img alt="Image placeholder" class="rounded avatar bg-white" v-if="subcategory.inventory_subcategory_icon" :src="subcategory.inventory_subcategory_icon">
                  </div> -->
                  <div class="col-md-2 align-self-center">
                    <img alt="Image placeholder" class="rounded" v-if="subcategory.inventory_subcategory_img" :src="subcategory.inventory_subcategory_img" width="100">
                  </div>
                  <!-- <div class="col-md-2 align-self-center">
                    <p class="mb-0">{{subcategory.status}}</p>
                  </div> -->
                  <div class="col-md-3 text-right align-self-center">
                    <!-- <a class="btn btn-primary btn-sm" :href="'#/products/variation/'+row._id+'/'+row.inventory_subcategory_name" icon="fa fa-info">View Variations</a> -->
                    <!-- <a class="btn btn-primary btn-sm" @click="viewsubcategoryproduct(row)" icon="fa fa-info">View product</a> -->
                    <base-dropdown menuOnRight>
                      <base-button slot="title-container" size="sm" type="transparent" class="shadow-none">
                        <i class="fas fa-ellipsis-v"></i>
                      </base-button>
                      <template>
                        <!-- <a class="dropdown-item" @click="openbanner(row)">Banner</a> -->
                        <a class="dropdown-item" @click="openeditsubcategory(subcategory)">Edit</a>
                        <a class="dropdown-item" @click="deletesubcategory(subcategory)">Delete</a>
                      </template>
                    </base-dropdown>
                  </div>
                </div>
              </draggable>
            </div>

            <!-- previously used table -->
            <!-- <div class="table-responsive" style="display: block;">
              <div>
                <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="overflow_visible list" :data="subcategoryData">
                  <template slot="columns">
                    <th>Sub-category</th>
                    <th>Banner Image</th>
                    <th>Status</th>
                    <th class="text-right">Actions</th>
                  </template>
                  <template slot-scope="{row,index}">
                    <td class="budget pr-2 pl-3" >
                      <p class="mb-0 d-flex">
                        <span class="description">{{index+1 }}. <img alt="Image placeholder" class="rounded mx-2" v-if="row.inventory_subcategory_img" :src="row.inventory_subcategory_img" width="50"></span> 
                        <span>
                          <span class="description">{{row.inventory_subcategory_name}}</span> 
                          <span class="mb-0 small d-block">{{row.status}}</span>
                        </span>
                      </p>
                    </td>
                    <td class="budget">
                      <img alt="Image placeholder" class="rounded" :src="row.inventory_category_img" width="100">
                    </td>
                    <td class="text-center px-2">
                      <a class="btn btn-primary btn-sm" :href="'#/products/variation/'+row._id+'/'+row.inventory_subcategory_name" icon="fa fa-info">View Variations</a>
                      <a class="btn btn-primary btn-sm" @click="viewsubcategoryproduct(row)" icon="fa fa-info">View product</a>
                      <base-dropdown menuOnRight>
                        <base-button slot="title-container" size="sm" type="transparent" class="shadow-none">
                          <i class="fas fa-ellipsis-v"></i>
                        </base-button>
                        <template>
                          <a class="dropdown-item" @click="openbanner(row)">Banner</a>
                          <a class="dropdown-item" @click="openeditsubcategory(row)">Edit</a>
                          <a class="dropdown-item" @click="deletesubcategory(row)">Delete</a>
                        </template>
                      </base-dropdown>
                    </td>
                  </template>
                </base-table>
              </div>
            </div> -->
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
        <!-- products of this sub-category -->
        <!-- <div class="col-12">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Products
                  </h3>
                </div>
                <div class="col text-right" :data="subcategoryData">
                  <select class="form-control" slot-scope="{row,index}">
                    <option>Sub-category</option>
                    <option>{{row.inventory_subcategory_name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="overflow_visible list" :data="subcategoryproductData">
                <template slot="columns">
                  <th>Product</th>
                  <th>Stock</th>
                  <th>Status</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget pr-2 pl-3">
                      <p class="mb-0 d-flex description font-weight-400" style="white-space: normal;"><span class="pr-2">{{index+1}}.</span> <img alt="Image placeholder" class="rounded avatar mr-2" :src="row.item_main_img" width="50"> {{row.item_name}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0 description font-weight-400">{{row.item_mp}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0 description font-weight-400">{{row.status}}</p>
                    </td>
                    <td class="text-right">
                      <base-dropdown class="" menuOnRight>
                        <base-button slot="title-container" size="sm" type="transparent" class="shadow-none">
                          <i class="fas fa-ellipsis-v"></i>
                        </base-button>
                             <a class="dropdown-item" :href="'#/products/editproduct/'+row._id">Edit</a>
                            <a class="dropdown-item" @click="openeditproduct(row)">Edit</a>
                            <a class="dropdown-item" @click="deleteproduct(row)">Delete</a>
                      </base-dropdown>
                    </td>
                </template>
              </base-table>
            </div>
            <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div>
          </div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-lg-12">
          <base-button
            type="warning"
            block
            class=""
            @click="savepositions()"
            id="savepositions"
            >
            Save Sub-category Positions
          </base-button>
        </div>
      </div>
    </div>
    <!-- create sub-category -->
    <modal :show.sync="createsubcategory" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create Sub-category</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-4">
                <base-input label="Subategory Name" placeholder="Subategory Name" v-model="categorymodal.inventory_subcategory_name" input-classes="" />
                <div
                    v-if="$v.categorymodal.inventory_subcategory_name.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            <!-- <div class="col-lg-4">
              <label class="form-control-label">Sub Category Ranking</label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="categorymodal.sequenced_at"> 
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
              </select>
            </div> -->
            <!-- <div class="col-lg-4">
              <label class="form-control-label">Make Sub-category as featured</label>
              <br>
              <base-switch v-model="categorymodal.is_featured"></base-switch>
              {{categorymodal.is_featured}}
            </div> -->
            <!-- <div class="col-lg-6">
                <label class="form-control-label">Subategory icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'icon')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="categorymodal.inventory_subcategory_icon" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div> -->
            <div class="col-md-4">
              <base-input label="Meta Title" v-model="categorymodal.meta_title" placeholder="Write meta title here" input-classes="" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">Meta Description</label>
              <textarea class="form-control" v-model="categorymodal.meta_description" id="" rows="2"></textarea>
            </div>
            <div class="col-md-4 meta_tags">
              <label class="form-control-label">Meta Tags</label>
              <textarea class="form-control" v-model="categorymodal.meta_keyword" id="" rows="2"></textarea>
            </div>
            <div class="col-lg-4">
                <label class="form-control-label">Subategory image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'image')"
                >
                <div
                    v-if="$v.categorymodal.inventory_subcategory_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="categorymodal.inventory_subcategory_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-md-4">
              <base-input label="OG Title" placeholder="Write title here" v-model="categorymodal.og_title" input-classes="" />
            </div>
            <div class="col-md-4">
              <base-input label="OG Type" placeholder="Write type here" v-model="categorymodal.og_type" input-classes="" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">OG Description</label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="categorymodal.og_description"></textarea>
            </div>
            <div class="col-lg-4 mt-2">
              <label class="form-control-label">OG Image</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'og_image')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="categorymodal.og_image">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="delete_addcategory_ogimage(categorymodal)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                  </base-button>
                    <img :src="categorymodal.og_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for Web</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for Web"   id="file" ref="myFiles" name="img" @change="">
            </div> -->
            <!-- <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " v-model="categorymodal.status" autocomplete="off">
                    <option>approved</option>
                    <option>pending</option>
               </select>
            </div> -->
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for App</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for App" id="file" ref="myFiles" name="img" @change="">
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.categorymodal.$error" @click="$v.categorymodal.$touch();savesubcategory()"
          id="updatebtn"
          type="success"
          class="float-center"
        >
          <span>Save Subategory</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="createsubcategory = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit sub-category -->
    <modal :show.sync="editsubcategory" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Sub-category</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-4">
                <base-input label="Subcategory Name" placeholder="Subcategory Name" v-model="editcategorymodal.inventory_subcategory_name" input-classes="" />
                <div
                    v-if="$v.editcategorymodal.inventory_subcategory_name.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            <!-- <div class="col-lg-4">
              <label class="form-control-label">Sub Category Ranking</label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editcategorymodal.sequenced_at"> 
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
              </select>
            </div> -->
            <!-- <div class="col-lg-4">
              <label class="form-control-label">Make category as featured</label>
              <br>
              <base-switch v-model="editcategorymodal.is_featured"></base-switch>
              {{editcategorymodal.is_featured}}
            </div> -->
            <!-- <div class="col-lg-4">
                <label class="form-control-label">Subcategory icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'web')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editcategorymodal.inventory_subcategory_icon" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div> -->
            <div class="col-md-4">
              <base-input label="Meta Title" v-model="editcategorymodal.inventory_subcategory_name" placeholder="e.g, Weight, Size, Pieces etc" input-classes="" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">Meta Description</label>
              <textarea class="form-control" v-model="editcategorymodal.inventory_subcategory_name" id="" rows="2"></textarea>
            </div>
            <div class="col-md-4 meta_tags">
              <label class="form-control-label">Meta Tags</label>
              <textarea class="form-control" v-model="editcategorymodal.inventory_subcategory_name" id="" rows="2"></textarea>
            </div>
            <div class="col-lg-4">
                <label class="form-control-label">Subcategory image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editimage')"
                >
                <div
                    v-if="$v.editcategorymodal.inventory_subcategory_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editcategorymodal.inventory_subcategory_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-4 mt-2">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editcategorymodal.status">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
            <div class="col-md-4">
              <base-input label="OG Title" placeholder="Write title here" v-model="editcategorymodal.og_title" input-classes="" />
            </div>
            <div class="col-md-4">
              <base-input label="OG Type" placeholder="Write type here" v-model="editcategorymodal.og_type" input-classes="" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">OG Description</label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="editcategorymodal.og_description"></textarea>
            </div>
            <div class="col-lg-4 mt-2">
              <label class="form-control-label">OG Image</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'og_image')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="editcategorymodal.og_image">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="delete_editcategory_ogimage(editcategorymodal)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                  </base-button>
                    <img :src="editcategorymodal.og_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for App</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for App" id="file" ref="myFiles" name="img" @change="">
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editcategorymodal.$error" @click="$v.editcategorymodal.$touch();updatesubcategory()"
          id="updatebtn1"
          type="success"
          class="float-center"
        >
          <span>Update Subategory</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editsubcategory = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    
    <modal :show.sync="editproduct">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Product</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="Item Name" placeholder="Item Name" v-model="editproductmodal.item_name" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_tagline" placeholder="item_tagline" v-model="editproductmodal.item_tagline" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="Short description" placeholder="Short description" v-model="editproductmodal.item_short_description" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_long_description" placeholder="item_long_description" v-model="editproductmodal.item_long_description" input-classes="" />
            </div>
            <!-- <div class="col-lg-6">
               <label class="form-control-label">item_category</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.item_category">
                    <option v-for="category in categoryData" :value="category._id">{{category.inventory_category_name}}</option>
               </select>
            </div> -->
            <div class="col-lg-6">
               <label class="form-control-label">item_subcategory</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.item_subcategory" @change="getvariation()">
                    <option v-for="subcategory in subcategoryData" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">item_variation</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.item_variation">
                    <option v-for="subcategory in vaiationData" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
            </div>
            <div class="col-lg-6">
                <base-input label="item_sp" placeholder="item_sp" v-model="editproductmodal.item_sp" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_mp" placeholder="item_mp" v-model="editproductmodal.item_mp" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input label="item_discount" placeholder="item_discount" v-model="editproductmodal.item_discount" input-classes="" />
            </div>
            
            <div class="col-lg-6">
                <label class="form-control-label">item_main_img</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'mainimage')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editproductmodal.item_main_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
          </div>
          <div class="row mt-1" id="optionsid">
            <div class="col-lg-6">
                <label class="form-control-label">item_secondary_img</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'secondary')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editproductmodal.item_secondary_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">item_thumbnail</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'thumbnail')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editproductmodal.item_thumbnail" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Store</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.store">
                     <option v-for="store in storeData" :value="store._id">{{store.store_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editproductmodal.status">
                    <option>approved</option>
                    <option>pending</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updateproduct()"
          id="updateproductbtn"
          type="success"
          class="float-center"
        >
          <span>Update product</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editproduct = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit banner -->
    <modal :show.sync="editbanner" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Banners</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="row" v-for="(banner_primary,index) in editcategorymodal.banner_primary">
                <div class="col-lg-4">
                  <label class="form-control-label">Primary Banner Link To</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="banner_primary.todo" @change="getchange_div('primary',index)">
                        <option value="openCategory">Category</option>
                        <option value="openProductFeed">Sub-Category</option>
                        <option value="openProduct">Product</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'pcategory'+index">
                  <label class="form-control-label">Category</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target" @change="getsubcategory()">
                        <option v-for="category in categoryData" :key="category._id">{{category.inventory_category_name}}</option>
                        
                        <!-- <option>Category 3</option> -->
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'psubcategory'+index">
                  <label class="form-control-label">Subcategory</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target">
                        <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                        <!-- <option>Sub-category 2</option>
                        <option>Sub-category 3</option> -->
                  </select>
                </div>
                <div class="col-lg-8 d-none" :id="'pproduct'+index">
                  <base-input label="Product oid" placeholder="Product oid" v-model="banner_primary.target" input-classes="" />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label mt-2">Upload Image</label>
                  <input
                    id="file"
                    ref="myFiles"
                    type="file"
                    aria-describedby="addon-right addon-left"
                    alternative=""
                    class="form-control form-control-alternative"
                    name="img"
                    @change="bannerhadleimage($event,'primary',index)"
                  >
                  <base-progress type="success" label="File uploaded"></base-progress>
                  <div>
                    <img :src="banner_primary.url" class="img-fluid rounded shadow mt-2 p-2">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <hr>
            </div>
            <div class="col-lg-12 mt-2">
              <div class="row" v-for="(banner_secondary,index) in editcategorymodal.banner_secondary">
                <div class="col-lg-4">
                  <label class="form-control-label">Secondary Banner Link To</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.todo" @change="getchange_div('secondary',index)">
                        <option value="openCategory">Category</option>
                        <option value="openProductFeed">Sub-Category</option>
                        <option value="openProduct">Product</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'scategory'+index">
                  <label class="form-control-label">Category</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                        <option v-for="category in categoryData" :key="category._id">{{category.inventory_category_name}}</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'ssubcategory'+index">
                  <label class="form-control-label">Subcategory</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                        <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                  </select>
                </div>
                <div class="col-lg-8 d-none" :id="'sproduct'+index">
                  <base-input label="Product SKU" placeholder="Product SKU" v-model="banner_secondary.target" input-classes="" />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label mt-2">Upload Image</label>
                  <input
                    id="file"
                    ref="myFiles"
                    type="file"
                    aria-describedby="addon-right addon-left"
                    alternative=""
                    class="form-control form-control-alternative"
                    name="img"
                    @change="bannerhadleimage($event,'secondary',index)"
                  >
                  <div>
                    <img :src="banner_secondary.url" class="img-fluid rounded shadow mt-2 p-2">
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                  <base-button
                    id="updatebtn1"
                    type="success"
                    outline
                    @click="addmore()"
                    class="float-center border-dashed"
                    block
                  >
                    <i class="fas fa-plus"></i><span>Add more banners</span>
                  </base-button>
                </div>
            </div>

          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatebanner()"
          id="updatebtn1"
          type="success"
          class="float-center"
        >
          <span>Update Banners</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editbanner = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import draggable from 'vuedraggable'
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList,
      draggable
    },
    data() {
      return {
        inputs: {
          file: []
        },
        subcategoryData:[],
        categoryData:[],
        vaiationData:[],
        subcategoryproductData:[],
        banneruploadPercentage:0,
        imagearr:[],
        storeData:[],
        createsubcategory:false,
        editbanner:false,
        editsubcategory:false,
        editproduct:false,
        uploadPercentage:0,
        categorymodal:{
          inventory_subcategory_id : '',
          inventory_subcategory_name : '',
          inventory_category_name :this.$route.params.name,
          inventory_category_oid :this.$route.params.id,
          inventory_subcategory_type : '',
          inventory_subcategory_img : '',
          inventory_subcategory_icon : '',
          inventory_subcategory_theme_color : '',
          banner_primary : [{url:'',todo:'',target:'',sequenced_at:0}],
          banner_secondary : [{url:'',todo:'',target:'',sequenced_at:0}],
          is_featured : '',
          sequenced_at : '',
          created_by : localStorage.getItem('_id'),
          status : 'approved',
          og_title : '',
          og_type : '',
          og_image : '',
          og_description : '',
          meta_title : '',
          meta_description : '',
          meta_keyword : ''
        },
        editcategorymodal:{
          inventory_subcategory_id : '',
          inventory_subcategory_name : '',
          inventory_category_name :'',
          inventory_category_oid :'',
          inventory_subcategory_type : '',
          inventory_subcategory_img : '',
          inventory_subcategory_icon : '',
          inventory_subcategory_theme_color : '',
          banner_primary : [{url:'',todo:'',target:'',sequenced_at:0}],
          banner_secondary : [{url:'',todo:'',target:'',sequenced_at:0}],
          is_featured : '',
          sequenced_at : '',
          created_by : localStorage.getItem('_id'),
          status : '',
          og_title : '',
          og_type : '',
          og_image : '',
          og_description : '',
          meta_title : '',
          meta_description : '',
          meta_keyword : ''
        },
        editproductmodal:{
          item_id : '',
          item_name : '',
          item_tagline : '',
          item_short_description : '',
          item_long_description : '',
          item_type : '',
          is_featured :'',
          sequenced_at :'',
          status:'',
          visibility :'', 
          item_category :this.$route.params.id,
          item_subcategory : '',
          item_variation : '',
          item_sp : '',
          item_mp : '',
          item_discount : '',
          minimum_quantity : '',
          item_ratings : '',
          item_theme_color : '',
          item_tags : [],
          item_main_img : '',
          item_secondary_img : '',
          item_thumbnail : '',
          store : '',
          number_available :'',
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    validations: {
      categorymodal: {
        inventory_subcategory_name:{
          required
        },
        inventory_subcategory_img:{
          required
        },
      },
      editcategorymodal: {
        inventory_subcategory_name:{
          required
        },
        inventory_subcategory_img:{
          required
        },
      },
    },
    mounted () {
      const querystring = require('querystring');
      console.log(this.auth);
      axios.post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{inventory_category_oid:this.$route.params.id}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.subcategoryData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.categoryData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        }
            
      })


      //inventory_category_oid:this.$route.params.id

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',{ "details" :{item_category:this.$route.params.id},"offset" : "0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryproductData=response.data.details;
        }
            
      })


    },
    methods: {
          createsubcatmodal(){
            this.createsubcategory=true;
          },
          bannerhadleimage: function(event,type,index) {
                // Reference to the DOM input element
                    this.uploadPercentage =0;
                    var input = event.target;
                    // Ensure that you have a file before attempting to read it
                    if(input.files[0].size < 5000000){

                    const fd= new FormData();
                    fd.append('image',input.files[0],input.files[0].name);
                    axios
                    .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
                    .then(response => {
                      console.log(response);
                      if(type=='primary'){
                        this.editcategorymodal.banner_primary[index].url=response.data.imageUrl;
                        console.log(this.editcategorymodal.banner_primary[index].url);
                      }else if(type=='secondary'){
                        this.editcategorymodal.banner_secondary[index].url=response.data.imageUrl;
                        console.log(this.editcategorymodal.banner_secondary[index].url);
                      }
                        
                    })

                    }else{

                    swal({
                      title:'File too large!',
                      text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                      icon:'warning',
                     }).then((result) => {
                       
                    })

                  }

            },
          getchange_div(type,index){

                if(this.editcategorymodal.banner_primary[index].todo=='openCategory' && type=='primary'){
                   document.getElementById("pcategory"+index).classList.remove('d-none');
                   document.getElementById("psubcategory"+index).classList.add('d-none');
                   document.getElementById("pproduct"+index).classList.add('d-none');
                }
                if(this.editcategorymodal.banner_primary[index].todo=='openProductFeed' && type=='primary'){
                   document.getElementById("pcategory"+index).classList.add('d-none');
                   document.getElementById("psubcategory"+index).classList.remove('d-none');
                   document.getElementById("pproduct"+index).classList.add('d-none');

                }
                if(this.editcategorymodal.banner_primary[index].todo=='openProduct' && type=='primary'){
                   document.getElementById("pcategory"+index).classList.add('d-none');
                   document.getElementById("psubcategory"+index).classList.add('d-none');
                   document.getElementById("pproduct"+index).classList.remove('d-none');

                }

                if(this.editcategorymodal.banner_secondary[index].todo=='openCategory' && type=='secondary'){
                   document.getElementById("scategory"+index).classList.remove('d-none');
                   document.getElementById("ssubcategory"+index).classList.add('d-none');
                   document.getElementById("sproduct"+index).classList.add('d-none');
                }
                if(this.editcategorymodal.banner_secondary[index].todo=='openProductFeed' && type=='secondary'){
                   document.getElementById("scategory"+index).classList.add('d-none');
                   document.getElementById("ssubcategory"+index).classList.remove('d-none');
                   document.getElementById("sproduct"+index).classList.add('d-none');

                }
                if(this.editcategorymodal.banner_secondary[index].todo=='openProduct' && type=='secondary'){
                   document.getElementById("scategory"+index).classList.add('d-none');
                   document.getElementById("ssubcategory"+index).classList.add('d-none');
                   document.getElementById("sproduct"+index).classList.remove('d-none');

                }
                  
          },
          addmore(){
            this.editcategorymodal.banner_secondary.push({url:'',todo:'',target:'',sequenced_at:0})
          },
          openbanner(row){
            this.editbanner=true;
            // this.editsubcategory=true;
            this.editcategorymodal.inventory_subcategory_id =row.inventory_subcategory_id;
            this.editcategorymodal.inventory_subcategory_name =row.inventory_subcategory_name;
            this.editcategorymodal.inventory_category_oid =row.inventory_category_oid;
            this.editcategorymodal.inventory_subcategory_type =row.inventory_subcategory_type;
            this.editcategorymodal.inventory_subcategory_img =row.inventory_subcategory_img;
            this.editcategorymodal.inventory_subcategory_icon =row.inventory_subcategory_icon;
            this.editcategorymodal.inventory_subcategory_theme_color =row.inventory_subcategory_theme_color;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.banner_secondary =row.banner_secondary;
            this.editcategorymodal.is_featured =row.is_featured;
            this.editcategorymodal.sequenced_at =row.sequenced_at;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
          },
          updatebanner() {
            const querystring = require('querystring');
             axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_subcategory',{ inventory_subcategory_id:this.editcategorymodal.inventory_subcategory_id,created_by:this.editcategorymodal.created_by,banner_primary:this.editcategorymodal.banner_primary,banner_secondary:this.editcategorymodal.banner_secondary},this.auth1)
            .then(response => {
              console.log(response);
              this.message=response.data.message;
              document.getElementById("updatebtn1").classList.remove('btn-primary');
              document.getElementById("updatebtn1").classList.add('btn-success');
              document.getElementById("updatebtn1").innerHTML='Updated';
              setTimeout(function(){
              document.getElementById("updatebtn1").classList.remove('btn-success');
              document.getElementById("updatebtn1").classList.add('btn-primary');
              document.getElementById("updatebtn1").innerHTML="Update";
            }, 2000);

             // window.location.reload()

            })

          },
          hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                if(type=='icon'){
                  this.categorymodal.inventory_subcategory_icon=response.data.imageUrl;
                  console.log(this.categorymodal.inventory_subcategory_icon);
                }else if(type=='image'){
                  this.categorymodal.inventory_subcategory_img=response.data.imageUrl;
                  console.log(this.categorymodal.inventory_subcategory_img);
                }else if(type=='editicon'){
                  this.editcategorymodal.inventory_subcategory_icon=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_icon);
                }else if(type=='editimage'){
                  this.editcategorymodal.inventory_subcategory_img=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_img);
                }else if(type=='og_image'){
                    this.editcategorymodal.og_image=response.data.imageUrl;
                    console.log(this.editcategorymodal.og_image);
                  }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

        },
        async bannerupload(event,type,uploatype) {
              // Reference to the DOM input element
                //console.log(event);
                // document.getElementById("editassignmentbtn").classList.add('d-none');
                // document.getElementById("editaddassignmentinvalid").classList.remove('d-none');
               this.banneruploadPercentage =0;
                if(uploatype=='file1'){
                  for( var i = 0; i < this.$refs.file1.files.length; i++ ){
                      let file = this.$refs.file1.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }
                if(uploatype=='file3'){
                  for( var i = 0; i < this.$refs.file3.files.length; i++ ){
                      let file = this.$refs.file3.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }

        },
        upload(file,type){
          //console.log(file);
          let fd = new FormData();
          fd.append('image',file,file.name);
          axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.banneruploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.banneruploadPercentage)}.bind(this)}).then(response => {
            //console.log(response);
            this.imagearr.push({"url":response.data.imageUrl,"sequenced_at":1});
            //console.log(this.imagearr);
            if(type=='add'){
              this.categorymodal.banner_primary=this.imagearr;
              //console.log(this.assignmentmodel.assignment_file);
            }
            else{
              this.categorymodal.banner_secondary=this.imagearr;
            }
            //console.log();
         })

        },
        savesubcategory() {
          if(this.$v.categorymodal.$error===false){ 
          //,banner_primary:this.categorymodal.banner_primary,banner_secondary:this.categorymodal.banner_secondary,is_featured:this.categorymodal.is_featured,
          const querystring = require('querystring');
          axios.post(process.env.VUE_APP_API_ENDPOINT+'create_inventory_subcategory',querystring.stringify({inventory_subcategory_name : this.categorymodal.inventory_subcategory_name,inventory_category_name:this.categorymodal.inventory_category_name,inventory_category_oid:this.categorymodal.inventory_category_oid,inventory_subcategory_type:this.categorymodal.inventory_subcategory_type,status:this.categorymodal.status,inventory_subcategory_icon:this.categorymodal.inventory_subcategory_icon,inventory_subcategory_img:this.categorymodal.inventory_subcategory_img,sequenced_at:this.categorymodal.sequenced_at,inventory_subcategory_theme_color:this.categorymodal.inventory_subcategory_theme_color,created_by:this.categorymodal.created_by,meta_title:this.categorymodal.meta_title,meta_description:this.categorymodal.meta_description,meta_keyword:this.categorymodal.meta_keyword,og_type:this.categorymodal.og_type,og_title:this.categorymodal.og_title,og_description:this.categorymodal.og_description,og_image:this.categorymodal.og_image}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn").classList.remove('btn-primary');
            document.getElementById("updatebtn").classList.add('btn-success');
            document.getElementById("updatebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn").classList.remove('btn-success');
            document.getElementById("updatebtn").classList.add('btn-primary');
            document.getElementById("updatebtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

            

          })
         }

        },
        openeditsubcategory(row){
            this.editsubcategory=true;
            this.editcategorymodal.inventory_subcategory_id =row.inventory_subcategory_id;
            this.editcategorymodal.inventory_subcategory_name =row.inventory_subcategory_name;
            this.editcategorymodal.inventory_category_oid =row.inventory_category_oid;
            this.editcategorymodal.inventory_subcategory_type =row.inventory_subcategory_type;
            this.editcategorymodal.inventory_subcategory_img =row.inventory_subcategory_img;
            this.editcategorymodal.inventory_subcategory_icon =row.inventory_subcategory_icon;
            this.editcategorymodal.inventory_subcategory_theme_color =row.inventory_subcategory_theme_color;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.banner_secondary =row.banner_secondary;
            this.editcategorymodal.is_featured =row.is_featured;
            this.editcategorymodal.sequenced_at =row.sequenced_at;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
            this.editcategorymodal.meta_title =row.meta_title;
            this.editcategorymodal.meta_description =row.meta_description;
            this.editcategorymodal.meta_keyword =row.meta_keyword;
            this.editcategorymodal.og_title =row.og_title;
            this.editcategorymodal.og_description =row.og_description;
            this.editcategorymodal.og_image =row.og_image;
            this.editcategorymodal.og_type =row.og_type;
            this.editcategorymodal.status =row.status;
          },

        updatesubcategory() {
          if(this.$v.editcategorymodal.$error===false){
           const querystring = require('querystring');
           // banner_primary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404864493banner-1%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404896351banner-1-top%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}],banner_secondary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}]
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_subcategory',{ inventory_subcategory_id:this.editcategorymodal.inventory_subcategory_id,inventory_subcategory_name : this.editcategorymodal.inventory_subcategory_name,inventory_category_name:this.editcategorymodal.inventory_category_name,inventory_category_oid:this.editcategorymodal.inventory_category_oid,inventory_subcategory_type:this.editcategorymodal.inventory_subcategory_type,status:this.editcategorymodal.status,inventory_subcategory_icon:this.editcategorymodal.inventory_subcategory_icon,inventory_subcategory_img:this.editcategorymodal.inventory_subcategory_img,is_featured:true,sequenced_at:this.editcategorymodal.sequenced_at,inventory_subcategory_theme_color:this.editcategorymodal.inventory_subcategory_theme_color,created_by:this.editcategorymodal.created_by,meta_title:this.editcategorymodal.meta_title,meta_description:this.editcategorymodal.meta_description,meta_keyword:this.editcategorymodal.meta_keyword,og_type:this.editcategorymodal.og_type,og_title:this.editcategorymodal.og_title,og_description:this.editcategorymodal.og_description,og_image:this.editcategorymodal.og_image},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
            window.location.reload()
          }, 2000);

            //window.location.reload()

          })

         }

        },
      deletesubcategory(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_inventory_subcategory',querystring.stringify({inventory_subcategory_id :row.inventory_subcategory_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your subcategory has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getvariation(){
       axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_variation',{ "details" :{inventory_subcategory_oid:this.editproductmodal.item_subcategory}},this.auth1)
      .then(response => {
        console.log(response);
          if(response.data.details){
          this.variationData=response.data.details;
          }   
        })
      },
      overflow_change() {
        document.getElementsByClassName("overflow_visible")[0].classList.add('overflow-x-visible');
        //document.getElementById("updatebtn").classList.add('btn-success');
      },
      deleteproduct(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_item',querystring.stringify({item_id :row.item_id,product_oid:row._id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your product has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      //save categories with loop
      savepositions() {
        document.getElementById("savepositions").innerHTML='<i class="fas fa-circle-notch fa-spin mr-2"></i> Saving';
        for (var i = 0; i < this.subcategoryData.length; i++) {
          const querystring = require('querystring');
          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_subcategory',querystring.stringify({inventory_subcategory_id:this.subcategoryData[i].inventory_subcategory_id,inventory_subcategory_name : this.subcategoryData[i].inventory_subcategory_name,inventory_category_name:this.subcategoryData[i].inventory_category_name,inventory_category_oid:this.subcategoryData[i].inventory_category_oid,status:this.subcategoryData[i].status,sequenced_at:i+1,created_by:this.subcategoryData[i].created_by}),this.auth).then(response => {

            document.getElementById("savepositions").classList.remove('btn-warning');
            document.getElementById("savepositions").classList.add('btn-success');
            document.getElementById("savepositions").innerHTML='Saved';
            setTimeout(function(){
            document.getElementById("savepositions").classList.remove('btn-success');
            document.getElementById("savepositions").classList.add('btn-warning');
            document.getElementById("savepositions").innerHTML="Saved";
            }, 2000);
              window.location.reload()
          })
        }

        
      },
      delete_editcategory_ogimage(index){
        this.editcategorymodal.og_image='';
        this.uploadPercentage =0;
      },
      delete_addcategory_ogimage(index){
        this.categorymodal.og_image='';
        this.uploadPercentage =0;
      }
    },
  };
</script>
<style></style>
