<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Manage content of About Us Page
                  </h3>
                </div>
                <div class="col text-right"></div>
              </div>
            </div>
            <div class="card border shadow-sm">
              <div class="card-header bg-secondary">About Content</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <base-input
                      label="About us title"
                      v-model="aboutdata.about_title"
                      placeholder="Appears in about us section"
                      input-classes=""
                    />
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">About us Picture</label>
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'about')"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="aboutdata.about_image">
                      <img
                        :src="aboutdata.about_image"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >About us short description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="aboutdata.about_short_description"
                      rows="2"
                      placeholder="Appears in about us section"
                    ></textarea>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label"
                      >About us description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="aboutdata.about_description"
                      rows="2"
                      placeholder="Appears in about us section"
                    ></textarea>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-control-label">Why us description</label>
                    <textarea
                      class="form-control"
                      v-model="aboutdata.why_description"
                      rows="2"
                      placeholder="Appears in why us section"
                    ></textarea>
                  </div>
                </div>
                <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(boxes, index) in aboutdata.whyUs_box"
                    :key="index"
                  >
                    <base-input
                      :label="'Why us box ' + (index + 1) + ' title'"
                      v-model="boxes.title"
                      placeholder="Appears in why us section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >Why us box {{ index + 1 }} description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="boxes.description"
                      rows="2"
                      placeholder="Appears in why us section"
                    ></textarea>
                  </div>
                </div>
                <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(history, index) in aboutdata.history"
                    :key="index"
                  >
                    <base-input
                      :label="'History ' + (index + 1) + ' Year'"
                      v-model="history.year"
                      placeholder="Appears in why us section"
                      input-classes=""
                    />
                    <base-input
                      :label="'History ' + (index + 1) + ' title'"
                      v-model="history.title"
                      placeholder="Appears in why us section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >History {{ index + 1 }} description</label
                    >
                    <textarea
                      class="form-control"
                      v-model="history.description"
                      rows="2"
                      placeholder="Appears in why us section"
                    ></textarea>
                  </div>
                </div>
                <div class="row px-3">
                  <div class="col-lg-12">
                    <div class="col-md-6">
                      <base-input
                        :label="'Vision title'"
                        v-model="aboutdata.vision.title"
                        placeholder="Appears in about us section"
                        input-classes=""
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label"
                        >Vision description</label
                      >
                      <textarea
                        class="form-control"
                        v-model="aboutdata.vision.description"
                        rows="2"
                        placeholder="Appears in why us section"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row px-3">
                  <div class="col-lg-12">
                    <div class="col-md-6">
                      <base-input
                        :label="'Mission title'"
                        v-model="aboutdata.mission.title"
                        placeholder="Appears in about us section"
                        input-classes=""
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label"
                        >Mission description</label
                      >
                      <textarea
                        class="form-control"
                        v-model="aboutdata.mission.description"
                        rows="2"
                        placeholder="Appears in why us section"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-12">
                    <base-button
                      @click="saveabout()"
                      id="saveaboutcontent"
                      type="primary"
                      class="float-center"
                      block
                    >
                      <span>Save</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  <div class="row">
                    <div class="col align-self-center">
                      <span>Board of Governers Content</span>
                    </div>
                    <div class="col text-right">
                      <base-button
                        @click="addboardmemberpopup()"
                        id="addboardmemberpopup"
                        type="primary"
                        class="float-center"
                        outline
                      >
                        <i class="fas fa-plus mr-2"></i> Add board member
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div
                        class="card border"
                        v-for="board_of_governor in board_of_governorData"
                        :key="board_of_governor._id"
                      >
                        <div class="row px-3">
                          <div class="col-lg-3 py-3">
                            <img
                              alt="Image placeholder"
                              class="img-fluid border p-1 rounded shadow-sm"
                              :src="board_of_governor.image"
                            />
                            <p class="mt-1 mb-0 font-weight-bold">
                              {{ board_of_governor.name }}
                            </p>
                            <p class="mb-0">
                              ({{ board_of_governor.designation }})
                            </p>
                          </div>
                          <div class="col-lg-9">
                            <div class="card-body p-3">
                              <p class="card-text mb-4">
                                {{ board_of_governor.long_message }}
                              </p>
                              <base-button
                                type="primary"
                                size="sm"
                                @click="editboardmemberpopup(board_of_governor)"
                                >Edit Details</base-button
                              >
                              <base-button
                                type="danger"
                                size="sm"
                                @click="deleteboardmember(board_of_governor)"
                                >Delete</base-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Affiliations Content</div>
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-for="affiliations in affiliationsData"
                      :key="affiliations._id"
                    >
                      <div
                        class="align-content-center border d-flex h-250px rounded shadow-sm"
                      >
                        <base-button
                          type="danger"
                          size="sm"
                          class="position-absolute right-0 mr-1 mt--2 btn-round"
                          @click="deleteaffiliation(affiliations)"
                          ><i class="fas fa-trash"></i
                        ></base-button>
                        <img
                          :src="affiliations.logo"
                          class="img-fluid img-150px d-block m-auto"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card card-body border rounded shadow-sm">
                        <label class="form-control-label">Upload Logo</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'logo')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="affiliationdata.logo">
                          <img
                            :src="affiliationdata.logo"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-lg-12">
                      <base-button
                        @click="saveaffliation()"
                        id="saveaffliationcontent"
                        type="primary"
                        class="float-center"
                        block
                      >
                        <span>Save</span>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Social Responsibility Content
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        label="Title"
                        v-model="social_responsibilitydata.title"
                        placeholder="Title"
                        input-classes=""
                      />
                      <textarea
                        class="form-control mb-2"
                        rows="5"
                        placeholder="Short description"
                        v-model="social_responsibilitydata.short_description"
                      ></textarea>
                      <label class="form-control-label">Images</label>
                      <input
                        type="file"
                        ref="file"
                        accept="image/*"
                        placeholder="image"
                        class="form-control form-control-alternative"
                        multiple="multiple"
                        @change="handlemultipleimage($event, 'add', 'file')"
                      />
                      <base-progress
                        type="success"
                        :value="multipleuploadPercentage"
                        label="Upload Progress"
                      ></base-progress>
                      <div class="row">
                        <div
                          class="col-6 position-relative mt-3"
                          v-for="(
                            image, index
                          ) in social_responsibilitydata.images"
                          :key="image"
                        >
                          <base-button
                            type="danger"
                            size="sm"
                            class="position-absolute right-0 mr-1 mt--2 btn-round"
                            @click="deletefile(index, 'add')"
                            ><i class="fas fa-trash"></i
                          ></base-button>
                          <img
                            :src="image"
                            class="img-fluid rounded shadow p-1"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label class="form-control-label">Content</label>
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="social_responsibilitydata.long_description"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="row">
                  <div class="col-lg-12"></div>
                  <div class="col-lg-12">
                    <base-button
                      @click="savecontent()"
                      id="savesocialcontent"
                      type="primary"
                      class="float-center"
                      block
                      size="lg"
                    >
                      <span>Save Content</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- create board member popup -->
    <modal :show.sync="addboardmember" size="lg">
      <p id="modal-title-default" slot="header" class="modal-title">
        <span>Create new board member</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input
                label="Board member name"
                v-model="model.name"
                placeholder="Board member name"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <base-input
                label="Designation"
                v-model="model.designation"
                placeholder="Designation"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Board Member Message</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea3"
                v-model="model.long_message"
                rows="10"
              ></textarea>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Picture</label>
              <input
                id="file"
                ref="myFiles"
                type="file"
                aria-describedby="addon-right addon-left"
                alternative=""
                class="form-control form-control-alternative"
                name="img"
                @change="hadleimage($event, 'addboardmember')"
              />
              <base-progress
                type="success"
                v-model="uploadPercentage"
                label="File uploaded"
              ></base-progress>
              <div class="position-relative">
                <base-button
                  type="danger"
                  size="sm"
                  class="position-absolute btn-icon-only"
                  @click="deleteimage(model, 'add')"
                  style="
                    right: 0px;
                    top: -5px;
                    font-size: x-small;
                    width: 1.3rem;
                    height: 1.3rem;
                  "
                  round
                >
                  <i class="fas fa-times"></i>
                </base-button>
                <img
                  :src="model.image"
                  style="max-width: 100px"
                  class="img-fluid rounded shadow mt-2 card-body"
                />
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createboardmember()"
          type="success"
          id="createboardmemberbtn"
          class="float-center"
        >
          <span>Create board member</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addboardmember = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit board member popup -->
    <modal :show.sync="editboardmember" size="lg">
      <p id="modal-title-default" slot="header" class="modal-title">
        <span>Edit board member</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input
                label="Board member name"
                v-model="editboardmembermodal.name"
                placeholder="Board member name"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <base-input
                label="Board member designation"
                v-model="editboardmembermodal.designation"
                placeholder="Board member designation"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Board member message</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="10"
                v-model="editboardmembermodal.long_message"
              ></textarea>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Picture</label>
              <input
                id="file"
                ref="myFiles"
                type="file"
                aria-describedby="addon-right addon-left"
                alternative=""
                class="form-control form-control-alternative"
                name="img"
                @change="hadleimage($event, 'editboardmember')"
              />
              <base-progress
                type="success"
                v-model="uploadPercentage"
                label="File uploaded"
              ></base-progress>
              <div class="position-relative">
                <base-button
                  type="danger"
                  size="sm"
                  class="position-absolute btn-icon-only"
                  @click="deleteimage(editboardmembermodal, 'edit')"
                  style="
                    right: 0px;
                    top: -5px;
                    font-size: x-small;
                    width: 1.3rem;
                    height: 1.3rem;
                  "
                  round
                >
                  <i class="fas fa-times"></i>
                </base-button>
                <img
                  :src="editboardmembermodal.image"
                  style="max-width: 100px"
                  class="img-fluid rounded shadow mt-2 card-body"
                />
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updateboardmember()"
          type="success"
          id="editcontent"
          class="float-center"
        >
          <span>Save</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editboardmember = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

import { Tabs } from "@/components";
import { BasePagination } from "@/components/Tabs/Tabs";

// Components
import BaseProgress from "@/components/BaseProgress";
// import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import StatsCard from "@/components/Cards/StatsCard";
import { required, minLength, between } from "vuelidate/lib/validators";
import VueSwal from "vue-swal";
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    // ActivityFeed,
    Tabs,
    LineChart,
    BarChart,
    BaseProgress,
    StatsCard,
    VueEditor,
    // TaskList,
    // PageVisitsTable,
    // SocialTrafficTable,
    // LightTable,
    // UserList,
    // ProgressTrackList
  },
  data() {
    return {
      addboardmember: false,
      datanotfound: false,
      editboardmember: false,
      addstore: false,
      editstore: false,
      addregion: false,
      editregion: false,
      addarea: false,
      editarea: false,
      datanotfound: false,
      uploadPercentage: 0,
      multipleuploadPercentage: 0,
      pagepermission: {},
      board_of_governorData: [],
      editboardmembermodal: {},
      aboutdata: {},
      regionData: [],
      areaData: [],
      affiliationsData: [],
      selectstore: "",
      selectpin: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"],
      ],
      model: {
        board_of_governor_id: "",
        name: "",
        image: "",
        short_message: "",
        long_message: "",
        designation: "",
        status: "",
      },
      affiliationdata: {
        affiliation_id: "",
        title: "",
        logo: "",
        link: "",
      },
      social_responsibilitydata: {
        social_responsibility_id: "",
        title: "",
        short_description: "",
        long_description: "",
        images: [],
        status: "approved",
      },
      radios: {
        radio1: "checked",
        radio2: "checked",
      },
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      auth1: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  validations: {
    // model: {
    //   store_name:{
    //     required
    //   },
    //   store_img:{
    //     required
    //   },
    //   shipping_charge:{
    //     required
    //   },
    // }
  },
  mounted() {
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_about",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.aboutdata = response.data.details[0];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_board_of_governor",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.board_of_governorData = response.data.details;
        }
      });

    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_social_responsibility",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.social_responsibilitydata = response.data.details[0];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_affiliation",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.affiliationsData = response.data.details;
        }
      });
  },
  methods: {
    async handlemultipleimage(event, type, uploatype) {
      // Reference to the DOM input element

      // document.getElementById("assignmentbtn").classList.add('d-none');
      // document.getElementById("addassignmentinvalid").classList.remove('d-none');
      // console.log(event);
      this.multipleuploadPercentage = 0;
      if (uploatype == "file") {
        for (var i = 0; i < this.$refs.file.files.length; i++) {
          let file = this.$refs.file.files[i];
          console.log(file);
          await this.upload(file, type);
        }
      }
      // if(uploatype=='fileb'){
      //   for( var i = 0; i < this.$refs.fileb.files.length; i++ ){
      //       let file = this.$refs.fileb.files[i];
      //       console.log(file);
      //       await this.upload(file,type);

      //   }
      // }
    },
    upload(file, type) {
      // console.log(file);
      let fd = new FormData();
      fd.append("image", file, file.name);
      axios
        .post(process.env.VUE_APP_API_ENDPOINT + "image-upload", fd, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: function (progressEvent) {
            this.multipleuploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            console.log(this.multipleuploadPercentage);
          }.bind(this),
        })
        .then((response) => {
          // console.log(response);
          this.social_responsibilitydata.images.push(response.data.imageUrl);
          // console.log(this.imagearr);
        });
    },
    deletefile(index, type) {
      if (type == "add") {
        this.social_responsibilitydata.images.splice(index, 1);
      }
    },
    hadleimage: function (event, type) {
      // Reference to the DOM input element
      this.uploadPercentage = 0;
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files[0].size < 5000000) {
        const fd = new FormData();
        fd.append("image", input.files[0], input.files[0].name);
        axios
          .post(process.env.VUE_APP_API_ENDPOINT + "image-upload", fd, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              console.log(this.uploadPercentage);
            }.bind(this),
          })
          .then((response) => {
            console.log(response);
            if (type == "addboardmember") {
              this.model.image = response.data.imageUrl;
              console.log(this.model.image);
            }
            if (type == "editboardmember") {
              this.editboardmembermodal.image = response.data.imageUrl;
              console.log(this.editboardmembermodal.image);
            }
            if (type == "logo") {
              this.affiliationdata.logo = response.data.imageUrl;
              console.log(this.affiliationdata.logo);
            }
            if (type == "social_responsibility") {
              this.social_responsibilitydata.images = response.data.imageUrl;
              console.log(this.social_responsibilitydata.images);
            }
            if (type == "block_2_icon") {
              this.model.block_2_icon = response.data.imageUrl;
              console.log(this.model.block_2_icon);
            }
            if (type == "block_3_icon") {
              this.model.block_3_icon = response.data.imageUrl;
              console.log(this.model.block_3_icon);
            }
            if (type == "about") {
              this.aboutdata.about_image = response.data.imageUrl;
              console.log(this.aboutdata.about_image);
            }
            //else{
            //   this.storemodal.store_img=response.data.imageUrl;
            //   console.log(this.storemodal.store_img);
            // }
          });
      } else {
        swal({
          title: "File too large!",
          text: "Your file is larger than 5 MB. Please upload images which are less than 5 MB.",
          icon: "warning",
        }).then((result) => {});
      }
    },
    deleteimage(row, type) {
      if ((type = "add")) {
        this.model.image = "";
      }
      if ((type = "edit")) {
        this.editmodel.image = "";
      }
    },
    saveaffliation() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "create_affiliation",
          this.affiliationdata,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("saveaffliationcontent")
            .classList.remove("btn-primary");
          document
            .getElementById("saveaffliationcontent")
            .classList.add("btn-success");
          document.getElementById("saveaffliationcontent").innerHTML =
            "Created";
          setTimeout(function () {
            document
              .getElementById("saveaffliationcontent")
              .classList.remove("btn-success");
            document
              .getElementById("saveaffliationcontent")
              .classList.add("btn-primary");
            document.getElementById("saveaffliationcontent").innerHTML = "Save";
            window.location.reload();
          }, 2000);
        });
    },
    saveabout() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_about",
          this.aboutdata,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("saveaboutcontent")
            .classList.remove("btn-primary");
          document
            .getElementById("saveaboutcontent")
            .classList.add("btn-success");
          document.getElementById("saveaboutcontent").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("saveaboutcontent")
              .classList.remove("btn-success");
            document
              .getElementById("saveaboutcontent")
              .classList.add("btn-primary");
            document.getElementById("saveaboutcontent").innerHTML = "Save";
            window.location.reload();
          }, 2000);
        });
    },
    createboardmember() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "create_board_of_governor",
          this.model,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("createboardmemberbtn")
            .classList.remove("btn-primary");
          document
            .getElementById("createboardmemberbtn")
            .classList.add("btn-success");
          document.getElementById("createboardmemberbtn").innerHTML = "Created";
          setTimeout(function () {
            document
              .getElementById("createboardmemberbtn")
              .classList.remove("btn-success");
            document
              .getElementById("createboardmemberbtn")
              .classList.add("btn-primary");
            document.getElementById("createboardmemberbtn").innerHTML = "Save";
          }, 2000);
          window.location.reload();
        });
    },
    updateboardmember() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_board_of_governor",
          this.editboardmembermodal,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("editcontent")
            .classList.remove("btn-primary");
          document.getElementById("editcontent").classList.add("btn-success");
          document.getElementById("editcontent").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("editcontent")
              .classList.remove("btn-success");
            document.getElementById("editcontent").classList.add("btn-primary");
            document.getElementById("editcontent").innerHTML = "Update";
            window.location.reload();
          }, 2000);
        });
    },
    savecontent() {
      const querystring = require("querystring");
      if (!this.social_responsibilitydata.social_responsibility_id) {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "create_social_responsibility",
            this.social_responsibilitydata,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savesocialcontent")
              .classList.remove("btn-primary");
            document
              .getElementById("savesocialcontent")
              .classList.add("btn-success");
            document.getElementById("savesocialcontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savesocialcontent")
                .classList.remove("btn-success");
              document
                .getElementById("savesocialcontent")
                .classList.add("btn-primary");
              document.getElementById("savesocialcontent").innerHTML = "Update";
              window.location.reload();
            }, 2000);

            //
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "update_social_responsibility",
            this.social_responsibilitydata,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savesocialcontent")
              .classList.remove("btn-primary");
            document
              .getElementById("savesocialcontent")
              .classList.add("btn-success");
            document.getElementById("savesocialcontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savesocialcontent")
                .classList.remove("btn-success");
              document
                .getElementById("savesocialcontent")
                .classList.add("btn-primary");
              document.getElementById("savesocialcontent").innerHTML = "Update";
              window.location.reload();
            }, 2000);
          });
      }
    },
    addboardmemberpopup(row) {
      console.log(row);
      this.addboardmember = true;
    },
    editboardmemberpopup(row) {
      this.editboardmembermodal = row;
      console.log(this.editboardmembermodal);
      this.editboardmember = true;
      // }
    },
    deleteboardmember(row) {
      console.log(row);
      const querystring = require("querystring");
      swal({
        title: "Are you sure?",
        text: "wan't to delete",
        icon: "warning",
        buttons: { cancel: true, confirm: true },
      }).then((result) => {
        // <--
        if (result) {
          console.log("result cloud", result);
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT + "delete_board_of_governor",
              querystring.stringify({
                board_of_governor_id: row.board_of_governor_id,
              }),
              this.auth
            )
            .then((response) => {
              let _this = this;
              console.log(response);
              swal({
                title: "Deleted!",
                text: "Member has been deleted.",
                icon: "success",
              }).then((result) => {
                console.log(result);
                _this.getalldata();
              });
            });
        }
      });
    },
    deleteaffiliation(row) {
      console.log(row);
      const querystring = require("querystring");
      swal({
        title: "Are you sure to delete?",
        text: "This action is irreversible.",
        icon: "warning",
        buttons: { cancel: true, confirm: true },
      }).then((result) => {
        // <--
        if (result) {
          console.log("result cloud", result);
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT + "delete_affiliation",
              querystring.stringify({ affiliation_id: row.affiliation_id }),
              this.auth
            )
            .then((response) => {
              let _this = this;
              console.log(response);
              swal({
                title: "Deleted!",
                text: "Affiliation logo has been deleted.",
                icon: "success",
              }).then((result) => {
                console.log(result);
                // get updated list
                axios
                  .post(
                    process.env.VUE_APP_API_ENDPOINT + "read_affiliation",
                    { details: {} },
                    this.auth1
                  )
                  .then((response) => {
                    console.log(response);
                    if (response.data.details) {
                      this.affiliationsData = response.data.details;
                    }
                  });
              });
            });
        }
      });
    },
  },
};
</script>
<style>
.search-input .form-group {
  display: inline-block;
  margin-bottom: 0px;
}
</style>
