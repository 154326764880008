<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Permissions
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button v-if="pagepermission.create" type="primary" @click="addpermissionpopup"><i class="fas fa-plus"></i> Create Permissions</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive d-block">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="permissionData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>permission_name</th>
                  <th>path</th>
                  <th>create</th>
                  <th>read</th>
                  <th>update</th>
                  <th>delete</th>
                  <th>status</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.permission_name}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.path}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.create}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.read}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.update}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.delete}}</p>
                    </td>
                    <td class="budget" >
                      <p class="mb-0">{{row.status}}</p>
                    </td>
                    <!-- <td class="budget">
                      <img alt="Image placeholder" class="rounded" :src="row.store_img" width="100">
                    </td> -->
                    <td class="text-right">
                      <base-button v-if="pagepermission.update" type="primary" size="sm" @click="editpermissionpopup(row)" >Edit</base-button>
                      <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deletepermission(row)">Delete</base-button>
                      <!-- <a class="btn btn-primary btn-sm" href="#" icon="fa fa-info">View</a> -->
                    </td>
                </template>
              </base-table>

              <!-- no result found -->
              <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No permissions received.</p>
                </div>
              </div>

              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="permissionData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- create permission popup -->
    <modal :show.sync="addstore">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create permission</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Permission name<span class="text-danger">*</span></label>
              <base-input placeholder="permission_name" v-model="permissionmodal.permission_name" input-classes="" />
            </div>
            <div class="col-lg-6">
                <base-input type="text" v-model="permissionmodal.permission_description" label="Permission Description" id="example-date-input"/>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Path<span class="text-danger">*</span></label>
              <base-input placeholder="/orders" v-model="permissionmodal.path" input-classes="" />
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Create</label>
              <br>
              <base-switch v-model="permissionmodal.create"></base-switch>
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Read</label>
              <br>
              <base-switch v-model="permissionmodal.read"></base-switch>
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Update</label>
              <br>
              <base-switch v-model="permissionmodal.update"></base-switch>
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Delete</label>
              <br>
              <base-switch v-model="permissionmodal.delete"></base-switch>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Status</label>
              <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off"
                v-model="permissionmodal.status">
                <option>approved</option>
                <option>disapprove</option>
              </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createpermission()"
          type="success"
          id="createstorebtn"
          class="float-center"
        >
          <span>Create Permission</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addstore = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit permission popup -->
    <modal :show.sync="editstore">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit permission/coupon</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Permission name<span class="text-danger">*</span></label>
              <base-input placeholder="permission_name" v-model="editpermissionmodal.permission_name" input-classes="" />
            </div>
            <div class="col-lg-6">
              <base-input type="text" v-model="editpermissionmodal.permission_description" label="Permission Description" id="example-date-input"/>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Path<span class="text-danger">*</span></label>
              <base-input placeholder="/orders" v-model="editpermissionmodal.path" input-classes="" />
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Create</label>
              <br>
              <base-switch v-model="editpermissionmodal.create"></base-switch>
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Read</label>
              <br>
              <base-switch v-model="editpermissionmodal.read"></base-switch>
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Update</label>
              <br>
              <base-switch v-model="editpermissionmodal.update"></base-switch>
            </div>
            <div class="col-lg-3">
              <label class="form-control-label">Delete</label>
              <br>
              <base-switch v-model="editpermissionmodal.delete"></base-switch>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Status</label>
              <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off"
                v-model="editpermissionmodal.status">
                <option>approved</option>
                <option>disapprove</option>
              </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatepermission()"
          type="success"
          id="updateestorebtn"
          class="float-center"
        >
          <span>Update permission</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editstore = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import moment from 'moment';
  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addstore: false,
        datanotfound:false,
        editstore: false,
        pagepermission:{},
        uploadPercentage:0,
        permissionData:[],
        permissionmodal:{
          permission_id:'',
          permission_name:'',
          permission_description:'',
          permission_image:'',
          path:'',
          sequence_at:0,
          create:1,
          read:1,
          update:1,
          delete:1,
          fields:[{field_name:'',rights:1}],
          status:'approved',
        },
        editpermissionmodal:{
          permission_id:'',
          permission_name:'',
          permission_description:'',
          permission_image:'',
          path:'',
          sequence_at:0,
          create:1,
          read:1,
          update:1,
          delete:1,
          fields:[{field_name:'',rights:1}],
          status:'approved',
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    // validations: {
    //   permissionmodal: {
    //     permission_code:{
    //       required
    //     },
    //     permission_type:{
    //       required
    //     },
    //     min_amount:{
    //       required
    //     },
    //     expires_on:{
    //       required
    //     },
    //     permission_value:{
    //       required
    //     },
    //   },
    //   editpermissionmodal: {
    //     permission_code:{
    //       required
    //     },
    //     permission_type:{
    //       required
    //     },
    //     min_amount:{
    //       required
    //     },
    //     expires_on:{
    //       required
    //     },
    //     permission_value:{
    //       required
    //     },
    //   },

    // },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_permission',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.permissionData=response.data.details;
        }else{
            this.permissionData=[];
            this.datanotfound=true;
        }
            
      })
      // axios
      // .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      // .then(response => {
      //   console.log(response);
      //   if(response.data.details){
      //   this.storeData=response.data.details;
      //   }
            
      // })
      // axios
      // .post(process.env.VUE_APP_API_ENDPOINT+'read_region',{ "details" :{}},this.auth1)
      // .then(response => {
      //   console.log(response);
      //   if(response.data.details){
      //   this.regionData=response.data.details;
      //   }
            
      // })
    },
    methods: {

      addpermissionpopup(row){
        console.log(row);
        this.addstore=true;
      },
      editpermissionpopup(row){
        this.editpermissionmodal=row;
        this.editpermissionmodal.expires_on= moment.utc(new Date(this.editpermissionmodal.expires_on)).format("yyyy-MM-DD");
        // this.editpermissionmodal.expires_on.toString('yyyy-MM-dd');
        console.log(this.editpermissionmodal.expires_on);
        this.editstore=true;
        console.log(this.editpermissionmodal);
        if(this.editpermissionmodal.permission_type=='PercentageOnCart'){
          document.getElementById("edit_max_amount").classList.remove('d-none');
        } else {
          document.getElementById("edit_max_amount").classList.add('d-none');
        }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addstore'){
                this.storemodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }if(type=='editstore'){
                this.editstoremodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }else{
                this.storemodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
              }).then((result) => {
               
              })
            }
      },
      createpermission(){
        if(this.permissionmodal.create){
          this.permissionmodal.create=1;
        }else{
          this.permissionmodal.create="0"
        }
        if(this.permissionmodal.read){
          this.permissionmodal.read=1;
        }else{
          this.permissionmodal.read="0"
        }
        if(this.permissionmodal.update){
          this.permissionmodal.update=1;
        }else{
          this.permissionmodal.update="0"
        }
        if(this.permissionmodal.delete){
          this.permissionmodal.delete=1;
        }else{
          this.permissionmodal.delete="0"
        }
       // if(this.$v.permissionmodal.$error===false){
        const querystring = require('querystring');
           // ,fields:this.permissionmodal.fields
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_permission',querystring.stringify({permission_name : this.permissionmodal.permission_name,permission_description : this.permissionmodal.permission_description,path : this.permissionmodal.path,sequence_at : this.permissionmodal.sequence_at,create : this.permissionmodal.create,read : this.permissionmodal.read,update : this.permissionmodal.update,delete : this.permissionmodal.delete,status:this.permissionmodal.status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })

        // }  
      },
      updatepermission(){
        // if(this.$v.editpermissionmodal.$error===false){
        const querystring = require('querystring');
            if(this.editpermissionmodal.create){
              this.editpermissionmodal.create=1;
            }else{
              this.editpermissionmodal.create="0"
            }
            if(this.editpermissionmodal.read){
              this.editpermissionmodal.read=1;
            }else{
              this.editpermissionmodal.read="0"
            }
            if(this.editpermissionmodal.update){
              this.editpermissionmodal.update=1;
            }else{
              this.editpermissionmodal.update="0"
            }
            if(this.editpermissionmodal.delete){
              this.editpermissionmodal.delete=1;
            }else{
              this.editpermissionmodal.delete="0"
            }
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_permission',querystring.stringify({permission_id : this.editpermissionmodal.permission_id,permission_name : this.editpermissionmodal.permission_name,permission_description : this.editpermissionmodal.permission_description,path : this.editpermissionmodal.path,sequence_at : this.editpermissionmodal.sequence_at,create : this.editpermissionmodal.create,read : this.editpermissionmodal.read,update : this.editpermissionmodal.update,delete : this.editpermissionmodal.delete,fields:this.editpermissionmodal.fields,status:this.editpermissionmodal.status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
          })
        // } 
      },
      deletepermission(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_permission',querystring.stringify({permission_id :row.permission_id,created_by:row.created_by}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your file has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      format_datetime(value){
        if(value) {
          return moment.utc(new Date(value)).format("LLL")
        }
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
