import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  store.dispatch('fetchAccessToken');
  let roueterpath=[];
  if (localStorage.getItem('roueterpath')!=null) {
     roueterpath=JSON.parse(localStorage.getItem('roueterpath'));
     roueterpath.push('/');
     roueterpath.push('/dashboard');
  }else{
     // roueterpath.push('/');
     // roueterpath.push('/dashboard');
  }
  console.log(to.fullPath);
  console.log(roueterpath);
  console.log(roueterpath.indexOf(to.fullPath));
  if (to.fullPath !== '/login' && to.fullPath !== '/register' && to.fullPath !== '/forgot' || to.fullPath === '/dashboard') {
    if (!store.state.accessToken || store.state.accessToken==null) {
      console.log(store.state.accessToken);
      next('/login');
    }
  }
  if (to.fullPath === '/login') {
    if (store.state.accessToken) {
      next('/dashboard');
    }
  }
  if(roueterpath.length>0){
    let realpath=to.fullPath;
    let path=to.fullPath.split('/');
    if(path.length>3){
      realpath='/'+path[1]+'/'+path[2]+'/';
      console.log(realpath);
    }
    
      console.log(roueterpath.indexOf(realpath));
      if(roueterpath.indexOf(realpath) != -1){
       next();
      }
    }else{
      next();
    }
  
});

// function requireAuth(){
//   proceed()
//   console.log("check permission")
// }

// function proceed () {

//   console.log("check permission")

  /*
    If the user has been loaded determine where we should
    send the user.
  */
  // if ( store.getters.getUserLoadStatus() == 2 ) {
  //   /*
  //     If the user is not empty, that means there's a user
  //     authenticated we allow them to continue. Otherwise, we
  //     send the user back to the home page.
  //   */
  //   if( store.getters.getUser != '' ){
  //     switch( to.meta.permission ){
  //       /*
  //         If the route that requires authentication is a user, then we continue.
  //         All users can access these routes
  //       */
  //       case 'user':
  //         next();
  //       break;
  //       /*
  //         If the route that requires authentication is an owner and the permission
  //         the user has is greater than or equal to 1 (an owner or higher), we allow
  //         access. Otherwise we redirect back to the cafes.
  //       */
  //       case 'owner':
  //         if( store.getters.getUser.permission >= 1 ){
  //           next();
  //         }else{
  //           next('/cafes');
  //         }
  //       break;
  //       /*
  //         If the route that requires authentication is an admin and the permission
  //         the user has is greater than or equal to 2 (an owner or higher), we allow
  //         access. Otherwise we redirect back to the cafes.
  //       */
  //       case 'admin':
  //         if( store.getters.getUser.permission >= 2 ){
  //           next();
  //         }else{
  //           next('/cafes');
  //         }
  //       break;
  //       /*
  //         If the route that requires authentication is a super admin and the permission
  //         the user has is equal to 3 (a super admin), we allow
  //         access. Otherwise we redirect back to the cafes.
  //       */
  //       case 'super-admin':
  //         if( store.getters.getUser.permission == 3 ){
  //           next();
  //         }else{
  //           next('/cafes');
  //         }
  //       break;
  //     }
  //   }else{
  //     next('/cafes');
  //   }
  // }
  //}

export default router;
