<template>
  <div class="container-fluid h-100vh">
    <div class="row justify-content-center h-100vh">
      <div class="col-md-4">
        <div class="header mt-6">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="header-body text-center mb-4">
                  <img src="img/brand/green.png" class="img-fluid w-50">
                  <!-- <h1 class="text-white">Create an account</h1> -->
                  <p class="text-lead my-3">If you face any issues please reach out to us on Whatsapp/Call at 9999999999.</p>               
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
              <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div> -->
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="card bg-secondary border-0 mb-0">
              <!-- <div class="card-header bg-transparent pb-5">
                <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
                <div class="btn-wrapper text-center">
                  <a href="#" class="btn btn-neutral btn-icon">
                    <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                    <span class="btn-inner--text">Github</span>
                  </a>
                  <a href="#" class="btn btn-neutral btn-icon">
                    <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                    <span class="btn-inner--text">Google</span>
                  </a>
                </div>
              </div> -->
              <div class="card-body px-lg-5 py-lg-5">
                <div class="text-center text-muted mb-4">
                  <small>Sign up with credentials</small>
                </div>
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                  <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <base-input alternative
                                class="mb-2"
                                prepend-icon="ni ni-hat-3"
                                placeholder="Name"
                                name="Name"
                                :rules="{required: true}"
                                v-model="model.user_name">
                    </base-input>

                    <base-input alternative
                                class="mb-2"
                                prepend-icon="ni ni-email-83"
                                placeholder="Email"
                                name="Email"
                                :rules="{required: true, email: true}"
                                v-model="model.user_email">
                    </base-input>

                    <select aria-describedby="addon-right addon-left"
                                addon-left-icon="ni ni-bullet-list-67"
                                class="form-control form-control-alternative mb-2"
                                :rules="{required: true}"
                                autocomplete="off" v-model="model.user_type">
                                <option value="">User Type</option>
                                <option value="CA">CA</option>
                                <option value="business">business</option>     
                    </select>

                    <base-input alternative
                                class="mb-2"
                                prepend-icon="ni ni-hat-3"
                                placeholder="user_phone"
                                name="user_phone"
                                :rules="{required: true}"
                                v-model="model.user_phone">
                    </base-input>
                    <base-input alternative
                                class="mb-2"
                                prepend-icon="ni ni-lock-circle-open"
                                placeholder="password"
                                type="password"
                                name="Password"
                                :rules="{required: true, min: 6}"
                                v-model="model.user_password">
                    </base-input>
                    <!-- <div class="text-muted font-italic"><small>password strength: <span
                      class="text-success font-weight-700">strong</span></small></div> -->
                    <div class="row my-4">
                      <div class="col-12">
                        <base-input :rules="{ required: { allowFalse: false } }" name=Privacy Policy>
                          <base-checkbox v-model="model.agree">
                            <span class="text-muted">I agree with the <a href="#!">Privacy Policy</a></span>
                          </base-checkbox>
                        </base-input>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary mt-4" id="adcatbtn">Create account</button>
                    </div>
                    <div class="row mt-3">
                      <div class="col-6">
                        <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
                      </div>
                      <div class="col-6 text-right">
                        <router-link to="/login" class="text-light"><small>Login</small></router-link>
                      </div>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
            <!-- <div class="row mt-3">
              <div class="col-6">
                <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
              </div>
              <div class="col-6 text-right">
                <router-link to="/login" class="text-light"><small>Login</small></router-link>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-8 bg-gradient-info">
        <div class="mt-8 w-50 mx-auto">
          <img src="img/theme/finance-automation.png" class="mx-auto text-center d-block img-fluid w-75">
          <p class="mb-0 pt-5 h1 text-white text-center">"Let technology reduce the hassel of creating bulk forms. Our AI enabled engine reduces turnaround time of creating any froms and keeps everything organized"</p>
          <footer class="blockquote-footer mt-3 text-white text-center">
            <cite title="Source Title">Used by 20 CA firms across India.</cite>
          </footer>
        </div>
      </div>
    </div>
  </div>
  <!-- <div>
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Create an account</h1>
              <p class="text-lead text-white">Use these awesome forms to login or create new account in your project for
                free.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="card bg-secondary border-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Or sign up with credentials</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-hat-3"
                              placeholder="Name"
                              name="Name"
                              :rules="{required: true}"
                              v-model="model.user_name">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              name="Email"
                              :rules="{required: true, email: true}"
                              v-model="model.user_email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-hat-3"
                              placeholder="user_phone"
                              name="user_phone"
                              :rules="{required: true}"
                              v-model="model.user_phone">
                  </base-input>
                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-lock-circle-open"
                              placeholder="password"
                              type="password"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              v-model="model.user_password">
                  </base-input>
                  <select aria-describedby="addon-right addon-left"
                                  addon-left-icon="ni ni-bullet-list-67"
                                  class="form-control"
                                  :rules="{required: true}"
                                  autocomplete="off" v-model="model.user_type">
                                  <option value="">User Type</option>
                                  <option value="CA">CA</option>
                                  <option value="business">business</option>
                                
                  </select>
                  <div class="text-muted font-italic"><small>password strength: <span
                    class="text-success font-weight-700">strong</span></small></div>
                  <div class="row my-4">
                    <div class="col-12">
                      <base-input :rules="{ required: { allowFalse: false } }" name=Privacy Policy>
                        <base-checkbox v-model="model.agree">
                          <span class="text-muted">I agree with the <a href="#!">Privacy Policy</a></span>
                        </base-checkbox>
                      </base-input>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary mt-4" id="adcatbtn">Create account</button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import axios from "axios";
  export default {
    name: 'register',
    data() {
      return {
        model: {
          user_type:'',
          user_name: '',
          user_email: '',
          user_password: '',
          user_phone:'',
          user_city:'',
          agree: false
        }
      }
    },
    methods: {
      onSubmit() {
         const querystring = require('querystring');
         axios.post(process.env.VUE_APP_API_ENDPOINT+'signup_user',querystring.stringify({user_type : this.model.user_type,user_name:this.model.user_name,user_email:this.model.user_email,user_password:this.model.user_password,user_phone:this.model.user_phone,user_city:this.model.user_city}),this.auth).then(response => {
          console.log(response);
          this.message=response.data.message; 
          document.getElementById("adcatbtn").classList.remove('btn-primary');
          document.getElementById("adcatbtn").classList.add('btn-success');
          document.getElementById("adcatbtn").innerHTML=this.message;
          setTimeout(function(){
          document.getElementById("adcatbtn").classList.remove('btn-success');
          document.getElementById("adcatbtn").classList.add('btn-primary');
          document.getElementById("adcatbtn").innerHTML="Saved";
        }, 2000);
        })
      }
    }

  };
</script>
<style></style>
