<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Director & Principle Message
                  </h3>
                </div>
                <div class="col text-right">
                  
                </div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  <div class="row">
                    <div class="col align-self-center">
                      <span>Director & Principle Message</span>
                    </div>
                    <div class="col text-right">
                      <base-button
                        @click="addmessagepopup()"
                        id="addmessagepopup"
                        type="primary"
                        class="float-center"
                        outline
                      >
                        <i class="fas fa-plus mr-2"></i> Add message
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card border" v-for="authority in authorityData" :key="authority._id">
                        <div class="row px-3">
                          <div class="col-lg-3 py-3">
                            <img alt="Image placeholder" class="img-fluid border p-1 rounded shadow-sm" :src="authority.authority_image">
                            <p class="mt-1 mb-0 font-weight-bold">{{authority.authority_name}}</p>
                            <p class="mb-0">({{authority.designation}})</p>
                          </div>
                          <div class="col-lg-9">
                            <div class="card-body p-3">
                              <p class="card-text mb-4" v-html="authority.authority_long_message"></p>
                              <base-button type="primary" size="sm" @click="editmessagepopup(authority)" >Edit Details</base-button>
                              <base-button type="danger" size="sm" @click="deletemessage(authority)">Delete</base-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- create authority message -->
    <modal :show.sync="addmessage" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create message</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input label="Name" v-model="model.authority_name" placeholder="Name" input-classes="" />
            </div>
            <div class="col-md-6">
              <base-input label="Designation" v-model="model.designation" placeholder="Designation" input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Message</label>
              <vue-editor :editorToolbar="customToolbar" v-model="model.authority_long_message" id="exampleFormControlTextarea3"/>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Picture</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'addmessage')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteimage(model,'add')"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="model.authority_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createboardmember()"
          type="success"
          id="createmessagebtn"
          class="float-center"
        >
          <span>Create board member</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addmessage = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit create authority message -->
    <modal :show.sync="editmessage" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Message</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input label="Name" v-model="editmessagemodal.authority_name" placeholder="Name" input-classes="" />
            </div>
            <div class="col-md-6">
              <base-input label="Designation" v-model="editmessagemodal.designation" placeholder="Designation" input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Message</label>
              <vue-editor :editorToolbar="customToolbar" v-model="editmessagemodal.authority_long_message" id="exampleFormControlTextarea3"/>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Picture</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editmessage')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteimage(editmessagemodal,'edit')"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="editmessagemodal.authority_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatemessage()"
          type="success"
          id="editcontent"
          class="float-center"
        >
          <span>Save</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editmessage = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      VueEditor
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addmessage: false,
        datanotfound:false,
        editmessage: false,
        addstore: false,
        editstore: false,
        addregion: false,
        editregion: false,
        addarea: false,
        editarea: false,
        datanotfound:false,
        uploadPercentage:0,
        multipleuploadPercentage:0,
        pagepermission:{},
        authorityData:[],
        editmessagemodal:{},
        regionData:[],
        areaData:[],
        affiliationsData :[],
        selectstore:'',
        selectpin:'',
        customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link", "image", "video"],
          ["clean"]],
        model:{
          authority_id:'',
          authority_name :'',
          authority_image :'',
          authority_short_message :'',
          authority_long_message :'',
          designation :'',
          status :'approved',
        },
        affiliationdata:{
          affiliation_id :'',
          title :'',
          logo :'',
          link :'',
        },
        social_responsibilitydata:{
          social_responsibility_id:'',
          title:'',
          short_description:'',
          long_description:'',
          images:[],
          status:'approved'
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      // model: {
      //   store_name:{
      //     required
      //   },
      //   store_img:{
      //     required
      //   },
      //   shipping_charge:{
      //     required
      //   },
      // }

    },
    mounted() {
      
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_authority',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.authorityData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_social_responsibility',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.social_responsibilitydata=response.data.details[0];
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_affiliation',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.affiliationsData=response.data.details;
        }
            
      })
    },
    methods: {

      async handlemultipleimage(event,type,uploatype) {
            // Reference to the DOM input element

              // document.getElementById("assignmentbtn").classList.add('d-none');
              // document.getElementById("addassignmentinvalid").classList.remove('d-none');
              // console.log(event);
              this.multipleuploadPercentage=0;
              if(uploatype=='file'){
                for( var i = 0; i < this.$refs.file.files.length; i++ ){
                    let file = this.$refs.file.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              // if(uploatype=='fileb'){
              //   for( var i = 0; i < this.$refs.fileb.files.length; i++ ){
              //       let file = this.$refs.fileb.files[i];
              //       console.log(file);
              //       await this.upload(file,type);
                    
                    
              //   }
              // }

      },
      upload(file,type){
        // console.log(file);
        let fd = new FormData();
        fd.append('image',file,file.name);
        axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.multipleuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.multipleuploadPercentage)}.bind(this)}).then(response => {
          // console.log(response);
          this.social_responsibilitydata.images.push(response.data.imageUrl);
          // console.log(this.imagearr);
          
       })

      },
      deletefile(index,type){
          if(type=='add'){
            this.social_responsibilitydata.images.splice(index,1);
          }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addmessage'){
                this.model.authority_image=response.data.imageUrl;
                console.log(this.model.authority_image);
              }if(type=='editmessage'){
                this.editmessagemodal.authority_image=response.data.imageUrl;
                console.log(this.editmessagemodal.authority_image);
              }
              //else{
              //   this.storemodal.store_img=response.data.imageUrl;
              //   console.log(this.storemodal.store_img);
              // }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }
      },
      deleteimage(row,type){
        if(type='add'){
         this.model.image='';
        }
        if(type='edit'){
          this.editmodel.image='';
        }
      },
      saveaffliation(){
        const querystring = require('querystring');
        axios.post(process.env.VUE_APP_API_ENDPOINT+'create_affiliation',this.affiliationdata,this.auth1)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("saveaffliationcontent").classList.remove('btn-primary');
          document.getElementById("saveaffliationcontent").classList.add('btn-success');
          document.getElementById("saveaffliationcontent").innerHTML='Created';
          setTimeout(function(){
          document.getElementById("saveaffliationcontent").classList.remove('btn-success');
          document.getElementById("saveaffliationcontent").classList.add('btn-primary');
          document.getElementById("saveaffliationcontent").innerHTML="Save";
           window.location.reload()
          }, 2000);
        })
      },
      createboardmember(){
        const querystring = require('querystring');
          axios.post(process.env.VUE_APP_API_ENDPOINT+'create_authority',this.model,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createmessagebtn").classList.remove('btn-primary');
            document.getElementById("createmessagebtn").classList.add('btn-success');
            document.getElementById("createmessagebtn").innerHTML='Created';
            setTimeout(function(){
            document.getElementById("createmessagebtn").classList.remove('btn-success');
            document.getElementById("createmessagebtn").classList.add('btn-primary');
            document.getElementById("createmessagebtn").innerHTML="Save";
          }, 2000);
            window.location.reload()
          })
      },
      updatemessage(){
        const querystring = require('querystring');
          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_authority',this.editmessagemodal,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("editcontent").classList.remove('btn-primary');
            document.getElementById("editcontent").classList.add('btn-success');
            document.getElementById("editcontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("editcontent").classList.remove('btn-success');
            document.getElementById("editcontent").classList.add('btn-primary');
            document.getElementById("editcontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
      },
      savecontent(){
        const querystring = require('querystring');
           if(!this.social_responsibilitydata.social_responsibility_id){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_social_responsibility',this.social_responsibilitydata,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savesocialcontent").classList.remove('btn-primary');
            document.getElementById("savesocialcontent").classList.add('btn-success');
            document.getElementById("savesocialcontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savesocialcontent").classList.remove('btn-success');
            document.getElementById("savesocialcontent").classList.add('btn-primary');
            document.getElementById("savesocialcontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

            //

          })
        }else{

          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_social_responsibility',this.social_responsibilitydata,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savesocialcontent").classList.remove('btn-primary');
            document.getElementById("savesocialcontent").classList.add('btn-success');
            document.getElementById("savesocialcontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savesocialcontent").classList.remove('btn-success');
            document.getElementById("savesocialcontent").classList.add('btn-primary');
            document.getElementById("savesocialcontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })

        }
      },
      addmessagepopup(row){
        console.log(row);
        this.addmessage=true;
      },
      editmessagepopup(row){
        this.editmessagemodal=row;
        console.log(this.editmessagemodal);
        this.editmessage=true;
        // }
      },
      deletemessage(row){
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_board_of_governor',querystring.stringify({board_of_governor_id :row.board_of_governor_id}),this.auth)
            .then(response => {
                let _this=this;
                console.log(response);
                swal({
                  title:'Deleted!',
                  text: 'Member has been deleted.',
                  icon:'success',
                 }).then((result) => {
                  console.log(result)
                  _this.getalldata();
                })
              })
             }
          });
      },
      deleteaffiliation(row){
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure to delete?',
              text: "This action is irreversible.",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_affiliation',querystring.stringify({affiliation_id :row.affiliation_id}),this.auth)
            .then(response => {
                let _this=this;
                console.log(response);
                swal({
                  title:'Deleted!',
                  text: 'Affiliation logo has been deleted.',
                  icon:'success',
                 }).then((result) => {
                  console.log(result)
                  // get updated list
                  axios
                  .post(process.env.VUE_APP_API_ENDPOINT+'read_affiliation',{ "details" :{}},this.auth1)
                  .then(response => {
                    console.log(response);
                    if(response.data.details){
                    this.affiliationsData=response.data.details;
                    }   
                  })
                })
              })
             }
          });
      },
  },
    
};
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
