<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Manage content of Academics Page
                  </h3>
                </div>
                <div class="col text-right">
                  
                </div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Top Menu
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <label class="form-control-label mt-3">Manage Top Menu</label>
                      <div class="row" v-for="menu,index in menuitem" :key="index">
                        <div class="col-md-10 pr-0">
                          <div class="row">
                            <div class="col-lg-4">
                              <base-input placeholder="Title" v-model="menu.menu_name" input-classes="mb--3" />
                            </div>
                            <div class="col-lg-4">
                              <base-input placeholder="Link" v-model="menu.link" input-classes="mb--3" />
                            </div>
                            <div class="col-lg-4" >
                              <select class="form-control" v-model="menu.highlights">
                                <option value="false">Don't highlight</option>
                                <option value="true">highlight</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 text-center">
                          <base-button
                            @click="updatemenu(menu)"
                            id="savemenu"
                            type="success"
                            class="float-center border-0 mt-2"
                            size="sm"
                            outline
                          >
                            <i class="fas fa-check"></i>
                          </base-button>
                          <base-button
                            @click="deletemenu(menu)"
                            id="deletemenu"
                            type="danger"
                            class="float-center border-0 mt-2"
                            size="sm"
                            outline
                          >
                            <i class="fas fa-trash"></i>
                          </base-button>
                        </div>
                      </div>
                      <div class="row d-none" id="addnewitem">
                        <div class="col-md-10 pr-0">
                          <div class="row">
                            <div class="col-lg-4">
                              <base-input placeholder="Title" v-model="model.menu_name" input-classes="mb--3" />
                            </div>
                            <div class="col-lg-4">
                              <base-input placeholder="Link" v-model="model.link" input-classes="mb--3" />
                            </div>
                            <div class="col-lg-4" >
                              <select class="form-control" v-model="model.highlights">
                                <option value="false">Don't highlight</option>
                                <option value="true">highlight</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 text-center">
                          <base-button
                            @click="savemenu()"
                            id="savecontent"
                            type="success"
                            class="float-center border-0 mt-2"
                            size="sm"
                            outline
                          >
                            <i class="fas fa-check"></i>
                          </base-button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-10 pr-0">
                          <base-button
                            @click="addfeature()"
                            id="addfeature"
                            type="primary"
                            class="float-center"
                            block
                            outline
                          >
                            <i class="fas fa-plus mr-2"></i>Add new menu item
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="card border shadow-sm">
                <div class="row">
                  <div class="col-lg-12">
                    <base-button
                      @click="savecontent()"
                      id="savecontent"
                      type="primary"
                      class="float-center"
                      block
                      size="lg"
                    >
                      <span>Save Menu</span>
                    </base-button>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // Charts
  // import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';

  // import { Tabs } from '@/components';
  // import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  // import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import { VueEditor } from "vue2-editor";
  export default {
    components: {
      // ActivityFeed,
      // Tabs,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // VueEditor
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addboardmember: false,
        editboardmember: false,
        addstore: false,
        editstore: false,
        addregion: false,
        editregion: false,
        addarea: false,
        editarea: false,
        datanotfound:false,
        uploadPercentage:0,
        pagepermission:{},
        storeData:[],
        regionData:[],
        areaData:[],
        allregionData :[],
        selectstore:'',
        selectpin:'',
        customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link", "image", "video"],
          ["clean"]],
        model:{
          menu_id:'',
          menu_name:'',
          link:'',
          highlights:'false',
        },
        menuitem: [],
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      // model: {
      //   store_name:{
      //     required
      //   },
      //   store_img:{
      //     required
      //   },
      //   shipping_charge:{
      //     required
      //   },
      // }

    },
    mounted() {
      
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_header_menu',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.menuitem=response.data.details;
        }
            
      })
    },
    methods: {

      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='curriculum_image'){
                this.model.curriculum_image=response.data.imageUrl;
                console.log(this.model.curriculum_image);
              }
              //else{
              //   this.storemodal.store_img=response.data.imageUrl;
              //   console.log(this.storemodal.store_img);
              // }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }
      },
      savecontent(){
        const querystring = require('querystring');
        if(!this.model.menu_id){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_header_menu',this.model,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontent").classList.remove('btn-primary');
            document.getElementById("savecontent").classList.add('btn-success');
            document.getElementById("savecontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savecontent").classList.remove('btn-success');
            document.getElementById("savecontent").classList.add('btn-primary');
            document.getElementById("savecontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

            //

          })
        }else{

          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_header_menu',this.model,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontent").classList.remove('btn-primary');
            document.getElementById("savecontent").classList.add('btn-success');
            document.getElementById("savecontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savecontent").classList.remove('btn-success');
            document.getElementById("savecontent").classList.add('btn-primary');
            document.getElementById("savecontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })

        }
      },
      savemenu(){
        const querystring = require('querystring');
        
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_header_menu',this.model,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontent").classList.remove('btn-primary');
            document.getElementById("savecontent").classList.add('btn-success');
            document.getElementById("savecontent").innerHTML='Created';
            setTimeout(function(){
            document.getElementById("savecontent").classList.remove('btn-success');
            document.getElementById("savecontent").classList.add('btn-primary');
            document.getElementById("savecontent").innerHTML="Saved";
            window.location.reload()
          }, 2000);

            //

          })
      },
      updatemenu(data){
        const querystring = require('querystring');
        
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_header_menu',data,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontent").classList.remove('btn-primary');
            document.getElementById("savecontent").classList.add('btn-success');
            document.getElementById("savecontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savecontent").classList.remove('btn-success');
            document.getElementById("savecontent").classList.add('btn-primary');
            document.getElementById("savecontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

            //

          })
      },
      deletemenu(row) {
        const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_header_menu',querystring.stringify({menu_id : row.menu_id}),this.auth).then(response => {
              console.log(response);
              this.message=response.data.message; 
              swal({
                title:'Deleted!',
                text: 'Your order has been deleted.',
                icon:'success',
               }).then((result) => {
                window.location.reload()
              })
            })
           }
        });
      },
      addfeature(){
            document.getElementById("addnewitem").classList.remove('d-none'); 
      },
      // deletemenu(index,type){
      //     this.model.menuitem.splice(index,1);
      // },
  },
    
};
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
