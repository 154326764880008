<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">Manage content of homepage</h3>
                </div>
                <div class="col text-right"></div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Hero Content</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <label class="form-control-label"
                        >Upload Hero Video</label
                      >
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event, 'hero_video')"
                      />
                      <base-progress
                        type="success"
                        v-model="uploadPercentage"
                        label="File uploaded"
                      ></base-progress>
                      <div v-if="model.hero_video">
                        <video width="320" height="240" controls>
                          <source :src="model.hero_video" type="video/mp4" />
                          <!-- <source src="movie.ogg" type="video/ogg"> -->
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">About Alpine Content</div>
                <div class="card-body">
                  <div class="row">
                    <!-- <div class="col-lg-12">
                      <base-input label="Why us title" v-model="model.why_us_title" placeholder="Appears in why us section" input-classes="" />
                    </div> -->
                    <div class="col-lg-12 mb-3">
                      <label class="form-control-label"
                        >About us description</label
                      >
                      <textarea
                        class="form-control"
                        v-model="model.about_description"
                        rows="2"
                        placeholder="Appears in why us section"
                      ></textarea>
                    </div>
                    <div class="row px-3">
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Picture</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_img1')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_img1">
                          <img
                            :src="model.about_img1"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Picture</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_img2')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_img2">
                          <img
                            :src="model.about_img2"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Picture</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_img3')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_img3">
                          <img
                            :src="model.about_img3"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Picture</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_img4')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_img4">
                          <img
                            :src="model.about_img4"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Picture</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_img5')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_img5">
                          <img
                            :src="model.about_img5"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Picture</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_img6')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_img6">
                          <img
                            :src="model.about_img6"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Picture</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_img7')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_img7">
                          <img
                            :src="model.about_img7"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="form-control-label">Upload Video</label>
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'about_video')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.about_video">
                          <video width="320" height="240" controls>
                            <source :src="model.about_video" type="video/mp4" />
                            <!-- <source src="movie.ogg" type="video/ogg"> -->
                          </video>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mb-3">
                      <label class="form-control-label">CTA Text</label>
                      <textarea
                        class="form-control"
                        v-model="model.cta_text"
                        rows="2"
                        placeholder="Appears in about us footer"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Why Us Content</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <label class="form-control-label"
                        >Why us description</label
                      >
                      <textarea
                        class="form-control"
                        v-model="model.whyUs_description"
                        rows="2"
                        placeholder="Appears in why us section"
                      ></textarea>
                    </div>
                    <div class="row px-3">
                      <div class="col-md-4">
                        <label class="form-control-label"
                          >Why us left box Picture</label
                        >
                        <input
                          id="file"
                          ref="myFiles"
                          type="file"
                          aria-describedby="addon-right addon-left"
                          alternative=""
                          class="form-control form-control-alternative"
                          name="img"
                          @change="hadleimage($event, 'whyUs_body_left')"
                        />
                        <base-progress
                          type="success"
                          v-model="uploadPercentage"
                          label="File uploaded"
                        ></base-progress>
                        <div v-if="model.whyUs_body_left.image">
                          <img
                            :src="model.whyUs_body_left.image"
                            class="img-fluid rounded shadow mt-2 card-body"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <base-input
                          label="Why us left box title"
                          v-model="model.whyUs_body_left.title"
                          placeholder="Appears in why us section"
                          input-classes=""
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="form-control-label"
                          >Why us left box description</label
                        >
                        <textarea
                          class="form-control"
                          v-model="model.whyUs_body_left.description"
                          rows="2"
                          placeholder="Appears in why us section"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row px-3">
                      <div
                        class="col-md-3"
                        v-for="(boxes, index) in model.whyUs_box"
                        :key="index"
                      >
                        <base-input
                          :label="'Why us box ' + (index + 1) + ' title'"
                          v-model="boxes.title"
                          placeholder="Appears in why us section"
                          input-classes=""
                        />
                        <label class="form-control-label"
                          >Why us box {{ index + 1 }} description</label
                        >
                        <textarea
                          class="form-control"
                          v-model="boxes.description"
                          rows="2"
                          placeholder="Appears in why us section"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Recent News Content</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <label class="form-control-label">Recent News</label>
                      <textarea
                        class="form-control"
                        v-model="model.recentNews"
                        rows="2"
                        placeholder="Appears in why us section"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Director Message Content
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        label="Director name"
                        v-model="model.director_name"
                        placeholder="Director name"
                        input-classes=""
                      />
                      <textarea
                        class="form-control mb-2"
                        rows="5"
                        placeholder="Director's message"
                        v-model="model.director_message"
                      ></textarea>
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">Image</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event, 'director_image')"
                      />
                      <base-progress
                        type="success"
                        v-model="uploadPercentage"
                        label="File uploaded"
                      ></base-progress>
                      <div v-if="model.director_image">
                        <img
                          :src="model.director_image"
                          class="img-fluid rounded shadow mt-2 card-body"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="row">
                  <div class="col-lg-12">
                    <base-button
                      @click="savecontent()"
                      id="savecontent"
                      type="primary"
                      class="float-center"
                      block
                      size="lg"
                    >
                      <span>Save Content</span>
                    </base-button>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Popup Data</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="popupmodel.text"
                      />
                      <label class="form-control-label">Status</label>
                      <select
                        aria-describedby="addon-right addon-left"
                        class="form-control"
                        autocomplete="off"
                        v-model="popupmodel.status"
                      >
                        <option value="approved">approved</option>
                        <option value="disapproved">disapproved</option>
                      </select>
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">Image</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event, 'popup')"
                      />
                      <base-progress
                        type="success"
                        v-model="uploadPercentage"
                        label="File uploaded"
                      ></base-progress>
                      <div v-if="popupmodel.image">
                        <img
                          :src="popupmodel.image"
                          class="img-fluid rounded shadow mt-2 card-body"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <base-button
                        @click="savepopup()"
                        id="savepopupid"
                        type="primary"
                        class="float-center"
                        block
                        size="lg"
                      >
                        <span>Save popup</span>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

import { Tabs } from "@/components";
import { BasePagination } from "@/components/Tabs/Tabs";

// Components
import BaseProgress from "@/components/BaseProgress";
// import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import StatsCard from "@/components/Cards/StatsCard";
import { required, minLength, between } from "vuelidate/lib/validators";
import VueSwal from "vue-swal";
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    // ActivityFeed,
    Tabs,
    LineChart,
    BarChart,
    BaseProgress,
    StatsCard,
    VueEditor,
    // TaskList,
    // PageVisitsTable,
    // SocialTrafficTable,
    // LightTable,
    // UserList,
    // ProgressTrackList
  },
  data() {
    return {
      addstore: false,
      editstore: false,
      addregion: false,
      editregion: false,
      addarea: false,
      editarea: false,
      datanotfound: false,
      uploadPercentage: 0,
      pagepermission: {},
      popupmodel: {},
      storeData: [],
      regionData: [],
      areaData: [],
      allregionData: [],
      selectstore: "",
      selectpin: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"],
      ],
      model: {
        homepage_id: "",
        hero_video: "",
        about_img1: "",
        about_img2: "",
        about_img3: "",
        about_img4: "",
        about_img5: "",
        about_img6: "",
        about_img7: "",
        about_video: "",
        about_description: "",
        cta_text: "",
        whyUs_description: "",
        whyUs_body_left: { image: "", title: "", description: "" },
        whyUs_box: [
          { title: "", description: "" },
          { title: "", description: "" },
          { title: "", description: "" },
          { title: "", description: "" },
        ],
        recentNews: "",
        director_message: "",
        director_name: "",
        director_image: "",
      },
      radios: {
        radio1: "checked",
        radio2: "checked",
      },
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      auth1: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  validations: {
    // model: {
    //   store_name:{
    //     required
    //   },
    //   store_img:{
    //     required
    //   },
    //   shipping_charge:{
    //     required
    //   },
    // }
  },
  mounted() {
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_homepage",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.model = response.data.details[0];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_homepopup",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.popupmodel = response.data.details[0];
        }
      });
  },
  methods: {
    hadleimage: function (event, type) {
      // Reference to the DOM input element
      this.uploadPercentage = 0;
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files[0].size < 15000000) {
        const fd = new FormData();
        fd.append("image", input.files[0], input.files[0].name);
        axios
          .post(process.env.VUE_APP_API_ENDPOINT + "image-upload", fd, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              console.log(this.uploadPercentage);
            }.bind(this),
          })
          .then((response) => {
            console.log(response);
            if (type == "hero_video") {
              this.model.hero_video = response.data.imageUrl;
              console.log(this.model.hero_video);
            }
            if (type == "about_img1") {
              this.model.about_img1 = response.data.imageUrl;
              console.log(this.model.about_img1);
            }
            if (type == "about_img2") {
              this.model.about_img2 = response.data.imageUrl;
              console.log(this.model.about_img2);
            }
            if (type == "about_img3") {
              this.model.about_img3 = response.data.imageUrl;
              console.log(this.model.about_img3);
            }
            if (type == "about_img4") {
              this.model.about_img4 = response.data.imageUrl;
              console.log(this.model.about_img4);
            }
            if (type == "about_img5") {
              this.model.about_img5 = response.data.imageUrl;
              console.log(this.model.about_img5);
            }
            if (type == "about_img6") {
              this.model.about_img6 = response.data.imageUrl;
              console.log(this.model.about_img6);
            }
            if (type == "about_img7") {
              this.model.about_img7 = response.data.imageUrl;
              console.log(this.model.about_img7);
            }
            if (type == "about_video") {
              this.model.about_video = response.data.imageUrl;
              console.log(this.model.about_video);
            }
            if (type == "director_image") {
              this.model.director_image = response.data.imageUrl;
              console.log(this.model.director_image);
            }
            if (type == "whyUs_body_left") {
              this.model.whyUs_body_left.image = response.data.imageUrl;
              console.log(this.model.whyUs_body_left.image);
            }
            if (type == "popup") {
              this.popupmodel.image = response.data.imageUrl;
              console.log(this.popupmodel.image);
            }
          });
      } else {
        swal({
          title: "File too large!",
          text: "Your file is larger than 15 MB. Please upload images which are less than 5 MB.",
          icon: "warning",
        }).then((result) => {});
      }
    },
    savecontent() {
      const querystring = require("querystring");
      if (!this.model.homepage_id) {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "create_homepage",
            this.model,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savecontent")
              .classList.remove("btn-primary");
            document.getElementById("savecontent").classList.add("btn-success");
            document.getElementById("savecontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savecontent")
                .classList.remove("btn-success");
              document
                .getElementById("savecontent")
                .classList.add("btn-primary");
              document.getElementById("savecontent").innerHTML = "Update";
              //window.location.reload();
            }, 2000);
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "update_homepage",
            this.model,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savecontent")
              .classList.remove("btn-primary");
            document.getElementById("savecontent").classList.add("btn-success");
            document.getElementById("savecontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savecontent")
                .classList.remove("btn-success");
              document
                .getElementById("savecontent")
                .classList.add("btn-primary");
              document.getElementById("savecontent").innerHTML = "Update";
              //window.location.reload();
            }, 2000);
          });
      }
    },
    savepopup() {
      // if (this.$v.editstoremodal.$error === false) {
      const querystring = require("querystring");
      if (this.popupmodel.text == "") {
        this.popupmodel.text = " ";
      }
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_homepopup",
          this.popupmodel,
          this.auth1
        )
        .then((response) => {
          document
            .getElementById("savepopupid")
            .classList.remove("btn-primary");
          document.getElementById("savepopupid").classList.add("btn-success");
          document.getElementById("savepopupid").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("savepopupid")
              .classList.remove("btn-success");
            document.getElementById("savepopupid").classList.add("btn-primary");
            document.getElementById("savepopupid").innerHTML = "Save Popup";
            window.location.reload();
          }, 2000);
        });
    },
    getstore_name(val) {
      const search = this.storeData.find((element) => element.store_id === val);
      if (search) {
        return search.store_name;
      }
    },
    getfilterdata() {
      localStorage.setItem("selectstore", this.selectstore);
      let details = { details: {} };
      if (this.selectstore) {
        details.details.region_store = this.selectstore;
      }

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "read_region",
          details,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          if (response.data.details) {
            this.regionData = response.data.details;
          } else {
            this.regionData = [];
          }
        });
      let adetails = { details: {} };
      if (this.selectstore) {
        adetails.details.area_store = this.selectstore;
      }
      if (this.selectpin) {
        adetails.details.area_region = this.selectpin;
      }
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "read_area",
          adetails,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          if (response.data.details) {
            this.areaData = response.data.details;
          } else {
            this.areaData = [];
          }
        });
    },
  },
};
</script>
<style>
.search-input .form-group {
  display: inline-block;
  margin-bottom: 0px;
}
</style>
