<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Manage content of Academics Page
                  </h3>
                </div>
                <div class="col text-right"></div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Overview</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-8">
                      <label class="form-control-label"
                        >Overview short Description</label
                      >
                      <textarea
                        class="form-control mt-2"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.overview_short_description"
                      ></textarea>
                      <label class="form-control-label"
                        >Overview Description</label
                      >
                      <textarea
                        class="form-control mt-2"
                        rows="5"
                        placeholder="description"
                        v-model="model.overview_description"
                      ></textarea>
                      <!-- <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="model.overview_description"
                      /> -->
                      <label class="form-control-label"
                        >Department Description</label
                      >
                      <textarea
                        class="form-control mt-2"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.department_description"
                      ></textarea>
                      <label class="form-control-label"
                        >Facilities Description</label
                      >
                      <textarea
                        class="form-control mt-2"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.facilities_description"
                      ></textarea>
                    </div>

                    <div class="col-lg-4">
                      <label class="form-control-label">Images</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event, 'overview_image')"
                      />
                      <base-progress
                        type="success"
                        v-model="uploadPercentage"
                        label="File uploaded"
                      ></base-progress>
                      <div v-if="model.overview_image">
                        <img
                          :src="model.overview_image"
                          class="img-fluid rounded shadow mt-2 card-body"
                        />
                      </div>
                      <textarea
                        class="form-control mt-2"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.overview_image_title"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Curriculum</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-8">
                      <label class="form-control-label"
                        >Curriculum content</label
                      >
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="model.curriculum_long_description"
                      />
                      <label class="form-control-label mt-3">Features</label>
                      <div
                        class="row"
                        v-for="(feature, index) in model.curriculum_features"
                        :key="index"
                      >
                        <div class="col-md-10 pr-0">
                          <base-input
                            placeholder="Feature"
                            v-model="model.curriculum_features[index]"
                            input-classes="mb--3"
                          />
                        </div>
                        <div class="col-lg-2 text-center">
                          <base-button
                            @click="deletefeature(index, 'add')"
                            id="deletefeature"
                            type="danger"
                            class="float-center border-0 mt-2"
                            size="sm"
                            outline
                          >
                            <i class="fas fa-trash"></i>
                          </base-button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-10 pr-0">
                          <base-button
                            @click="addfeature()"
                            id="addfeature"
                            type="primary"
                            class="float-center"
                            block
                            outline
                          >
                            <i class="fas fa-plus mr-2"></i>Add new feature
                          </base-button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <label class="form-control-label">Images</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event, 'curriculum_image')"
                      />
                      <base-progress
                        type="success"
                        v-model="uploadPercentage"
                        label="File uploaded"
                      ></base-progress>
                      <div v-if="model.curriculum_image">
                        <img
                          :src="model.curriculum_image"
                          class="img-fluid rounded shadow mt-2 card-body"
                        />
                      </div>
                      <textarea
                        class="form-control mt-2"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.curriculum_short_description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="row">
                  <div class="col-lg-12">
                    <base-button
                      @click="savecontent()"
                      id="savecontent"
                      type="primary"
                      class="float-center"
                      block
                      size="lg"
                    >
                      <span>Save Content</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow" :class="''">
              <div class="card-header border-bottom" :class="''">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0" :class="''">Department</h3>
                  </div>
                  <div class="col text-right">
                    <base-button type="primary" @click="adddepartmentpopup"
                      ><i class="fas fa-plus"></i> Add new
                      department</base-button
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-lg-4"
                    v-for="(department, index) in departmentData"
                    :key="department._id"
                  >
                    <!-- Basic with list group -->
                    <div class="card border shadow-sm">
                      <!-- List group -->
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item small">
                          <div
                            :class="
                              'icon icon-lg icon-shape shadow rounded-circle mx-auto ' +
                              department.icon_class
                            "
                          >
                            <i :class="department.icon"></i>
                          </div>
                        </li>
                        <li class="list-group-item small">
                          {{ department.heading }}
                        </li>
                        <li class="list-group-item small">
                          {{ department.short_description }}
                        </li>
                      </ul>
                      <!-- Card body -->
                      <div class="card-body p-3">
                        <!-- <p class="card-text mb-4" v-html="post.blog_body"></p> -->
                        <div class="">
                          <div class="float-left">
                            <!-- <router-link to="#" class="btn btn-primary btn-sm">View</router-link> -->
                          </div>
                          <div class="float-right">
                            <base-button
                              type="primary"
                              size="sm"
                              @click="editdepartmentpopup(department)"
                              >Edit</base-button
                            >
                            <base-button
                              type="danger"
                              size="sm"
                              @click="deletedepartment(department)"
                              >Delete</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow" :class="''">
              <div class="card-header border-bottom" :class="''">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0" :class="''">Library & Smart Classes</h3>
                  </div>
                  <div class="col text-right">
                    <!-- <base-button type="primary" @click="adddepartmentpopup"
                      ><i class="fas fa-plus"></i> Add new
                      department</base-button
                    > -->
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-lg-4"
                    v-for="(library, index) in libraryData"
                    :key="library._id"
                  >
                    <!-- Basic with list group -->
                    <div class="card border shadow-sm">
                      <!-- List group -->
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item small">
                          <div
                            :class="
                              'icon icon-lg icon-shape shadow rounded-circle mx-auto ' +
                              library.icon_class
                            "
                          >
                            <i :class="library.icon"></i>
                          </div>
                        </li>
                        <li class="list-group-item small">
                          {{ library.name }}
                        </li>
                        <li class="list-group-item small">
                          {{ library.heading }}
                        </li>
                      </ul>
                      <!-- Card body -->
                      <div class="card-body p-3">
                        <!-- <p class="card-text mb-4" v-html="post.blog_body"></p> -->
                        <div class="">
                          <div class="float-left">
                            <!-- <router-link to="#" class="btn btn-primary btn-sm">View</router-link> -->
                          </div>
                          <div class="float-right">
                            <base-button
                              type="primary"
                              size="sm"
                              @click="editlibrarypopup(library)"
                              >Edit</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow" :class="''">
              <div class="card-header border-bottom" :class="''">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0" :class="''">Labs</h3>
                  </div>
                  <div class="col text-right">
                    <!-- <base-button type="primary" @click="adddepartmentpopup"
                      ><i class="fas fa-plus"></i> Add new
                      department</base-button
                    > -->
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-lg-4"
                    v-for="(lab, index) in labData"
                    :key="lab._id"
                  >
                    <!-- Basic with list group -->
                    <div class="card border shadow-sm">
                      <!-- List group -->
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item small">
                          <div
                            :class="
                              'icon icon-lg icon-shape shadow rounded-circle mx-auto ' +
                              lab.bgcolor_class
                            "
                          >
                            <i :class="lab.icon"></i>
                          </div>
                        </li>
                        <li class="list-group-item small">
                          {{ lab.name }}
                        </li>
                        <li class="list-group-item small">
                          <span v-html="lab.description"></span>
                        </li>
                      </ul>
                      <!-- Card body -->
                      <div class="card-body p-3">
                        <!-- <p class="card-text mb-4" v-html="post.blog_body"></p> -->
                        <div class="">
                          <div class="float-left">
                            <!-- <router-link to="#" class="btn btn-primary btn-sm">View</router-link> -->
                          </div>
                          <div class="float-right">
                            <base-button
                              type="primary"
                              size="sm"
                              @click="editlabpopup(lab)"
                              >Edit</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow" :class="''">
              <div class="card-header border-bottom" :class="''">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0" :class="''">Our campus & Class room</h3>
                  </div>
                  <div class="col text-right">
                    <!-- <base-button type="primary" @click="adddepartmentpopup"
                      ><i class="fas fa-plus"></i> Add new
                      department</base-button
                    > -->
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-lg-4"
                    v-for="(ourcampus, index) in ourcampusData"
                    :key="ourcampus._id"
                  >
                    <!-- Basic with list group -->
                    <div class="card border shadow-sm">
                      <!-- List group -->
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item small">
                          <div
                            :class="
                              'icon icon-lg icon-shape shadow rounded-circle mx-auto ' +
                              ourcampus.icon_class
                            "
                          >
                            <i :class="ourcampus.icon"></i>
                          </div>
                        </li>
                        <li class="list-group-item small">
                          {{ ourcampus.name }}
                        </li>
                        <li class="list-group-item small">
                          <span v-html="ourcampus.description"></span>
                        </li>
                      </ul>
                      <!-- Card body -->
                      <div class="card-body p-3">
                        <!-- <p class="card-text mb-4" v-html="post.blog_body"></p> -->
                        <div class="">
                          <div class="float-left">
                            <!-- <router-link to="#" class="btn btn-primary btn-sm">View</router-link> -->
                          </div>
                          <div class="float-right">
                            <base-button
                              type="primary"
                              size="sm"
                              @click="editourcampuspopup(ourcampus)"
                              >Edit</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- add department popup -->
      <modal :show.sync="adddepartment" modalClasses="modal-lg">
        <p id="modal-title-default" slot="header" class="modal-title">
          <span>Add Department</span>
        </p>
        <template>
          <form @submit.prevent>
            <div class="row mt--3">
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Heading</label>
                <base-input
                  v-model="departmentmodel.heading"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Icon class</label>
                <base-input
                  v-model="departmentmodel.icon_class"
                  alternative=""
                  input-classes="form-control-alternative"
                />
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">icon</label>
                <base-input
                  v-model="departmentmodel.icon"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Short description</label>
                <base-input
                  v-model="departmentmodel.short_description"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">linkId</label>
                <base-input
                  v-model="departmentmodel.linkId"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Description</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="departmentmodel.description"
                />
              </div>
            </div>
          </form>
        </template>

        <template slot="footer">
          <base-button
            @click="saveDepartment()"
            type="success"
            id="createdepartmentbtn"
            class="float-center"
          >
            Save Department
          </base-button>
          <base-button
            type="link"
            class="ml-auto"
            @click="adddepartment = false"
          >
            <span>Close</span>
          </base-button>
        </template>
      </modal>
      <!-- edit department popup -->
      <modal :show.sync="editdepartment" modalClasses="modal-lg">
        <p id="modal-title-default" slot="header" class="modal-title">
          <span>Edit Department</span>
        </p>
        <template>
          <form @submit.prevent>
            <div class="row mt--3">
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Heading</label>
                <base-input
                  v-model="editdepartmentmodal.heading"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">icon class</label>
                <base-input
                  v-model="editdepartmentmodal.icon_class"
                  alternative=""
                  input-classes="form-control-alternative"
                />
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">icon</label>
                <base-input
                  v-model="editdepartmentmodal.icon"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">short_description</label>
                <base-input
                  v-model="editdepartmentmodal.short_description"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">linkId</label>
                <base-input
                  v-model="editdepartmentmodal.linkId"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Description</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="editdepartmentmodal.description"
                />
              </div>
            </div>
          </form>
        </template>

        <template slot="footer">
          <base-button
            @click="updateDepartment()"
            type="success"
            id="updatedepartmentbtn"
            class="float-center"
          >
            Update Department
          </base-button>
          <base-button
            type="link"
            class="ml-auto"
            @click="editdepartment = false"
          >
            <span>Close</span>
          </base-button>
        </template>
      </modal>
      <!-- edit Library popup -->
      <modal :show.sync="editlibrary" modalClasses="modal-lg">
        <p id="modal-title-default" slot="header" class="modal-title">
          <span>Edit </span>
        </p>
        <template>
          <form @submit.prevent>
            <div class="row mt--3">
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Name</label>
                <base-input
                  v-model="editlibrarymodal.name"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <!-- <div class="col-lg-12 mb-2">
                <label class="form-control-label">bgcolor class</label>
                <base-input
                  v-model="editlibrarymodal.bgcolor"
                  alternative=""
                  input-classes="form-control-alternative"
                />
              </div> -->
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">icon</label>
                <base-input
                  v-model="editlibrarymodal.icon"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Heading</label>
                <base-input
                  v-model="editlibrarymodal.heading"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mt-2">
                <label class="form-control-label">Image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control"
                  name="img"
                  @change="hadleimage($event, 'library')"
                />
                <base-progress
                  type="success"
                  v-model="uploadPercentage"
                  label="File uploaded"
                  class="mt-2"
                ></base-progress>
                <div>
                  <img
                    :src="editlibrarymodal.image"
                    class="img-fluid rounded shadow mt-2"
                  />
                </div>
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">linkId</label>
                <base-input
                  v-model="editlibrarymodal.linkId"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Description</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="editlibrarymodal.description"
                />
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Values</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="editlibrarymodal.values"
                />
              </div>
            </div>
          </form>
        </template>

        <template slot="footer">
          <base-button
            @click="updateLibrary()"
            type="success"
            id="updatelibrary"
            class="float-center"
          >
            Update
          </base-button>
          <base-button type="link" class="ml-auto" @click="editlibrary = false">
            <span>Close</span>
          </base-button>
        </template>
      </modal>
      <!-- edit lab popup -->
      <modal :show.sync="editlab" modalClasses="modal-lg">
        <p id="modal-title-default" slot="header" class="modal-title">
          <span>Edit </span>
        </p>
        <template>
          <form @submit.prevent>
            <div class="row mt--3">
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Name</label>
                <base-input
                  v-model="editlabmodal.name"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">bgcolor class</label>
                <base-input
                  v-model="editlabmodal.bgcolor"
                  alternative=""
                  input-classes="form-control-alternative"
                />
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">icon</label>
                <base-input
                  v-model="editlabmodal.icon"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mt-2">
                <label class="form-control-label">Image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control"
                  name="img"
                  @change="hadleimage($event, 'lab')"
                />
                <base-progress
                  type="success"
                  v-model="uploadPercentage"
                  label="File uploaded"
                  class="mt-2"
                ></base-progress>
                <div>
                  <img
                    :src="editlabmodal.image"
                    class="img-fluid rounded shadow mt-2"
                  />
                </div>
              </div>
              <!-- <div class="col-lg-12 mb-2">
                <label class="form-control-label">Heading</label>
                <base-input
                  v-model="editlabmodal.heading"
                  alternative=""
                  input-classes="form-control-alternative"
                />
              </div> -->
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">linkId</label>
                <base-input
                  v-model="editlabmodal.linkId"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Description</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="editlabmodal.description"
                />
              </div>
            </div>
          </form>
        </template>

        <template slot="footer">
          <base-button
            @click="updateLab()"
            type="success"
            id="updatelab"
            class="float-center"
          >
            Update
          </base-button>
          <base-button type="link" class="ml-auto" @click="editlibrary = false">
            <span>Close</span>
          </base-button>
        </template>
      </modal>
      <!-- edit ourcampus popup -->
      <modal :show.sync="editourcampus" modalClasses="modal-lg">
        <p id="modal-title-default" slot="header" class="modal-title">
          <span>Edit </span>
        </p>
        <template>
          <form @submit.prevent>
            <div class="row mt--3">
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Name</label>
                <base-input
                  v-model="editourcampusmodal.name"
                  alternative=""
                  input-classes="form-control-alternative"
                />
              </div>
              <!-- <div class="col-lg-12 mb-2">
                <label class="form-control-label">bgcolor class</label>
                <base-input
                  v-model="editourcampusmodal.icon_class"
                  alternative=""
                  input-classes="form-control-alternative"
                />
              </div> -->
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">icon</label>
                <base-input
                  v-model="editourcampusmodal.icon"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mt-2">
                <label class="form-control-label">Image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control"
                  name="img"
                  @change="hadleimage($event, 'ourcampus1')"
                />
                <base-progress
                  type="success"
                  v-model="uploadPercentage"
                  label="File uploaded"
                  class="mt-2"
                ></base-progress>
                <div>
                  <img
                    :src="editourcampusmodal.image1"
                    class="img-fluid rounded shadow mt-2"
                  />
                </div>
              </div>
              <div class="col-lg-12 mt-2">
                <label class="form-control-label">Image 2</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control"
                  name="img"
                  @change="hadleimage($event, 'ourcampus2')"
                />
                <base-progress
                  type="success"
                  v-model="uploadPercentage"
                  label="File uploaded"
                  class="mt-2"
                ></base-progress>
                <div>
                  <img
                    :src="editourcampusmodal.image2"
                    class="img-fluid rounded shadow mt-2"
                  />
                </div>
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Heading</label>
                <base-input
                  v-model="editourcampusmodal.heading"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">linkId</label>
                <base-input
                  v-model="editourcampusmodal.linkId"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Description</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="editourcampusmodal.description"
                />
              </div>
            </div>
          </form>
        </template>

        <template slot="footer">
          <base-button
            @click="updateOurcampus()"
            type="success"
            id="updateourcampus"
            class="float-center"
          >
            Update
          </base-button>
          <base-button
            type="link"
            class="ml-auto"
            @click="editourcampus = false"
          >
            <span>Close</span>
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

import { Tabs } from "@/components";
import { BasePagination } from "@/components/Tabs/Tabs";

// Components
import BaseProgress from "@/components/BaseProgress";
// import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import StatsCard from "@/components/Cards/StatsCard";
import { required, minLength, between } from "vuelidate/lib/validators";
import VueSwal from "vue-swal";
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    // ActivityFeed,
    Tabs,
    LineChart,
    BarChart,
    BaseProgress,
    StatsCard,
    VueEditor,
    // TaskList,
    // PageVisitsTable,
    // SocialTrafficTable,
    // LightTable,
    // UserList,
    // ProgressTrackList
  },
  data() {
    return {
      addboardmember: false,
      datanotfound: false,
      editboardmember: false,
      editdepartment: false,
      adddepartment: false,
      editlibrary: false,
      editlab: false,
      editourcampus: false,
      editstore: false,
      addregion: false,
      editregion: false,
      addarea: false,
      editarea: false,
      datanotfound: false,
      uploadPercentage: 0,
      departmentData: [],
      libraryData: [],
      labData: [],
      ourcampusData: [],
      allregionData: [],
      selectstore: "",
      selectpin: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"],
      ],
      model: {
        academicpage_id: "",
        curriculum_image: "",
        curriculum_short_description: "",
        curriculum_long_description: "",
        curriculum_features: ["", "", "", ""],
      },
      departmentmodel: {
        department_id: "",
        icon: "",
        icon_class: "",
        heading: "",
        short_description: "",
        description: "",
        linkId: "",
        status: "approved",
      },
      editdepartmentmodal: {
        department_id: "",
        icon: "",
        icon_class: "",
        heading: "",
        short_description: "",
        description: "",
        linkId: "",
        status: "approved",
      },
      editlibrarymodal: {
        library_id: "",
        name: "",
        icon: "",
        bgcolor: "",
        heading: "",
        image: "",
        description: "",
        values: "",
        linkId: "",
        status: "approved",
      },
      editlabmodal: {
        lab_id: "",
        name: "",
        icon: "",
        bgcolor: "",
        heading: "",
        image: "",
        description: "",
        linkId: "",
        status: "approved",
      },
      editourcampusmodal: {
        ourcampus_id: "",
        name: "",
        icon: "",
        bgcolor: "",
        heading: "",
        image1: "",
        image2: "",
        description: "",
        linkId: "",
        status: "approved",
      },
      radios: {
        radio1: "checked",
        radio2: "checked",
      },
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      auth1: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  validations: {
    // model: {
    //   store_name:{
    //     required
    //   },
    //   store_img:{
    //     required
    //   },
    //   shipping_charge:{
    //     required
    //   },
    // }
  },
  mounted() {
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_academic",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.model = response.data.details[0];
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_department",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.departmentData = response.data.details;
        }
      });

    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_library",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.libraryData = response.data.details;
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_lab",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.labData = response.data.details;
        }
      });
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_ourcampus",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.ourcampusData = response.data.details;
        }
      });
  },
  methods: {
    hadleimage: function (event, type) {
      // Reference to the DOM input element
      this.uploadPercentage = 0;
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files[0].size < 5000000) {
        const fd = new FormData();
        fd.append("image", input.files[0], input.files[0].name);
        axios
          .post(process.env.VUE_APP_API_ENDPOINT + "image-upload", fd, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              console.log(this.uploadPercentage);
            }.bind(this),
          })
          .then((response) => {
            console.log(response);
            if (type == "curriculum_image") {
              this.model.curriculum_image = response.data.imageUrl;
              console.log(this.model.curriculum_image);
            }
            if (type == "overview_image") {
              this.model.overview_image = response.data.imageUrl;
              console.log(this.model.overview_image);
            }
            if (type == "li") {
              this.model.overview_image = response.data.imageUrl;
              console.log(this.model.overview_image);
            }
            if (type == "overview_image") {
              this.model.overview_image = response.data.imageUrl;
              console.log(this.model.overview_image);
            }
            if (type == "overview_image") {
              this.model.overview_image = response.data.imageUrl;
              console.log(this.model.overview_image);
            }
            if (type == "library") {
              this.editlibrarymodal.image = response.data.imageUrl;
              console.log(this.editlibrarymodal.image);
            }
            if (type == "lab") {
              this.editlabmodal.image = response.data.imageUrl;
              console.log(this.editlabmodal.image);
            }
            if (type == "ourcampus1") {
              this.editourcampusmodal.image1 = response.data.imageUrl;
              console.log(this.editourcampusmodal.image1);
            }
            if (type == "ourcampus2") {
              this.editourcampusmodal.image2 = response.data.imageUrl;
              console.log(this.editourcampusmodal.image2);
            }

            //else{
            //   this.storemodal.store_img=response.data.imageUrl;
            //   console.log(this.storemodal.store_img);
            // }
          });
      } else {
        swal({
          title: "File too large!",
          text: "Your file is larger than 5 MB. Please upload images which are less than 5 MB.",
          icon: "warning",
        }).then((result) => {});
      }
    },
    savecontent() {
      const querystring = require("querystring");
      if (!this.model.academicpage_id) {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "create_academic",
            this.model,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savecontent")
              .classList.remove("btn-primary");
            document.getElementById("savecontent").classList.add("btn-success");
            document.getElementById("savecontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savecontent")
                .classList.remove("btn-success");
              document
                .getElementById("savecontent")
                .classList.add("btn-primary");
              document.getElementById("savecontent").innerHTML = "Update";
              window.location.reload();
            }, 2000);

            //
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "update_academic",
            this.model,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savecontent")
              .classList.remove("btn-primary");
            document.getElementById("savecontent").classList.add("btn-success");
            document.getElementById("savecontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savecontent")
                .classList.remove("btn-success");
              document
                .getElementById("savecontent")
                .classList.add("btn-primary");
              document.getElementById("savecontent").innerHTML = "Update";
              window.location.reload();
            }, 2000);
          });
      }
    },
    addfeature() {
      this.model.curriculum_features.push("");
    },
    deletefeature(index, type) {
      this.model.curriculum_features.splice(index, 1);
    },
    adddepartmentpopup() {
      this.adddepartment = true;
    },
    saveDepartment() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "create_department",
          this.departmentmodel,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("createdepartmentbtn")
            .classList.remove("btn-primary");
          document
            .getElementById("createdepartmentbtn")
            .classList.add("btn-success");
          document.getElementById("createdepartmentbtn").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("createdepartmentbtn")
              .classList.remove("btn-success");
            document
              .getElementById("createdepartmentbtn")
              .classList.add("btn-primary");
            document.getElementById("createdepartmentbtn").innerHTML = "Save";
            window.location.reload();
          }, 2000);

          //
        });
    },
    editdepartmentpopup(row) {
      console.log(row);
      this.editdepartmentmodal = row;
      this.editdepartment = true;
    },
    updateDepartment() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_department",
          this.editdepartmentmodal,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("updatedepartmentbtn")
            .classList.remove("btn-primary");
          document
            .getElementById("updatedepartmentbtn")
            .classList.add("btn-success");
          document.getElementById("updatedepartmentbtn").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("updatedepartmentbtn")
              .classList.remove("btn-success");
            document
              .getElementById("updatedepartmentbtn")
              .classList.add("btn-primary");
            document.getElementById("updatedepartmentbtn").innerHTML = "Update";
            window.location.reload();
          }, 2000);
        });
    },
    editlibrarypopup(row) {
      console.log(row);
      this.editlibrarymodal = row;
      this.editlibrary = true;
    },
    updateLibrary() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_library",
          this.editlibrarymodal,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("updatelibrary")
            .classList.remove("btn-primary");
          document.getElementById("updatelibrary").classList.add("btn-success");
          document.getElementById("updatelibrary").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("updatelibrary")
              .classList.remove("btn-success");
            document
              .getElementById("updatelibrary")
              .classList.add("btn-primary");
            document.getElementById("updatelibrary").innerHTML = "Update";
            window.location.reload();
          }, 2000);
        });
    },
    editlabpopup(row) {
      console.log(row);
      this.editlabmodal = row;
      this.editlab = true;
    },
    updateLab() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_lab",
          this.editlabmodal,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document.getElementById("updatelab").classList.remove("btn-primary");
          document.getElementById("updatelab").classList.add("btn-success");
          document.getElementById("updatelab").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("updatelab")
              .classList.remove("btn-success");
            document.getElementById("updatelab").classList.add("btn-primary");
            document.getElementById("updatelab").innerHTML = "Update";
            window.location.reload();
          }, 2000);
        });
    },
    editourcampuspopup(row) {
      console.log(row);
      this.editourcampusmodal = row;
      this.editourcampus = true;
    },
    updateOurcampus() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_ourcampus",
          this.editourcampusmodal,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("updateourcampus")
            .classList.remove("btn-primary");
          document
            .getElementById("updateourcampus")
            .classList.add("btn-success");
          document.getElementById("updateourcampus").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("updateourcampus")
              .classList.remove("btn-success");
            document
              .getElementById("updateourcampus")
              .classList.add("btn-primary");
            document.getElementById("updateourcampus").innerHTML = "Update";
            //window.location.reload();
          }, 2000);
        });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      this.uploadPercentage = 0;
      if (file.size < 5000000) {
        var formData = new FormData();
        formData.append("image", file);

        axios
          .post(process.env.VUE_APP_API_ENDPOINT + "image-upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              console.log(this.uploadPercentage);
            }.bind(this),
          })
          .then((response) => {
            console.log(response);
            let url = response.data.imageUrl; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal({
          title: "File to large!",
          text: "Your file is larger than 5 MB. Please upload images which are less than 5 MB.",
          icon: "warning",
        }).then((result) => {});
      }
    },
    deletedepartment(row) {
      console.log(row);
      const querystring = require("querystring");
      swal({
        title: "Are you sure?",
        text: "wan't to delete",
        icon: "warning",
        buttons: { cancel: true, confirm: true },
      }).then((result) => {
        // <--
        if (result) {
          console.log("result cloud", result);
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT + "delete_department",
              {
                department_id: row.department_id,
              },
              this.auth1
            )
            .then((response) => {
              console.log(response);
              swal({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              }).then((result) => {
                console.log(result);
                window.location.reload();
              });
            });
        }
      });
    },
  },
};
</script>
<style>
.search-input .form-group {
  display: inline-block;
  margin-bottom: 0px;
}
</style>
