<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <!-- <div class="col-lg-6">

              <card gradient="info" class="border-0">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0 text-white">Total traffic</h5>
                    <span class="h2 font-weight-bold mb-0 text-white">350,897</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="ni ni-active-40"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                  <span class="text-white mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                  <span class="text-nowrap text-light">Since last month</span>
                </p>
              </card>
            </div>
            <div class="col-lg-6">

              <card gradient="danger" class="border-0">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0 text-white">Performance</h5>
                    <span class="h2 font-weight-bold mb-0 text-white">49,65%</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                      <i class="ni ni-spaceship"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                  <span class="text-white mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                  <span class="text-nowrap text-light">Since last month</span>
                </p>
              </card>
            </div> -->
          </div>
          <card class="">
            <div slot="header" class="row align-items-center">
              <div class="col-12">
                <h3 class="mb-0">Admin Details</h3>
              </div>
              <div class="col-4 text-right">
                <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
              </div>
            </div>
            <form @submit.prevent="updateProfile">
              <div class="">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Full Name"
                      v-model="model.user_name"
                      placeholder="e.g, Rahul Maurya"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="email"
                      label="Email address"
                      v-model="model.user_email"
                      placeholder="mike@email.com"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Mobile"
                      v-model="model.user_phone"
                      placeholder="e.g, 999999999"
                      oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">Image</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'main_img')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div>
                        <img :src="model.user_dp" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Address"
                      placeholder="e.g, delhi"
                      v-model="model.user_address"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Password"
                      placeholder="e.g, aaa@89"
                      v-model="model.user_password"
                    >
                    </base-input>
                  </div>
                  <!-- <div class="col-lg-6">
                    <base-input
                      type="text"
                      label="Last Name"
                      placeholder="Last Name"
                    >
                    </base-input>
                  </div> -->
                  <div class="col-xl-12">
                    <base-button type="primary" size="lg" id="saveuserbtn" @click="updateprofile()">Save Details</base-button>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import FileInput from '@/components/Inputs/FileInput'
  import { Tabs } from '@/components';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  export default {
    name: 'form-elements',
    components: {
      FileInput,
      Tabs
    },
    data() {
      return {
        uploadPercentage:0,
        inputs: {
          file: []
        },
        model:{
          user_name:"",
          user_address:"",
          user_dp:"",
          user_email:"",
          user_password:'',
          user_mobile:"",
        },
        checkboxes: {
          unchecked: false,
          checked: true,
          uncheckedDisabled: false,
          checkedDisabled: true
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted() {
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_admin',{ "details" :{"_id":localStorage.getItem('_id')}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.model=response.data.details[0];
        this.model.user_password="";
        }
            
      })
    },
    methods: {
      filesChange(files) {
        this.inputs.file = files
      },
      hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                 if(type=='main_img'){
                  this.model.user_dp=response.data.imageUrl;
                  console.log(this.model.user_dp);
                }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

        },
      updateprofile(){
            document.getElementById("saveuserbtn").innerHTML='Loading...';
            const querystring = require('querystring');
            axios.post(process.env.VUE_APP_API_ENDPOINT+'update_admin',querystring.stringify({user_id:localStorage.getItem('admin_id'),user_name: this.model.user_name,user_address: this.model.user_address,user_dp: this.model.user_dp,user_email: this.model.user_email,user_phone: this.model.user_phone,user_password: this.model.user_password}),this.auth)
          .then(response => {
                   console.log(response);
                    localStorage.setItem('admin_name', response.data.user_details[0].user_name);
                    localStorage.setItem('admin_email', response.data.user_details[0].user_email);
                    localStorage.setItem('admin_mobile', response.data.user_details[0].user_mobile);
                    localStorage.setItem('admin_img', response.data.user_details[0].user_dp);
                    localStorage.setItem('admin_address', response.data.user_details[0].user_address);
              document.getElementById("saveuserbtn").classList.remove('btn-primary');
              document.getElementById("saveuserbtn").classList.add('btn-success');
              document.getElementById("saveuserbtn").innerHTML='Updated';
              setTimeout(function(){
              document.getElementById("saveuserbtn").classList.remove('btn-success');
              document.getElementById("saveuserbtn").classList.add('btn-primary');
              document.getElementById("saveuserbtn").innerHTML="Update business";
              //window.location.reload()
            }, 2000);
            })
    },

  }
}
</script>
<style>
  .profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
  }
</style>
