<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-3" :class="''">
            <div class="p-3 border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Client Testimonials
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button v-if="pagepermission.create" type="primary" @click="addtestimonialpopup"><i class="fas fa-plus"></i> Create new testimonial</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4" v-for="testimonial, index in testimonialData" :key="testimonial.testimonialdata_id">
          <!-- Basic with list group -->
          <div class="card">
            <!-- List group -->
            <ul class="list-group list-group-flush">
              <li class="list-group-item small"><i class="fas fa-user-circle"></i> {{testimonial.auther}}</li>
            </ul>
            <!-- Card body -->
            <div class="card-body px-3 pt-0">
              <p class="card-text mb-4">{{testimonial.body}}</p>
              <base-button v-if="pagepermission.update" type="primary" size="sm" @click="edittestimonialpopup(testimonial)" >Edit</base-button>
              <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deletetestimonial(testimonial)">Delete</base-button>
            </div>
          </div>
        </div>

        <!-- shimmer starts here -->
        <div class="col-md-12" v-if="testimonialData.length==0 && !datanotfound">
          <div class="row justify-content-center">
            <div class="col-lg-12 mt-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- shimmer ends here -->
        <!-- no result found -->
        <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
          <div class="col-md-3 mx-auto mt-4 text-center">
              <img src="/img/theme/no-result.png" class="img-fluid">
              <p class="mt-4">No testimonial deta found.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- create testimonial popup -->
    <modal :show.sync="addtestimonial" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create new testimonial</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <label class="form-control-label">Auther name<span class="text-danger">*</span></label>
              <base-input placeholder="Auther name" v-model="testimonialmodal.auther" input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Testimonial Description (max characters allowed : 200)<span class="text-danger">*</span></label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="10" v-model="testimonialmodal.body"></textarea>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createtestimonial()"
          type="success"
          id="createtestimonialbtn"
          class="float-center"
        >
          <span>Create Testimonial</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addtestimonial = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit testimonial popup -->
    <modal :show.sync="edittestimonial" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit testimonial</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <base-input label="Auther name" placeholder="Auther name" v-model="edittestimonialmodal.auther" input-classes="" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Testimonial Description (max characters allowed : 200)</label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="5" maxlength="200" v-model="edittestimonialmodal.body"></textarea>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatetestimonial()"
          type="success"
          id="updateestorebtn"
          class="float-center"
        >
          <span>Update Testimonial</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="edittestimonial = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  // import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';
  import moment from 'moment';
  // import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  // import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      // Tabs,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addtestimonial: false,
        datanotfound:false,
        edittestimonial: false,
        uploadPercentage:0,
        pagepermission:{},
        testimonialData:[],
        storeData:[],
        regionData:[],
        testimonialmodal:{
          testimonial_id:'',
          title:'',
          body:'',
          auther:'',
          img:'',
          avtar:'',
          status:'',
          auther:'',
          body:'',
          status:'approved',
        },
        edittestimonialmodal:{
          testimonial_id:'',
          title:'',
          body:'',
          auther:'',
          img:'',
          avtar:'',
          status:'',
          auther:'',
          body:'',
          status:'',
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      testimonialmodal: {
        offer_code:{
          required
        },
        offer_type:{
          required
        },
        min_amount:{
          required
        },
        expires_on:{
          required
        },
        offer_value:{
          required
        },
      },
      edittestimonialmodal: {
        offer_code:{
          required
        },
        offer_type:{
          required
        },
        min_amount:{
          required
        },
        expires_on:{
          required
        },
        offer_value:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_testimonial',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.testimonialData=response.data.details;
        }else{
            this.testimonialData=[];
            this.datanotfound=true;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_region',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.regionData=response.data.details;
        }
            
      })
    },
    methods: {

      addtestimonialpopup(row){
        console.log(row);
        this.addtestimonial=true;
      },
      edittestimonialpopup(row){
        this.edittestimonialmodal=row;
        console.log(this.edittestimonialmodal);
        this.edittestimonial=true;
        // }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addtestimonial'){
                this.testimonialmodal.og_image=response.data.imageUrl;
                console.log(this.testimonialmodal.og_image);
              } else if(type=='edittestimonial'){
                this.edittestimonialmodal.og_image=response.data.imageUrl;
                console.log(this.edittestimonialmodal.og_image);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
              }).then((result) => {
               
              })
            }
      },
      createtestimonial(){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_testimonial',querystring.stringify({auther : this.testimonialmodal.auther,body : this.testimonialmodal.body}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createtestimonialbtn").classList.remove('btn-primary');
            document.getElementById("createtestimonialbtn").classList.add('btn-success');
            document.getElementById("createtestimonialbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createtestimonialbtn").classList.remove('btn-success');
            document.getElementById("createtestimonialbtn").classList.add('btn-primary');
            document.getElementById("createtestimonialbtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })

      },
      updatetestimonial(){
        const querystring = require('querystring');
        axios.post(process.env.VUE_APP_API_ENDPOINT+'update_testimonial',querystring.stringify({testimonial_id : this.edittestimonialmodal.testimonial_id,auther : this.edittestimonialmodal.auther,body : this.edittestimonialmodal.body}),this.auth)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("createtestimonialbtn").classList.remove('btn-primary');
          document.getElementById("createtestimonialbtn").classList.add('btn-success');
          document.getElementById("createtestimonialbtn").innerHTML='Updated';
          setTimeout(function(){
          document.getElementById("createtestimonialbtn").classList.remove('btn-success');
          document.getElementById("createtestimonialbtn").classList.add('btn-primary');
          document.getElementById("createtestimonialbtn").innerHTML="Update";
        }, 2000);
          window.location.reload()
        })
      },
      deletetestimonial(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_testimonial',querystring.stringify({testimonial_id :row.testimonial_id}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your file has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      format_datetime(value){
        if(value) {
          return moment.utc(new Date(value)).format("LLL")
        }
      },
      change_offer_type(type){
          if(this.testimonialmodal.offer_type=='FlatOnCart' && type=='add'){
            document.getElementById("max_amount").classList.add('d-none');
          }
          if(this.testimonialmodal.offer_type=='PercentageOnCart' && type=='add'){
            document.getElementById("max_amount").classList.remove('d-none');
          }

          if(this.edittestimonialmodal.offer_type=='FlatOnCart' && type=='edit'){
            document.getElementById("edit_max_amount").classList.add('d-none');
          }
          if(this.edittestimonialmodal.offer_type=='PercentageOnCart' && type=='edit'){
            document.getElementById("edit_max_amount").classList.add('d-none');
          }
      },
      deleteogimage(index){
        this.testimonialmodal.og_image='';
        this.uploadPercentage =0;
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
