var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Home',
          icon: 'fas fa-home text-body',
          path: '/dashboard'
        }}}),(_vm.checkpermission('/class/allclasses') || _vm.checkpermission('/class/subjects') || _vm.checkpermission('/class/exams') || _vm.checkpermission('/class/question-paper') || _vm.checkpermission('/response'))?_c('sidebar-item',{attrs:{"link":{
                name: 'Academics',
                icon: 'fas fa-chalkboard-teacher text-body'
                }}},[(_vm.checkpermission('/class/allclasses'))?_c('sidebar-item',{attrs:{"link":{ name: 'Classes', path: '/class/allclasses' }}}):_vm._e(),(_vm.checkpermission('/sections'))?_c('sidebar-item',{attrs:{"link":{ name: 'Sections', path: '/sections' }}}):_vm._e(),(_vm.checkpermission('/subjects'))?_c('sidebar-item',{attrs:{"link":{ name: 'Subjects', path: '/subjects' }}}):_vm._e(),(_vm.checkpermission('/exams'))?_c('sidebar-item',{attrs:{"link":{ name: ' Exam Session', path: '/exams' }}}):_vm._e(),(_vm.checkpermission('/question-paper'))?_c('sidebar-item',{attrs:{"link":{ name: 'Question Paper', path: '/question-paper' }}}):_vm._e(),(_vm.checkpermission('/response'))?_c('sidebar-item',{attrs:{"link":{ name: 'Response', path: '/response' }}}):_vm._e()],1):_vm._e(),(_vm.checkpermission('/toppers'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Toppers',
          icon: 'fas fa-trophy text-body',
          path: '/toppers'
        }}}):_vm._e(),(_vm.checkpermission('/results'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Results',
          icon: 'fas fa-poll text-body',
          path: '/results'
        }}}):_vm._e(),(_vm.checkpermission('/tc'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Transfer Certificates',
          icon: 'fas fa-file-contract text-body',
          path: '/tc'
        }}}):_vm._e(),(_vm.checkpermission('/content/home') || _vm.checkpermission('/content/home'))?_c('sidebar-item',{attrs:{"link":{
                name: 'Website Content',
                icon: 'fas fa-quote-left text-body'
                }}},[(_vm.checkpermission('/content/home'))?_c('sidebar-item',{attrs:{"link":{ name: 'Homepage', path: '/content/home' }}}):_vm._e(),(_vm.checkpermission('/content/about'))?_c('sidebar-item',{attrs:{"link":{ name: 'About', path: '/content/about' }}}):_vm._e(),(_vm.checkpermission('/content/authoritymessage'))?_c('sidebar-item',{attrs:{"link":{ name: 'Messages', path: '/content/authoritymessage' }}}):_vm._e(),(_vm.checkpermission('/content/academics'))?_c('sidebar-item',{attrs:{"link":{ name: 'Academics', path: '/content/academics' }}}):_vm._e(),(_vm.checkpermission('/content/beyondacademics'))?_c('sidebar-item',{attrs:{"link":{ name: 'Beyond Academics', path: '/content/beyondacademics' }}}):_vm._e(),(_vm.checkpermission('/content/admissions'))?_c('sidebar-item',{attrs:{"link":{ name: 'Admissions', path: '/content/admissions' }}}):_vm._e(),(_vm.checkpermission('/content/preprimary'))?_c('sidebar-item',{attrs:{"link":{ name: 'Pre Primary', path: '/content/preprimary' }}}):_vm._e(),(_vm.checkpermission('/content/contact'))?_c('sidebar-item',{attrs:{"link":{ name: 'Contact Page', path: '/content/contact' }}}):_vm._e()],1):_vm._e(),(_vm.checkpermission('/recentnews'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Recent News',
          icon: 'fas fa-newspaper text-body',
          path: '/recentnews'
        }}}):_vm._e(),(_vm.checkpermission('/trips/alltrips'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Annual Trips',
          icon: 'fas fa-bus text-body',
          path: '/trips/alltrips'
        }}}):_vm._e(),(_vm.checkpermission('/queries'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Queries',
          icon: 'fas fa-life-ring text-body',
          path: '/queries'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Admission Queries',
          icon: 'fas fa-id-badge text-body',
          path: '/admissionenquiries'
        }}}),(_vm.checkpermission('/testimonials'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Testimonials',
          icon: 'fas fa-comment-alt text-body',
          path: '/testimonials'
        }}}):_vm._e(),(_vm.checkpermission('/headermenu'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Top Menu',
          icon: 'fas fa-bars text-body',
          path: '/headermenu'
        }}}):_vm._e(),(_vm.checkpermission('/team/staff') || _vm.checkpermission('/team/roles') || _vm.checkpermission('/team/permissions'))?_c('sidebar-item',{attrs:{"link":{
                name: 'Staff Management',
                icon: 'fas fa-sitemap text-body',
                }}},[(_vm.checkpermission('/team/staff'))?_c('sidebar-item',{attrs:{"link":{ name: 'Staff', path: '/team/staff' }}}):_vm._e(),(_vm.checkpermission('/team/roles'))?_c('sidebar-item',{attrs:{"link":{ name: 'Roles', path: '/team/roles' }}}):_vm._e(),(_vm.checkpermission('/team/permissions'))?_c('sidebar-item',{attrs:{"link":{ name: 'Permissions', path: '/team/permissions' }}}):_vm._e()],1):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }