<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Subjects
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectstore" @change="getfilterdata()">
                    <option value="">Select Store</option>
                    <option v-for="store in storeData" :key="store.store_id" :value="store.store_id">{{store.store_name}}</option>
                  </select> -->
                  <base-button type="primary" @click="addsubjectpopup"><i class="fas fa-plus"></i> Create Subject</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="subjectData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Subject name</th>
                  <th>Subject image</th>
                  <th>Teacher</th>
                  <th>class</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget">
                      {{row.subject_name}}
                    </td>
                    <td class="budget">
                      <img :src="row.subject_image" width="100">
                    </td>
                    <td class="budget">
                      <p class="mb-0" v-if="row.subject_teacher">{{row.subject_teacher.user_name}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0" v-if="row.class_oid">{{row.class_oid.class_name}}</p>
                    </td>
                    <td class="text-right">
                      <base-button type="primary" size="sm" @click="editsubjectpopup(row)" >Edit</base-button>
                      <base-button type="danger" size="sm" @click="deletesubject(row)">Delete</base-button>
                      <!-- <router-link class="btn btn-primary btn-sm" to="/seo/meta" icon="fa fa-info">View</router-link> -->
                    </td>
                </template>
              </base-table>

              <!-- no data found here -->
              <div class="card-footer justify-content-center" v-if="!datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No Subject created.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="subjectData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- add baner -->
    <modal :show.sync="addsubject">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create Subject</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">Subject name<span class="text-danger">*</span></label>
              <base-input placeholder="Subject name" v-model="subjectmodal.subject_name" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.subjectmodal.subject_name.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Subject teacher<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="subjectmodal.subject_teacher">
                <option value="">Select teacher</option>
                <option v-for="teacher in teacherData" :key="teacher.user_id" :value="teacher._id">{{teacher.user_name}}</option>
              </select>
              <div
                  v-if="$v.subjectmodal.subject_teacher.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="subjectmodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.subjectmodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12 mt-2">
                <label class="form-control-label">Subject image<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'add')"
                >
                <div
                    v-if="$v.subjectmodal.subject_image.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="subjectmodal.subject_image" class="img-fluid rounded shadow mt-2">
                </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="subjectmodal.subject_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.subjectmodal.subject_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
         :disabled="$v.subjectmodal.$error" @click="$v.subjectmodal.$touch();createsubject()"
          type="success"
          id="createsubjectbtn"
          class="float-center"
        >
          <span>Save Subject</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addsubject = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit banner popup -->
    <modal :show.sync="editsubject">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Subject</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">Subject name<span class="text-danger">*</span></label>
              <base-input placeholder="subject name" v-model="editsubjectmodal.subject_name" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.editsubjectmodal.subject_name.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Subject teacher<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editsubjectmodal.subject_teacher">
                <option value="">Select teacher</option>
                <option v-for="teacher in teacherData" :key="teacher.user_id" :value="teacher._id">{{teacher.user_name}}</option>
              </select>
              <div
                  v-if="$v.editsubjectmodal.subject_teacher.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editsubjectmodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.editsubjectmodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12 mt-2">
                <label class="form-control-label">Subject image<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'edit')"
                >
                <div
                    v-if="$v.editsubjectmodal.subject_image.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="editsubjectmodal.subject_image" class="img-fluid rounded shadow mt-2">
                </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editsubjectmodal.subject_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.editsubjectmodal.subject_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editsubjectmodal.$error" @click="$v.editsubjectmodal.$touch();updatesubject()"
          type="success"
          id="updatesubjectbtn"
          class="float-center"
        >
          <span>Update Subject</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editsubject = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { ModelListSelect } from 'vue-search-select'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import 'vue-search-select/dist/VueSearchSelect.css'
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
// cayegory
// subcategory
// variation
// item
// offer
      DropzoneFileUpload,
      ModelListSelect,
    },
    data() {
      return {
        addsubject: false,
        editsubject: false,
        datanotfound:false,
        uploadPercentage:0,
        selectstore:'',
        pagepermission:{},
        subjectData:[],
        teacherData:[],
        classesData:[],
        subjectmodal:{
          subject_id:'',
          subject_name:'',
          subject_teacher:'',
          subject_image:'',
          subject_status:'approved',
          class_oid:'',
          verified:true
        },
        editsubjectmodal:{
          subject_id:'',
          subject_name:'',
          subject_teacher:'',
          subject_image:'',
          subject_status:'approved',
          class_oid:'',
          verified:true
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      subjectmodal: {
        subject_name:{
          required
        },
        subject_image:{
          required
        },
        subject_teacher:{
          required
        },
        subject_status:{
          required
        },
        class_oid:{
          required
        },
        
      },
      editsubjectmodal: {
        subject_name:{
          required
        },
        subject_image:{
          required
        },
        subject_teacher:{
          required
        },
        subject_status:{
          required
        },
        class_oid:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_subject',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subjectData=response.data.details;
        this.datanotfound=false;
        }else{
          this.subjectData=[];
        } this.datanotfound=true;
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_admin',{ "details" :{"user_type":'teacher'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.teacherData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.classesData=response.data.details;
        }
            
      })
    },
    methods: {

      addsubjectpopup(row){
        console.log(row);
        this.addsubject=true;
      },
      editsubjectpopup(row){
        console.log(row);
        this.editsubjectmodal.subject_id=row.subject_id;
        this.editsubjectmodal.subject_name=row.subject_name;
        // this.editsubjectmodal.subject_teacher=row.subject_teacher;
        this.editsubjectmodal.subject_image=row.subject_image;
        this.editsubjectmodal.subject_status=row.subject_status;
        this.editsubjectmodal.class_oid=row.class_oid._id;
        if(row.subject_teacher){
          this.editsubjectmodal.subject_teacher=row.subject_teacher._id;
        }
        this.editsubject=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='add'){
                this.subjectmodal.subject_image=response.data.imageUrl;
                console.log(this.subjectmodal.subject_image);
              }if(type=='edit'){
                this.editsubjectmodal.subject_image=response.data.imageUrl;
                console.log(this.subjectmodal.subject_image);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    createsubject(){
        if(this.$v.subjectmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_subject',querystring.stringify({subject_name : this.subjectmodal.subject_name,teacher_oid:this.subjectmodal.subject_teacher,subject_image:this.subjectmodal.subject_image,subject_status:this.subjectmodal.subject_status,class_oid:this.subjectmodal.class_oid}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createsubjectbtn").classList.remove('btn-primary');
            document.getElementById("createsubjectbtn").classList.add('btn-success');
            document.getElementById("createsubjectbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createsubjectbtn").classList.remove('btn-success');
            document.getElementById("createsubjectbtn").classList.add('btn-primary');
            document.getElementById("createsubjectbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
        }
        
      },
      updatesubject(){
        if(this.$v.editsubjectmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_subject',querystring.stringify({subject_id : this.editsubjectmodal.subject_id,subject_name : this.editsubjectmodal.subject_name,teacher_oid:this.editsubjectmodal.subject_teacher,subject_image:this.editsubjectmodal.subject_image,subject_status:this.editsubjectmodal.subject_status,class_oid:this.editsubjectmodal.class_oid}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatesubjectbtn").classList.remove('btn-primary');
            document.getElementById("updatesubjectbtn").classList.add('btn-success');
            document.getElementById("updatesubjectbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatesubjectbtn").classList.remove('btn-success');
            document.getElementById("updatesubjectbtn").classList.add('btn-primary');
            document.getElementById("updatesubjectbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
          })

        }
        
      },
      deletesubject(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_subject',querystring.stringify({subject_id :row.subject_id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getstore_name(val){
        const search = this.storeData.find(element => element.store_id === val);
        if(search){
          return search.store_name;
        }
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
