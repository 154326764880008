<template>
  <div>
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="h3 mb-0">Product details</h5>
                  </div>
                  <div class="col text-right">
                    <a type="primary" :href="'/products/editproduct/'+productData._id" class="btn btn-success"><i class="fas fa-edit"></i> EditProduct</a>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="col-lg-12">
                        <img alt="Image placeholder" :src="productData.item_thumbnail" class="img-thumbnail img-fluid rounded" v-if="productData.length!==0">
                        <div class="col-md-12" v-if="productData.length==0">
                          <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="card border-0 shadow">
                                   <div class="card-body pt-3 pb-3">
                                        <span class="photo-shimmer shine mb-3">
                                            
                                        </span>
                                        <div class="row">
                                            <div class="col-md-3 col-3 mt-1 mb-1">
                                                <span class="circle-shimmer shine"></span>
                                            </div>
                                            <div class="col-md-9 col-9 mt-1 mb-1">
                                                <div class="div-shimmer w-100 mt-0 pr-4">
                                                  <span class="line-shimmer w-100 shine"></span>
                                                  <span class="line-shimmer w-100 shine"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <!-- shimmer ends here -->
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-3" v-for="gallery in productData.item_gallery">
                        <img :src="gallery.item_img" class="avatar avatar-lg img-fluid">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row align-items-center pb-3">
                      <div class="col-sm-12">
                        <p class="small mb-0"><i class="fas fa-font"></i> Product Title</p>
                        <p class="h2 mb-2 font-weight-500">{{ productData.item_name }}</p>
                        <p class="small mb-0 mt-3"><i class="fas fa-rupee-sign"></i> Price</p>
                        <select class="form-control form-control-sm d-inline w-auto mr-2" @change="getprice()" v-model="productprice">
                          <option v-for="(item_price_type,index) in productData.item_price_type" :value="item_price_type">{{item_price_type.unit}}({{item_price_type.quantity}})</option>
                        </select>
                        <span class="font-weight-bold h3">Rs. {{price}}</span>
                        <p class="small mb-0 mt-3"><i class="fas fa-font"></i> Category & Sub-category</p>
                        <p class="h4 mb-2 mt-1 font-weight-500"><span v-if="productData.item_category">{{ productData.item_category.inventory_category_name }} </span>• <span v-if="productData.item_subcategory">{{ productData.item_subcategory.inventory_subcategory_name }}</span></p>
                        <!-- <p class="small mb-0 mt-3"><i class="fas fa-font"></i> Brand</p> -->
                        <!-- <p class="h4 mb-2 mt-1 font-weight-500"><span v-if="productData.item_brand">{{ productData.productData.item_brand.brand_name }} </span></p> -->
                        <p class="small mb-0 mt-4"><i class="fas fa-align-left"></i> Product Description</p>
                        <p class="small mb-0 font-weight-bold mt-2">Short Description</p>
                        <p class="mb-2 font-weight-400 small">
                            {{ productData.item_short_description }}
                        </p>
                        <p class="small mb-0 font-weight-bold">Note</p>
                        <p class="mb-2 font-weight-400 item_long_description" v-html="productData.item_long_description"></p>
                      </div>
                    </div>
                    <!-- Comments -->
                    <!-- <div class="mb-1">
                      <div class="media media-comment">
                        <img alt="Image placeholder" src="img/theme/team-1.jpg" class="avatar avatar-lg media-comment-avatar rounded-circle">
                        <div class="media-body">
                          <div class="media-comment-text">
                             <h6 class="h5 mt-0">Rahul Maurya</h6>
                             <p class="font-weight-400 lh-160">ABCDE FGHIJ KLMNO</p>
                             <div class="icon-actions">
                                <a href="#" class="like active">
                                  <i class="ni ni-like-2"></i>
                                  <span class="text-muted">11 likes</span>
                                </a>
                                <a href="#">
                                  <i class="ni ni-curved-next"></i>
                                  <span class="text-muted">15 shares</span>
                                </a>
                                
                                <base-dropdown class="pl-3">
                                  <base-button slot="title-container" size="sm" type="transparent" class="shadow-none">
                                    <i class="fas fa-ellipsis-v"></i>
                                  </base-button>
                                      <a class="dropdown-item">Edit</a>
                                      <a class="dropdown-item">Delete</a>
                                </base-dropdown>
                             </div>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="media align-items-center">
                        <img alt="Image placeholder" class="avatar avatar-lg rounded-circle mr-4" src="img/theme/team-3.jpg">
                        <div class="media-body">
                          <form>
                            <textarea class="form-control" placeholder="Write your comment" rows="1"></textarea>
                          </form>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>

                <!-- reviews -->
                <div class="row">
                  <div class="col-md-4 mt-2 px-2">
                    <div class="border shadow-sm py-3 px-3">
                      <div class="row all-average-ratings">
                        <div class="col-md-3 pr-0 pl-3">
                          <span class="text-success h2">{{productData.item_ratings}}<i class="fa fa-star"></i></span>
                          <span class="small d-block">{{productData.item_reviews}} reviews</span>
                        </div>
                        <div class="col-md-8">
                          <div class="mb--2" v-if="productData.delivery_time_rating">
                            <span class="small">Delivery time ({{productData.delivery_time_rating}} star)</span>
                            <base-progress :value="productData.delivery_time_rating*100/5" label="Default" type="warning" class="pt-2"></base-progress>
                          </div>
                          <div class="mb--2" v-if="productData.safety_and_hygiene_rating">
                            <span class="small">Safety & Hygiene ({{productData.safety_and_hygiene_rating}} star)</span>
                            <base-progress :value="productData.safety_and_hygiene_rating*100/5" label="Default" type="warning" class="pt-2"></base-progress>
                          </div>
                          <div class="mb--2" v-if="productData.taste_rating">
                            <span class="small">Taste ({{productData.taste_rating}} star)</span>
                            <base-progress :value="productData.taste_rating*100/5" label="Default" type="warning" class="pt-2"></base-progress>
                          </div>
                          <div class="mb-1 mt-1" >
                            <span class="small">Recommended : <span class="font-weight-bold">{{recommended_yes}} customers</span></span>
                          </div>
                          <div class="mb-0" >
                            <span class="small">Not Recommended : <span class="font-weight-bold">{{recommended_no}} customers</span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mt-2" v-for="rating in ratingData" :key="rating._id">
                    <div class="card card-blog">
                      <div class="card-body pt-2 pb-2 px-3" style="z-index: 2">
                          <span class="small"></span><badge type="danger" class="">{{rating.avg_rating}}<i class="fa fa-star"></i></badge><base-button size="sm" type="link" class="float-right mr-0" @click="deleterating(rating.rating_id,rating.item_oid._id)"><i class="fa fa-trash"></i></base-button><base-button size="sm" v-if="checkreview(rating.user_oid)" type="link" @click="editrating(rating)" class="float-right"><i class="fa fa-edit"></i></base-button>
                          <!-- <h5 class="card-title">Focus on Your Employees</h5> -->
                          <p class="card-description my-2 small">{{rating.review}}</p>
                          <div class="row mx-0 border rounded pt-1 pb-2 bg-secondary shadow-sm">
                            <div class="col-md-6 px-2" v-if="rating.delivery_time_rating">
                              <span class="small">Delivery time: {{rating.delivery_time_rating}}</span>
                            </div>
                            <div class="col-md-6 px-2" v-if="rating.delivery_time_rating">
                              <span class="small">Safety & Hygiene: {{rating.safety_and_hygiene_rating}}</span>
                            </div>
                            <div class="col-md-6 px-2" v-if="rating.delivery_time_rating">
                              <span class="small">Taste: {{rating.taste_rating}}</span>
                            </div>
                            <div class="col-md-6 px-2" v-if="rating.delivery_time_rating">
                              <span class="small">Recommended: {{rating.recommended}}</span>
                            </div>
                          </div>
                      </div>
                      <div class="card-footer pt-3 px-3">
                          <div class="author d-flex float-left w-60"><img :src="rating.user_oid.user_dp"  v-if="rating.user_oid.user_dp" alt="..." class="avatar img-raised avatar-sm" /><img src="/img/theme/user.png" v-if="!rating.user_oid.user_dp" alt="..." class="avatar img-raised avatar-sm" /><div class="pl-2 d-inline"><span class="d-block mb--1 small">{{rating.user_oid.user_name}}</span><span class="small">{{rating.user_oid.user_phone}}</span></div></div>
                          <div class="stats stats-right float-right mt-2 small"><i class="ni ni-calendar-grid-58"></i> {{dateformat(rating.created_at)}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="editproduct" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Product</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="Category Name" placeholder="Category Name" input-classes="" />
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">Category Thumbnail</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'web')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img src="model.default_img_web" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
          </div>
          <div class="row mt-1" id="optionsid">
            <div class="col-lg-6">
                <label class="form-control-label">Category Featured Graphic</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'web')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img src="model.default_img_web" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for Web</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for Web"   id="file" ref="myFiles" name="img" @change="">
            </div> -->
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for App</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for App" id="file" ref="myFiles" name="img" @change="">
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatecollection()"
          type="success"
          class="float-center"
        >
          <span>Save Category</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editproduct = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import moment from 'moment';
  import _ from 'lodash';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        inputs: {
          file: []
        },
        subcategoryData:[],
        categoryData:[],
        vaiationData:[],
        ratingData:[],
        subcategoryproductData:[],
        storeData:[],
        productprice:'',
        price:'',
        productData:[],
        recommended_yes:0,
        recommended_no:0,
        createproduct:false,
        createcategory:false,
        editcategory:false,
        editproduct:false,
        uploadPercentage:0,
        categorymodal:{
        inventory_subcategory_id : '',
        inventory_subcategory_name : '',
        inventory_category_name :this.$route.params.name,
        inventory_category_oid :this.$route.params.id,
        inventory_subcategory_type : '',
        inventory_subcategory_img : '',
        inventory_subcategory_icon : '',
        inventory_subcategory_theme_color : '',
        banner_primary : '',
        banner_secondary : '',
        is_featured : '',
        sequenced_at : '',
        created_by : localStorage.getItem('_id'),
        status : '',
        },
        editcategorymodal:{
          inventory_subcategory_id : '',
          inventory_subcategory_name : '',
          inventory_category_name :'',
          inventory_category_oid :'',
          inventory_subcategory_type : '',
          inventory_subcategory_img : '',
          inventory_subcategory_icon : '',
          inventory_subcategory_theme_color : '',
          banner_primary : '',
          banner_secondary : '',
          is_featured : '',
          sequenced_at : '',
          created_by : localStorage.getItem('_id'),
          status : '',
        },
        editproductmodal:{
          item_id : '',
          item_name : '',
          item_tagline : '',
          item_short_description : '',
          item_long_description : '',
          item_type : '',
          is_featured :'',
          sequenced_at :'',
          status:'',
          visibility :'', 
          item_category :this.$route.params.id,
          item_subcategory : '',
          item_variation : '',
          item_sp : '',
          item_mp : '',
          item_discount : '',
          minimum_quantity : '',
          item_ratings : '',
          item_theme_color : '',
          item_tags : [],
          item_main_img : '',
          item_secondary_img : '',
          item_thumbnail : '',
          store : '',
          number_available :'',
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted () {

      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item_by_id',{item_id:this.$route.params.id},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.productData=response.data.details[0];
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_rating_review',{ "details" :{item_oid:this.productData._id},"offset":"0"},this.authjson)
          .then(response => {
            console.log(response);
            if(response.data.details){
              this.ratingData=response.data.details;
              this.revieditems =  _.map(this.ratingData, reviews => (reviews.user_oid._id));
              console.log(this.revieditems);
              for (var i = 0; i < this.ratingData.length; i++) {
                if(this.ratingData[i].recommended=='Yes'){
                  this.recommended_yes++;
                }else{
                  this.recommended_no++;
                }
                
              }
                
            }else{
              this.ratingData=[];
            }
                
          })
        if (this.productData.hasOwnProperty('item_brand')) {
          this.productData.item_brand='';
        } 
        this.price=this.productData.item_price_type[0].price;
        this.productprice=this.productData.item_price_type[0];
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.categoryData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        }
            
      })


      //inventory_category_oid:this.$route.params.id

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',{ "details" :{},"offset" : "0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryproductData=response.data.details;
        }
            
      })


    },
    methods: {
          dateformat(val) {
            return moment(new Date(val)).format("MMM Do YY");
            //console.log(this.blogData.date);
          },
          reviewexist(){
              return this.revieditems.includes(localStorage.getItem('_id'));
          },
          checkreview(user_oid){
            if(localStorage.getItem('_id')==user_oid._id){
              return true;
            }else{
              return false;
            }

          },
          createcategorymodal(){
            this.createcategory=true;
          },
          getprice(){
            this.price=this.productprice.price;
          },
          createproductpopup(){
            this.createproduct=true;
          },
           hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                if(type=='icon'){
                  this.categorymodal.inventory_subcategory_icon=response.data.imageUrl;
                  console.log(this.categorymodal.inventory_subcategory_icon);
                }else if(type=='image'){
                  this.categorymodal.inventory_subcategory_img=response.data.imageUrl;
                  console.log(this.categorymodal.inventory_subcategory_img);
                }else if(type=='editicon'){
                  this.editcategorymodal.inventory_subcategory_icon=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_icon);
                }else if(type=='editimage'){
                  this.editcategorymodal.inventory_subcategory_img=response.data.imageUrl;
                  console.log(this.editcategorymodal.inventory_subcategory_img);
                }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

        },
        savesubcategory() {
          //,banner_primary:this.categorymodal.banner_primary,banner_secondary:this.categorymodal.banner_secondary,is_featured:this.categorymodal.is_featured,
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_inventory_subcategory',querystring.stringify({inventory_subcategory_name : this.categorymodal.inventory_subcategory_name,inventory_category_name:this.categorymodal.inventory_category_name,inventory_category_oid:this.categorymodal.inventory_category_oid,inventory_subcategory_type:this.categorymodal.inventory_subcategory_type,status:this.categorymodal.status,inventory_subcategory_icon:this.categorymodal.inventory_subcategory_icon,inventory_subcategory_img:this.categorymodal.inventory_subcategory_img,sequenced_at:this.categorymodal.sequenced_at,inventory_subcategory_theme_color:this.categorymodal.inventory_subcategory_theme_color,created_by:this.categorymodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn").classList.remove('btn-primary');
            document.getElementById("updatebtn").classList.add('btn-success');
            document.getElementById("updatebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn").classList.remove('btn-success');
            document.getElementById("updatebtn").classList.add('btn-primary');
            document.getElementById("updatebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })

        },
        openeditsubcategory(row){
            this.editcategory=true;
            this.editcategorymodal.inventory_subcategory_id =row.inventory_subcategory_id;
            this.editcategorymodal.inventory_subcategory_name =row.inventory_subcategory_name;
            this.editcategorymodal.inventory_category_oid =row.inventory_category_oid;
            this.editcategorymodal.inventory_subcategory_type =row.inventory_subcategory_type;
            this.editcategorymodal.inventory_subcategory_img =row.inventory_subcategory_img;
            this.editcategorymodal.inventory_subcategory_icon =row.inventory_subcategory_icon;
            this.editcategorymodal.inventory_subcategory_theme_color =row.inventory_subcategory_theme_color;
            this.editcategorymodal.banner_primary =row.banner_primary;
            this.editcategorymodal.banner_secondary =row.banner_secondary;
            this.editcategorymodal.is_featured =row.is_featured;
            this.editcategorymodal.sequenced_at =row.sequenced_at;
            this.editcategorymodal.created_by =row.created_by;
            this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
          },

        updatesubcategory() {
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_subcategory',{ inventory_subcategory_id:this.editcategorymodal.inventory_subcategory_id,inventory_subcategory_name : this.editcategorymodal.inventory_subcategory_name,inventory_category_name:this.editcategorymodal.inventory_category_name,inventory_category_oid:this.editcategorymodal.inventory_category_oid,inventory_subcategory_type:this.editcategorymodal.inventory_subcategory_type,status:this.editcategorymodal.status,inventory_subcategory_icon:this.editcategorymodal.inventory_subcategory_icon,inventory_subcategory_img:this.editcategorymodal.inventory_subcategory_img,banner_primary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404864493banner-1%20%281%29.jpg',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611404896351banner-1-top%20%281%29.jpg',sequenced_at:1}],banner_secondary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611479053519banner%202%20%281%29.jpg',sequenced_at:1}],is_featured:true,sequenced_at:this.editcategorymodal.sequenced_at,inventory_subcategory_theme_color:this.editcategorymodal.inventory_subcategory_theme_color,created_by:this.editcategorymodal.created_by},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
          }, 2000);

            //window.location.reload()

          })

        },
      deletesubcategory(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_inventory_subcategory',querystring.stringify({inventory_subcategory_id :row.inventory_subcategory_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your subcategory has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getvariation(){
       axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_variation',{ "details" :{inventory_subcategory_oid:this.editproductmodal.item_subcategory}},this.auth1)
      .then(response => {
        console.log(response);
          if(response.data.details){
          this.variationData=response.data.details;
          }   
        })
      },
      overflow_change() {
        document.getElementsByClassName("overflow_visible")[0].classList.add('overflow-x-visible');
        //document.getElementById("updatebtn").classList.add('btn-success');
      },
      openeditproduct(row){
            this.editproduct=true;
            this.editproductmodal.item_id =row.item_id;
            this.editproductmodal.inventory_subcategory_name =row.inventory_subcategory_name;
            this.editproductmodal.item_name =row.item_name;
            this.editproductmodal.item_tagline =row.item_tagline;
            this.editproductmodal.item_short_description =row.item_short_description;
            this.editproductmodal.item_long_description =row.item_long_description;
            this.editproductmodal.item_type =row.item_type;
            this.editproductmodal.is_featured =row.is_featured;
            this.editproductmodal.sequenced_at =row.sequenced_at;
            this.editproductmodal.status =row.status;
            this.editproductmodal.visibility =row.visibility;
            this.editproductmodal.item_category =row.item_category;
            this.editproductmodal.item_subcategory =row.item_subcategory;
            this.editproductmodal.item_variation =row.item_variation;
            this.editproductmodal.item_sp =row.item_sp;
            this.editproductmodal.item_mp =row.item_mp;
            this.editproductmodal.item_discount =row.item_discount;
            this.editproductmodal.minimum_quantity =row.minimum_quantity;
            this.editproductmodal.item_theme_color =row.item_theme_color;
            this.editproductmodal.item_tags =row.item_tags;
            this.editproductmodal.item_main_img =row.item_main_img;
            this.editproductmodal.item_secondary_img =row.item_secondary_img;
            this.editproductmodal.item_thumbnail =row.item_thumbnail;
            this.editproductmodal.store =row.store;
            this.editproductmodal.number_available =row.number_available;
        },
        updateproduct() {
           const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_item',querystring.stringify({ item_id:this.editproductmodal.item_id,item_name : this.editproductmodal.item_name,item_tagline:this.editproductmodal.item_tagline,item_short_description:this.editproductmodal.item_short_description,item_long_description:this.editproductmodal.item_long_description,item_subcategory:this.editproductmodal.item_subcategory,item_variation:this.editproductmodal.item_variation,item_sp:this.editproductmodal.item_sp,item_mp:this.editproductmodal.item_mp,item_discount:this.editproductmodal.item_discount,item_main_img:this.editproductmodal.item_main_img,item_secondary_img:this.editproductmodal.item_secondary_img,item_thumbnail:this.editproductmodal.item_thumbnail,store:this.editproductmodal.store,status:this.editproductmodal.status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateproductbtn").classList.remove('btn-primary');
            document.getElementById("updateproductbtn").classList.add('btn-success');
            document.getElementById("updateproductbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateproductbtn").classList.remove('btn-success');
            document.getElementById("updateproductbtn").classList.add('btn-primary');
            document.getElementById("updateproductbtn").innerHTML="Update";
          }, 2000);

            //window.location.reload()

          })

        },
      deleteproduct(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_item',querystring.stringify({item_id :row.item_id,product_oid:row._id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your product has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      deleterating(rating_id,item_oid){
        console.log(rating_id);
        console.log(item_oid);
       swal({
          title:'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          confirmButtonText: 'Yes, delete it!',
          buttons: {cancel: true,confirm: true,}
        }).then((result) => {
          if (result) {
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'delete_rating_review',{rating_id:rating_id,item_oid:item_oid},this.auth1)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Review has been deleted.',
                icon:'success',
              })
              // this.ratingwindow=false;
              window.location.reload();
            })
            
          }
        })
      },
    },
  };
</script>
<style>
.item_long_description p {
  margin-bottom: 0px;
  font-size: .9rem;
}
</style>
