<template>
  <div>
    <div class="container-fluid mt-0">
      <tabs tabNavClasses="flex-column flex-sm-row nav-wrapper"
        tabContentClasses="">
        <tab-pane id="allorders">
          <span slot="title">
              All Classes
          </span>
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Class Names
                      </h3>
                    </div>
                    <div class="col text-right">
                      <base-input class="mb-0 search-input mr-2" input-group-classes="input-group-merge" placeholder="Search class" v-model="search_term"  @keyup.enter="searchclass()">
                          <template slot="append"><a href="#"><i class="fas fa-search" @click="searchclass()"></i></a></template>
                      </base-input>
                      <!-- <select class="form-control d-inline w-auto mr-2" v-model="status" @change="getorderbystatus()">
                        <option value="">Status</option>
                        <option value="pending">Pending</option>
                        <option value="confirm">Confirmed</option>
                        <option value="preparing">Preparing</option>
                        <option value="ready">Ready</option>
                        <option value="dispatched">Dispatched</option>
                        <option value="delivered">Completed</option>
                        <option value="cancelled">Cancelled</option>
                        <option value="returned">Returned</option>
                      </select> -->
                      <base-button type="primary" @click="cleardata()">Clear</base-button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive" style="display: block;">
                  <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="classData">
                    <template slot="columns">
                      <th>S. No.</th>
                      <th>Class Name</th>
                      <!-- <th>No. of students</th> -->
                      <th>Teacher</th>
                      <th class="text-right">Actions</th>
                    </template>
                    <template slot-scope="{row,index}">
                        <td class="budget">
                          <span class="" v-if="!pagination_load">{{ (offset*30)+(index+1) }}.</span>
                        </td>
                        <td class="budget" >
                          <span class="d-block" v-if="row.class_name">{{row.class_name}}</span>
                        </td>
                        <!-- <td class="budget">
                          <span class="pr-1" style="white-space: normal;">30</span>
                        </td> -->
                        <td class="budget px-2">
                          <span class="d-block" v-if="row.class_teacher">{{row.class_teacher.user_name}}</span>
                        </td>
                        <td class="text-right">
                          <base-button v-if="pagepermission.update" type="primary" size="sm" @click="opencategory(row)">Edit</base-button>
                          <a class="btn btn-primary btn-sm" v-if="pagepermission.read" :href="'/class/classdetails/'+row._id+'/'+row.class_name" icon="fa fa-info">View Details</a>
                          <base-button v-if="pagepermission.delete"  type="danger" size="sm" @click="deleteclass(row)" ><i class="fas fa-trash"></i></base-button>
                        </td>
                    </template>
                  </base-table>
                  <!-- <div class="d-block mb-5 mt-2">
                    <base-button
                      type="success"
                      class="float-center m-auto d-block"
                       v-if="loadmorefound" 
                       @click="loadmore()"
                    >
                      <span>Load More</span>
                    </base-button>
                  </div> -->
                  <!-- no result found -->
                  <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                    <div class="col-md-3 mx-auto mt-4 text-center">
                        <img src="/img/theme/no-result.png" class="img-fluid">
                        <p class="mt-4">No class Created.</p>
                        <base-button type="primary" @click="cleardata()" outline>Fetch all</base-button>
                    </div>
                  </div>
                  <!-- shimmer starts here -->
                  <div class="col-md-12" v-if="classData.length==0 && !datanotfound">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 mt-3">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <!-- shimmer ends here -->
                </div>
                <div class="card-footer d-flex justify-content-end" v-if="!datanotfound">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <!-- <li class="page-item d-flex mr-2">
                          <p class="mb-0 px-1 mt-1">
                            Page
                          </p>
                          <input
                            id="page_no"
                            size="4"
                            class="mx-1 p-1 text-center"
                            style="border: 1px solid #e9ecff;border-radius: 5px !important;background-color: #e9ecff;width: 4em;"
                            type="number"
                            name="page_no"
                          >
                          <base-button
                            block
                            type="primary"
                            class="px-3 mx-1"
                            title="GO"
                            size="sm"
                            @click="gotopage()"
                          >
                            GO
                          </base-button>
                        </li> -->
                        <li class="page-item">
                          <a
                            class="page-link"
                            :disable="pagination_load"
                            @click="previouspage()"
                          > <span aria-hidden="true"><i
                            aria-hidden="true"
                            class="fa fa-angle-left"
                          /></span> </a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            :disable="pagination_load"
                            @click="nextpage()"
                          > <span aria-hidden="true"><i
                            aria-hidden="true"
                            class="fa fa-angle-right"
                          /></span> </a>
                        </li>
                      </ul>
                    </nav> 
                  </div>
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane id="exportdata">
          <span slot="title">
              Create New Class
          </span>
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''">
                  <div class="row align-items-center">
                    <div class="col-4 text-center">
                      <base-input placeholder="Class Name" v-model="model.class_name" input-classes="mb-0" class="mb-0 form-group-0" />
                      <div
                          v-if="$v.model.class_name.$error"
                          class="error h5 text-danger mt-1 pl-1"
                        >
                          Field is required.
                      </div>
                    </div>
                    <div class="col-md-6 text-left">
                      <select class="form-control d-inline w-auto mr-3" v-model="model.teacher_oid">
                        <option value="">Class Teacher</option>
                        <option v-for="teachers in teacherData" :key="teachers._id" :value="teachers._id">{{teachers.user_name}}</option>
                      </select>
                      <div
                          v-if="$v.model.teacher_oid.$error"
                          class="error h5 text-danger mt-1 pl-1"
                        >
                          Field is required.
                      </div>
                      <base-button id="createclass" type="primary" :disabled="$v.model.$error" @click="$v.model.$touch();createclass()">Create Class</base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-pane>
      </tabs>
    </div>
    <modal :show.sync="editcategory" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Class Details</span>
      </p>  
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
                <base-input label="Class Name" placeholder="Class Name" input-classes="" v-model="classmodal.class_name" />
                  <div
                      v-if="$v.classmodal.class_name.$error"
                      class="error h5 text-danger mt-1 pl-1"
                    >
                      Field is required.
                  </div>
            </div>
            <div class="col-lg-6">
                 <label class="form-control-label">Class Teacher <span class="text-danger">*</span></label>
                  <select  aria-describedby="addon-right addon-left" class="form-control" v-model="classmodal.class_teacher" autocomplete="off">
                      <option v-for="teachers in teacherData" :key="teachers._id" :value="teachers._id">{{teachers.user_name}}</option>
                 </select>
                 <div
                      v-if="$v.classmodal.class_teacher.$error"
                      class="error h5 text-danger mt-1 pl-1"
                    >
                      Field is required.
                  </div>
             </div>
          </div>
        </form>
      </template>
      <template slot="footer">
        <base-button
          :disabled="$v.classmodal.$error" @click="$v.classmodal.$touch();updateclass()"
          type="success"
          class="float-center"
          id="updateclassbtn"
        >
          <span>Update Order</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editcategory = false">
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import moment from 'moment';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';
  import { required } from 'vuelidate/lib/validators';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
      flatPicker,
      Tabs,
      DropzoneFileUpload,
    },
    data() {
      return {
        inputs: {
          file: []
        },
        status:'',
        classData:[],
        store_name:'',
        pagepermission:{},
        datanotfound:false,
        pagination_load:false,
        order_status:'',
        variationData:[],
        search_term:'',
        json_data: [],
        subcategoryproductData:[],
        storeData:[],
        role:localStorage.getItem('role'),
        galleryarr:[],
        teacherData:[],
        banneruploadPercentage:0,
        offset:0,
        model:{
          class_id :'',
          class_name :'',
          teacher_oid:'',
          class_image :'',
        },
        classmodal:{
          class_id :'',
          class_name :'',
          class_teacher:'',
          class_image :'',
        },
        loadmorefound:1,
        editcategory:false,
        ordermodal:{ order_no:'',order_status:''},
        editproduct:false,
        uploadPercentage:0,
        dates: {
          simple: new Date(),
          // range: '2019-04-19 to 2019-04-24'
        },
        item_price:[{item_id : '',item_name : '',unit : '',quantity : 0,availability_now : 0,marked_price : 0,price : 0,is_featured : false,sequenced_at : 0,status: "approved",visibility : "visible"}],
        
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    validations: {
      model: {
        class_name:{
          required
        },
        teacher_oid:{
          required
        },
        
      },
      classmodal: {
        class_name:{
          required
        },
        class_teacher:{
          required
        },
      },

    },
    mounted () {
      console.log(this.$router.currentRoute.path);
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      //"user_type":"teacher"
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_admin',{ "details" :{"user_type":'teacher'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.teacherData=response.data.details;
        }
            
      })
      axios
        .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
        .then(response => {
          console.log(response);
          if(response.data.details){
          this.classData=response.data.details;
          this.datanotfound=false;
          }else{
              this.classData=[];
              this.datanotfound=true;
          }
              
        })
        
      // this.json_data.push({'Name': this.upcommingpayment[x].user_id[0].user_name,'Batch':this.upcommingpayment[x].batch_id.batch_name,'Amount':this.upcommingpayment[x].amount,'Payable Amount':this.upcommingpayment[x].payable_amount,'Fee date':this.format_date(this.upcommingpayment[x].fee_date),'Paid on date':this.format_date(this.upcommingpayment[x].paid_on_date),'Status':this.upcommingpayment[x].fee_status});


    },
    methods: {
          getalldata(){
            axios
              .post(process.env.VUE_APP_API_ENDPOINT+'read_admin',{ "details" :{"user_type":'teacher'}},this.auth1)
              .then(response => {
                console.log(response);
                if(response.data.details){
                this.teacherData=response.data.details;
                }
                    
              })
              axios
                .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
                .then(response => {
                  console.log(response);
                  if(response.data.details){
                  this.classData=response.data.details;
                  this.datanotfound=false;
                  }else{
                      this.classData=[];
                      this.datanotfound=true;
                  }
                      
                })

          },
          createclass(){
            if(this.$v.model.$error===false){
            axios.post(process.env.VUE_APP_API_ENDPOINT+'create_class',{class_name : this.model.class_name,teacher_oid : this.model.teacher_oid,class_image : this.model.class_image},this.auth1)
                .then(response => {
                  console.log(response);
                  this.message=response.data.message;
                  document.getElementById("createclass").classList.remove('btn-primary');
                  document.getElementById("createclass").classList.add('btn-success');
                  document.getElementById("createclass").innerHTML='Updated';
                  setTimeout(function(){
                  document.getElementById("createclass").classList.remove('btn-success');
                  document.getElementById("createclass").classList.add('btn-primary');
                  document.getElementById("createclass").innerHTML="Update";
                  window.location.reload()
                }, 2000);

                })
              }

          },
          updateclass(){
            if(this.$v.classmodal.$error===false){

            axios.post(process.env.VUE_APP_API_ENDPOINT+'update_class',{class_id : this.classmodal.class_id,class_name : this.classmodal.class_name,class_teacher : this.classmodal.class_teacher,class_image : this.classmodal.class_image},this.auth1)
                .then(response => {
                  console.log(response);
                  this.message=response.data.message;
                  document.getElementById("updateclassbtn").classList.remove('btn-primary');
                  document.getElementById("updateclassbtn").classList.add('btn-success');
                  document.getElementById("updateclassbtn").innerHTML='Updated';
                  setTimeout(function(){
                  document.getElementById("updateclassbtn").classList.remove('btn-success');
                  document.getElementById("updateclassbtn").classList.add('btn-primary');
                  document.getElementById("updateclassbtn").innerHTML="Update";
                  window.location.reload()
                }, 2000);

                })

              }

          },
          getstore_name(val){
              const search = this.storeData.find(element => element.store_id === val);
              return search.store_name;
          },
          hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                if(type=='thumbnail'){
                  this.productmodal.item_thumbnail=response.data.imageUrl;
                  console.log(this.productmodal.item_thumbnail);
                }else if(type=='main_img'){
                  this.productmodal.item_main_img=response.data.imageUrl;
                  console.log(this.productmodal.item_main_img);
                }else if(type=='secondary_img'){
                  this.productmodal.item_secondary_img=response.data.imageUrl;
                  console.log(this.productmodal.item_secondary_img);
                }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

        },
        async bannerupload(event,type,uploatype) {
              // Reference to the DOM input element
                //console.log(event);
                // document.getElementById("editassignmentbtn").classList.add('d-none');
                // document.getElementById("editaddassignmentinvalid").classList.remove('d-none');
               this.banneruploadPercentage =0;
                if(uploatype=='file1'){
                  for( var i = 0; i < this.$refs.file1.files.length; i++ ){
                      let file = this.$refs.file1.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }
                if(uploatype=='file3'){
                  for( var i = 0; i < this.$refs.file3.files.length; i++ ){
                      let file = this.$refs.file3.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }

        },
        upload(file,type){
          //console.log(file);
          let fd = new FormData();
          fd.append('image',file,file.name);
          axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.banneruploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.banneruploadPercentage)}.bind(this)}).then(response => {
            //console.log(response);
            this.galleryarr.push(response.data.imageUrl);
            //console.log(this.imagearr);
            // if(type=='add'){
            //   this.categorymodal.banner_primary=this.imagearr;
            //   //console.log(this.assignmentmodel.assignment_file);
            // }
            // else{
            //   this.categorymodal.banner_secondary=this.imagearr;
            // }
            //console.log();
         })

        },
      opencategory(row){
          this.editcategory=true;
          // this.classmodal=row;
          this.classmodal.class_id=row.class_id;
          this.classmodal.class_name=row.class_name;
          this.classmodal.class_image=row.class_image
          if(row.class_teacher){
            this.classmodal.class_teacher=row.class_teacher._id;
          }
          
          //this.order_status=row.order_status;

        },
    searchclass(){
      let details={ "search_term" :this.search_term};
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'search_class',details,this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.classData=response.data.details;
        this.datanotfound=false;
        }else{
          this.classData=[];
          this.datanotfound=true;
        }
            
       })
      },
      cleardata(){
        this.search_term='';
        this.status='';
        this.offset=0;
        axios
        .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
        .then(response => {
          console.log(response);
          if(response.data.details){
          this.classData=response.data.details;
          this.datanotfound=false;
          }else{
              this.classData=[];
              this.datanotfound=true;
          }
              
        })
          
      },
      deleteclass(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_class',querystring.stringify({class_id :row.class_id}),this.auth)
            .then(response => {
                let _this=this;
                console.log(response);
                swal({
                  title:'Deleted!',
                  text: 'Your order has been deleted.',
                  icon:'success',
                 }).then((result) => {
                  console.log(result)
                  _this.getalldata();
                })
              })
             }
          });
      },
      format_datetime(value){
        if(value) {
         return moment(new Date(value)).format("LLL")
        }
      },
      format_datetime_utc(value){
        if(value) {
         return moment.utc(new Date(value)).format("LLL")
        }
      },
      format_date_inddmmyy(value){
        if (value) {
         return moment(String(value)).format("DD-MM-YYYY")
        }
      },
      previouspage(){
      const querystring = require('querystring');
      //console.log(this.auth);
      if(this.offset>0){
        this.pagination_load=true;
      --this.offset;

      let details={ "details" :{"type":"order"},"offset":this.offset.toString()};
        if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
              details.details.store_id=localStorage.getItem('store_id');
        }

     axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',details,this.auth1)
      .then(response => {
        //console.log(response);
        if(response.data.details){
        this.datanotfound=false;
        this.classData=response.data.details;
        this.pagination_load=false;
        }else{
          this.classData=[];
          this.datanotfound=true;
          this.pagination_load=false;

        }
            
      })
    }
    },
    gotopage(){
      const querystring = require('querystring');
      //console.log(this.auth);
      if(document.getElementById("page_no").value){
      this.offset=document.getElementById("page_no").value;
      let details={ "details" :{"type":"order"},"offset":this.offset.toString()};
        if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
              details.details.store_id=localStorage.getItem('store_id');
        }
     axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',details,this.auth1)
      .then(response => {
        //console.log(response);
        if(response.data.details){
        this.classData=response.data.details;
        this.datanotfound=false;
        }else{
          this.classData=[];
          this.datanotfound=true;
        }
            
      })

      }

    },
    nextpage(){
        this.pagination_load=true;
        this.offset=this.offset+1;
        const querystring = require('querystring');
        //console.log(this.auth);
        let details={ "details" :{"type":"order"},"offset":this.offset.toString()};
        if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
              details.details.store_id=localStorage.getItem('store_id');
        }
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_class',details,this.auth1)
          .then(response => {
            //console.log(response);
            if(response.data.details){
            this.classData=response.data.details;
            this.datanotfound=false;
            this.pagination_load=false;

            }else{
              this.offset=this.offset-1;
              this.classData=[];
              this.datanotfound=true;
              this.pagination_load=false;

            }
                
          })

    },

    },
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
  .datepicker-input .form-group {
    margin-bottom: 0px;
  }
</style>
