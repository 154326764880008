<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-bottom" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Recent News
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button v-if="pagepermission.create" type="primary" @click="addnewspopup"><i class="fas fa-plus"></i> Add new news</base-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4" v-for="(post,index) in postData" :key="post._id">
                  <!-- Basic with list group -->
                  <div class="card border shadow-sm">
                    <!-- Card image -->
                    <img alt="Image placeholder" class="card-img-top" v-if="post.blog_img" :src="post.blog_img" width="100">
                    <!-- List group -->
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item small">{{format_datetime(post.created_at)}}</li>
                      <li class="list-group-item small">{{post.blog_title}}</li>
                    </ul>
                    <!-- Card body -->
                    <div class="card-body p-3">
                      <!-- <p class="card-text mb-4" v-html="post.blog_body"></p> -->
                      <div class="">
                        <div class="float-left">
                          <!-- <router-link to="#" class="btn btn-primary btn-sm">View</router-link> -->
                        </div>
                        <div class="float-right">
                          <base-button v-if="pagepermission.update" type="primary" size="sm" @click="editnewspopup(post)" >Edit</base-button>
                          <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deletenews(post)">Delete</base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- shimmer starts here -->
                <!-- <div class="col-md-12" v-if="metaData.length==0 && !datanotfound">
                  <div class="row justify-content-center">
                    <div class="col-lg-12 mt-3">
                        <div class="card border-0 shadow">
                           <div class="card-body pt-2 pb-1">
                                <div class="row">
                                    <div class="col-md-3 col-3 mt-1 mb-1">
                                        <span class="circle-shimmer shine"></span>
                                    </div>
                                    <div class="col-md-9 col-9 mt-1 mb-1">
                                        <div class="div-shimmer w-100 mt-0 pr-4">
                                          <span class="line-shimmer w-100 shine"></span>
                                          <span class="line-shimmer w-100 shine"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card border-0 shadow">
                           <div class="card-body pt-2 pb-1">
                                <div class="row">
                                    <div class="col-md-3 col-3 mt-1 mb-1">
                                        <span class="circle-shimmer shine"></span>
                                    </div>
                                    <div class="col-md-9 col-9 mt-1 mb-1">
                                        <div class="div-shimmer w-100 mt-0 pr-4">
                                          <span class="line-shimmer w-100 shine"></span>
                                          <span class="line-shimmer w-100 shine"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card border-0 shadow">
                           <div class="card-body pt-2 pb-1">
                                <div class="row">
                                    <div class="col-md-3 col-3 mt-1 mb-1">
                                        <span class="circle-shimmer shine"></span>
                                    </div>
                                    <div class="col-md-9 col-9 mt-1 mb-1">
                                        <div class="div-shimmer w-100 mt-0 pr-4">
                                          <span class="line-shimmer w-100 shine"></span>
                                          <span class="line-shimmer w-100 shine"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card border-0 shadow">
                           <div class="card-body pt-2 pb-1">
                                <div class="row">
                                    <div class="col-md-3 col-3 mt-1 mb-1">
                                        <span class="circle-shimmer shine"></span>
                                    </div>
                                    <div class="col-md-9 col-9 mt-1 mb-1">
                                        <div class="div-shimmer w-100 mt-0 pr-4">
                                          <span class="line-shimmer w-100 shine"></span>
                                          <span class="line-shimmer w-100 shine"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div> -->
                <!-- shimmer ends here -->
                <!-- no result found -->
                <!-- <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                  <div class="col-md-3 mx-auto mt-4 text-center">
                      <img src="img/theme/no-result.png" class="img-fluid">
                      <p class="mt-4">No meta deta found.</p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- add news popup -->
    <modal :show.sync="addnews" modalClasses="modal-lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Add News</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12 mb-2">
              <label class="form-control-label">News Title<span class="text-danger">*</span></label>
              <base-input
                v-model="model.blog_title"
                alternative=""
                input-classes="form-control-alternative"
              /> 
                <div
                    v-if="$v.model.blog_title.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            <div class="col-lg-12 mb-2">
              <label class="form-control-label">News<span class="text-danger">*</span></label>
              <vue-editor :editorToolbar="customToolbar" useCustomImageHandler @image-added="handleImageAdded" v-model="model.blog_body"/>
            </div>
            <div class="col-lg-12 mt-2">
                <label class="form-control-label">Upload Picture<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control"
                  name="img"
                  @change="hadleimage($event,'addnews')"
                >
                <div
                    v-if="$v.model.blog_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded" class="mt-2"></base-progress>
                <div>
                  <img :src="model.blog_img" class="img-fluid rounded shadow mt-2">
                </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="saveblog()"
          type="success"
          id="createnewsbtn"
          class="float-center"
        >
          Save blog
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addnews = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit news popup -->
    <modal :show.sync="editbanner" modalClasses="modal-lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit News</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--4">
            <div class="col-lg-12">
              <label class="form-control-label">News Title<span class="text-danger">*</span></label>
              <base-input placeholder="News Title" v-model="editbannermodal.blog_title" input-classes="" class="form-group-mb0" />
                <div
                    v-if="$v.editbannermodal.blog_title.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            <div class="col-lg-12 mb-2">
              <label class="form-control-label">News<span class="text-danger">*</span></label>
              <vue-editor :editorToolbar="customToolbar" useCustomImageHandler @image-added="handleImageAdded" v-model="editbannermodal.blog_body"/>
            </div>
            <div class="col-lg-12 mt-2">
                <label class="form-control-label">Upload Picture<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editbanner')"
                >
                <div
                    v-if="$v.editbannermodal.blog_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="editbannermodal.blog_img" class="img-fluid rounded shadow mt-2">
                </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editbannermodal.$error" @click="$v.editbannermodal.$touch();updateblog()"
          type="success"
          id="updatenewsbtn"
          class="float-center"
        >
          <span>Save</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editbanner = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';

  // import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  // import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';
  // import { ModelListSelect } from 'vue-search-select'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import 'vue-search-select/dist/VueSearchSelect.css'
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import { VueEditor } from "vue2-editor";
  import moment from 'moment';
  

  export default {
    components: {
      // ActivityFeed,
      // Tabs,
      // LineChart,
      // BarChart,
      BaseProgress,
      // StatsCard,
      VueEditor,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
// cayegory
// subcategory
// variation
// item
// offer
      // DropzoneFileUpload,
      // ModelListSelect,
    },
    data() {
      return {
        addnews: false,
        editbanner: false,
        addregion: false,
        editregion: false,
        datanotfound:false,
        uploadPercentage:0,
        selectstore:'',
        pagepermission:{},
        heroData:[],
        offerData:[],
        aboutData:[],
        logoData:[],
        highlightData:[],
        postData:[],
        storeData:[],
        allcategoryData:[],
        subcategoryData:[],
        productData:[],
        model:{ 
          blog_title:'',
          blog_body:'',
          blog_img:'',
          status:'approved',
          created_by:'Admin'
        },
        editbannermodal:{
          blog_id:'',
          blog_title:'',
          blog_body:'',
          blog_img:'',
          status:'approved',
          created_by:'Admin'
        },
        customToolbar: 
          [[{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link", "image", "video"],
          ["clean"]],
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      model: {
        blog_title:{
          required
        },
        blog_body:{
          required
        },
        blog_img:{
          required
        },
      },
      editbannermodal: {
        blog_title:{
          required
        },
        blog_body:{
          required
        },
        blog_img:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_blog_all',{"details" :{},"offset" :"0"})
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.postData=response.data.details;
          this.datanotfound=false;
        }else{
          this.postData=[];
        } this.datanotfound=true;
            
      })
    },
    methods: {
      addnewspopup(row){
        console.log(row);
        this.addnews=true;
      },
      editnewspopup(row){
        console.log(row);
        this.editbannermodal=row;
        this.editbanner=true;
      },
      handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      this.uploadPercentage =0;
      if(file.size < 5000000){

      var formData = new FormData();
      formData.append("image", file);

      axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',formData,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
        .then(response => {
              console.log(response);
              let url = response.data.imageUrl; // Get url from response
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
            }).catch(err => {
          console.log(err);
           });
        }else{

         swal({
              title:'File to large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }


        
    },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addnews'){
                this.model.blog_img=response.data.imageUrl;
                console.log(this.model.blog_img);
              }if(type=='editbanner'){
                this.editbannermodal.blog_img=response.data.imageUrl;
                console.log(this.editbannermodal.blog_img);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }
      },
      deletenews(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_blog',querystring.stringify({blog_id :row.blog_id,created_by:row.created_by}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your file has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
        },
        getstore_name(val){
          const search = this.storeData.find(element => element.store_id === val);
          if(search){
            return search.store_name;
          }
        },
      saveblog() {
        const querystring = require('querystring');
         console.log(this.model);
         axios.post(process.env.VUE_APP_API_ENDPOINT+'create_blog',{blog_title:this.model.blog_title,blog_body:this.model.blog_body,blog_img:this.model.blog_img,status:this.model.status,created_by:this.model.created_by},this.auth1)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("createnewsbtn").classList.remove('btn-primary');
          document.getElementById("createnewsbtn").classList.add('btn-success');
          document.getElementById("createnewsbtn").innerHTML='Blog Saved';
          setTimeout(function(){
            document.getElementById("createnewsbtn").classList.remove('btn-success');
            document.getElementById("createnewsbtn").classList.add('btn-primary');
            document.getElementById("createnewsbtn").innerHTML="Save";
            window.location.reload()
          }, 2000);
        })
      },
      updateblog() {
        const querystring = require('querystring');
         console.log(this.model);
         axios.post(process.env.VUE_APP_API_ENDPOINT+'update_blog',{blog_id:this.editbannermodal.blog_id,blog_title:this.editbannermodal.blog_title,blog_body:this.editbannermodal.blog_body,blog_img:this.editbannermodal.blog_img,status:this.editbannermodal.status,created_by:this.editbannermodal.created_by},this.auth1)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("updatenewsbtn").classList.remove('btn-primary');
          document.getElementById("updatenewsbtn").classList.add('btn-success');
          document.getElementById("updatenewsbtn").innerHTML='Blog Saved';
          setTimeout(function(){
            document.getElementById("updatenewsbtn").classList.remove('btn-success');
            document.getElementById("updatenewsbtn").classList.add('btn-primary');
            document.getElementById("updatenewsbtn").innerHTML="Save";
            window.location.reload()
          }, 2000);
        })
      },
      format_datetime(value){
        if(value) {
          return moment.utc(new Date(value)).format("Do MMM YYYY")
        }
      },
    },
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
