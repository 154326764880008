<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Trips
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectstore" @change="getfilterdata()">
                    <option value="">Select Store</option>
                    <option v-for="store in storeData" :key="store.store_id" :value="store.store_id">{{store.store_name}}</option>
                  </select> -->
                  <base-button v-if="pagepermission.create" type="primary" @click="addbannerpopup"><i class="fas fa-plus"></i> Create Year</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="heroData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Year</th>
                  <th>No. of Trips</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">
                        <span v-if="row.banner_type=='Fixed_Banner_Top_Primary'">2014</span>
                        <span v-if="row.banner_type=='Carousel_Banner_Top_Secondary'">Highlights</span>
                        <span v-if="row.banner_type=='Carousel_Banner_Bottom_Secondary'">Offers</span>
                        <span v-if="row.banner_type=='Fixed_Banner_Bottom_Primary'">About Us Slide</span>
                        <span v-if="row.banner_type=='Carousel_Banner_Bottom_Secondary_Large_Category'">Logo Slider</span>
                      </p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">5</p>
                    </td>
                    <td class="text-right">
                      <base-button v-if="pagepermission.update" type="primary" size="sm" @click="editbannerpopup(row)" >Edit</base-button>
                      <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deletebanner(row)">Delete</base-button>
                      <router-link class="btn btn-primary btn-sm" to="/seo/meta" icon="fa fa-info">View</router-link>
                    </td>
                </template>
              </base-table>

              <!-- no data found here -->
              <div class="card-footer justify-content-center" v-if="!datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No banner created.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="heroData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- add baner -->
    <modal :show.sync="addbanner">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create Year</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">Year<span class="text-danger">*</span></label>
              <base-input placeholder="e.g, 2014" v-model="bannermodal.banner_name" input-classes="" class="form-group-mb0" />
                <div
                    v-if="$v.bannermodal.banner_name.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            <!-- <div class="col-lg-12 mt-2">
                <label class="form-control-label">Upload Picture<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'addbanner')"
                >
                <div
                    v-if="$v.bannermodal.static_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="bannermodal.static_img" class="img-fluid rounded shadow mt-2">
                </div>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
         :disabled="$v.bannermodal.$error" @click="$v.bannermodal.$touch();createbanner()"
          type="success"
          id="createstorebtn"
          class="float-center"
        >
          <span>Add Year</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addbanner = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit banner popup -->
    <modal :show.sync="editbanner">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Year</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--4">
            <div class="col-lg-12">
              <label class="form-control-label">Year Name<span class="text-danger">*</span></label>
              <base-input placeholder="Year Name" v-model="editbannermodal.banner_name" input-classes="" class="form-group-mb0" />
                <div
                    v-if="$v.editbannermodal.banner_name.$error"
                    class="error h5 text-danger mt--4 pl-1"
                  >
                    Field is required.
                </div>
            </div>
            
            <!-- <div class="col-lg-12 mt-2">
                <label class="form-control-label">Upload Picture<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editbanner')"
                >
                <div
                    v-if="$v.editbannermodal.static_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="editbannermodal.static_img" class="img-fluid rounded shadow mt-2">
                </div>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editbannermodal.$error" @click="$v.editbannermodal.$touch();updatebanner()"
          type="success"
          id="updateestorebtn"
          class="float-center"
        >
          <span>Update Year</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editbanner = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { ModelListSelect } from 'vue-search-select'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import 'vue-search-select/dist/VueSearchSelect.css'
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
// cayegory
// subcategory
// variation
// item
// offer
      DropzoneFileUpload,
      ModelListSelect,
    },
    data() {
      return {
        addbanner: false,
        editbanner: false,
        addregion: false,
        editregion: false,
        datanotfound:false,
        uploadPercentage:0,
        selectstore:'',
        pagepermission:{},
        heroData:[],
        offerData:[],
        aboutData:[],
        logoData:[],
        highlightData:[],
        bannerData:[],
        storeData:[],
        allcategoryData:[],
        subcategoryData:[],
        productData:[],
        bannermodal:{
          banner_id:'',
          banner_name:'',
          banner_type:'',
          placement_type:'',
          placement_oid:'',
          static_img:'',
          todo:'',
          target:'',
          store:'',
          is_featured:false,
          sequenced_at:0,
          status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        editbannermodal:{
          banner_id:'',
          banner_name:'',
          banner_type:'',
          placement_type:'',
          placement_oid:'',
          static_img:'',
          todo:'',
          target:'',
          store:'',
          is_featured:false,
          sequenced_at:0,
          status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      bannermodal: {
        banner_name:{
          required
        },
        banner_type:{
          required
        },
        static_img:{
          required
        },
        todo:{
          required
        },
        target:{
          required
        },
        
      },
      editbannermodal: {
        banner_name:{
          required
        },
        banner_type:{
          required
        },
        static_img:{
          required
        },
        todo:{
          required
        },
        target:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.bannerData=response.data.details;
        this.datanotfound=false;
        }else{
          this.bannerData=[];
        } this.datanotfound=true;
            
      })

      // banners data api calls starts here 
      // Call for Fixed_Banner_Top_Primary
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{'banner_type':'Fixed_Banner_Top_Primary'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.heroData=response.data.details;
        this.datanotfound=false;
        }else{
          this.heroData=[];
        } this.datanotfound=true;
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{'banner_type':'Carousel_Banner_Top_Secondary'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.highlightData=response.data.details;
        this.datanotfound=false;
        }else{
          this.highlightData=[];
        } this.datanotfound=true;
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{'banner_type':'Carousel_Banner_Bottom_Secondary'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.offerData=response.data.details;
        this.datanotfound=false;
        }else{
          this.offerData=[];
        } this.datanotfound=true;
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{'banner_type':'Fixed_Banner_Bottom_Primary'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.aboutData=response.data.details;
        this.datanotfound=false;
        }else{
          this.aboutData=[];
        } this.datanotfound=true;
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',{ "details" :{'banner_type':'Carousel_Banner_Bottom_Secondary_Large_Category'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.logoData=response.data.details;
        this.datanotfound=false;
        }else{
          this.logoData=[];
        } this.datanotfound=true;
            
      })

      // banners data api calls end here 
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        this.bannermodal.store=this.storeData[0].store_id;

        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.allcategoryData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_item',{ "details" :{},"offset" : "0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.productData=response.data.details;
        }else{
            this.productData=[];
        }
        
            
      })
    },
    methods: {

      addbannerpopup(row){
        console.log(row);
        this.addbanner=true;
      },
      editbannerpopup(row){
        console.log(row);
        this.editbannermodal=row;
        this.editbanner=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addbanner'){
                this.bannermodal.static_img=response.data.imageUrl;
                console.log(this.bannermodal.static_img);
              }if(type=='editbanner'){
                this.editbannermodal.static_img=response.data.imageUrl;
                console.log(this.bannermodal.static_img);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    getchange_bannerdiv(type){

            if(this.bannermodal.todo=='openCategory' && type=='add'){
                 document.getElementById("addcategory").classList.remove('d-none');
                 document.getElementById("addsubcategory").classList.add('d-none');
                 document.getElementById("addproduct").classList.add('d-none');
                 document.getElementById("addexternalLink").classList.add('d-none');

              }
              if(this.bannermodal.todo=='openProductFeed' && type=='add'){
                 document.getElementById("addcategory").classList.add('d-none');
                 document.getElementById("addsubcategory").classList.remove('d-none');
                 document.getElementById("addproduct").classList.add('d-none');
                 document.getElementById("addexternalLink").classList.add('d-none');


              }
              if(this.bannermodal.todo=='openProduct' && type=='add'){
                 document.getElementById("addcategory").classList.add('d-none');
                 document.getElementById("addsubcategory").classList.add('d-none');
                 document.getElementById("addproduct").classList.remove('d-none');
                 document.getElementById("addexternalLink").classList.add('d-none');


              }
              if(this.bannermodal.todo=='externalLink' && type=='add'){
                 document.getElementById("addcategory").classList.add('d-none');
                 document.getElementById("addsubcategory").classList.add('d-none');
                 document.getElementById("addproduct").classList.add('d-none');
                 document.getElementById("addexternalLink").classList.remove('d-none');

              }

              if(this.editbannermodal.todo=='openCategory' && type=='edit'){
                 document.getElementById("editcategory").classList.remove('d-none');
                 document.getElementById("editsubcategory").classList.add('d-none');
                 document.getElementById("editproduct").classList.add('d-none');
              }
              if(this.editbannermodal.todo=='openProductFeed' && type=='edit'){
                 document.getElementById("editcategory").classList.add('d-none');
                 document.getElementById("editsubcategory").classList.remove('d-none');
                 document.getElementById("editproduct").classList.add('d-none');

              }
              if(this.editbannermodal.todo=='openProduct' && type=='edit'){
                 document.getElementById("editcategory").classList.add('d-none');
                 document.getElementById("editsubcategory").classList.add('d-none');
                 document.getElementById("editproduct").classList.remove('d-none');

              }
              if(this.editbannermodal.todo=='externalLink' && type=='edit'){
                 document.getElementById("editcategory").classList.add('d-none');
                 document.getElementById("editsubcategory").classList.add('d-none');
                 document.getElementById("editproduct").classList.add('d-none');
                 document.getElementById("editexternalLink").classList.remove('d-none');

              }

    },
    createbanner(){
        if(this.$v.bannermodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_banner',querystring.stringify({banner_name : this.bannermodal.banner_name,banner_type:this.bannermodal.banner_type,placement_type:this.bannermodal.placement_type,placement_oid:this.bannermodal.placement_oid,static_img:this.bannermodal.static_img,todo:this.bannermodal.todo,target:this.bannermodal.target,store:this.bannermodal.store,is_featured:this.bannermodal.is_featured,sequenced_at:this.bannermodal.sequenced_at,status:this.bannermodal.status,created_by:this.bannermodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
        
      },
      updatebanner(){
        if(this.$v.bannermodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_banner',querystring.stringify({banner_name : this.editbannermodal.banner_name,banner_id : this.editbannermodal.banner_id,banner_type:this.editbannermodal.banner_type,placement_type:this.editbannermodal.placement_type,placement_oid:this.editbannermodal.placement_oid,static_img:this.editbannermodal.static_img,todo:this.editbannermodal.todo,target:this.editbannermodal.target,store:this.editbannermodal.store,is_featured:this.editbannermodal.is_featured,sequenced_at:this.editbannermodal.sequenced_at,status:this.editbannermodal.status,created_by:this.editbannermodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })

        }
        
      },
      deletebanner(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_banner',querystring.stringify({banner_id :row.banner_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getstore_name(val){
        const search = this.storeData.find(element => element.store_id === val);
        if(search){
          return search.store_name;
        }
      },
      getfilterdata(){
          localStorage.setItem('selectstore',this.selectstore);
          let details={ "details" :{'banner_type':'Fixed_Banner_Top_Primary'},"offset" : "0"};
          if(this.selectstore){
              details.details.store=this.selectstore;

            }

          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.heroData=response.data.details;
            this.datanotfound=false;
            }else{
              this.heroData=[];
            } this.datanotfound=true;
                
          })

          details.details.banner_type='Carousel_Banner_Top_Secondary';

          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.highlightData=response.data.details;
            this.datanotfound=false;
            }else{
              this.highlightData=[];
            } this.datanotfound=true;
                
          })

          details.details.banner_type='Carousel_Banner_Bottom_Secondary';

          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.offerData=response.data.details;
            this.datanotfound=false;
            }else{
              this.offerData=[];
            } this.datanotfound=true;
                
          })

          details.details.banner_type='Fixed_Banner_Bottom_Primary';

          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.aboutData=response.data.details;
            this.datanotfound=false;
            }else{
              this.aboutData=[];
            } this.datanotfound=true;
                
          })
          details.details.banner_type='Carousel_Banner_Bottom_Secondary_Large_Category';

          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_banner',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.logoData=response.data.details;
            this.datanotfound=false;
            }else{
              this.logoData=[];
            } this.datanotfound=true;
                
          })
         
        },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
