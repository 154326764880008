<template>
  <div>
    <div class="container-fluid my-4">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-2" :class="''">
            <div class="card-header border-bottom" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Product Categories
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button v-if="pagepermission.create" type="primary" @click="createcategorymodal"><i class="fas fa-plus"></i> Create Category</base-button>
                </div>
              </div>
            </div>
            <div class="card-body p-0">
              <div class="row p-2 mx-0 bg-table-header border-bottom">
                <div class="col-md-1">
                  <span>S. No.</span>
                </div>
                <div class="col-md-4">
                  <span>Category</span>
                </div>
                <div class="col-md-2">
                  <span>Thumnbail</span>
                </div>
                <!-- <div class="col-md-2">
                  <span>Banner Image</span>
                </div> -->
                <div class="col-md-2">
                  <span>Status</span>
                </div>
                <div class="col-md-3 text-right">
                  <span>Action</span>
                </div>
              </div>
              <draggable class="draggable-items" v-model="categoryData" group="people" @start="drag=true" @end="drag=false">
                <div v-for="category, index in categoryData" :key="category.id" class="row border-bottom p-2 mx-0 cursor-drag">
                  <div class="col-md-1 align-self-center">
                    <span>{{index+1}}</span>
                  </div>
                  <div class="col-md-4 align-self-center">
                    <span>{{category.inventory_category_name}}</span>
                  </div>
                  <!-- <div class="col-md-1 align-self-center">
                    <img alt="Image placeholder" class="rounded avatar bg-white" v-if="category.inventory_category_icon" :src="category.inventory_category_icon">
                  </div> -->
                  <div class="col-md-2 align-self-center">
                    <img alt="Image placeholder" class="rounded" v-if="category.inventory_category_img" :src="category.inventory_category_img" width="100">
                  </div>
                  <div class="col-md-2 align-self-center">
                    <p class="mb-0">{{category.status}}</p>
                  </div>
                  <div class="col-md-3 text-right align-self-center">
                    <a class="btn btn-primary btn-sm" :href="'/products/subcategories/'+category._id+'/'+category.inventory_category_name" icon="fa fa-info">View Sub-categories</a>
                      <base-dropdown menuOnRight>
                        <base-button slot="title-container" size="sm" type="white" class="mr-0 px-0 shadow-none">
                          <i class="fas fa-ellipsis-v"></i>
                        </base-button>
                            <a v-if="pagepermission.update" class="dropdown-item" @click="openeditcategory(category)">Edit</a>
                            <div class="dropdown-divider"></div>
                            <a v-if="pagepermission.delete" class="dropdown-item" @click="deletecategory(category)">Delete</a>
                      </base-dropdown>
                  </div>
                </div>
              </draggable>
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <base-button
            type="warning"
            block
            class=""
            id="savepositions"
            @click="savepositions()"
            >
            Save Category Positions
          </base-button>
        </div>
      </div>
    </div>
    <!-- create category -->
    <modal :show.sync="createcategory" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create Category</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-4">
              <label class="form-control-label">Category Name<span class="text-danger">*</span></label>
              <base-input placeholder="Category Name" v-model="categorymodal.inventory_category_name" input-classes="" />
              <div
                  v-if="$v.categorymodal.inventory_category_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-4">
              <label class="form-control-label">Category Ranking</label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="categorymodal.sequenced_at"> 
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
              </select>
            </div>
            <div class="col-lg-4">
              <label class="form-control-label">Show category in menu</label>
              <br>
              <base-switch v-model="categorymodal.is_featured"></base-switch>
            </div>
            <!-- <div class="col-lg-4">
                <label class="form-control-label">Category Icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'icon')"
                >
                <div
                    v-if="$v.categorymodal.inventory_category_icon.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="categorymodal.inventory_category_icon" class="img-fluid rounded shadow mt-2 p-2">
                </div>
            </div> -->
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for Web</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for Web"   id="file" ref="myFiles" name="img" @change="">
            </div> -->
            <!-- <div class="col-lg-4">
               <label class="form-control-label">Store</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="categorymodal.store">
                     <option v-for="store in storeData" :value="store.store_id">{{store.store_name}}</option>
               </select>
            </div> -->
            <div class="col-md-4">
              <base-input label="Meta Title" v-model="categorymodal.meta_title" placeholder="Write meta title here" input-classes="" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">Meta Description</label>
              <textarea class="form-control" id="" rows="2" v-model="categorymodal.meta_description"></textarea>
            </div>
            <div class="col-md-4 meta_tags">
              <label class="form-control-label">Meta Keywords</label>
              <textarea class="form-control" id="" v-model="categorymodal.meta_keyword" rows="2"></textarea>
            </div>
            <div class="col-lg-4">
                <label class="form-control-label">Category Featured Img<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'image')"
                >
                <div
                    v-if="$v.categorymodal.inventory_category_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="categorymodal.inventory_category_img" class="img-fluid rounded shadow mt-2 p-2">
                </div>
            </div>
            <div class="col-md-4 meta_tags">
              <base-input label="OG Title" placeholder="Write title here" v-model="categorymodal.og_title" input-classes="" />
            </div>
            <div class="col-md-4 meta_tags">
              <base-input label="OG Type" placeholder="Write type here" v-model="categorymodal.og_type" input-classes="" />
            </div>
            <div class="col-md-4 meta_tags">
              <label class="form-control-label">OG Description</label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="categorymodal.og_description"></textarea>
            </div>
            <div class="col-lg-4 mt-2">
              <label class="form-control-label">OG Image</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'og_image')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="categorymodal.og_image">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="delete_addcategory_ogimage(categorymodal)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                  </base-button>
                    <img :src="categorymodal.og_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <!-- <div class="col-lg-6 mt-2">
                <label class="form-control-label">Primary Banner</label>
                <input
                  type="file"
                  ref="file1"
                  accept="image/*" 
                  placeholder="User DP"
                  class="form-control form-control-alternative"
                  multiple="multiple"
                  @change="bannerupload($event,'add','file1')"
                />
              <base-progress type="success" :value="banneruploadPercentage" label="File uploaded"></base-progress>
               <div v-for="(banner_primary,index) in categorymodal.banner_primary" :key="'pb'+index" >
                <div class="position-relative">
                <base-button
                  type="danger"
                  size="sm"
                  class="position-absolute delete-btn-style"
                  @click="deletefile(index,'edit')"
                  >
                </base-button>
                <img :src="banner_primary.url" class="img-fluid rounded shadow mt-3 card-body">
              </div>
             </div>
            </div> -->
            <!-- <div class="col-lg-6 mt-2">
                <label class="form-control-label">Secondary Banners</label>
                <input
                  type="file"
                  ref="file3"
                  accept="application/pdf,application/vnd.ms-excel" 
                  placeholder="User DP"
                  class="form-control form-control-alternative"
                  multiple="multiple"
                  @change="bannerupload($event,'edit','file3')"
                />
              <base-progress type="success" :value="banneruploadPercentage" label="File uploaded"></base-progress>
              
              <div class="row" v-for="(banner_secondary,index) in categorymodal.banner_secondary" :key="'sb'+index" >
                <div class="col-6 position-relative">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute delete-btn-style"
                    @click="deletefile(index,'edit')"
                    >
                  </base-button>
                   <img :src="banner_secondary.url" class="img-fluid rounded shadow mt-3 card-body">
                </div>
              </div>
            </div> -->
            <!-- <div class="col-6">
              <label class="form-control-label">Third Banner</label>
              <dropzone-file-upload></dropzone-file-upload>
            </div>
            <div class="col-6">
              <label class="form-control-label">Fourth Banner</label>
              <dropzone-file-upload></dropzone-file-upload>
            </div> -->
            <!-- <div class="col-lg-4 mt-2">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " v-model="categorymodal.status" autocomplete="off">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div> -->
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for App</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for App" id="file" ref="myFiles" name="img" @change="">
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.categorymodal.$error" @click="$v.categorymodal.$touch();savecategory()"
          id="updatebtn"
          type="success"
          class="float-center"
        >
          <span>Create Category</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="createcategory = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit category -->
    <modal :show.sync="editcategory" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Category</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-4">
              <label class="form-control-label">Category Name<span class="text-danger">*</span></label>
              <base-input placeholder="Category Name" v-model="editcategorymodal.inventory_category_name" input-classes="" />
              <div
                  v-if="$v.editcategorymodal.inventory_category_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-4">
              <label class="form-control-label">Show category in menu</label>
              <br>
              <base-switch v-model="editcategorymodal.is_featured"></base-switch>
            </div>
            <!-- <div class="col-lg-4">
                <label class="form-control-label">Category Icon</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editicon')"
                >
                <div
                    v-if="$v.editcategorymodal.inventory_category_icon.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editcategorymodal.inventory_category_icon" class="img-fluid rounded shadow mt-2 p-2">
                </div>
            </div> -->
            <!-- <div class="col-lg-4">
               <label class="form-control-label">Store</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="categorymodal.store">
                     <option v-for="store in storeData" :value="store.store_id">{{store.store_name}}</option>
               </select>
            </div> -->
            <div class="col-lg-4">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editcategorymodal.status">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
            <div class="col-md-4">
              <base-input label="Meta Title" v-model="editcategorymodal.meta_title" placeholder="Write title here" input-classes="" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">Meta Description</label>
              <textarea class="form-control" v-model="editcategorymodal.meta_description" id="" rows="2"></textarea>
            </div>
            <div class="col-md-4 meta_tags">
              <label class="form-control-label">Meta Keywords</label>
              <textarea class="form-control" v-model="editcategorymodal.meta_keyword" id="" rows="2"></textarea>
            </div>
            <div class="col-lg-4">
                <label class="form-control-label">Category Featured Img<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editimage')"
                >
                <div
                    v-if="$v.editcategorymodal.inventory_category_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
                </div>
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="editcategorymodal.inventory_category_img" class="img-fluid rounded shadow mt-2 p-2">
                </div>
            </div>
            <div class="col-md-4">
              <base-input label="OG Title" placeholder="Write title here" v-model="editcategorymodal.og_title" input-classes="" />
            </div>
            <div class="col-md-4">
              <base-input label="OG Type" placeholder="Write type here" v-model="editcategorymodal.og_type" input-classes="" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">OG Description</label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="editcategorymodal.og_description"></textarea>
            </div>
            <div class="col-lg-4 mt-2">
              <label class="form-control-label">OG Image</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'og_image')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="editcategorymodal.og_image">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="delete_editcategory_ogimage(editcategorymodal)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                  </base-button>
                    <img :src="editcategorymodal.og_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for Web</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for Web"   id="file" ref="myFiles" name="img" @change="">
            </div> -->
            <!-- <div class="col-lg-4 mt-2">
                <label class="form-control-label">Primary Banner</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'image')"
                >
                <base-progress type="success" label="File uploaded"></base-progress>
                <div>
                  <img :src="categorymodal.inventory_category_img" class="img-fluid rounded shadow mt-2 p-2">
                </div>
            </div>
            <div class="col-lg-4 mt-2">
                <label class="form-control-label">Secondary Slid Banners</label>
                <dropzone-file-upload multiple></dropzone-file-upload>
            </div> -->
            <!-- <div class="col-lg-6">
              <label class="form-control-label">Category Picture for App</label><input type="text" aria-describedby="addon-right addon-left" alternative="" class="form-control " placeholder="Category Picture for App" id="file" ref="myFiles" name="img" @change="">
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editcategorymodal.$error" @click="$v.editcategorymodal.$touch();updatecategory()"
          id="updatebtn1"
          type="success"
          class="float-center"
        >
          <span>Update Category</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editcategory = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit banner -->
    <modal :show.sync="editbanner" size="lg">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Banners</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-12 mt-2">
              <div class="row" v-for="(banner_primary,index) in editcategorymodal.banner_primary">
                <div class="col-lg-4">
                  <label class="form-control-label">Primary Banner Link To</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="banner_primary.todo" @change="getchange_div('primary',index)">
                        <option value="openCategory">Category</option>
                        <option value="openProductFeed">Sub-Category</option>
                        <option value="openProduct">Product</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'pcategory'+index">
                  <label class="form-control-label">Category</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target" @change="getsubcategory()">
                        <option v-for="category in categoryData" :key="category._id">{{category.inventory_category_name}}</option>
                        
                        <!-- <option>Category 3</option> -->
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'psubcategory'+index">
                  <label class="form-control-label">Subcategory</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_primary.target">
                        <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                        <!-- <option>Sub-category 2</option>
                        <option>Sub-category 3</option> -->
                  </select>
                </div>
                <div class="col-lg-8 d-none" :id="'pproduct'+index">
                  <base-input label="Product oid" placeholder="Product oid" v-model="banner_primary.target" input-classes="" />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label mt-2">Upload Image</label>
                  <input
                    id="file"
                    ref="myFiles"
                    type="file"
                    aria-describedby="addon-right addon-left"
                    alternative=""
                    class="form-control form-control-alternative"
                    name="img"
                    @change="bannerhadleimage($event,'primary',index)"
                  >
                  <base-progress type="success" label="File uploaded"></base-progress>
                  <div>
                    <img :src="banner_primary.url" class="img-fluid rounded shadow mt-2 p-2">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <hr>
            </div>
            <div class="col-lg-12 mt-2">
              <div class="row" v-for="(banner_secondary,index) in editcategorymodal.banner_secondary">
                <div class="col-lg-4">
                  <label class="form-control-label">Secondary Banner Link To</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.todo" @change="getchange_div('secondary',index)">
                        <option value="openCategory">Category</option>
                        <option value="openProductFeed">Sub-Category</option>
                        <option value="openProduct">Product</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'scategory'+index">
                  <label class="form-control-label">Category</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                        <option v-for="category in categoryData" :key="category._id">{{category.inventory_category_name}}</option>
                  </select>
                </div>
                <div class="col-lg-4 d-none" :id="'ssubcategory'+index">
                  <label class="form-control-label">Subcategory</label>
                  <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="banner_secondary.target">
                        <option v-for="subcategory in subcategoryData" :key="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                  </select>
                </div>
                <div class="col-lg-8 d-none" :id="'sproduct'+index">
                  <base-input label="Product SKU" placeholder="Product SKU" v-model="banner_secondary.target" input-classes="" />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label mt-2">Upload Image</label>
                  <input
                    id="file"
                    ref="myFiles"
                    type="file"
                    aria-describedby="addon-right addon-left"
                    alternative=""
                    class="form-control form-control-alternative"
                    name="img"
                    @change="bannerhadleimage($event,'secondary',index)"
                  >
                  <div>
                    <img :src="banner_secondary.url" class="img-fluid rounded shadow mt-2 p-2">
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                  <base-button
                    id="updatebtn1"
                    type="success"
                    outline
                    @click="addmore()"
                    class="float-center border-dashed"
                    block
                  >
                    <i class="fas fa-plus"></i><span>Add more banners</span>
                  </base-button>
                </div>
            </div>

          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatebanner()"
          id="updatebtn1"
          type="success"
          class="float-center"
        >
          <span>Update Banners</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editbanner = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import draggable from 'vuedraggable'

  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
      DropzoneFileUpload,
      draggable
    },
    data() {
      return {
        inputs: {
          file: []
        },
        categoryData:[],
        subcategoryData:[],
        storeData:[],
        datanotfound:false,
        banneruploadPercentage:0,
        imagearr:[],
        pagepermission:{},
        createcategory:false,
        editcategory:false,
        editbanner:false,
        uploadPercentage:0,
        categorymodal:{
          inventory_category_id:'',
          inventory_category_name:'',
          inventory_category_type:'product',
          status:'approved',
          inventory_category_icon:'',
          inventory_category_img:'',
          store:'',
          region:'',
          banner_primary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414002181banner-1700.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414092328toppng.com-grocery-png-1000x667.png',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0}],
          banner_secondary:[{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414002181banner-1700.jpg',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:0},{url:'https://captionplus.s3.ap-south-1.amazonaws.com/1611414092328toppng.com-grocery-png-1000x667.png',todo:'openCategory',target:'600bed42445289483999ecd9',sequenced_at:1}],
          is_featured:false,
          sequenced_at:0,
          created_by:localStorage.getItem('_id'),
          inventory_category_theme_color:'#d3d3d3',
          og_title : '',
          og_type : '',
          og_image : '',
          og_description : '',
          meta_title : '',
          meta_description : '',
          meta_keyword : ''
        },
        editcategorymodal:{
          inventory_category_id:'',
          inventory_category_name:'',
          inventory_category_type:'',
          status:'',
          inventory_category_icon:'',
          inventory_category_img:'',
          store:'',
          region:'',
          banner_primary:[{url:'',todo:'',target:'',sequenced_at:0}],
          banner_secondary:[{url:'',todo:'',target:'',sequenced_at:0}],
          is_featured:'',
          sequenced_at:'',
          created_by:localStorage.getItem('_id'),
          inventory_category_theme_color:'',
          og_title : '',
          og_type : '',
          og_image : '',
          og_description : '',
          meta_title : '',
          meta_description : '',
          meta_keyword : ''
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    validations: {
      categorymodal: {
        inventory_category_name:{
          required
        },
        inventory_category_img:{
          required
        },
      },
      editcategorymodal: {
        inventory_category_name:{
          required
        },
        inventory_category_img:{
          required
        },
      },

    },
    mounted () {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.categoryData=response.data.details;
        
        }else{
          this.categoryData=[];
          this.datanotfound=true;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        this.categorymodal.store=this.storeData[0].store_id;
        }
            
      })

    },
    methods: {
          getalldata(){
            axios
              .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
              .then(response => {
                console.log(response);
                if(response.data.details){
                this.categoryData=response.data.details;
                this.datanotfound=false;
                }else{
                  this.categoryData=[];
                }
                    
              })
          },
          createcategorymodal(){
            this.createcategory=true;
          },
          bannerhadleimage: function(event,type,index) {
                // Reference to the DOM input element
                this.uploadPercentage =0;
                var input = event.target;
                // Ensure that you have a file before attempting to read it
                if(input.files[0].size < 5000000){

                const fd= new FormData();
                fd.append('image',input.files[0],input.files[0].name);
                axios
                .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
                .then(response => {
                  console.log(response);
                  if(type=='primary'){
                    this.editcategorymodal.banner_primary[index].url=response.data.imageUrl;
                    console.log(this.editcategorymodal.banner_primary[index].url);
                  }else if(type=='secondary'){
                    this.editcategorymodal.banner_secondary[index].url=response.data.imageUrl;
                    console.log(this.editcategorymodal.banner_secondary[index].url);
                  }
                    
                })

                }else{

                swal({
                  title:'File too large!',
                  text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                  icon:'warning',
                 }).then((result) => {
                   
                })

              }

        },
        getchange_div(type,index){

          let banner_secondary_todo=this.editcategorymodal.banner_secondary[index].todo;
          let banner_primary_todo=this.editcategorymodal.banner_primary[index].todo;

          console.log(this.editcategorymodal.banner_secondary[index].todo);

              if(this.editcategorymodal.banner_primary[index].todo=='openCategory' && type=='primary'){
                 document.getElementById("pcategory"+index).classList.remove('d-none');
                 document.getElementById("psubcategory"+index).classList.add('d-none');
                 document.getElementById("pproduct"+index).classList.add('d-none');
              }
              if(this.editcategorymodal.banner_primary[index].todo=='openProductFeed' && type=='primary'){
                 document.getElementById("pcategory"+index).classList.add('d-none');
                 document.getElementById("psubcategory"+index).classList.remove('d-none');
                 document.getElementById("pproduct"+index).classList.add('d-none');

              }
              if(this.editcategorymodal.banner_primary[index].todo=='openProduct' && type=='primary'){
                 document.getElementById("pcategory").classList.add('d-none');
                 document.getElementById("psubcategory").classList.add('d-none');
                 document.getElementById("pproduct").classList.remove('d-none');

              }

              if(this.editcategorymodal.banner_secondary[index].todo=='openCategory' && type=='secondary'){
                 document.getElementById("scategory"+index).classList.remove('d-none');
                 document.getElementById("ssubcategory"+index).classList.add('d-none');
                 document.getElementById("sproduct"+index).classList.add('d-none');
              }
              if(this.editcategorymodal.banner_secondary[index].todo=='openProductFeed' && type=='secondary'){
                 document.getElementById("scategory"+index).classList.add('d-none');
                 document.getElementById("ssubcategory"+index).classList.remove('d-none');
                 document.getElementById("sproduct"+index).classList.add('d-none');

              }
              if(this.editcategorymodal.banner_secondary[index].todo=='openProduct' && type=='secondary'){
                 document.getElementById("scategory"+index).classList.add('d-none');
                 document.getElementById("ssubcategory"+index).classList.add('d-none');
                 document.getElementById("sproduct"+index).classList.remove('d-none');

              }
              
        },
        hadleimage: function(event,type) {
                // Reference to the DOM input element
                this.uploadPercentage =0;
                var input = event.target;
                // Ensure that you have a file before attempting to read it
                if(input.files[0].size < 5000000){

                const fd= new FormData();
                fd.append('image',input.files[0],input.files[0].name);
                axios
                .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
                .then(response => {
                  console.log(response);
                  if(type=='icon'){
                    this.categorymodal.inventory_category_icon=response.data.imageUrl;
                    console.log(this.categorymodal.inventory_category_icon);
                  }else if(type=='image'){
                    this.categorymodal.inventory_category_img=response.data.imageUrl;
                    console.log(this.categorymodal.inventory_category_img);
                  }else if(type=='editicon'){
                    this.editcategorymodal.inventory_category_icon=response.data.imageUrl;
                    console.log(this.editcategorymodal.inventory_category_icon);
                  }else if(type=='editimage'){
                    this.editcategorymodal.inventory_category_img=response.data.imageUrl;
                    console.log(this.editcategorymodal.inventory_category_img);
                  }else if(type=='og_image'){
                    this.editcategorymodal.og_image=response.data.imageUrl;
                    console.log(this.editcategorymodal.og_image);
                  }
                    
                })

                }else{

                swal({
                  title:'File too large!',
                  text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                  icon:'warning',
                 }).then((result) => {
                   
                })

              }

        },

        async bannerupload(event,type,uploatype) {
              // Reference to the DOM input element
                //console.log(event);
                // document.getElementById("editassignmentbtn").classList.add('d-none');
                // document.getElementById("editaddassignmentinvalid").classList.remove('d-none');
               this.banneruploadPercentage =0;
                if(uploatype=='file1'){
                  for( var i = 0; i < this.$refs.file1.files.length; i++ ){
                      let file = this.$refs.file1.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }
                if(uploatype=='file3'){
                  for( var i = 0; i < this.$refs.file3.files.length; i++ ){
                      let file = this.$refs.file3.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }

        },
        upload(file,type){
          //console.log(file);
          let fd = new FormData();
          fd.append('image',file,file.name);
          axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.banneruploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.banneruploadPercentage)}.bind(this)}).then(response => {
            //console.log(response);
            this.imagearr.push({"url":response.data.imageUrl,"sequenced_at":1});
            //console.log(this.imagearr);
            if(type=='add'){
              this.categorymodal.banner_primary=this.imagearr;
              //console.log(this.assignmentmodel.assignment_file);
            }
            else{
              this.categorymodal.banner_secondary=this.imagearr;
            }
            //console.log();
         })

        },
        addmore(){
          this.editcategorymodal.banner_secondary.push({url:'',todo:'',target:'',sequenced_at:0})
        },
        savecategory() {
          if(this.$v.categorymodal.$error===false){
          //region:this.categorymodal.region,banner_primary:this.categorymodal.banner_primary,banner_secondary:this.categorymodal.banner_secondary,
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_inventory_category',{inventory_category_name : this.categorymodal.inventory_category_name,inventory_category_type:this.categorymodal.inventory_category_type,status:this.categorymodal.status,inventory_category_icon:this.categorymodal.inventory_category_img,inventory_category_img:this.categorymodal.inventory_category_img,store:this.categorymodal.store,is_featured:this.categorymodal.is_featured.toString(),sequenced_at:this.categorymodal.sequenced_at,created_by:this.categorymodal.created_by,inventory_category_theme_color:this.categorymodal.inventory_category_theme_color,meta_title:this.categorymodal.meta_title,meta_description:this.categorymodal.meta_description,meta_keyword:this.categorymodal.meta_keyword,og_type:this.categorymodal.og_type,og_title:this.categorymodal.og_title,og_description:this.categorymodal.og_description,og_image:this.categorymodal.og_image},this.auth1)
            .then(response => {
              console.log(response);
              this.message=response.data.message;
              let _this=this;
              document.getElementById("updatebtn").classList.remove('btn-primary');
              document.getElementById("updatebtn").classList.add('btn-success');
              document.getElementById("updatebtn").innerHTML='Updated';
              setTimeout(function(){
              document.getElementById("updatebtn").classList.remove('btn-success');
              document.getElementById("updatebtn").classList.add('btn-primary');
              document.getElementById("updatebtn").innerHTML="Update";
              _this.createcategory=false;
              _this.getalldata();
              }, 2000); 

            })
          }
        },
        openeditcategory(row){
          this.editcategory=true;
          this.editcategorymodal.inventory_category_id =row.inventory_category_id;
          this.editcategorymodal.inventory_category_name =row.inventory_category_name;
          this.editcategorymodal.inventory_category_type =row.inventory_category_type;
          this.editcategorymodal.status =row.status;
          this.editcategorymodal.inventory_category_icon =row.inventory_category_icon;
          this.editcategorymodal.inventory_category_img =row.inventory_category_img;
          this.editcategorymodal.store =row.store;
          this.editcategorymodal.region =row.region;
          this.editcategorymodal.banner_primary =row.banner_primary;
          this.editcategorymodal.is_featured =row.is_featured;
          this.editcategorymodal.sequenced_at =row.sequenced_at;
          this.editcategorymodal.created_by =row.created_by;
          this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
          this.editcategorymodal.meta_title =row.meta_title;
          this.editcategorymodal.meta_description =row.meta_description;
          this.editcategorymodal.meta_keyword =row.meta_keyword;
          this.editcategorymodal.og_title =row.og_title;
          this.editcategorymodal.og_description =row.og_description;
          this.editcategorymodal.og_image =row.og_image;
          this.editcategorymodal.og_type =row.og_type;
        },
        openbanner(row){
          this.editbanner=true;
          this.editcategorymodal.inventory_category_id =row.inventory_category_id;
          this.editcategorymodal.inventory_category_name =row.inventory_category_name;
          this.editcategorymodal.inventory_category_type =row.inventory_category_type;
          this.editcategorymodal.status =row.status;
          this.editcategorymodal.inventory_category_icon =row.inventory_category_icon;
          this.editcategorymodal.inventory_category_img =row.inventory_category_img;
          this.editcategorymodal.store =row.store;
          this.editcategorymodal.region =row.region;
          this.editcategorymodal.banner_primary =row.banner_primary;
          this.editcategorymodal.banner_secondary =row.banner_secondary;
          this.editcategorymodal.is_featured =row.is_featured;
          this.editcategorymodal.sequenced_at =row.sequenced_at;
          this.editcategorymodal.created_by =row.created_by;
          this.editcategorymodal.inventory_category_theme_color =row.inventory_category_theme_color;
        },

        updatecategory() {
          if(this.$v.editcategorymodal.$error===false){
          //region:this.editcategorymodal.region,banner_primary:this.editcategorymodal.banner_primary,banner_secondary:this.editcategorymodal.banner_secondary,is_featured:this.editcategorymodal.is_featured
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_category',{inventory_category_id : this.editcategorymodal.inventory_category_id,inventory_category_name : this.editcategorymodal.inventory_category_name,inventory_category_type:this.editcategorymodal.inventory_category_type,status:this.editcategorymodal.status,inventory_category_icon:this.editcategorymodal.inventory_category_img,inventory_category_img:this.editcategorymodal.inventory_category_img,store:this.editcategorymodal.store,sequenced_at:this.editcategorymodal.sequenced_at,created_by:this.editcategorymodal.created_by,is_featured:this.editcategorymodal.is_featured.toString(),meta_title:this.editcategorymodal.meta_title,meta_description:this.editcategorymodal.meta_description,meta_keyword:this.editcategorymodal.meta_keyword,og_type:this.editcategorymodal.og_type,og_title:this.editcategorymodal.og_title,og_description:this.editcategorymodal.og_description,og_image:this.editcategorymodal.og_image},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            let _this=this;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
            _this.editcategory=false;
            _this.getalldata();
          }, 2000);

           // window.location.reload()

          })
         }

        },
        updatebanner() {
          const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_category',{inventory_category_id : this.editcategorymodal.inventory_category_id,created_by:this.editcategorymodal.created_by,banner_primary:this.editcategorymodal.banner_primary,banner_secondary:this.editcategorymodal.banner_secondary},this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatebtn1").classList.remove('btn-primary');
            document.getElementById("updatebtn1").classList.add('btn-success');
            document.getElementById("updatebtn1").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatebtn1").classList.remove('btn-success');
            document.getElementById("updatebtn1").classList.add('btn-primary');
            document.getElementById("updatebtn1").innerHTML="Update";
          }, 2000);

           // window.location.reload()

          })

        },
        deletecategory(row) {
          console.log(row);
          const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_inventory_category',querystring.stringify({inventory_category_id :row.inventory_category_id,created_by:row.created_by}),this.auth)
            .then(response => {
              let _this=this;
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your category has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
               _this.getalldata();
              })
              })
             }
          });
        },
        //save categories with loop
        savepositions() {
          document.getElementById("savepositions").innerHTML='<i class="fas fa-circle-notch fa-spin mr-2"></i> Saving';
            for (var i = 0; i < this.categoryData.length; i++) {
              axios.post(process.env.VUE_APP_API_ENDPOINT+'update_inventory_category',{inventory_category_id : this.categoryData[i].inventory_category_id,sequenced_at:i+1,created_by:this.categoryData[i].created_by},this.auth1).then(response => {
                  document.getElementById("savepositions").classList.remove('btn-primary');
                  document.getElementById("savepositions").classList.add('btn-success');
                  document.getElementById("savepositions").innerHTML='Saved';
                  setTimeout(function(){
                    document.getElementById("savepositions").classList.remove('btn-success');
                    document.getElementById("savepositions").classList.add('btn-primary');
                    document.getElementById("savepositions").innerHTML="Saved";
                  }, 2000);
                  window.location.reload()
                })
            }
        },
        delete_editcategory_ogimage(index){
          this.editcategorymodal.og_image='';
          this.uploadPercentage =0;
        },
        delete_addcategory_ogimage(index){
          this.categorymodal.og_image='';
          this.uploadPercentage =0;
        }

    },
  };
</script>
<style></style>
