<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Results
                  </h3>
                </div>
                <div class="col text-right">
                  <!-- <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectstore" @change="getfilterdata()">
                    <option value="">Select Store</option>
                    <option v-for="store in storeData" :key="store.store_id" :value="store.store_id">{{store.store_name}}</option>
                  </select> -->
                  <base-button type="primary" @click="addresultpopup"><i class="fas fa-plus"></i> Create Result</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="resultData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Upload Answer</th>
                  <th>exam</th>
                  <th>class</th>
                  <th>result_status</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget">
                      <a :href="row.result_link" target="_blank">
                        <img src="img/theme/pdf.png" class="img-fluid" style="width: 70px">
                      </a>
                    </td>
                    <td class="budget">
                      {{row.exam_oid.exam_name}}
                    </td>
                    <td class="budget">
                      <p class="mb-0">{{row.class_oid.class_name}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">{{row.result_status}}</p>
                    </td>
                    <td class="text-right">
                      <base-button type="primary" size="sm" @click="editresultpopup(row)" >Edit</base-button>
                      <base-button type="danger" size="sm" @click="deleteresult(row)">Delete</base-button>
                      <!-- <router-link class="btn btn-primary btn-sm" to="/seo/meta" icon="fa fa-info">View</router-link> -->
                    </td>
                </template>
              </base-table>

              <!-- no data found here -->
              <div class="card-footer justify-content-center" v-if="!datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No result created.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="resultData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- add baner -->
    <modal :show.sync="addresult">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create result</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
             <div class="col-lg-12">
              <label class="form-control-label">Result Link<span class="text-danger">*</span></label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'add')"
                >
              <div
                  v-if="$v.resultmodal.result_link.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
              <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
              <div>
                  <img :src="resultmodal.result_link" class="img-fluid rounded shadow mt-2">
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Student<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="resultmodal.student_oid">
                <option value="">Select Student</option>
                <option v-for="teacher in teacherData" :key="teacher.user_id" :value="teacher._id">{{teacher.user_name}}</option>
              </select>
              <div
                  v-if="$v.resultmodal.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="resultmodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.resultmodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Exam<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="resultmodal.exam_oid">
                <option value="">Select exam</option>
                <option v-for="exam in examData" :key="exam.exam_id" :value="exam._id">{{exam.exam_name}}</option>
              </select>
              <div
                  v-if="$v.resultmodal.exam_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Download from<span class="text-danger">*</span></label>
              <base-input type="date" placeholder="Download from" v-model="resultmodal.download_from" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.resultmodal.download_from.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Download to<span class="text-danger">*</span></label>
              <base-input type="date" placeholder="Download to" v-model="resultmodal.download_to" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.resultmodal.download_to.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="resultmodal.result_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.resultmodal.result_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
         :disabled="$v.resultmodal.$error" @click="$v.resultmodal.$touch();createresult()"
          type="success"
          id="createresultbtn"
          class="float-center"
        >
          <span>Save result</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addresult = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit banner popup -->
    <modal :show.sync="editresult">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Result</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
             <div class="col-lg-12">
              <label class="form-control-label">Result link<span class="text-danger">*</span></label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'edit')"
                >
              <div
                  v-if="$v.editresultmodal.result_link.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
              <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
              <div>
                  <img :src="editresultmodal.result_link" class="img-fluid rounded shadow mt-2">
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Student<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresultmodal.student_oid">
                <option value="">Select student</option>
                <option v-for="teacher in teacherData" :key="teacher.user_id" :value="teacher._id">{{teacher.user_name}}</option>
              </select>
              <div
                  v-if="$v.editresultmodal.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresultmodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.editresultmodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Exam<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresultmodal.exam_oid">
                <option value="">Select exam</option>
                <option v-for="exam in examData" :key="exam.exam_id" :value="exam._id">{{exam.exam_name}}</option>
              </select>
              <div
                  v-if="$v.editresultmodal.exam_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Download from<span class="text-danger">*</span></label>
              <base-input type="date" placeholder="Download from" v-model="editresultmodal.download_from" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.editresultmodal.download_from.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Download to<span class="text-danger">*</span></label>
              <base-input type="date" placeholder="Download to" v-model="editresultmodal.download_to" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.editresultmodal.download_to.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresultmodal.result_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.editresultmodal.result_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editresultmodal.$error" @click="$v.editresultmodal.$touch();updateresult()"
          type="success"
          id="updateresultbtn"
          class="float-center"
        >
          <span>Update result</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editresult = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { ModelListSelect } from 'vue-search-select'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import 'vue-search-select/dist/VueSearchSelect.css'
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
// cayegory
// subcategory
// variation
// item
// offer
      DropzoneFileUpload,
      ModelListSelect,
    },
    data() {
      return {
        addresult: false,
        editresult: false,
        datanotfound:false,
        uploadPercentage:0,
        selectstore:'',
        pagepermission:{},
        resultData:[],
        teacherData:[],
        classesData:[],
        subjectData:[],
        examData:[],
        resultmodal:{
          result_id : '',
          student_oid : '',
          result_link : '',
          class_oid : '',
          exam_oid : '',
          result_status : '',
          verified : true,
          download_from : '',
          download_to : '',
        },
        editresultmodal:{
          result_id : '',
          student_oid : '',
          result_link : '',
          class_oid : '',
          exam_oid : '',
          result_status : '',
          verified : true,
          download_from : '',
          download_to : '',
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {

      resultmodal: {
        result_link:{
          required
        },
        student_oid:{
          required
        },
        result_status:{
          required
        },
        class_oid:{
          required
        },
        exam_oid:{
          required
        },
        download_from:{
          required
        },
        download_to:{
          required
        },
        
      },
      editresultmodal: {
        result_link:{
          required
        },
        student_oid:{
          required
        },
        result_status:{
          required
        },
        class_oid:{
          required
        },
        exam_oid:{
          required
        },
        download_from:{
          required
        },
        download_to:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_result',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.resultData=response.data.details;
        this.datanotfound=false;
        }else{
          this.resultData=[];
        } this.datanotfound=true;
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_student',{ "details" :{"user_type":'student'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.teacherData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.classesData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_subject',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.subjectData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_exam',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.examData=response.data.details;
        }
            
      })
      
    },
    methods: {

      addresultpopup(row){
        console.log(row);
        this.addresult=true;
      },
      editresultpopup(row){
          console.log(row);
          this.editresultmodal.result_id =row.result_id;
          this.editresultmodal.result_link =row.result_link;
          if(row.class_oid){
          this.editresultmodal.class_oid =row.class_oid._id;
          }
          if(row.exam_oid){
          this.editresultmodal.exam_oid =row.exam_oid._id;
          }
          if(row.subject_oid){
          this.editresultmodal.subject_oid =row.subject_oid._id;
          }
          if(row.student_oid){
          this.editresultmodal.student_oid =row.student_oid._id;
          }
          //this.editresultmodal.student_oid =row.student_oid;
          this.editresultmodal.result_status=row.result_status;
          if(row.download_from){
             let dfrom=row.download_from.split('T');
             this.editresultmodal.download_from=dfrom[0];
          }
          if(row.download_to){
             let tfrom=row.download_to.split('T');
             this.editresultmodal.download_to=tfrom[0];
          }
          this.editresult=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='add'){
                this.resultmodal.result_link=response.data.imageUrl;
                console.log(this.resultmodal.result_link);
              }if(type=='edit'){
                this.editresultmodal.result_link=response.data.imageUrl;
                console.log(this.editresultmodal.result_link);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    createresult(){
        if(this.$v.resultmodal.$error===false){
        const querystring = require('querystring');
      
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_result',querystring.stringify({result_link:this.resultmodal.result_link,student_oid:this.resultmodal.student_oid,class_oid:this.resultmodal.class_oid,exam_oid:this.resultmodal.exam_oid,result_status:this.resultmodal.result_status,verified:this.resultmodal.verified,download_from:this.resultmodal.download_from,download_to:this.resultmodal.download_to}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createresultbtn").classList.remove('btn-primary');
            document.getElementById("createresultbtn").classList.add('btn-success');
            document.getElementById("createresultbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createresultbtn").classList.remove('btn-success');
            document.getElementById("createresultbtn").classList.add('btn-primary');
            document.getElementById("createresultbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
        }
        
      },
      updateresult(){
        if(this.$v.editresultmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_result',querystring.stringify({result_id : this.editresultmodal.result_id,result_link:this.editresultmodal.result_link,student_oid:this.editresultmodal.student_oid,class_oid:this.editresultmodal.class_oid,exam_oid:this.editresultmodal.exam_oid,result_status:this.editresultmodal.result_status,verified:this.editresultmodal.verified,download_from:this.editresultmodal.download_from,download_to:this.editresultmodal.download_to}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateresultbtn").classList.remove('btn-primary');
            document.getElementById("updateresultbtn").classList.add('btn-success');
            document.getElementById("updateresultbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateresultbtn").classList.remove('btn-success');
            document.getElementById("updateresultbtn").classList.add('btn-primary');
            document.getElementById("updateresultbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
          })

        }
        
      },
      deleteresult(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_result',querystring.stringify({result_id :row.result_id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getstore_name(val){
        const search = this.storeData.find(element => element.store_id === val);
        if(search){
          return search.store_name;
        }
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
