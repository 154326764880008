<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Toppers List
                  </h3>
                </div>
                <div class="col text-right">
                  <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectclass" @change="getfilterdata()">
                    <option value="">Select Class</option>
                    <option v-for="row in classesData" :key="row.class_id" :value="row._id">{{row.class_name}}</option>
                  </select>
                  <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectyear" @change="getfilterdata()">
                    <option value="">Select Year</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                  </select>
                  <base-button type="primary" @click="addresultpopup"><i class="fas fa-plus"></i> Add Topper Student</base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="resultData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Student</th>
                  <th>Class</th>
                  <th>Marks</th>
                  <th>Year</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">{{row.student_oid.user_name}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">{{row.class_oid.class_name}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">{{row.marks}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0">{{row.year}}</p>
                    </td>
                    <td class="text-right">
                      <base-button type="primary" size="sm" @click="editresultpopup(row)" >Edit</base-button>
                      <base-button type="danger" size="sm" @click="deleteresult(row)">Delete</base-button>
                      <!-- <router-link class="btn btn-primary btn-sm" to="/seo/meta" icon="fa fa-info">View</router-link> -->
                    </td>
                </template>
              </base-table>

              <!-- no data found here -->
              <div class="card-footer justify-content-center" v-if="!datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No result created.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="resultData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- add topper -->
    <modal :show.sync="addresult">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Add topper student</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">Student<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="toppermodel.student_oid">
                <option value="">Select Student</option>
                <option v-for="teacher in teacherData" :key="teacher.user_id" :value="teacher._id">{{teacher.user_name}}</option>
              </select>
              <div
                  v-if="$v.toppermodel.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="toppermodel.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.toppermodel.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Section<span class="text-danger">*</span></label>
              <base-input type="text" placeholder="A" v-model="toppermodel.section" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.toppermodel.section.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Year<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="toppermodel.year">
                <option value="">Select year</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </select>
              <div
                  v-if="$v.toppermodel.year.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Marks<span class="text-danger">*</span></label>
              <base-input type="text" placeholder="200/350" v-model="toppermodel.marks" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.toppermodel.marks.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Percentage<span class="text-danger">*</span></label>
              <base-input type="text" placeholder="60%" v-model="toppermodel.percentage" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.toppermodel.percentage.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <!-- <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="toppermodel.result_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.toppermodel.result_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
         :disabled="$v.toppermodel.$error" @click="$v.toppermodel.$touch();createtopper()"
          type="success"
          id="createtopperbtn"
          class="float-center"
        >
          <span>Save details</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addresult = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit topper popup -->
    <modal :show.sync="editresult">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Result</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">Student<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="edittoppermodel.student_oid">
                <option value="">Select teacher</option>
                <option v-for="teacher in teacherData" :key="teacher.user_id" :value="teacher._id">{{teacher.user_name}}</option>
              </select>
              <div
                  v-if="$v.edittoppermodel.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="edittoppermodel.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.edittoppermodel.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Section<span class="text-danger">*</span></label>
              <base-input type="text" placeholder="A" v-model="edittoppermodel.section" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.edittoppermodel.section.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Year<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="edittoppermodel.year">
                <option value="select">Select year</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </select>
              <div
                  v-if="$v.edittoppermodel.year.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Marks<span class="text-danger">*</span></label>
              <base-input type="text" placeholder="200/350" v-model="edittoppermodel.marks" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.edittoppermodel.marks.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Percentage<span class="text-danger">*</span></label>
              <base-input type="text" placeholder="60%" v-model="edittoppermodel.percentage" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.edittoppermodel.percentage.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <!-- <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="edittoppermodel.result_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.edittoppermodel.result_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.edittoppermodel.$error" 
          @click="$v.edittoppermodel.$touch();updatetopper()"
          type="success"
          id="updatetopperbtn"
          class="float-center"
        >
          <span>Update result</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editresult = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { ModelListSelect } from 'vue-search-select'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import 'vue-search-select/dist/VueSearchSelect.css'
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
// cayegory
// subcategory
// variation
// item
// offer
      DropzoneFileUpload,
      ModelListSelect,
    },
    data() {
      return {
        addresult: false,
        editresult: false,
        datanotfound:false,
        uploadPercentage:0,
        selectstore:'',
        selectclass:'',
        selectyear:'',
        pagepermission:{},
        resultData:[],
        teacherData:[],
        classesData:[],
        subjectData:[],
        examData:[],
        toppermodel:{
          topper_id : '',
          student_oid : '',
          percentage : '',
          class_oid : '',
          marks : '',
          topper_status : '',
          year : '',
          section:'',
        },
        edittoppermodel:{
          topper_id : '',
          student_oid : '',
          percentage : '',
          class_oid : '',
          marks : '',
          topper_status : '',
          section:'',
          year : '',
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {

      toppermodel: {
        student_oid:{
          required
        },
        class_oid:{
          required
        },
        percentage:{
          required
        },
        marks:{
          required
        },
        year:{
          required
        },
        section:{
          required
        },
        
      },
      edittoppermodel: {
        student_oid:{
          required
        },
        class_oid:{
          required
        },
        percentage:{
          required
        },
        marks:{
          required
        },
        year:{
          required
        },
        section:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_topper',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.resultData=response.data.details;
        this.datanotfound=false;
        }else{
          this.resultData=[];
        } this.datanotfound=true;
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_student',{ "details" :{"user_type":'student'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.teacherData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.classesData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_subject',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.subjectData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_exam',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.examData=response.data.details;
        }
            
      })
      
    },
    methods: {
      getfilterdata(){
        let details={details:{}};
        if(this.selectclass){
           details.details.class_oid=this.selectclass;
        }
        if(this.selectyear){
           details.details.year=this.selectyear;
        }

         axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_topper',details,this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.resultData=response.data.details;
            this.datanotfound=false;
            }else{
              this.resultData=[];
            } this.datanotfound=true;
                
          })

      },
      addresultpopup(row){
        console.log(row);
        this.addresult=true;
      },
      editresultpopup(row){
          console.log(row);
          this.edittoppermodel.topper_id =row.topper_id;
          this.edittoppermodel.percentage =row.percentage;
          if(row.class_oid){
          this.edittoppermodel.class_oid =row.class_oid._id;
          }
          if(row.student_oid){
          this.edittoppermodel.student_oid =row.student_oid._id;
          }
          this.edittoppermodel.marks =row.marks;
          this.edittoppermodel.topper_status=row.topper_status;
          this.edittoppermodel.year=row.year;
          this.edittoppermodel.section=row.section;
          this.editresult=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='add'){
                this.toppermodel.result_link=response.data.imageUrl;
                console.log(this.toppermodel.result_link);
              }if(type=='edit'){
                this.edittoppermodel.result_link=response.data.imageUrl;
                console.log(this.edittoppermodel.result_link);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    createtopper(){
        if(this.$v.toppermodel.$error===false){
        const querystring = require('querystring');
      
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_topper',querystring.stringify({percentage:this.toppermodel.percentage,student_oid:this.toppermodel.student_oid,class_oid:this.toppermodel.class_oid,marks:this.toppermodel.marks,section:this.toppermodel.section,year:this.toppermodel.year}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createtopperbtn").classList.remove('btn-primary');
            document.getElementById("createtopperbtn").classList.add('btn-success');
            document.getElementById("createtopperbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createtopperbtn").classList.remove('btn-success');
            document.getElementById("createtopperbtn").classList.add('btn-primary');
            document.getElementById("createtopperbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
        }
        
      },
      updatetopper(){
        if(this.$v.edittoppermodel.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_topper',querystring.stringify({topper_id : this.edittoppermodel.topper_id,class_oid:this.edittoppermodel.class_oid,marks:this.edittoppermodel.marks,section:this.edittoppermodel.section,year:this.edittoppermodel.year}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatetopperbtn").classList.remove('btn-primary');
            document.getElementById("updatetopperbtn").classList.add('btn-success');
            document.getElementById("updatetopperbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatetopperbtn").classList.remove('btn-success');
            document.getElementById("updatetopperbtn").classList.add('btn-primary');
            document.getElementById("updatetopperbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
          })

        }
        
      },
      deleteresult(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_topper',querystring.stringify({topper_id :row.topper_id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
             })
            })
           }
        });
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
