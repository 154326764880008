<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <!-- <sidebar-item
          :link="{
            name: 'Home',
            icon: 'fas fa-home text-body',
          }"
        >
          <sidebar-item
            :link="{ name: 'Dashboard', path: '/dashboard' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Alternative', path: '/alternative' }"
          ></sidebar-item>
        </sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'Home',
            icon: 'fas fa-home text-body',
            path: '/dashboard'
          }">
        </sidebar-item>
        
        <sidebar-item v-if="checkpermission('/class/allclasses') || checkpermission('/class/subjects') || checkpermission('/class/exams') || checkpermission('/class/question-paper') || checkpermission('/response')" :link="{
                  name: 'Academics',
                  icon: 'fas fa-chalkboard-teacher text-body'
                  }">
          <sidebar-item v-if="checkpermission('/class/allclasses')" :link="{ name: 'Classes', path: '/class/allclasses' }"/>
          <sidebar-item v-if="checkpermission('/sections')" :link="{ name: 'Sections', path: '/sections' }"/>
          <sidebar-item v-if="checkpermission('/subjects')" :link="{ name: 'Subjects', path: '/subjects' }"/>
          <sidebar-item v-if="checkpermission('/exams')" :link="{ name: ' Exam Session', path: '/exams' }"/>
          <sidebar-item v-if="checkpermission('/question-paper')" :link="{ name: 'Question Paper', path: '/question-paper' }"/>
          <sidebar-item v-if="checkpermission('/response')" :link="{ name: 'Response', path: '/response' }"/>
        </sidebar-item>
        <sidebar-item
          v-if="checkpermission('/toppers')"
          :link="{
            name: 'Toppers',
            icon: 'fas fa-trophy text-body',
            path: '/toppers'
          }">
        </sidebar-item>
        <sidebar-item
          v-if="checkpermission('/results')"
          :link="{
            name: 'Results',
            icon: 'fas fa-poll text-body',
            path: '/results'
          }">
        </sidebar-item>
        <sidebar-item
          v-if="checkpermission('/tc')"
          :link="{
            name: 'Transfer Certificates',
            icon: 'fas fa-file-contract text-body',
            path: '/tc'
          }">
        </sidebar-item>

        <sidebar-item v-if="checkpermission('/content/home') || checkpermission('/content/home')" :link="{
                  name: 'Website Content',
                  icon: 'fas fa-quote-left text-body'
                  }">
          <sidebar-item v-if="checkpermission('/content/home')" :link="{ name: 'Homepage', path: '/content/home' }"/>
          <sidebar-item v-if="checkpermission('/content/about')" :link="{ name: 'About', path: '/content/about' }"/>
          <sidebar-item v-if="checkpermission('/content/authoritymessage')" :link="{ name: 'Messages', path: '/content/authoritymessage' }"/>
          <sidebar-item v-if="checkpermission('/content/academics')" :link="{ name: 'Academics', path: '/content/academics' }"/>
          <sidebar-item v-if="checkpermission('/content/beyondacademics')" :link="{ name: 'Beyond Academics', path: '/content/beyondacademics' }"/>
          <sidebar-item v-if="checkpermission('/content/admissions')" :link="{ name: 'Admissions', path: '/content/admissions' }"/>
          <sidebar-item v-if="checkpermission('/content/preprimary')" :link="{ name: 'Pre Primary', path: '/content/preprimary' }"/>
          <sidebar-item v-if="checkpermission('/content/contact')" :link="{ name: 'Contact Page', path: '/content/contact' }"/>
        </sidebar-item>

        <!-- <sidebar-item
          v-if="checkpermission('/students/allstudents')"
          :link="{
            name: 'Students',
            icon: 'fas fa-users text-body',
            path: '/students/allstudents'
          }">
        </sidebar-item> -->

        <sidebar-item
          v-if="checkpermission('/recentnews')"
          :link="{
            name: 'Recent News',
            icon: 'fas fa-newspaper text-body',
            path: '/recentnews'
          }">
        </sidebar-item>
        <sidebar-item
          v-if="checkpermission('/trips/alltrips')"
          :link="{
            name: 'Annual Trips',
            icon: 'fas fa-bus text-body',
            path: '/trips/alltrips'
          }">
        </sidebar-item>
        <sidebar-item
          v-if="checkpermission('/queries')"
          :link="{
            name: 'Queries',
            icon: 'fas fa-life-ring text-body',
            path: '/queries'
          }">
        </sidebar-item>
        <!-- <sidebar-item 
          v-if="checkpermission('/seo/meta') || checkpermission('/seo/productsmeta')"
          :link="{
                  name: 'Meta Details',
                  icon: 'fab fa-google text-body',
                  }">
          <sidebar-item v-if="checkpermission('/seo/meta')" :link="{ name: 'Pages SEO', path: '/seo/meta' }"/>
          <sidebar-item v-if="checkpermission('/seo/productsmeta')" :link="{ name: 'Products SEO', path: '/seo/productsmeta' }"/>
        </sidebar-item> -->
         <!-- <sidebar-item
          :link="{
            name: 'Brands',
            icon: 'fas fa-copyright text-body',
            path: '/brands'
          }">
        </sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'Admission Queries',
            icon: 'fas fa-id-badge text-body',
            path: '/admissionenquiries'
          }">
        </sidebar-item>
        <!-- <sidebar-item :link="{
                  name: 'Referral Center',
                  icon: 'fas fa-crown text-body'
                  }">
          <sidebar-item :link="{ name: 'Reward Settings', path: '/rewardamount' }"/>
          <sidebar-item :link="{ name: 'Reward Transactions', path: '/rewardpoints' }"/>
        </sidebar-item> -->
        <!-- <sidebar-item
          :link="{
            name: 'Notifications',
            icon: 'fas fa-bell text-body',
            path: '/notifications'
          }">
        </sidebar-item> -->

        <sidebar-item
          v-if="checkpermission('/testimonials')"
          :link="{
            name: 'Testimonials',
            icon: 'fas fa-comment-alt text-body',
            path: '/testimonials'
          }">
        </sidebar-item>

        <sidebar-item
          v-if="checkpermission('/headermenu')"
          :link="{
            name: 'Top Menu',
            icon: 'fas fa-bars text-body',
            path: '/headermenu'
          }">
        </sidebar-item>

        <!-- <sidebar-item
          v-if="checkpermission('/settings')"
          :link="{
            name: 'Settings',
            icon: 'fas fa-cogs text-body',
            path: '/settings'
          }">
        </sidebar-item>

        <sidebar-item
          v-if="checkpermission('/kitchen')"
          :link="{
            name: 'Preparing Orders',
            icon: 'fas fa-birthday-cake text-body',
            path: '/kitchen'
          }">
        </sidebar-item> -->
        <sidebar-item v-if="checkpermission('/team/staff') || checkpermission('/team/roles') || checkpermission('/team/permissions')" :link="{
                  name: 'Staff Management',
                  icon: 'fas fa-sitemap text-body',
                  }">
          <sidebar-item v-if="checkpermission('/team/staff')" :link="{ name: 'Staff', path: '/team/staff' }"/>
          <sidebar-item v-if="checkpermission('/team/roles')" :link="{ name: 'Roles', path: '/team/roles' }"/>
          <sidebar-item v-if="checkpermission('/team/permissions')" :link="{ name: 'Permissions', path: '/team/permissions' }"/>
        </sidebar-item>

        <!-- <sidebar-item :link="{
                  name: 'Examples',
                  icon: 'ni ni-ungroup text-body'
                  }">
          <sidebar-item :link="{ name: 'Pricing', path: '/pricing' }"/>
          <sidebar-item :link="{ name: 'Login', path: '/login' }"/>
          <sidebar-item :link="{ name: 'Register', path: '/register' }"/>
          <sidebar-item :link="{ name: 'Lock', path: '/lock' }"/>
          <sidebar-item :link="{ name: 'Timeline', path: '/pages/timeline' }"/>
          <sidebar-item :link="{ name: 'Profile', path: '/pages/user' }"/>


        </sidebar-item>

        <sidebar-item :link="{
                  name: 'Components',
                  icon: 'ni ni-ui-04 text-body'
                }">
          <sidebar-item :link="{ name: 'Buttons', path: '/components/buttons' }"/>
          <sidebar-item :link="{ name: 'Cards', path: '/components/cards' }"/>
          <sidebar-item :link="{ name: 'Grid', path: '/components/grid-system' }"/>
          <sidebar-item :link="{ name: 'Notifications', path: '/components/notifications' }"/>
          <sidebar-item :link="{ name: 'Icons', path: '/components/icons' }"/>
          <sidebar-item :link="{ name: 'Typography', path: '/components/typography' }"/>

          <sidebar-item :link="{ name: 'Multi Level' }">
            <sidebar-item :link="{ name: 'Third level menu', path:'#!' }"/>
            <sidebar-item :link="{ name: 'Just another link', path:'#a' }"/>
            <sidebar-item :link="{ name: 'One last link', path:'#b' }"/>
          </sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{
                        name: 'Forms',
                        icon: 'ni ni-single-copy-04 text-body'
                      }">
          <sidebar-item :link="{ name: '15CB', path: '/forms/15cb' }"/>
          <sidebar-item :link="{ name: 'Components', path: '/forms/components' }"/>
          <sidebar-item :link="{ name: 'Validation', path: '/forms/validation' }"/>
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Tables',
                        icon: 'ni ni-align-left-2 text-body'
                      }">
          <sidebar-item :link="{ name: 'Tables', path: '/tables/regular' }"/>
          <sidebar-item :link="{ name: 'Sortable', path: '/tables/sortable' }"/>
          <sidebar-item :link="{ name: 'Paginated Tables', path: '/tables/paginated' }"/>
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Maps',
                        icon: 'ni ni-map-big text-body'
                      }">
          <sidebar-item :link="{ name: 'Google', path: '/maps/google' }"/>
          <sidebar-item :link="{ name: 'Vector', path: '/maps/vector' }"/>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Widgets',
            icon: 'ni ni-archive-2 text-body',
            path: '/widgets'
          }">
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Charts',
            icon: 'ni ni-chart-pie-35 text-body',
            path: '/charts'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Form History',
            icon: 'ni ni-calendar-grid-58 text-body',
            path: '/formhistory'
          }">
        </sidebar-item> -->
      </template>

      <!-- <template slot="links-after">
        <hr class="my-3">
        <h6 class="navbar-heading p-0 text-body">Documentation</h6>

        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a class="nav-link" href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"
               target="_blank">
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html"
               target="_blank">
              <i class="ni ni-palette"></i>
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html"
               target="_blank">
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/plugin_datepicker.html"
               target="_blank">
              <i class="ni ni-chart-pie-35"></i>
              <span class="nav-link-text">Plugins</span>
            </a>
          </li>
        </ul>
      </template> -->
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        permissions:[],
        ordercount:0,
        interval:null,
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    // created () {
    //   console.log("hi"+localStorage.getItem('bell_notification'));
    //   if(localStorage.getItem('bell_notification')!='0'){
    //   let details={ "details" :{"type":"order","order_status":{$ne:"pending"}},"offset":"0"};
    //     if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
    //           details.details.store_id=localStorage.getItem('store_id');
    //     }
    //   axios
    //     .post(process.env.VUE_APP_API_ENDPOINT+'read_order_count',details,this.auth1)
    //     .then(response => {
    //       // console.log(response);
    //       if(response.data.details.length>0){
    //       this.ordercount=response.data.details[0].COUNT;
    //       }
              
    //     })
    //   this.currentorderData()
    //   }
    // },
    beforeDestroy: function(){
     clearInterval(this.interval);
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      // currentorderData () {
      //   let ref=this;
      //   ref.interval =setInterval(function(){ 
      //             console.log("Api call"); 
      //             // var audio = new Audio('https://bakenetoweb.s3.ap-south-1.amazonaws.com/notification.wav') 
      //             //   audio.play();
      //                ref.checkData();
      //                //clearInterval(ref.interval);
      //         }, 5000);
      // },
      // checkData: function () {
      //   let ref=this;
      //   // console.log("calling");
      //         let details={ "details" :{"type":"order","order_status":{$ne:"pending"}},"offset":"0","count":ref.ordercount};
      //           if(localStorage.getItem('store_id')!='undefined' && localStorage.getItem('store_id')!=''){
      //                 details.details.store_id=localStorage.getItem('store_id');
      //           }
      //         // axios
      //         // .post(process.env.VUE_APP_API_ENDPOINT+'check_order_count',details,ref.auth1)
      //         // .then(response => {
      //         //   // console.log(response);
      //         //   if(response.data.details.neworder){
      //         //     var audio = new Audio('https://bakenetoweb.s3.ap-south-1.amazonaws.com/notification.wav') 
      //         //       audio.play();
      //         //       swal({
      //         //           title:'New order!',
      //         //           text: 'New order has been created.',
      //         //           icon:'success',
      //         //           buttons: {
      //         //             cancel: true,
      //         //             confirm: true,
      //         //           },

      //         //          }).then((result) => {
      //         //           // console.log(result)
      //         //           if (result) {
      //         //           document.location.href = "/";
      //         //           }
      //         //         })
      //         //     // var audio = new Audio(require('https://bakenetoweb.s3.ap-south-1.amazonaws.com/notification.wav')) 
      //         //     // // var audio = new Audio('file.mp3'),
      //         //     // audio.play();
      //         //       // axios
      //         //       //   .post(process.env.VUE_APP_API_ENDPOINT+'read_order_count',details,ref.auth1)
      //         //       //   .then(response => {
      //         //       //     // console.log(response);
      //         //       //     if(response.data.details.length>0){
      //         //       //     ref.ordercount=response.data.details[0].COUNT;
      //         //       //     }
                            
      //         //       //   })
                   
      //         //   }
                    
      //         // })
      //   // $.get('/api/data', function (response) {
      //   //   this.items = response.items;
      //   // }.bind(this));
      // },
      checkpermission(value){
        //console.log(this.permissions);
        let obj = this.permissions.find(o => o.path === value);
        // let obj = this.permissions.find((o, i) => {
        //     if (o.path === value) {
        //          localStorage.getItem('permissions')
        //         this.permissions[i].path = { name: 'new string', value: 'this', other: 'that' };
                
        //         return true; // stop searching
        //      }else{
        //         return false;
        //      }
        //  });
        //console.log(obj);
        if(obj!=undefined){
          localStorage.setItem(obj.path,JSON.stringify(obj))
        }
        return obj;
      }
    },
    mounted() {
      this.initScrollbar()
      this.permissions=JSON.parse(localStorage.getItem('permissions'))
      console.log(this.permissions);
    }
  };
</script>
<style lang="scss">
.sidenav .navbar-brand, .sidenav .navbar-heading {
  padding: 0.5rem !important;
  padding-right: 0px !important;
}
.inline-field .form-group {
  display: inline;  
}
.inline-field .form-group > div {
  display: inline;  
}
.form-fields .form-group {
  display: flex;
  width: 100%;
  margin-bottom: .6rem;
  flex-wrap: wrap;
}
.form-fields .has-label {
  width: 40%;
}
.line-fields .has-label {
  width: 90%;
}
.line-fields .form-group {
  width: 50%;
  float: left;
}
.line-fields .form-control {
  border: none;
  border-bottom: 1px dashed;
  border-radius: 0px;
}

.w-60 {
  width: 60%;
}
.navbar-vertical .navbar-nav .nav-link > i {
    min-width: 2.2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
    padding-left: 3.8rem;
}

/* menu visibility in last table row */
tbody.overflow-x-visible tr:last-child {
  height: 150px;
}

tbody.overflow-x-visible tr:last-child > td, tbody.overflow-x-visible tr:last-child > th {
  vertical-align: top;
}
.border-dashed {
    border: 1px dashed;
}
.cursor-pointer {
  cursor: pointer;
}
.sidenav-header {
    height: auto;
    align-items: start !important;
}
.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
    max-height: 100px;
}
.g-sidenav-pinned .sidenav .navbar-brand {
    margin: auto;
}
.div-shimmer {
  display: inline-flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 15px;
  vertical-align: top;
}
.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 180px; 
  display: inline-block;
  position: relative; 
  
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  }

.box-shimmer {
  height: 150px;
  border-radius: 5px;
}

.circle-shimmer {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.circle-shimmer.icon-shimmer {
    height: 30px;
    width: 30px;
}
span.line-shimmer {
  height: 10px;
  margin-top: 10px;
  width: 200px; 
  border-radius: 5px;
}

span.photo-shimmer {
  display: block!important;
  height: 180px; 
  border-radius: 5px;
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}
.table-responsive {
    display: contents;
}
.gallery .position-relative.col-lg-6:nth-child(odd) {
  padding-right: 0.25rem !important;
}
.gallery .position-relative.col-lg-6:nth-child(even) {
  padding-left: 0.25rem !important;
}
.form-group-nomargin .form-group {
  margin-bottom: 0.5rem;
}
.form-group-0 .form-group {
  margin-bottom: 0rem;
}
.bg-table-header {
  background-color: #f6f9fc;
  color: #8898aa;
  font-size: 0.65rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
/* 5 Columns */

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
    padding: 0.55rem 1.5rem;
}
.btn-round {
  border-radius: 50%;
  padding: 0.25rem 0.30rem;
}
/* gallery css here */
.images-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -10px;
}
.images-wrapper .image-wrapper {
    margin-bottom: 10px;
    width: calc(50% - 10px);
    margin-right: 10px;
}
.images-wrapper .image-wrapper .gallery_image {
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100%;
    border-radius: 5px;
}
.img-100px {
  width: 100px;
}
.img-150px {
  width: 150px;
}
.h-250px {
  height: 250px;
}
@media (min-width: 576px) {
  .col-sm-15 {
    flex: 0 0 20%;
    max-width: 20%;
    float: left;
  }
}
@media (min-width: 768px) {
  .col-md-15 {
    flex: 0 0 20%;
    max-width: 20%;
    float: left;
  }
  .images-wrapper {
    margin-right: -20px;
  }
  .images-wrapper .image-wrapper {
    margin-bottom: 20px;
    width: calc(19.66667% - 20px);
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
    .col-lg-15 {
        flex: 0 0 20%;
      max-width: 20%;
        float: left;
    }
    .sidenav .sidenav-toggler {
      padding: 1.5rem;
      padding-left: 0.8rem;
    }
    .sidenav-header {
      margin-bottom: 10px;
    }
}
</style>
