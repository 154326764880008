import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// HeaderMenu
const HeaderMenu = () => import(/* webpackChunkName: "extra" */ '@/views/HeaderMenu.vue');
// FormHistory
const FormHistory = () => import(/* webpackChunkName: "extra" */ '@/views/FormHistory/FormHistory.vue');
// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/FormHistory/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

//AdmissionEnquiries
const AdmissionEnquiries = () => import(/* webpackChunkName: "dashboard" */ '@/views/AdmissionEnquiries.vue');

// Setings
const Settings = () => import(/* webpackChunkName: "dashboard" */ '@/views/Settings.vue');

// Query and Complaints
const Queries = () => import(/* webpackChunkName: "dashboard" */ '@/views/Queries.vue');
// SEO and Meta Control
const Meta = () => import(/* webpackChunkName: "dashboard" */ '@/views/Seo/Meta.vue');
const Productsmeta = () => import(/* webpackChunkName: "dashboard" */ '@/views/Seo/Products.vue');
const Editproductmeta = () => import(/* webpackChunkName: "dashboard" */ '@/views/Seo/EditProductMeta.vue');

// Kitchen Control
const Kitchen = () => import(/* webpackChunkName: "dashboard" */ '@/views/Kitchen.vue');

// Team Control
const Staff = () => import(/* webpackChunkName: "dashboard" */ '@/views/Team/Staff.vue');
const Roles = () => import(/* webpackChunkName: "components" */ '@/views/Team/Roles.vue');
const Permissions = () => import(/* webpackChunkName: "components" */ '@/views/Team/Permissions.vue');

// Content
const HomeContent = () => import(/* webpackChunkName: "dashboard" */ '@/views/Content/Home.vue');
const About = () => import(/* webpackChunkName: "components" */ '@/views/Content/About.vue');
const Academics = () => import(/* webpackChunkName: "components" */ '@/views/Content/Academics.vue');
const BeyondAcademics = () => import(/* webpackChunkName: "components" */ '@/views/Content/BeyondAcademics.vue');
const Admissions = () => import(/* webpackChunkName: "components" */ '@/views/Content/Admissions.vue');
const PrePrimary = () => import(/* webpackChunkName: "components" */ '@/views/Content/PrePrimary.vue');
const AuthorityMessage = () => import(/* webpackChunkName: "components" */ '@/views/Content/AuthorityMessage.vue');
const ContactContent = () => import(/* webpackChunkName: "components" */ '@/views/Content/ContactContent.vue');


// Notifications
const Notifications = () => import(/* webpackChunkName: "dashboard" */ '@/views/Notifications.vue');

// RecentNews
const RecentNews = () => import(/* webpackChunkName: "dashboard" */ '@/views/RecentNews.vue');

// Testimonials
const Testimonials = () => import(/* webpackChunkName: "dashboard" */ '@/views/Testimonials.vue');

// Banners
const Banners = () => import(/* webpackChunkName: "dashboard" */ '@/views/Banners.vue');

// Subjects
const Subjects = () => import(/* webpackChunkName: "dashboard" */ '@/views/Subjects.vue');

// Sections
const Sections = () => import(/* webpackChunkName: "dashboard" */ '@/views/Sections.vue');

// Exams
const Exams = () => import(/* webpackChunkName: "dashboard" */ '@/views/Exams.vue');

// Questionpaper
const Questionpaper = () => import(/* webpackChunkName: "dashboard" */ '@/views/Questionpaper.vue');

// Response
const Response = () => import(/* webpackChunkName: "dashboard" */ '@/views/Response.vue');

// Tc
const Tc = () => import(/* webpackChunkName: "dashboard" */ '@/views/Tc.vue');

// Results
const Results = () => import(/* webpackChunkName: "dashboard" */ '@/views/Results.vue');

// Toppers
const Toppers = () => import(/* webpackChunkName: "dashboard" */ '@/views/Toppers.vue');

// Trips
const Trips = () => import(/* webpackChunkName: "dashboard" */ '@/views/Trips/AllTrips.vue');
const TripDetails = () => import(/* webpackChunkName: "dashboard" */ '@/views/Trips/TripDetails.vue');

// Rewardpoints
const Rewardpoints = () => import(/* webpackChunkName: "dashboard" */ '@/views/Rewardpoints.vue');
// Rewardpoints
const Rewardamount = () => import(/* webpackChunkName: "dashboard" */ '@/views/Rewardamount.vue');

// Students
const Students = () => import(/* webpackChunkName: "dashboard" */ '@/views/Students/AllStudents.vue');
const ViewStudent = () => import(/* webpackChunkName: "dashboard" */ '@/views/Students/ViewStudent.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const NotificationSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const Dashboard2 = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard2.vue');
const OldDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/OldDashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Classes pages
const AllClasses = () => import(/* webpackChunkName: "components" */ '@/views/Class/AllClasses.vue');
const ClassDetails= () => import(/* webpackChunkName: "components" */ '@/views/Class/ClassDetails.vue');
// const NeedApproval = () => import(/* webpackChunkName: "components" */ '@/views/Captions/NeedApproval.vue');
// const CaptionCategories = () => import( webpackChunkName: "components"  '@/views/Captions/Categories.vue');
// const Subcategories = () => import(/* webpackChunkName: "components" */ '@/views/Captions/Subcategories.vue');

// product pages
const AllProducts = () => import(/* webpackChunkName: "components" */ '@/views/Products/AllProducts.vue');
const Product = () => import(/* webpackChunkName: "components" */ '@/views/Products/Product.vue');
const AddProduct = () => import(/* webpackChunkName: "components" */ '@/views/Products/AddProduct.vue');
const EditProduct = () => import(/* webpackChunkName: "components" */ '@/views/Products/EditProduct.vue');
const ProductCategories = () => import(/* webpackChunkName: "components" */ '@/views/Products/Categories.vue');
const CategoryBanner = () => import(/* webpackChunkName: "components" */ '@/views/Products/CategoryBanner.vue');
const Productsubcats = () => import(/* webpackChunkName: "components" */ '@/views/Products/SubCategories.vue');
const Productvariations = () => import(/* webpackChunkName: "components" */ '@/views/Products/Variations.vue');
const Brands = () => import(/* webpackChunkName: "dashboard" */ '@/views/Products/Brand.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const Form15CB = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/15cb.vue');
const Form15CBView = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/15cbform.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const Profile = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Profile.vue');
const Assets = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Assets.vue');
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Forgot = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Forgot.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');
let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'NotificationSystem',
      component: NotificationSystem
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};
// let formsMenu = {
//   path: '/forms',
//   component: DashboardLayout,
//   redirect: '/forms/elements',
//   name: 'Forms',
//   children: [
//     {
//       path: 'elements',
//       name: 'Form elements',
//       component:  FormElements
//     },
//     {
//       path: '15cb',
//       name: '15cb',
//       component:  Form15CB
//     },
//     {
//       path: 'form15cbview',
//       name: '15cb Form',
//       component:  Form15CBView
//     },
//     {
//       path: 'components',
//       name: 'Form components',
//       component:  FormComponents
//     },
//     {
//       path: 'validation',
//       name: 'Form validation',
//       component:  FormValidation
//     }
//   ]
// };

let classMenu = {
  path: '/class',
  component: DashboardLayout,
  redirect: '/class/allclasses',
  name: 'Classes',
  children: [
    {
      path: 'allclasses',
      name: 'All Classes',
      component:  AllClasses
      // beforeEnter: requireAuth,
      //   meta: {
      //     permission: 'user'
      // }
    },
    {
      path: 'classdetails/:id/:name',
      name: 'Class Details',
      component:  ClassDetails
    },
    // {
    //   path: 'categories',
    //   name: 'Caption Categories',
    //   component:  CaptionCategories
    // },
    // {
    //   path: 'subcategories/:id/:name',
    //   name: 'Subcategories',
    //   component:  Subcategories
    // }
  ]
};

let tripMenu = {
  path: '/trips',
  component: DashboardLayout,
  redirect: '/trips/alltrips',
  name: 'Trips',
  children: [
    {
      path: 'alltrips',
      name: 'All Trips',
      component:  Trips
      // beforeEnter: requireAuth,
      //   meta: {
      //     permission: 'user'
      // }
    },
    {
      path: 'tripdetails/:id',
      name: 'Trip Details',
      component:  TripDetails
    },
  ]
};

let contentMenu = {
  path: '/content',
  component: DashboardLayout,
  redirect: '/content/home',
  name: 'Content',
  children: [
    {
      path: 'home',
      name: 'Homepage Content',
      component:  HomeContent
      // beforeEnter: requireAuth,
      //   meta: {
      //     permission: 'user'
      // }
    },
    {
      path: 'about',
      name: 'About',
      component:  About
    },
    {
      path: 'academics',
      name: 'Academics',
      component:  Academics
    },
    {
      path: 'beyondacademics',
      name: 'BeyondAcademics',
      component:  BeyondAcademics
    },
    {
      path: 'admissions',
      name: 'Admissions',
      component:  Admissions
    },
    {
      path: 'preprimary',
      name: 'Pre Primary',
      component:  PrePrimary
    },
    {
      path: 'authoritymessage',
      name: 'Authority Message',
      component:  AuthorityMessage
    },
    {
      path: 'contact',
      name: 'Content Page Contact',
      component:  ContactContent
    },
  ]
};


let teamMenu = {
  path: '/team',
  component: DashboardLayout,
  redirect: '/team/staff',
  name: 'Team',
  children: [
    {
      path: 'staff',
      name: 'All Staff',
      component:  Staff
    },
    {
      path: 'roles',
      name: 'Roles',
      component:  Roles
    },
    {
      path: 'permissions',
      name: 'Permissions',
      component:  Permissions
    }
  ]
};

let seoMenu = {
  path: '/Seo',
  component: DashboardLayout,
  redirect: '/seo/meta',
  name: 'Seo',
  children: [
    {
      path: 'meta',
      name: 'Meta',
      component:  Meta
    },
    {
      path: 'productsmeta',
      name: 'Admission Enquiries',
      component:  Productsmeta
    },
    {
      path: 'editproductmeta/:id',
      name: 'Edit Product Meta',
      component:  Editproductmeta
    },
  ]
};

let productsMenu = {
  path: '/products',
  component: DashboardLayout,
  redirect: '/products/allproducts',
  name: 'Products',
  children: [
    {
      path: 'allproducts',
      name: 'All Products',
      component:  AllProducts
    },
    {
      path: 'addproduct',
      name: 'Add Product',
      component:  AddProduct
    },
    {
      path: 'editproduct/:id',
      name: 'Edit Product',
      component:  EditProduct
    },
    {
      path: '/products/categories',
      name: 'Product Categories',
      component:  ProductCategories
    },
    {
      path: '/products/categorybanner/:id',
      name: 'Trips',
      component:  CategoryBanner
    },
    {
      path: '/products/subcategories/:id/:name',
      name: 'Product Sub-Categories',
      component:  Productsubcats
    },
    {
      path: '/products/variation/:id/:name',
      name: 'Product Variations',
      component:  Productvariations
    },
    {
      path: '/products/product/:id',
      name: 'Product',
      component:  Product
    },
    {
      path: '/products/brands',
      name: 'Brands',
      component: Brands
    },
  ]
};

let usersMenu = {
  path: '/students',
  component: DashboardLayout,
  redirect: '/students/allstudents',
  name: 'Users',
  children: [
    {
      path: '/students/allstudents',
      name: 'Students',
      component:  Students
    },
    {
      path: '/students/viewstudent/:id',
      name: 'Student Details',
      component:  ViewStudent
    }
    // {
    //   path: '/products/product',
    //   name: 'Product',
    //   component:  Product
    // }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'profile',
      name: 'Profile',
      component: Profile
    },
     {
      path: 'assets',
      name: 'Assets',
      component: Assets
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: Forgot
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Dashboard'
  },
  componentsMenu,
  // formsMenu,
  classMenu,
  tripMenu,
  productsMenu,
  usersMenu,
  tablesMenu,
  mapsMenu,
  teamMenu,
  pagesMenu,
  seoMenu,
  contentMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'olddashboard',
        name: 'OldDashboard',
        component: OldDashboard
      },
      {
        path: 'dashboard2',
        name: 'Dashboard2',
        component: Dashboard2
      },
      {
        path: 'alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'formhistory',
        name: 'Form History',
        component: FormHistory
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings
      },
      {
        path: 'queries',
        name: 'Queries',
        component: Queries
      },
      {
        path: 'kitchen',
        name: 'Kitchen',
        component: Kitchen
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: 'recentnews',
        name: 'RecentNews',
        component: RecentNews
      },
      {
        path: 'subjects',
        name: 'Subjects',
        component: Subjects
      },
      {
        path: 'sections',
        name: 'Sections',
        component: Sections
      },
      {
        path: 'exams',
        name: 'Exams',
        component: Exams
      },
      {
        path: 'question-paper',
        name: 'Questionpaper',
        component: Questionpaper
      },
      {
        path: 'response',
        name: 'Response',
        component: Response
      },
      {
        path: 'tc',
        name: 'Tc',
        component: Tc
      },
      {
        path: 'results',
        name: 'Results',
        component: Results
      },
      {
        path: 'toppers',
        name: 'Toppers',
        component: Toppers
      },
      {
        path: 'testimonials',
        name: 'Testimonials',
        component: Testimonials
      },
      {
        path: 'banners',
        name: 'Trips',
        component: Banners
      },
      {
        path: 'rewardpoints',
        name: 'Rewardpoints',
        component: Rewardpoints
      },
      {
        path: 'rewardamount',
        name: 'Reward amount',
        component: Rewardamount
      },
      {
        path: 'admissionenquiries',
        name: 'Admission Enquiries',
        component: AdmissionEnquiries
      },
      {
        path: 'headermenu',
        name: 'Top Menu',
        component: HeaderMenu
      },
      
    ]
  },
  authPages,
  // {
  //     path: '*',
  //     redirect: '/login'
  // }
];

export default routes;
