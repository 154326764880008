<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col">
          <div class="card shadow mb-3" :class="''">
            <div class="p-3 border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Trips
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button v-if="pagepermission.create" type="primary" @click="addmetapopup"><i class="fas fa-plus"></i> Add new trip</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <!-- Basic with list group -->
          <div class="card" v-for="trips in tripData" :key="trips._id">
            <!-- List group -->
            <ul class="list-group list-group-flush px-2">
              <li class="list-group-item h2">{{trips.trip_name}} - {{trips.year}}</li>
            </ul>
            <!-- Card body -->
            <div class="card-body pb-3 pt-0">
              <p class="card-text mb-4">{{trips.trip_description}}</p>
              <base-button v-if="pagepermission.update" type="primary" size="sm" @click="editmetapopup(trips)" >Edit</base-button>
              <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deletemeta(trips)">Delete</base-button>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-lg-12 col-12 mb-3">
                  <template>
                    <div :id="'app'+index">
                      <CoolLightBox 
                        :items="trips.trip_images" 
                        :index="index"
                        @close="index = null">
                      </CoolLightBox>

                      <div class="images-wrapper row mx-0">
                        <div class="image-wrapper position-relative" v-for="(image, imageIndex) in trips.trip_images" :key="imageIndex">
                          <div
                            class="gallery_image"
                            @click="index = imageIndex"
                            :style="{ backgroundImage: 'url(' + image + ')' }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-md-12 px-4">
                  <!-- <form>
                    <div class="custom-file">
                        <base-input type="file" inputClasses="custom-file-input" id="customFileLang" lang="en"></base-input>
                        <label class="custom-file-label" for="customFileLang">Upload photos</label>
                    </div>
                  </form> -->
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-12 mb-3">
                  <template>
                    <div :id="'app'+index">
                      <div class="row mx-0">
                        <div class="image-wrapper position-relative col-md-4" v-for="(video, imageIndex) in trips.trip_videos" :key="imageIndex">
                          <!-- <div
                            class="gallery_image"
                            @click="index = imageIndex"
                            :style="{ backgroundImage: 'url(' + video + ')' }"
                          ></div> -->
                          <video width="320" height="240" controls class="img-fluid">
                            <source :src="video" type="video/mp4">
                            <!-- <source src="movie.ogg" type="video/ogg"> -->
                          </video>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-md-12 px-4">
                  <!-- <form>
                    <div class="custom-file">
                        <base-input type="file" inputClasses="custom-file-input" id="customFileLang" lang="en"></base-input>
                        <label class="custom-file-label" for="customFileLang">Upload photos</label>
                    </div>
                  </form> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- shimmer starts here -->
        <div class="col-md-12" v-if="tripData.length==0 && !datanotfound">
          <div class="row justify-content-center">
            <div class="col-lg-12 mt-3">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card border-0 shadow">
                   <div class="card-body pt-2 pb-1">
                        <div class="row">
                            <div class="col-md-3 col-3 mt-1 mb-1">
                                <span class="circle-shimmer shine"></span>
                            </div>
                            <div class="col-md-9 col-9 mt-1 mb-1">
                                <div class="div-shimmer w-100 mt-0 pr-4">
                                  <span class="line-shimmer w-100 shine"></span>
                                  <span class="line-shimmer w-100 shine"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- shimmer ends here -->
        <!-- no result found -->
        <div class="col-lg-12">
          <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
            <div class="col-md-3 mx-auto mt-4 text-center">
                <img src="/img/theme/no-result.png" class="img-fluid">
                <p class="mt-4">No trips added yet.</p>
                <base-button v-if="pagepermission.create" outline type="primary" @click="addmetapopup"><i class="fas fa-plus"></i> Add first trip</base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- create meta popup -->
    <modal :show.sync="addmeta" size="lg" bodyClasses="pt-0">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create new trip</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-12">
           
              <label class="form-control-label">Select Year<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control mb-3" autocomplete="off" v-model="modal.year">
                <option >2014</option>
                <option >2015</option>
                <option >2016</option>
                <option >2017</option>
                <option >2018</option>
                <option >2019</option>
                <option >2020</option>
                <option >2021</option>
                <option >2022</option>
                <option >2023</option>
                <option >2024</option>
                <option >2025</option>
              </select>
              <label class="form-control-label">Trip name<span class="text-danger">*</span></label>
              <base-input placeholder="Trip name" v-model="modal.trip_name" input-classes="" />
            </div>
            <div class="col-md-12">
              <label class="form-control-label">Trip Description<span class="text-danger">*</span></label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="6" v-model="modal.trip_description"></textarea>
            </div>
            <div class="col-lg-12 mt-2">
              <label class="form-control-label">Images</label>
              <input
                type="file"
                ref="file"
                accept="image/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'add','file')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-md-4 col-6 position-relative mt-3" v-for="(image,index) in modal.trip_images" :key="image">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletefile(index,'add')"><i class="fas fa-trash"></i></base-button>
                  <img :src="image" class="img-fluid rounded shadow p-1 border">
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-2">
              <label class="form-control-label">Video</label>
              <input
                type="file"
                ref="filev"
                accept="video/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'addvideo','filev')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-md-4 col-6 position-relative mt-3" v-for="(video,index) in modal.          trip_videos" :key="video">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletevideofile(index,'addvideo')"><i class="fas fa-trash"></i></base-button>
                    <video width="320" height="240" controls class="img-fluid">
                      <source :src="video" type="video/mp4">
                      <!-- <source src="movie.ogg" type="video/ogg"> -->
                    </video>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createtrip()"
          type="success"
          id="createtripbtn"
          class="float-center"
        >
          <span>Save Trip</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addmeta = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit trip popup -->
    <modal :show.sync="editmeta" size="lg" bodyClasses="pt-2">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit trip details</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-12">
           
              <label class="form-control-label">Select Year<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control mb-3" autocomplete="off" v-model="editmodal.year">
                <option >2014</option>
                <option >2015</option>
                <option >2016</option>
                <option >2017</option>
                <option >2018</option>
                <option >2019</option>
                <option >2020</option>
                <option >2021</option>
                <option >2022</option>
                <option >2023</option>
                <option >2024</option>
                <option >2025</option>
              </select>
              <label class="form-control-label">Trip name<span class="text-danger">*</span></label>
              <base-input label="Trip name" placeholder="Page name" v-model="editmodal.trip_name" input-classes="" />
            </div>
            <div class="col-md-12">
              <label class="form-control-label">Trip Description<span class="text-danger">*</span></label>
              <textarea class="form-control" id="exampleFormControlTextarea3" rows="6" v-model="editmodal.trip_description"></textarea>
            </div>
            <div class="col-lg-12 mt-2">
              <label class="form-control-label">Images</label>
              <input
                type="file"
                ref="fileb"
                accept="image/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'edit','fileb')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-md-4 col-6 position-relative mt-3" v-for="(image,index) in editmodal.trip_images" :key="image">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletefile(index,'edit')"><i class="fas fa-trash"></i></base-button>
                  <img :src="image" class="img-fluid rounded shadow p-1 border">
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-2">
              <label class="form-control-label">Video</label>
              <input
                type="file"
                ref="fileev"
                accept="video/*" 
                placeholder="image"
                class="form-control form-control-alternative"
                multiple="multiple"
                @change="handlemultipleimage($event,'editvideo','fileev')"
              />
              <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
              <div class="row">
                <div class="col-md-4 col-6 position-relative mt-3" v-for="(video,index) in editmodal.          trip_videos" :key="video">
                  <base-button type="danger" size="sm" class="position-absolute right-0 mr-1 mt--2 btn-round" @click="deletevideofile(index,'editvideo')"><i class="fas fa-trash"></i></base-button>
                    <video width="320" height="240" controls>
                      <source :src="video" type="video/mp4" class="img-fluid">
                      <!-- <source src="movie.ogg" type="video/ogg"> -->
                    </video>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatetrip()"
          type="success"
          id="updatetripbtn"
          class="float-center"
        >
          <span>Save Trip</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editmeta = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  import CoolLightBox from 'vue-cool-lightbox';
  import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';
  import moment from 'moment';
  // import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    bodyClass: "trips",
    components: {
      CoolLightBox,
      // ActivityFeed,
      // Tabs,
      // LineChart,
      // BarChart,
      BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addmeta: false,
        datanotfound:false,
        editmeta: false,
        uploadPercentage:0,
        multipleuploadPercentage:0,
        pagepermission:{},
        tripData:[],
        storeData:[],
        regionData:[],
        modal:{
          trip_id :'',
          year :'',
          trip_name :'',
          trip_description: '',
          trip_images :[],
          trip_videos :[],
          status :'approved',
        },
        editmodal:{
          year :'',
          trip_name :'',
          trip_description: '',
          trip_images :[],
          trip_videos :[],
          status :'approved',
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        items: [
        '/img/theme/team-1.jpg',
        '/img/theme/team-2.jpg',
        '/img/theme/team-3.jpg',
        '/img/theme/team-4.jpg',
        '/img/theme/team-5.jpg',
        '/img/theme/team-1.jpg',
        ],
        index: null
      };
    },
    validations: {
     modal: {    
        year:{
          required
        },
        trip_name:{
          required
        },
        trip_description:{
          required
        },
      },
      editmodal: {
        year:{
          required
        },
        trip_name:{
          required
        },
        trip_description:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_trip',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.tripData=response.data.details;
        }else{
            this.tripData=[];
            this.datanotfound=true;
        }
            
      })
      // axios
      // .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      // .then(response => {
      //   console.log(response);
      //   if(response.data.details){
      //   this.storeData=response.data.details;
      //   }
            
      // })
      // axios
      // .post(process.env.VUE_APP_API_ENDPOINT+'read_region',{ "details" :{}},this.auth1)
      // .then(response => {
      //   console.log(response);
      //   if(response.data.details){
      //   this.regionData=response.data.details;
      //   }
            
      // })
    },
    methods: {

      async handlemultipleimage(event,type,uploatype) {
            // Reference to the DOM input element

              // document.getElementById("assignmentbtn").classList.add('d-none');
              // document.getElementById("addassignmentinvalid").classList.remove('d-none');
              // console.log(event);
              this.multipleuploadPercentage=0;
              if(uploatype=='file'){
                for( var i = 0; i < this.$refs.file.files.length; i++ ){
                    let file = this.$refs.file.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='fileb'){
                for( var i = 0; i < this.$refs.fileb.files.length; i++ ){
                    let file = this.$refs.fileb.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='filev'){
                for( var i = 0; i < this.$refs.filev.files.length; i++ ){
                    let file = this.$refs.filev.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              if(uploatype=='fileev'){
                for( var i = 0; i < this.$refs.fileev.files.length; i++ ){
                    let file = this.$refs.fileev.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }

      },
      upload(file,type){
        // console.log(file);
        let fd = new FormData();
        fd.append('image',file,file.name);
        axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.multipleuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.multipleuploadPercentage)}.bind(this)}).then(response => {
          // console.log(response);
          if(type=='add'){
            this.modal.trip_images.push(response.data.imageUrl);
          }
          if(type=='edit'){
          this.editmodal.trip_images.push(response.data.imageUrl);

          }
          if(type=='addvideo'){
            this.modal.trip_videos.push(response.data.imageUrl);
          }
          if(type=='editvideo'){
          this.editmodal.trip_videos.push(response.data.imageUrl);

          }
          // console.log(this.imagearr);
          
       })

      },

      addmetapopup(row){
        console.log(row);
        this.addmeta=true;
      },
      editmetapopup(row){
        this.editmodal=row;
        console.log(this.editmodal);
        this.editmeta=true;
        // }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addmeta'){
                this.metamodal.og_image=response.data.imageUrl;
                console.log(this.metamodal.og_image);
              } else if(type=='editmeta'){
                this.editmetamodal.og_image=response.data.imageUrl;
                console.log(this.editmetamodal.og_image);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
              }).then((result) => {
               
              })
            }
      },
      createtrip(){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_trip',querystring.stringify({year : this.modal.year,trip_name : this.modal.trip_name,trip_description : this.modal.trip_description,trip_images : this.modal.trip_images,trip_videos : this.modal.trip_videos,status :this.modal.status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createtripbtn").classList.remove('btn-primary');
            document.getElementById("createtripbtn").classList.add('btn-success');
            document.getElementById("createtripbtn").innerHTML='Updated';
            setTimeout(function(){
              document.getElementById("createtripbtn").classList.remove('btn-success');
              document.getElementById("createtripbtn").classList.add('btn-primary');
              document.getElementById("createtripbtn").innerHTML="Update";
              window.location.reload()
            }, 2000);
          })

      },
      updatetrip(){
        const querystring = require('querystring');
        axios.post(process.env.VUE_APP_API_ENDPOINT+'update_trip',querystring.stringify({trip_id : this.editmodal.trip_id,year : this.editmodal.year,trip_name : this.editmodal.trip_name,trip_description : this.editmodal.trip_description,trip_images : this.editmodal.trip_images,trip_videos : this.editmodal.trip_videos,status : this.editmodal.status}),this.auth)
        .then(response => {
          console.log(response);
          this.message=response.data.message;
          document.getElementById("updatetripbtn").classList.remove('btn-primary');
          document.getElementById("updatetripbtn").classList.add('btn-success');
          document.getElementById("updatetripbtn").innerHTML='Updated';
          setTimeout(function(){
            document.getElementById("updatetripbtn").classList.remove('btn-success');
            document.getElementById("updatetripbtn").classList.add('btn-primary');
            document.getElementById("updatetripbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
        })
      },
      deletemeta(row) {
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_trip',querystring.stringify({trip_id :row.trip_id}),this.auth)
            .then(response => {
              console.log(response);
              swal({
                title:'Deleted!',
                text: 'Your file has been deleted.',
                icon:'success',
               }).then((result) => {
                console.log(result)
                window.location.reload()
              })
              })
             }
          });
      },
      format_datetime(value){
        if(value) {
          return moment.utc(new Date(value)).format("LLL")
        }
      },
      deletefile(index,type){
          if(type=='add'){
            this.modal.trip_images.splice(index,1);
          }
          if(type=='edit'){
            this.editmodal.trip_images.splice(index,1);
          }
      },
      deletevideofile(index,type){
        console.log(this.modal.trip_videos);
          if(type=='addvideo'){
            this.modal.trip_videos.splice(index,1);
          }
          if(type=='editvideo'){
            this.editmodal.trip_videos.splice(index,1);
          }
      },
      deleteogimage(index){
        this.metamodal.og_image='';
        this.uploadPercentage =0;
      },
    },
    
  };
</script>
<style>
.search-input .form-group {
  display: inline-block;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .images-wrapper .image-wrapper {
    width: calc(16.66667% - 20px);
  }
}
</style>
