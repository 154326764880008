<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col-md-3">
                  <h3 class="mb-0" :class="''">
                    Responses
                  </h3>
                </div>
                <div class="col-md-9 text-right">
                  <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectclass" @change="getfilterdata()">
                    <option value="">Select class</option>
                    <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
                  </select>
                  <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectexam" @change="getfilterdata()">
                    <option value="">Select exam session</option>
                    <option v-for="exam in examData" :key="exam.exam_id" :value="exam._id">{{exam.exam_name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <vue-easy-lightbox
                :visible="visible"
                :imgs="imgs"
                :index="index"
                @hide="handleHide"
            ></vue-easy-lightbox>
            <div class="table-responsive">
              <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="responseData">
                <template slot="columns">
                  <th>S. No.</th>
                  <th>Student name</th>
                  <th>Answer Sheets</th>
                  <th>Exam</th>
                  <th>class</th>
                  <th class="text-right">Actions</th>
                </template>
                <template slot-scope="{row,index}">
                    <td class="budget">
                      <p class="mb-0">{{index+1}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0" v-if="row.student_oid">{{row.student_oid.user_name}} ({{row.student_oid.admission_no}})</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0 font-weight-400 description" v-if="row.response_body">
                        <!-- <img v-for="image,index in row.response_body" :key="image" :src="image" class="img-fluid rounded border " style="width: 70px;cursor: pointer;" @click="showImg(index,image)"> -->
                        <a v-for="image,index in row.response_body" :key="image" :href="image" download="">Download file, </a>
                      </p>
                    </td>
                    <td class="budget">
                      <p class="mb-0" v-if="row.exam_oid">{{row.exam_oid.exam_name}}</p>
                    </td>
                    <td class="budget">
                      <p class="mb-0" v-if="row.class_oid">{{row.class_oid.class_name}}</p>
                    </td>
                    <td class="text-right">
                      <!-- <base-button type="primary" size="sm" @click="editresponsepopup(row)" >Edit</base-button> -->
                      <base-button type="danger" size="sm" @click="deleteresponse(row)">Delete</base-button>
                      <!-- <router-link class="btn btn-primary btn-sm" to="/seo/meta" icon="fa fa-info">View</router-link> -->
                    </td>
                </template>
              </base-table>

              <!-- no data found here -->
              <div class="card-footer justify-content-center" v-if="datanotfound">
                <div class="col-md-3 mx-auto mt-4 text-center">
                    <img src="/img/theme/no-result.png" class="img-fluid">
                    <p class="mt-4">No response created.</p>
                </div>
              </div>
              <!-- shimmer starts here -->
              <div class="col-md-12" v-if="responseData.length==0 && !datanotfound">
                <div class="row justify-content-center">
                  <div class="col-lg-12 mt-3">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="card border-0 shadow">
                         <div class="card-body pt-2 pb-1">
                              <div class="row">
                                  <div class="col-md-3 col-3 mt-1 mb-1">
                                      <span class="circle-shimmer shine"></span>
                                  </div>
                                  <div class="col-md-9 col-9 mt-1 mb-1">
                                      <div class="div-shimmer w-100 mt-0 pr-4">
                                        <span class="line-shimmer w-100 shine"></span>
                                        <span class="line-shimmer w-100 shine"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <!-- shimmer ends here -->
            </div>
            <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
              <base-pagination :total="30"></base-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- add baner -->
    <modal :show.sync="addresponse">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create response</span>
      </p>

      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">response_body<span class="text-danger">*</span></label>
              <base-input placeholder="response name" v-model="responsemodal.response_body" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.responsemodal.response_body.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="responsemodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.responsemodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">exam_oid<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="responsemodal.exam_oid">
                <option value="">Select exam</option>
                <option v-for="exam in examData" :key="exam.exam_id" :value="exam._id">{{exam.exam_name}}</option>
              </select>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">subject_oid<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="responsemodal.subject_oid">
                <option value="">Select subject</option>
                <option v-for="subject in subjectData" :key="subject.subject_id" :value="subject._id">{{subject.subject_name}}</option>
              </select>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">student_oid<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="responsemodal.student_oid">
                <option value="">Select student</option>
                <option v-for="student in studentData" :key="student.user_id" :value="student._id">{{student.user_name}}</option>
              </select>
              <div
                  v-if="$v.responsemodal.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="responsemodal.response_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.responsemodal.response_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
         :disabled="$v.responsemodal.$error" @click="$v.responsemodal.$touch();createresponse()"
          type="success"
          id="createresponsebtn"
          class="float-center"
        >
          <span>Save response</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addresponse = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit banner popup -->
    <modal :show.sync="editresponse">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit response</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row mt--3">
            <div class="col-lg-12">
              <label class="form-control-label">response_body<span class="text-danger">*</span></label>
              <base-input placeholder="response name" v-model="editresponsemodal.response_body" input-classes="" class="form-group-mb0" />
              <div
                  v-if="$v.editresponsemodal.response_body.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Class<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresponsemodal.class_oid">
                <option value="">Select class</option>
                <option v-for="classes in classesData" :key="classes.class_id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.editresponsemodal.class_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">exam_oid<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresponsemodal.exam_oid">
                <option value="">Select exam</option>
                <option v-for="exam in examData" :key="exam.exam_id" :value="exam._id">{{exam.exam_name}}</option>
              </select>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">subject_oid<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresponsemodal.subject_oid">
                <option value="">Select subject</option>
                <option v-for="subject in subjectData" :key="subject.subject_id" :value="subject._id">{{subject.subject_name}}</option>
              </select>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">student_oid<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresponsemodal.student_oid">
                <option value="">Select student</option>
                <option v-for="student in studentData" :key="student.user_id" :value="student._id">{{student.user_name}}</option>
              </select>
              <div
                  v-if="$v.editresponsemodal.student_oid.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">Status<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="editresponsemodal.response_status">
                <option value="approved">approved</option>
                <option value="disapproved">disapproved</option>
              </select>
              <div
                  v-if="$v.editresponsemodal.response_status.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editresponsemodal.$error" @click="$v.editresponsemodal.$touch();updateresponse()"
          type="success"
          id="updateresponsebtn"
          class="float-center"
        >
          <span>Update response</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editresponse = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { ModelListSelect } from 'vue-search-select'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import 'vue-search-select/dist/VueSearchSelect.css'
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import VueEasyLightbox from 'vue-easy-lightbox'

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      VueEasyLightbox,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
// cayegory
// subcategory
// variation
// item
// offer
      DropzoneFileUpload,
      ModelListSelect,
    },
    data() {
      return {
        addresponse: false,
        editresponse: false,
        datanotfound:false,
        imgs: '',  // Img Url , string or Array of string
        visible: false,
        index:0,
        selectclass:'',
        selectexam:'',
        uploadPercentage:0,
        selectstore:'',
        pagepermission:{},
        responseData:[],
        studentData:[],
        classesData:[],
        subjectData:[],
        examData:[],
        responsemodal:{
          response_id :'',
          response_body : '',
          class_oid : '',
          exam_oid : '',
          subject_oid : '',
          student_oid : '',
          response_status : ''
        },
        editresponsemodal:{
          response_id : '',
          response_body : '',
          class_oid : '',
          exam_oid : '',
          subject_oid : '',
          student_oid : '',
          response_status : ''
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {

      responsemodal: {
        response_body:{
          required
        },
        class_oid:{
          required
        },
        student_oid:{
          required
        },
        response_status:{
          required
        },
        
      },
      editresponsemodal: {
        response_body:{
          required
        },
        class_oid:{
          required
        },
        student_oid:{
          required
        },
        response_status:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_response',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.success){
        this.responseData=response.data.details;
        this.datanotfound=false;
        console.log(this.datanotfound);
        }else{
          this.responseData=[];
          this.datanotfound=true;
        } 
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_student',{ "details" :{"user_type":'student'}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.studentData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.classesData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_subject',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.subjectData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_exam',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.examData=response.data.details;
        }
            
      })
      
    },
    methods: {
      getfilterdata(){
        let details={ "details" :{}};
        if(this.selectclass){
          details.details.class_oid=this.selectclass;
        }
        if(this.selectexam){
          details.details.exam_oid=this.selectexam;
        }

        axios
        .post(process.env.VUE_APP_API_ENDPOINT+'read_response',details,this.auth1)
        .then(response => {
          console.log(response);
          if(response.data.success){
          this.responseData=response.data.details;
          this.datanotfound=false;
          }else{
            this.responseData=[];
            this.datanotfound=true;
          }
              
        })

      },
      handleHide() {
        this.visible = false
      },
      showImg (index,imgs) {
        this.index = index;
        this.imgs = imgs;
        this.visible = true
      },
      addresponsepopup(row){
        console.log(row);
        this.addresponse=true;
      },
      editresponsepopup(row){
          console.log(row);
          this.editresponsemodal.response_id =row.response_id;
          this.editresponsemodal.response_body =row.response_body;
          if(row.class_oid){
          this.editresponsemodal.class_oid =row.class_oid._id;
          }
          if(row.exam_oid){
          this.editresponsemodal.exam_oid =row.exam_oid._id;
          }
          if(row.subject_oid){
          this.editresponsemodal.subject_oid =row.subject_oid._id;
          }
          if(row.student_oid){
          this.editresponsemodal.student_oid =row.student_oid._id;
          }
          //this.editresponsemodal.student_oid =row.student_oid;
          this.editresponsemodal.response_status=row.response_status;
          this.editresponse=true;
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='add'){
                this.responsemodal.response_image=response.data.imageUrl;
                console.log(this.responsemodal.response_image);
              }if(type=='edit'){
                this.editresponsemodal.response_image=response.data.imageUrl;
                console.log(this.responsemodal.response_image);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    createresponse(){
        if(this.$v.responsemodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_response',querystring.stringify({response_body : this.responsemodal.response_body,class_oid:this.responsemodal.class_oid,exam_oid:this.responsemodal.exam_oid,subject_oid:this.responsemodal.subject_oid,student_oid:this.responsemodal.student_oid,response_status:this.responsemodal.response_status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createresponsebtn").classList.remove('btn-primary');
            document.getElementById("createresponsebtn").classList.add('btn-success');
            document.getElementById("createresponsebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createresponsebtn").classList.remove('btn-success');
            document.getElementById("createresponsebtn").classList.add('btn-primary');
            document.getElementById("createresponsebtn").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })
        }
        
      },
      updateresponse(){
        if(this.$v.editresponsemodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_response',querystring.stringify({response_id : this.editresponsemodal.response_id,response_body : this.editresponsemodal.response_body,class_oid:this.editresponsemodal.class_oid,exam_oid:this.editresponsemodal.exam_oid,subject_oid:this.editresponsemodal.subject_oid,student_oid:this.editresponsemodal.student_oid,response_status:this.editresponsemodal.response_status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateresponsebtn").classList.remove('btn-primary');
            document.getElementById("updateresponsebtn").classList.add('btn-success');
            document.getElementById("updateresponsebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateresponsebtn").classList.remove('btn-success');
            document.getElementById("updateresponsebtn").classList.add('btn-primary');
            document.getElementById("updateresponsebtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
          })

        }
        
      },
      deleteresponse(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_response',querystring.stringify({response_id :row.response_id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },
      getstore_name(val){
        const search = this.storeData.find(element => element.store_id === val);
        if(search){
          return search.store_name;
        }
      },
    },
    
  };
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
