<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Manage content of Admissions Page
                  </h3>
                </div>
                <div class="col text-right"></div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Admission Criterias</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <label class="form-control-label">Overview Heading</label>
                      <textarea
                        class="form-control"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.overview_heading"
                      ></textarea>
                      <label class="form-control-label">Images</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event, 'overview_image')"
                      />
                      <base-progress
                        type="success"
                        v-model="uploadPercentage"
                        label="File uploaded"
                      ></base-progress>
                      <div v-if="model.overview_image">
                        <img
                          :src="model.overview_image"
                          class="img-fluid rounded shadow mt-2 card-body"
                        />
                      </div>
                      <base-input
                        label="Image text"
                        v-model="model.overview_image_text"
                        placeholder="Image text"
                        input-classes=""
                      />
                      <label class="form-control-label"
                        >Admission Heading</label
                      >
                      <textarea
                        class="form-control"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.admission_heading"
                      ></textarea>

                      <label class="form-control-label">Age Criteria</label>
                      <textarea
                        class="form-control"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.age_criteria"
                      ></textarea>
                      <label class="form-control-label mt-3"
                        >Academic Criteria</label
                      >
                      <textarea
                        class="form-control"
                        rows="5"
                        placeholder="Short description"
                        v-model="model.academic_criteria"
                      ></textarea>
                      <label class="form-control-label mt-3"
                        >Credentials Required</label
                      >
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="model.credential_criteria"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Procedure</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <label class="form-control-label">Description</label>
                      <textarea
                        class="form-control"
                        rows="5"
                        placeholder="Add details"
                        v-model="model.procedure_description"
                      ></textarea>
                      <label class="form-control-label mt-3"
                        >Domestic Payment Details</label
                      >
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="model.domestic_payments"
                      />
                      <label class="form-control-label mt-3"
                        >Foreign Payment Details</label
                      >
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="model.foreign_payments"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">Fee Structure</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-8">
                      <base-input
                        label="Title"
                        v-model="model.fee_structure.title"
                        placeholder="Title"
                        input-classes=""
                      />
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="model.fee_structure.description"
                      />
                    </div>
                    <div class="col-lg-4">
                      <label class="form-control-label">Picture</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event, 'fee_image')"
                      />
                      <base-progress
                        type="success"
                        v-model="uploadPercentage"
                        label="File uploaded"
                      ></base-progress>
                      <div v-if="model.fee_structure.image">
                        <img
                          :src="model.fee_structure.image"
                          class="img-fluid rounded shadow mt-2 card-body"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">FAQs</div>
                <div class="card-body py-2">
                  <div class="row">
                    <div
                      class="col-lg-12 mb-3 bg-secondary py-3 rounded"
                      v-for="(faq, index) in model.faq"
                      :key="index"
                    >
                      <base-button
                        type="danger"
                        size="sm"
                        class="position-absolute right-0 mr-1 mt--2 btn-round"
                        @click="deletefaq(index)"
                        ><i class="fas fa-trash"></i
                      ></base-button>
                      <base-input
                        v-model="faq.question"
                        placeholder="question"
                        input-classes="mb--3"
                      />
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea3"
                        rows="4"
                        v-model="faq.answer"
                      ></textarea>
                    </div>
                    <base-button
                      @click="addfaq()"
                      id="addfaq"
                      type="primary"
                      class="float-center mt-2 mx-1 mb-3"
                      block
                      outline
                    >
                      <i class="fas fa-plus"></i> Add new faq
                    </base-button>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="row">
                  <div class="col-lg-12">
                    <base-button
                      @click="savecontent()"
                      id="savecontent"
                      type="primary"
                      class="float-center"
                      block
                      size="lg"
                    >
                      <span>Save Content</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- create board member popup -->
    <modal :show.sync="addboardmember" size="lg">
      <p id="modal-title-default" slot="header" class="modal-title">
        <span>Create new board member</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input
                label="Board member name"
                placeholder="Board member name"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <base-input
                label="Designation"
                placeholder="Designation"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Board Member Message</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="10"
              ></textarea>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Picture</label>
              <input
                id="file"
                ref="myFiles"
                type="file"
                aria-describedby="addon-right addon-left"
                alternative=""
                class="form-control form-control-alternative"
                name="img"
                @change="hadleimage($event, 'addboardmember')"
              />
              <base-progress
                type="success"
                v-model="uploadPercentage"
                label="File uploaded"
              ></base-progress>
              <div class="position-relative">
                <base-button
                  type="danger"
                  size="sm"
                  class="position-absolute btn-icon-only"
                  @click="deleteogimage(boardmembermodal)"
                  style="
                    right: 0px;
                    top: -5px;
                    font-size: x-small;
                    width: 1.3rem;
                    height: 1.3rem;
                  "
                  round
                >
                  <i class="fas fa-times"></i>
                </base-button>
                <img
                  src="boardmembermodal.og_image"
                  style="max-width: 100px"
                  class="img-fluid rounded shadow mt-2 card-body"
                />
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="createboardmember()"
          type="success"
          id="createboardmemberbtn"
          class="float-center"
        >
          <span>Create board member</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addboardmember = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- edit board member popup -->
    <modal :show.sync="editboardmember" size="lg">
      <p id="modal-title-default" slot="header" class="modal-title">
        <span>Edit board member</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <base-input
                label="Board member name"
                placeholder="Board member name"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <base-input
                label="Board member designation"
                placeholder="Board member designation"
                input-classes=""
              />
            </div>
            <div class="col-md-6">
              <label class="form-control-label">Board member message</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea3"
                rows="10"
              ></textarea>
            </div>
            <div class="col-lg-6 mt-2">
              <label class="form-control-label">Picture</label>
              <input
                id="file"
                ref="myFiles"
                type="file"
                aria-describedby="addon-right addon-left"
                alternative=""
                class="form-control form-control-alternative"
                name="img"
                @change="hadleimage($event, 'editboardmember')"
              />
              <base-progress
                type="success"
                v-model="uploadPercentage"
                label="File uploaded"
              ></base-progress>
              <div class="position-relative">
                <base-button
                  type="danger"
                  size="sm"
                  class="position-absolute btn-icon-only"
                  @click="deleteogimage(editboardmembermodal)"
                  style="
                    right: 0px;
                    top: -5px;
                    font-size: x-small;
                    width: 1.3rem;
                    height: 1.3rem;
                  "
                  round
                >
                  <i class="fas fa-times"></i>
                </base-button>
                <img
                  src="editboardmembermodal.og_image"
                  style="max-width: 100px"
                  class="img-fluid rounded shadow mt-2 card-body"
                />
              </div>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updateoffer()"
          type="success"
          id="updateestorebtn"
          class="float-center"
        >
          <span>Save</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editboardmember = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

import { Tabs } from "@/components";
import { BasePagination } from "@/components/Tabs/Tabs";

// Components
import BaseProgress from "@/components/BaseProgress";
// import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import StatsCard from "@/components/Cards/StatsCard";
import { required, minLength, between } from "vuelidate/lib/validators";
import VueSwal from "vue-swal";
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    // ActivityFeed,
    Tabs,
    LineChart,
    BarChart,
    BaseProgress,
    StatsCard,
    VueEditor,
    // TaskList,
    // PageVisitsTable,
    // SocialTrafficTable,
    // LightTable,
    // UserList,
    // ProgressTrackList
  },
  data() {
    return {
      addboardmember: false,
      datanotfound: false,
      editboardmember: false,
      addstore: false,
      editstore: false,
      addregion: false,
      editregion: false,
      addarea: false,
      editarea: false,
      datanotfound: false,
      uploadPercentage: 0,
      pagepermission: {},
      storeData: [],
      regionData: [],
      areaData: [],
      allregionData: [],
      selectstore: "",
      selectpin: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"],
      ],
      model: {
        admission_id: "",
        age_criteria: "",
        academic_criteria: "",
        credential_criteria: "",
        procedure_description: "",
        domestic_payments: "",
        foreign_payments: "",
        fee_structure: { ttile: "", image: "", description: "" },
        faq: [
          { question: "", answer: "" },
          { question: "", answer: "" },
        ],
      },
      radios: {
        radio1: "checked",
        radio2: "checked",
      },
      auth: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
      auth1: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "caption#" + localStorage.getItem("accessToken"),
          _id: localStorage.getItem("_id"),
        },
      },
    };
  },
  validations: {
    // model: {
    //   store_name:{
    //     required
    //   },
    //   store_img:{
    //     required
    //   },
    //   shipping_charge:{
    //     required
    //   },
    // }
  },
  mounted() {
    axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "read_admission",
        { details: {} },
        this.auth1
      )
      .then((response) => {
        console.log(response);
        if (response.data.details) {
          this.model = response.data.details[0];
        }
      });
  },
  methods: {
    hadleimage: function (event, type) {
      // Reference to the DOM input element
      this.uploadPercentage = 0;
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files[0].size < 5000000) {
        const fd = new FormData();
        fd.append("image", input.files[0], input.files[0].name);
        axios
          .post(process.env.VUE_APP_API_ENDPOINT + "image-upload", fd, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              console.log(this.uploadPercentage);
            }.bind(this),
          })
          .then((response) => {
            console.log(response);
            if (type == "fee_image") {
              this.model.fee_structure.image = response.data.imageUrl;
              console.log(this.model.hero_banner);
            }
            if (type == "image_1") {
              this.model.image_1 = response.data.imageUrl;
              console.log(this.model.image_1);
            }
            if (type == "image_2") {
              this.model.image_2 = response.data.imageUrl;
              console.log(this.model.image_2);
            }
            if (type == "block_1_icon") {
              this.model.block_1_icon = response.data.imageUrl;
              console.log(this.model.block_1_icon);
            }
            if (type == "block_2_icon") {
              this.model.block_2_icon = response.data.imageUrl;
              console.log(this.model.block_2_icon);
            }
            if (type == "block_3_icon") {
              this.model.block_3_icon = response.data.imageUrl;
              console.log(this.model.block_3_icon);
            }

            if (type == "overview_image") {
              this.model.overview_image = response.data.imageUrl;
              console.log(this.model.overview_image);
            }
            //else{
            //   this.storemodal.store_img=response.data.imageUrl;
            //   console.log(this.storemodal.store_img);
            // }
          });
      } else {
        swal({
          title: "File too large!",
          text: "Your file is larger than 5 MB. Please upload images which are less than 5 MB.",
          icon: "warning",
        }).then((result) => {});
      }
    },
    savecontent() {
      const querystring = require("querystring");
      if (!this.model.admission_id) {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "create_admission",
            this.model,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savecontent")
              .classList.remove("btn-primary");
            document.getElementById("savecontent").classList.add("btn-success");
            document.getElementById("savecontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savecontent")
                .classList.remove("btn-success");
              document
                .getElementById("savecontent")
                .classList.add("btn-primary");
              document.getElementById("savecontent").innerHTML = "Update";
              //window.location.reload()
            }, 2000);
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "update_admission",
            this.model,
            this.auth1
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("savecontent")
              .classList.remove("btn-primary");
            document.getElementById("savecontent").classList.add("btn-success");
            document.getElementById("savecontent").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("savecontent")
                .classList.remove("btn-success");
              document
                .getElementById("savecontent")
                .classList.add("btn-primary");
              document.getElementById("savecontent").innerHTML = "Update";
              //window.location.reload()
            }, 2000);
          });
      }
    },
    updateestore() {
      if (this.$v.editstoremodal.$error === false) {
        const querystring = require("querystring");
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "update_store_details",
            querystring.stringify({
              store_id: this.editstoremodal.store_id,
              store_name: this.editstoremodal.store_name,
              store_img: this.editstoremodal.store_img,
              store_status: this.editstoremodal.store_status,
              created_by: this.editstoremodal.created_by,
              shipping_charge: this.editstoremodal.shipping_charge,
              store_code: this.editstoremodal.store_code,
            }),
            this.auth
          )
          .then((response) => {
            console.log(response);
            this.message = response.data.message;
            document
              .getElementById("createstorebtn")
              .classList.remove("btn-primary");
            document
              .getElementById("createstorebtn")
              .classList.add("btn-success");
            document.getElementById("createstorebtn").innerHTML = "Updated";
            setTimeout(function () {
              document
                .getElementById("createstorebtn")
                .classList.remove("btn-success");
              document
                .getElementById("createstorebtn")
                .classList.add("btn-primary");
              document.getElementById("createstorebtn").innerHTML = "Update";
            }, 2000);

            window.location.reload();
          });
      }
    },
    getstore_name(val) {
      const search = this.storeData.find((element) => element.store_id === val);
      if (search) {
        return search.store_name;
      }
    },
    getfilterdata() {
      localStorage.setItem("selectstore", this.selectstore);
      let details = { details: {} };
      if (this.selectstore) {
        details.details.region_store = this.selectstore;
      }

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "read_region",
          details,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          if (response.data.details) {
            this.regionData = response.data.details;
          } else {
            this.regionData = [];
          }
        });
      let adetails = { details: {} };
      if (this.selectstore) {
        adetails.details.area_store = this.selectstore;
      }
      if (this.selectpin) {
        adetails.details.area_region = this.selectpin;
      }
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "read_area",
          adetails,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          if (response.data.details) {
            this.areaData = response.data.details;
          } else {
            this.areaData = [];
          }
        });
    },
    addboardmemberpopup(row) {
      console.log(row);
      this.addboardmember = true;
    },
    addfaq() {
      this.model.faq.push({ question: "", answer: "" });
    },
    editboardmemberpopup(row) {
      this.editboardmembermodal = row;
      console.log(this.editboardmembermodal);
      this.editboardmember = true;
      // }
    },
    deletefaq(index) {
      this.model.faq.splice(index, 1);
    },
  },
};
</script>
<style>
.search-input .form-group {
  display: inline-block;
  margin-bottom: 0px;
}
</style>
