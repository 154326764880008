<template>
  <div>
    <div class="container-fluid mt-3">
      <tabs tabNavClasses="flex-column flex-sm-row nav-wrapper"
        tabContentClasses="">
        <tab-pane id="all">
          <span slot="title">
              Stores
          </span>
            
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Stores where products are available
                      </h3>
                    </div>
                    <div class="col text-right">
                      <base-button v-if="pagepermission.create" type="primary" @click="addstorepopup"><i class="fas fa-plus"></i> Create store</base-button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="storeData">
                    <template slot="columns">
                      <th>S. No.</th>
                      <th>Store Name</th>
                      <th>Store Code</th>
                      <th>Shipping Charges</th>
                      <th>Thumnbail</th>
                      <th class="text-right">Actions</th>
                    </template>
                    <template slot-scope="{row,index}">
                        <td class="budget">
                          <p class="mb-0">{{index+1}}</p>
                        </td>
                        <td class="budget" >
                          <p class="mb-0">{{row.store_name}}</p>
                        </td>
                        <td class="budget" >
                          <p class="mb-0">{{row.store_code}}</p>
                        </td>
                        <td class="budget" >
                          <p class="mb-0">{{row.shipping_charge}}</p>
                        </td>
                        <td class="budget">
                          <img alt="Image placeholder" class="rounded" :src="row.store_img" width="100">
                        </td>
                        <td class="text-right">
                          <base-button v-if="pagepermission.update" type="primary" size="sm" @click="editstorepopup(row)" >Edit</base-button>
                          <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deletestore(row)">Delete</base-button>
                          <a class="btn btn-primary btn-sm" href="#" icon="fa fa-info">View</a>
                        </td>
                    </template>
                  </base-table>
                  <!-- no result found -->
                  <div class="card-footer justify-content-center" id="noresult" v-if="datanotfound">
                    <div class="col-md-3 mx-auto mt-4 text-center">
                        <img src="/img/theme/no-result.png" class="img-fluid">
                        <p class="mt-4">No stores created yet.</p>
                    </div>
                  </div>

                  <!-- shimmer starts here -->
                  <div class="col-md-12" v-if="storeData.length==0 && !datanotfound">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 mt-3">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="card border-0 shadow">
                             <div class="card-body pt-2 pb-1">
                                  <div class="row">
                                      <div class="col-md-3 col-3 mt-1 mb-1">
                                          <span class="circle-shimmer shine"></span>
                                      </div>
                                      <div class="col-md-9 col-9 mt-1 mb-1">
                                          <div class="div-shimmer w-100 mt-0 pr-4">
                                            <span class="line-shimmer w-100 shine"></span>
                                            <span class="line-shimmer w-100 shine"></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <!-- shimmer ends here -->
                </div>
                <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
                  <base-pagination :total="30"></base-pagination>
                </div> -->
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane id="addcategory">
          <span slot="title">
              Pincode
          </span>
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Pincodes where products are available
                      </h3>
                    </div>
                    <div class="col text-right">
                      <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectstore" @change="getfilterdata()">
                        <option value="">Select Store</option>
                        <option v-for="store in storeData" :key="store.store_id" :value="store.store_id">{{store.store_name}}</option>
                      </select>
                      <base-button v-if="pagepermission.create" type="primary" @click="addregionpopup"><i class="fas fa-plus"></i> Add region</base-button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="regionData">
                    <template slot="columns">
                      <th>S. No.</th>
                      <th>Pincode</th>
                      <th>Store</th>
                      <th class="text-right">Actions</th>
                    </template>
                    <template slot-scope="{row,index}">
                        <td class="budget">
                          <p class="mb-0">{{index+1}}</p>
                        </td>
                        <td class="budget" >
                          <p class="mb-0">{{row.region_name}}</p>
                        </td>
                        <td class="budget">
                            <span><i class="fas fa-map-marker-alt mr-2"></i> {{getstore_name(row.region_store)}}</span>
                        </td>
                        <td class="text-right">
                          <base-button v-if="pagepermission.update" type="primary" size="sm" @click="editregionpopup(row)">Edit</base-button>
                          <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deleteregion(row)">Delete</base-button>
                          <a class="btn btn-primary btn-sm" href="#" icon="fa fa-info">View</a>
                        </td>
                    </template>
                  </base-table>
                </div>
                <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
                  <base-pagination :total="30"></base-pagination>
                </div> -->
              </div>
            </div>
          </div>
        </tab-pane>
        <tab-pane id="addcategory1">
          <span slot="title">
              Area
          </span>
          <div class="row mb-5">
            <div class="col">
              <div class="card shadow" :class="''">
                <div class="card-header border-0" :class="''">
                  <div class="row align-items-center">
                    <div class="col">
                      <h3 class="mb-0" :class="''">
                        Pincodes where products are available
                      </h3>
                    </div>
                    <div class="col text-right">
                      <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectstore" @change="getfilterdata()">
                        <option value="">Select Store</option>
                        <option v-for="store in storeData" :key="store.store_id" :value="store.store_id">{{store.store_name}}</option>
                      </select>
                      <select  aria-describedby="addon-right addon-left" class="form-control w-auto mr-2 d-inline" autocomplete="off" v-model="selectpin" @change="getfilterdata()">
                        <option value="">Select Pincode</option>
                        <option v-for="pincode in regionData" :key="pincode.region_id" :value="pincode._id">{{pincode.region_name}}</option>
                      </select>
                      <base-button v-if="pagepermission.create" type="primary" @click="addareapopup"><i class="fas fa-plus"></i> Add area</base-button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <base-table class="table align-items-center table-flush" :class="''" :thead-classes="'thead-light'" tbody-classes="list" :data="areaData">
                    <template slot="columns">
                      <th>S. No.</th>
                      <th>Area</th>
                      <th>Pincode</th>
                      <th>Store</th>
                      <th class="text-right">Actions</th>
                    </template>
                    <template slot-scope="{row,index}">
                        <td class="budget">
                          <p class="mb-0">{{index+1}}</p>
                        </td>
                        <td class="budget" >
                          <p class="mb-0">{{row.area_name}}</p>
                        </td>
                        <td class="budget">
                            <span>{{row.area_region.region_name}}</span>
                        </td>
                        <td class="budget">
                            <span><i class="fas fa-map-marker-alt mr-2"></i> {{getstore_name(row.area_store)}}</span>
                        </td>
                        <td class="text-right">
                          <base-button v-if="pagepermission.update" type="primary" size="sm" @click="editareapopup(row)">Edit</base-button>
                          <base-button v-if="pagepermission.delete" type="danger" size="sm" @click="deletearea(row)">Delete</base-button>
                          <a class="btn btn-primary btn-sm" href="#" icon="fa fa-info">View</a>
                        </td>
                    </template>
                  </base-table>
                </div>
                <!-- <div class="card-footer d-flex justify-content-end"  :class="type === 'dark' ? 'bg-transparent': ''">
                  <base-pagination :total="30"></base-pagination>
                </div> -->
              </div>
            </div>
          </div>
        </tab-pane>
      </tabs>
    </div>
    <!-- add store popup -->
    <modal :show.sync="addstore">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Create store</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Store Name<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, Delhi" v-model="storemodal.store_name" input-classes="" />
              <div
                  v-if="$v.storemodal.store_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
             </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Store Code<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, DEL" v-model="storemodal.store_code" input-classes="" />
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Store Thumbnail<span class="text-danger">*</span></label>
              <input
                id="file"
                ref="myFiles"
                type="file"
                aria-describedby="addon-right addon-left"
                alternative=""
                class="form-control form-control-alternative"
                name="img"
                @change="hadleimage($event,'addstore')"
              >
              <div
                  v-if="$v.storemodal.store_img.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
              <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
              <div>
                <img :src="storemodal.store_img" class="img-fluid rounded shadow mt-2 card-body">
              </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Shipping Charge<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, 40" v-model="storemodal.shipping_charge" input-classes="" />
              <div
                  v-if="$v.storemodal.shipping_charge.$error"
                  class="error h5 text-danger mt--3 pl-1"
                >
                  Field is required.
             </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status<span class="text-danger">*</span></label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="storemodal.status">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.storemodal.$error" @click="$v.storemodal.$touch();createstore()"
          type="success"
          id="createstorebtn"
          class="float-center"
        >
          <span>Add store</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addstore = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit store popup -->
    <modal :show.sync="editstore">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit store</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Store Name<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, Delhi" v-model="editstoremodal.store_name" input-classes="" />
              <div
                  v-if="$v.editstoremodal.store_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
             </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Store Code<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, DEL" v-model="editstoremodal.store_code" input-classes="" />
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">Store Thumbnail<span class="text-danger">*</span></label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editstore')"
                >
                <div
                    v-if="$v.editstoremodal.store_img.$error"
                    class="error h5 text-danger mt-1 pl-1"
                  >
                    Field is required.
               </div>
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="editstoremodal.store_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Shipping Charge<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, 40" v-model="editstoremodal.shipping_charge" input-classes="" />
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status<span class="text-danger">*</span></label>
                <select  aria-describedby="addon-right addon-left" class="form-control " v-model="editstoremodal.store_status" autocomplete="off">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editstoremodal.$error" @click="$v.editstoremodal.$touch();updateestore()"
          type="success"
          id="updateestorebtn"
          class="float-center"
        >
          <span>Update store</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editstore = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- add region popup -->
    <modal :show.sync="addregion">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Add region</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Add code<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, 110075" v-model="regionmodal.region_name" input-classes="" />
              <div
                  v-if="$v.regionmodal.region_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Region Type<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" v-model="regionmodal.region_type" autocomplete="off">
                    <option>PostCode</option>
                    <option>Geography</option>
              </select>
              <div
                  v-if="$v.regionmodal.region_type.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Store</label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="regionmodal.region_store" autocomplete="off">
                    <option v-for="store in storeData" :key="store._id" :value="store.store_id">{{store.store_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
               <div
                  v-if="$v.regionmodal.region_store.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="regionmodal.region_status" autocomplete="off">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.regionmodal.$error" @click="$v.regionmodal.$touch();createregion()"
          type="success"
          id="createregionbtn"
          class="float-center"
        >
          <span>Add Region</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addregion = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit region popup -->
    <modal :show.sync="editregion">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Region</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Add code<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, 110075" v-model="editregionmodal.region_name" input-classes="" />
              <div
                  v-if="$v.editregionmodal.region_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
             </div>
            </div>
            <div class="col-lg-6">
                <label class="form-control-label">Image</label>
                <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'editregion')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div>
                  <img :src="editregionmodal.region_img" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Region Type</label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="editregionmodal.region_type" autocomplete="off">
                    <option>PostCode</option>
                    <option>Geography</option>
               </select>
               <div
                  v-if="$v.editregionmodal.region_type.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Store</label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="editregionmodal.region_store" autocomplete="off">
                    <option v-for="store in storeData" :key="store._id" :value="store.store_id">{{store.store_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
               <div
                  v-if="$v.editregionmodal.region_store.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="editregionmodal.region_status" autocomplete="off">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editregionmodal.$error" @click="$v.editregionmodal.$touch();updateregion()"
          type="success"
          id="updateregionbtn"
          class="float-center"
        >
          <span>Update Region</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editregion = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>

    <!-- add area popup -->
    <modal :show.sync="addarea">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Add area</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Add area<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, Sector 62" v-model="areamodal.area_name" input-classes="" />
              <div
                  v-if="$v.areamodal.area_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Store<span class="text-danger">*</span></label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="areamodal.area_store" autocomplete="off" @change="getregionbystore('add')">
                    <option v-for="store in storeData" :key="store._id" :value="store.store_id">{{store.store_name}}</option>
                    <!-- <option>disapprove</option> -->
               </select>
               <div
                  v-if="$v.areamodal.area_store.$error"
                  class="error h5 text-danger mt-0 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Region<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" v-model="areamodal.area_region" autocomplete="off">
                <option v-for="region in allregionData" :key="region._id" :value="region._id">{{region.region_name}}</option>
              </select>
              <div
                  v-if="$v.areamodal.area_region.$error"
                  class="error h5 text-danger mt-0 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="areamodal.area_status" autocomplete="off">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.areamodal.$error" @click="$v.areamodal.$touch();createarea()"
          type="success"
          id="createareabtn"
          class="float-center"
        >
          <span>Add area</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="addarea = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
    <!-- edit area popup -->
    <modal :show.sync="editarea">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit area</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-6">
              <label class="form-control-label">Add area<span class="text-danger">*</span></label>
              <base-input placeholder="E.g, Sector 62" v-model="editareamodal.area_name" input-classes="" />
              <div
                  v-if="$v.editareamodal.area_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
             </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Store<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" v-model="editareamodal.area_store" autocomplete="off" @change="getregionbystore('edit')">
                    <option v-for="store in storeData" :key="store._id" :value="store.store_id">{{store.store_name}}</option>
              </select>
              <div
                  v-if="$v.editareamodal.area_store.$error"
                  class="error h5 text-danger mt-0 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6">
              <label class="form-control-label">Region<span class="text-danger">*</span></label>
              <select  aria-describedby="addon-right addon-left" class="form-control" v-model="editareamodal.area_region" autocomplete="off">
                <option v-for="region in allregionData" :key="region._id" :value="region._id">{{region.region_name}}</option>
              </select>
              <div
                  v-if="$v.editareamodal.area_region.$error"
                  class="error h5 text-danger mt-0 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-6">
               <label class="form-control-label">Status</label>
                <select  aria-describedby="addon-right addon-left" class="form-control" v-model="editareamodal.area_status" autocomplete="off">
                    <option>approved</option>
                    <option>disapprove</option>
               </select>
            </div>
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          :disabled="$v.editareamodal.$error" @click="$v.editareamodal.$touch();updatearea()"
          type="success"
          id="updateareabtn"
          class="float-center"
        >
          <span>Update area</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editarea = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";

  export default {
    components: {
      // ActivityFeed,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addstore: false,
        editstore: false,
        addregion: false,
        editregion: false,
        addarea: false,
        editarea: false,
        datanotfound:false,
        uploadPercentage:0,
        pagepermission:{},
        storeData:[],
        regionData:[],
        areaData:[],
        allregionData :[],
        selectstore:'',
        selectpin:'',
        storemodal:{
          store_name:'',
          store_img:'',
          store_code:'',
          store_status:'approved',
          shipping_charge:0,
          created_by:localStorage.getItem('_id'),
        },
        editstoremodal:{
          store_name:'',
          store_img:'',
          store_code:'',
          store_status:'approved',
          shipping_charge:0,
          created_by:localStorage.getItem('_id'),
        },
        regionmodal:{
          region_name:'',
          region_img:'',
          region_store:'',
          region_type:'PostCode',
          region_status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        editregionmodal:{
          region_name:'',
          region_img:'',
          region_store:'',
          region_type:'PostCode',
          region_status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        areamodal:{
          area_name:'',
          area_img:'',
          area_store:'',
          area_region:'',
          area_type:'PostCode',
          area_status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        editareamodal:{
          area_name:'',
          area_img:'',
          area_store:'',
          area_region:'',
          area_type:'PostCode',
          area_status:'approved',
          created_by:localStorage.getItem('_id'),
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      storemodal: {
        store_name:{
          required
        },
        store_img:{
          required
        },
        shipping_charge:{
          required
        },
      },
      editstoremodal: {
        store_name:{
          required
        },
        store_img:{
          required
        },
        shipping_charge:{
          required
        },
      },
      regionmodal: {
        region_name:{
          required
        },
        region_type:{
          required
        },
        region_store:{
          required
        },
      },
      editregionmodal: {
        region_name:{
          required
        },
        region_type:{
          required
        },
        region_store:{
          required
        },
      },
      areamodal: {
        area_name:{
          required
        },
        area_store:{
          required
        },
        area_region:{
          required
        },
      },
      editareamodal: {
        area_name:{
          required
        },
        area_store:{
          required
        },
        area_region:{
          required
        },
      },

    },
    mounted() {
      this.pagepermission=JSON.parse(localStorage.getItem(this.$router.currentRoute.path));
      console.log(this.pagepermission);
      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        this.regionmodal.region_store=this.storeData[0].store_id;
        }else{
            this.storeData=[];
            this.datanotfound=true;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_region',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.regionData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_area',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.areaData=response.data.details;
        }
            
      })
    },
    methods: {

      addstorepopup(row){
        console.log(row);
        this.addstore=true;
      },
      editstorepopup(row){
        console.log(row);
        this.editstoremodal=row;
        this.editstore=true;
      },
      addregionpopup(){
        this.addregion=true;
      },
      editregionpopup(row){
        this.editregionmodal=row;
        this.editregion=true;
      },
      addareapopup(){
        this.addarea=true;
      },
      editareapopup(row){
        console.log(row);
        this.editareamodal=row;
        if(this.editareamodal.area_region){
          this.editareamodal.area_region=this.editareamodal.area_region._id;
        }
        axios
          .post(process.env.VUE_APP_API_ENDPOINT+'read_region',{ "details" :{"region_store":this.editareamodal.area_store}},this.auth1)
          .then(response => {
            console.log(response);
            if(response.data.details){
            this.allregionData=response.data.details;
            }else{
              this.allregionData=[];
            }
                
          })
        this.editarea=true;
      },
      getregionbystore(type){
        let region_store='';
        if(type=='add'){
         region_store=this.areamodal.area_store;
        }else if(type=='edit'){
         region_store=this.editareamodal.area_store;
        }

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_region',{ "details" :{"region_store":region_store}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.allregionData=response.data.details;
        }
            
      })

      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addstore'){
                this.storemodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }if(type=='editstore'){
                this.editstoremodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }else{
                this.storemodal.store_img=response.data.imageUrl;
                console.log(this.storemodal.store_img);
              }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }

    },
    createstore(){
        if(this.$v.storemodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_store',querystring.stringify({store_name : this.storemodal.store_name,store_img:this.storemodal.store_img,store_status:this.storemodal.store_status,created_by:this.storemodal.created_by,shipping_charge:this.storemodal.shipping_charge,store_code:this.storemodal.store_code}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
      },
      updateestore(){
        if(this.$v.editstoremodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_store_details',querystring.stringify({store_id : this.editstoremodal.store_id,store_name : this.editstoremodal.store_name,store_img:this.editstoremodal.store_img,store_status:this.editstoremodal.store_status,created_by:this.editstoremodal.created_by,shipping_charge:this.editstoremodal.shipping_charge,store_code: this.editstoremodal.store_code}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createstorebtn").classList.remove('btn-primary');
            document.getElementById("createstorebtn").classList.add('btn-success');
            document.getElementById("createstorebtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createstorebtn").classList.remove('btn-success');
            document.getElementById("createstorebtn").classList.add('btn-primary');
            document.getElementById("createstorebtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
      },
      createregion(){
        if(this.$v.regionmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_region',querystring.stringify({region_name : this.regionmodal.region_name,region_img:this.regionmodal.region_img,region_store:this.regionmodal.region_store,region_status:this.regionmodal.region_status,region_type:this.regionmodal.region_type,created_by:this.regionmodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createregionbtn").classList.remove('btn-primary');
            document.getElementById("createregionbtn").classList.add('btn-success');
            document.getElementById("createregionbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createregionbtn").classList.remove('btn-success');
            document.getElementById("createregionbtn").classList.add('btn-primary');
            document.getElementById("createregionbtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
        
      },
      updateregion(){
        if(this.$v.editregionmodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_region_details',querystring.stringify({region_id : this.editregionmodal.region_id,region_name : this.editregionmodal.region_name,region_img:this.editregionmodal.region_img,region_store:this.editregionmodal.region_store,region_status:this.editregionmodal.region_status,region_type:this.editregionmodal.region_type,created_by:this.editregionmodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateregionbtn").classList.remove('btn-primary');
            document.getElementById("updateregionbtn").classList.add('btn-success');
            document.getElementById("updateregionbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateregionbtn").classList.remove('btn-success');
            document.getElementById("updateregionbtn").classList.add('btn-primary');
            document.getElementById("updateregionbtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
        
      },
      createarea(){
        if(this.$v.areamodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_area',querystring.stringify({area_name : this.areamodal.area_name,area_img:this.areamodal.area_img,area_store:this.areamodal.area_store,area_status:this.areamodal.area_status,area_region:this.areamodal.area_region,created_by:this.areamodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("createareabtn").classList.remove('btn-primary');
            document.getElementById("createareabtn").classList.add('btn-success');
            document.getElementById("createareabtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("createareabtn").classList.remove('btn-success');
            document.getElementById("createareabtn").classList.add('btn-primary');
            document.getElementById("createareabtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
        
      },
      updatearea(){
        if(this.$v.editareamodal.$error===false){
        const querystring = require('querystring');
           axios.post(process.env.VUE_APP_API_ENDPOINT+'update_area_details',querystring.stringify({area_id : this.editareamodal.area_id,area_name : this.editareamodal.area_name,area_img:this.editareamodal.area_img,area_store:this.editareamodal.area_store,area_status:this.editareamodal.area_status,area_region:this.editareamodal.area_region,created_by:this.editareamodal.created_by}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateareabtn").classList.remove('btn-primary');
            document.getElementById("updateareabtn").classList.add('btn-success');
            document.getElementById("updateareabtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateareabtn").classList.remove('btn-success');
            document.getElementById("updateareabtn").classList.add('btn-primary');
            document.getElementById("updateareabtn").innerHTML="Update";
          }, 2000);

            window.location.reload()

          })
        }
        
      },
      deletestore(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_store',querystring.stringify({store_id :row.store_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
    },
    deleteregion(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_region',querystring.stringify({region_id :row.region_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
    },
    deletearea(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure?',
            text: "wan't to delete",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_area',querystring.stringify({area_id :row.area_id,created_by:row.created_by}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your file has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
    },
    getstore_name(val){
      const search = this.storeData.find(element => element.store_id === val);
      if(search){
        return search.store_name;
      }
    },
    getfilterdata(){
          localStorage.setItem('selectstore',this.selectstore);
          let details={ "details" :{}};
          if(this.selectstore){
              details.details.region_store=this.selectstore;

            }

          axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_region',details,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.regionData=response.data.details;
              }else{
                this.regionData=[];
              }
                  
            })
            let adetails={ "details" :{}};
             if(this.selectstore){
               adetails.details.area_store=this.selectstore;

             }
             if(this.selectpin){
               adetails.details.area_region=this.selectpin;

             }
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'read_area',adetails,this.auth1)
            .then(response => {
              console.log(response);
              if(response.data.details){
              this.areaData=response.data.details;
              }else{
                this.areaData=[];
              }
                  
            })
         
        },
  },
    
};
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
