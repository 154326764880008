<template>
  <div>
    <div class="container-fluid">
      <form @submit.prevent class="row mt-3">
        <div class="col-lg-12">
          <div class="card-wrapper">
            <div class="card">
              <!-- <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="h3 mb-0">Product details</h5>
                  </div>
                  <div class="col text-right">
                    <base-button type="primary" @click="editproductpopup"><i class="fas fa-edit"></i> EditProduct</base-button>
                  </div>
                </div>
              </div> -->
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-6">
                          <base-input label="Referee Amount" placeholder="Referee Amount" v-model="rewarddata.referee_point" input-classes="" />
                      </div>
                      <div class="col-lg-6">
                          <base-input label="Referral Amount" placeholder="Referral Amount" v-model="rewarddata.referral_point" input-classes="" />
                      </div>
                      <div class="col-lg-6">
                         <label class="form-control-label">Store</label>
                          <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="rewarddata.store">
                               <option v-for="store in storeData" :value="store.store_id">{{store.store_name}}</option>
                              <!-- <option>disapprove</option> -->
                         </select>
                      </div>
                      <div class="col-lg-12 mt-4">
                      <base-button
                          @click="updaterewardpoint()"
                          id="updateproductbtn"
                          type="primary"
                          class=""
                          block
                          
                        >
                          <i class="fas fa-plus"></i>
                          <span>Save</span>
                        </base-button>

                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
   import VueSwal from 'vue-swal';
  import axios from "axios";
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
      DropzoneFileUpload,
    },
    data() {
      return {
        inputs: {
          file: []
        },
        storeData:[],
        deleteitem_price:[],
        deletegalleryarr:[],
        banneruploadPercentage:0,
        createproduct:false,
        createcategory:false,
        editcategory:false,
        editproduct:false,
        uploadPercentage:0,
        item_price:[{item_id : '',item_name : '',unit : '',quantity : 0,availability_now : 0,marked_price : 0,price : 0,is_featured : false,sequenced_at : 0,status: "approved",visibility : "visible"}],
        rewarddata:{
          store:'',
          region:'',
          referee_point:'',
          referral_point:''
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    mounted () {
      const querystring = require('querystring');
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_rewardpoint',{ "details" :{}},this.auth1)
      .then(response => {
          console.log(response);
          if(response.data.details){
          this.rewarddata=response.data.details[0];
          console.log(this.galleryarr);

        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.storeData=response.data.details;
        }
            
      })


    },
    methods: {
          
          
      updaterewardpoint() {
           const querystring = require('querystring');
           if(this.rewarddata.rewardpoint_id){
              axios.post(process.env.VUE_APP_API_ENDPOINT+'update_rewardpoint',querystring.stringify({ rewardpoint_id :this.rewarddata.rewardpoint_id,store : this.rewarddata.store,region:this.rewarddata.region,referee_point:this.rewarddata.referee_point,referral_point:this.rewarddata.referral_point}),this.auth).then(response => {
                console.log(response);
                this.message=response.data.message;
                document.getElementById("updateproductbtn").classList.remove('btn-primary');
                document.getElementById("updateproductbtn").classList.add('btn-success');
                document.getElementById("updateproductbtn").innerHTML='Updated';
                setTimeout(function(){
                document.getElementById("updateproductbtn").classList.remove('btn-success');
                document.getElementById("updateproductbtn").classList.add('btn-primary');
                document.getElementById("updateproductbtn").innerHTML="Update";
                window.location.reload()
              }, 2000);

              })
          }else{

            axios.post(process.env.VUE_APP_API_ENDPOINT+'create_rewardpoint',querystring.stringify({store : this.rewarddata.store,region:this.rewarddata.region,referee_point:this.rewarddata.referee_point,referral_point:this.rewarddata.referral_point}),this.auth)
              .then(response => {
                console.log(response);
                this.message=response.data.message;
                document.getElementById("updateproductbtn").classList.remove('btn-primary');
                document.getElementById("updateproductbtn").classList.add('btn-success');
                document.getElementById("updateproductbtn").innerHTML='Updated';
                setTimeout(function(){
                document.getElementById("updateproductbtn").classList.remove('btn-success');
                document.getElementById("updateproductbtn").classList.add('btn-primary');
                document.getElementById("updateproductbtn").innerHTML="Update";
                window.location.reload()
              }, 2000);

              })

          }

        },

    },
  };
</script>
<style></style>
