<template>
  <div>
    <div class="container-fluid">
      <form @submit.prevent class="row mt-3">
        <div class="col-lg-12">
          <div class="card-wrapper">
            <div class="card">
              <!-- <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="h3 mb-0">Product details</h5>
                  </div>
                  <div class="col text-right">
                    <base-button type="primary" @click="editproductpopup"><i class="fas fa-edit"></i> EditProduct</base-button>
                  </div>
                </div>
              </div> -->
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="form-control-label">Item Name<span class="text-danger">*</span></label>
                          <base-input placeholder="Item Name" v-model="productmodal.item_name" input-classes="" />
                            <div
                                v-if="$v.productmodal.item_name.$error"
                                class="error h5 text-danger mt--4 pl-1"
                              >
                                Field is required.
                            </div>
                      </div>
                      <div class="col-lg-6">
                          <base-input label="Tag line" placeholder="Tag line" v-model="productmodal.item_tagline" input-classes="" />
                      </div>
                      <div class="col-lg-12">
                        <label class="form-control-label">Short Description</label>
                        <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="productmodal.item_short_description"></textarea>
                      </div>
                      <div class="col-lg-12 mt-2">
                        <label class="form-control-label">Long Description</label>
                        <!-- <textarea class="form-control" id="exampleFormControlTextarea3" rows="5" v-model="productmodal.item_long_description" ></textarea> -->
                        <vue-editor :editorToolbar="customToolbar" v-model="productmodal.item_long_description" />
                      </div>
                      <!-- <div class="col-lg-6">
                         <label class="form-control-label">item_category</label>
                          <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="productmodal.item_category">
                              <option v-for="category in categoryData" :value="category._id">{{category.inventory_category_name}}</option>
                         </select>
                      </div> -->
                      <!-- <div class="col-lg-4 mt-2">
                         <label class="form-control-label">Item Variation</label>
                          <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="productmodal.item_variation">
                              <option value="">select</option>
                              <option v-for="variation in variationData" :value="variation._id">{{variation.variation_name}}</option>
                         </select>
                      </div> -->
                      <div class="col-lg-4 mt-2">
                          <base-input label="Flavour" placeholder="Flavour" v-model="productmodal.item_flavour" input-classes="" />
                      </div>
                      <div class="col-lg-4 mt-2">
                          <base-input label="Type" placeholder="Type" v-model="productmodal.item_flavour_type" input-classes="" />
                      </div>
                      <div class="col-lg-4 mt-2">
                          <base-input label="Tax (in percentage)" placeholder="e.g, 10" v-model="productmodal.tax" input-classes="" />
                      </div>
                      <div class="col-lg-12">
                        <hr>
                      </div>
                      <!-- <div class="col-lg-6">
                        <base-input label="Item Unit (Quantity)" placeholder="Item Unit" v-model="productmodal.item_count" input-classes="form-control-sm" />
                      </div> -->
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-10">
                            <base-input label="Item Quantity Title" placeholder="e.g, Weight, Size, Pieces etc" v-model="productmodal.price_type_title" input-classes="" />
                          </div>
                        </div>
                      </div>

                      <div v-for="(item,index) in item_price" class="col-lg-12">
                        <div class="row form-group-nomargin">
                          <div class="col-lg-2">
                              <base-input label="Unit name" placeholder="Unit" v-model="item.unit" input-classes="" />
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Quantity" placeholder="e.g, 1, 2 or any number" type="number" v-model="item.quantity" input-classes="" />
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Selling Price" type="number" placeholder="Selling Price" v-model="item.price" input-classes="" />
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Marked Price" type="number" placeholder="Marked Price" v-model="item.marked_price" input-classes="" />
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Stock" placeholder="Stock" v-model="item.availability_now" input-classes="" type="number" />
                          </div>
                          <div class="col-lg-1">
                            <label class="form-control-label text-white">.</label>
                            <base-button
                              id="updatebtn"
                              type=""
                              @click="deleteproducttype(index)"
                              class="btn-icon-only d-block"
                              size="sm"
                            >
                              <i class="fas fa-times"></i>
                            </base-button>
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Serving" placeholder="Serving" v-model="item.serves" input-classes="" />
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Size" placeholder="Size" v-model="item.size" input-classes="" />
                          </div>
                          <div class="col-lg-2">
                            <label class="form-control-label">Add shape?</label>
                            <br>
                            <base-switch v-model="item.is_shape"></base-switch>
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Standard" placeholder="Price"  v-if="item.is_shape" v-model="item.shape[0].price" input-classes="" />
                          </div>
                          <div class="col-lg-2">
                              <base-input label="Heart" placeholder="Price" v-if="item.is_shape" v-model="item.shape[1].price" input-classes="" />
                          </div>
                          <div class="col-lg-10">
                            <hr class="my-2">
                          </div>
                          <div
                              :id="'pricetypeerror'+index"
                              class="error h5 text-danger mt--3 pl-1 d-none"
                            >
                             All field are required and price always >= 1 and less than market price.
                          </div>
                        </div> 
                      </div>
                      <div class="container mt-2">
                        <div class="row">
                          <div class="col-lg-10">
                            <!-- <label class="form-control-label text-white">.</label> -->
                            <base-button
                              @click="additem()"
                              id="updatebtn"
                              type="success"
                              class=""
                              block
                              size="sm"
                            >
                              <i class="fas fa-plus"></i>
                              <span>Add Unit</span>
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-10">
                        <hr class="mt-4">
                      </div>
                      <!-- <div class="col-lg-3">
                         <label class="form-control-label">Item Brand</label>
                          <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="productmodal.item_brand">
                             <option value="">select</option>
                              <option v-for="brand in brandData" :value="brand._id">{{brand.brand_name}}</option>
                         </select>
                      </div> -->
                      <!-- <div class="col-lg-3">
                          <base-input label="item_discount" placeholder="item_discount" v-model="productmodal.item_discount" input-classes="" />
                      </div> -->
                      
                      <!-- <div class="col-lg-6">
                          <label class="form-control-label">item_main_img</label>
                          <input
                            id="file"
                            ref="myFiles"
                            type="file"
                            aria-describedby="addon-right addon-left"
                            alternative=""
                            class="form-control form-control-alternative"
                            name="img"
                            @change="hadleimage($event,'mainimage')"
                          >
                          <base-progress type="success" label="File uploaded"></base-progress>
                          <div>
                            <img :src="productmodal.item_main_img" class="img-fluid rounded shadow mt-2 card-body">
                          </div>
                      </div>
                      <div class="col-lg-6">
                          <label class="form-control-label">item_secondary_img</label>
                          <input
                            id="file"
                            ref="myFiles"
                            type="file"
                            aria-describedby="addon-right addon-left"
                            alternative=""
                            class="form-control form-control-alternative"
                            name="img"
                            @change="hadleimage($event,'secondary')"
                          >
                          <base-progress type="success" label="File uploaded"></base-progress>
                          <div>
                            <img :src="productmodal.item_secondary_img" class="img-fluid rounded shadow mt-2 card-body">
                          </div>
                      </div> -->
                      <!-- <div class="col-lg-6">
                          <label class="form-control-label">item_thumbnail</label>
                          <input
                            id="file"
                            ref="myFiles"
                            type="file"
                            aria-describedby="addon-right addon-left"
                            alternative=""
                            class="form-control form-control-alternative"
                            name="img"
                            @change="hadleimage($event,'thumbnail')"
                          >
                          <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                          <div>
                            <img :src="productmodal.item_thumbnail" class="img-fluid rounded shadow mt-2 card-body">
                          </div>
                      </div> -->
                      <div class="col-lg-3">
                        <label class="form-control-label">Show on homepage</label>
                        <br>
                        <base-switch v-model="productmodal.is_featured"></base-switch>
                      </div>
                      <div class="col-lg-3">
                        <label class="form-control-label">Show input message</label>
                        <br>
                        <base-switch v-model="productmodal.message_on_item"></base-switch>
                      </div>
                      <div class="col-lg-3">
                        <label class="form-control-label">Show input image</label>
                        <br>
                        <base-switch v-model="productmodal.picture_on_item"></base-switch>
                      </div>
                      <div class="col-lg-5 mt-2">
                        <!-- <base-input label="Store">
                          <el-select v-model="productmodal.store"
                                     multiple
                                     filterable
                                     placeholder="Select store">
                            <el-option v-for="store in storeData"
                                       :key="store.store_id"
                                       :label="store.store_name"
                                       :value="store.store_id">
                            </el-option>
                          </el-select>
                        </base-input> -->
                        <label class="form-control-label">Store<span class="text-danger">*</span></label>
                        <multi-select 
                          :options="options"
                          :selected-options="items"
                          placeholder="select store"
                          @select="onSelect">
                        </multi-select>
                        <div
                            v-if="items_error"
                            class="error h5 text-danger mt-1 pl-1"
                          >
                            Field is required.
                        </div>
                         <!-- <label class="form-control-label">Store</label>
                          <select multiple="true" aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="productmodal.store">

                            <option v-for="store in storeData" :value="store.store_id">{{store.store_name}}</option>
                         </select> -->
                      </div>
                      <div class="col-lg-5 mt-2">
                         <label class="form-control-label">Status</label>
                          <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="productmodal.status">
                              <option>approved</option>
                              <option>pending</option>
                         </select>
                      </div>
                      <div class="col-10"><hr></div>
                      <div class="col-md-10">
                        <base-input label="Meta Title" placeholder="e.g, Weight, Size, Pieces etc" v-model="productmodal.meta_title" input-classes="" />
                      </div>
                      <div class="col-lg-10">
                        <label class="form-control-label">Meta Description</label>
                        <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="productmodal.meta_description"></textarea>
                      </div>
                      <div class="col-md-10 meta_tags">
                        <label class="form-control-label">Meta Keywords</label>
                        <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="productmodal.meta_keyword"></textarea>
                      </div>
                      <div class="col-md-5 meta_tags">
                        <base-input label="OG Title" placeholder="e.g, Weight, Size, Pieces etc" v-model="productmodal.og_title" input-classes="" />
                      </div>
                      <div class="col-md-5 meta_tags">
                        <base-input label="OG Type" placeholder="e.g, Weight, Size, Pieces etc" v-model="productmodal.og_type" input-classes="" />
                      </div>
                      <div class="col-md-10 meta_tags">
                        <label class="form-control-label">OG Description</label>
                        <textarea class="form-control" id="exampleFormControlTextarea3" rows="2" v-model="productmodal.og_description"></textarea>
                      </div>
                      <div class="col-lg-5 mt-2">
                        <label class="form-control-label">OG Image</label>
                        <input
                            id="file"
                            ref="myFiles"
                            type="file"
                            aria-describedby="addon-right addon-left"
                            alternative=""
                            class="form-control form-control-alternative"
                            name="img"
                            @change="hadleimage($event,'og_image')"
                          >
                          <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                          <div class="position-relative" v-if="productmodal.og_image">
                            <base-button
                              type="danger"
                              size="sm"
                              class="position-absolute btn-icon-only"
                              @click="deleteogimage(productmodal)"
                              style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                              round
                              >
                              <i class="fas fa-times"></i>
                          </base-button>
                            <img :src="productmodal.og_image" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                          </div>
                      </div>
                      <div class="col-lg-10 mt-4">
                        <base-button
                          :disabled="$v.productmodal.$error" @click="$v.productmodal.$touch();saveproduct()"
                          id="updateproductbtn"
                          type="primary"
                          class=""
                          block
                          
                        >
                          <!-- <i class="fas fa-plus"></i> -->
                          <span>Add Product</span>
                        </base-button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="row">
                      <div class="col-lg-12">
                         <label class="form-control-label">Category<span class="text-danger">*</span></label>
                          <select aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="productmodal.item_category" @change="getsubcategory()">
                              <option v-for="category in categoryData" :value="category._id">{{category.inventory_category_name}}</option>
                              <!-- <option>disapprove</option> -->
                         </select>
                          <div
                              v-if="$v.productmodal.item_category.$error"
                              class="error h5 text-danger mt-1 pl-1"
                            >
                              Field is required.
                          </div>
                      </div>
                      <div class="col-lg-12 my-3">
                         <label class="form-control-label">Sub-category</label>
                          <select  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off" v-model="productmodal.item_subcategory" @change="getvariation()">
                              <option value="">select</option>
                              <option v-for="subcategory in subcategoryData" :value="subcategory._id">{{subcategory.inventory_subcategory_name}}</option>
                              <!-- <option>disapprove</option> -->
                         </select>
                      </div>
                      <div class="col-lg-12">
                        <label class="form-control-label">Main Image<span class="text-danger">*</span></label>
                        <input
                            id="file"
                            ref="myFiles"
                            type="file"
                            aria-describedby="addon-right addon-left"
                            alternative=""
                            class="form-control form-control-alternative"
                            name="img"
                            @change="hadleimage($event,'main_img')"
                          >
                          <div
                                v-if="$v.productmodal.item_main_img.$error"
                                class="error h5 text-danger mt-1 pl-1"
                              >
                                Field is required.
                            </div>
                          <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                          <div class="position-relative" v-if="productmodal.item_main_img">
                            <base-button
                              type="danger"
                              size="sm"
                              class="position-absolute btn-icon-only"
                              @click="deletemainimage(productmodal)"
                              style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                              round
                              >
                              <i class="fas fa-times"></i>
                          </base-button>
                            <img :src="productmodal.item_main_img" class="img-fluid rounded shadow mt-2 card-body">
                          </div>
                      </div>
                    </div>
                    <!-- <div class="row mt-4">
                      <div class="col-12">
                        <label class="form-control-label">Secondary Images</label>
                        <input
                            id="file"
                            ref="myFiles"
                            type="file"
                            aria-describedby="addon-right addon-left"
                            alternative=""
                            class="form-control form-control-alternative"
                            name="img"
                            @change="hadleimage($event,'secondary_img')"
                          >
                          <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                          <div>
                            <img :src="productmodal.item_secondary_img" class="img-fluid rounded shadow mt-2 card-body">
                          </div>
                      </div>
                    </div> -->
                    <div class="row mt-4">
                      <div class="col-12">
                        <label class="form-control-label">Gallery Images</label>
                            <input
                              type="file"
                              ref="file1"
                              accept="image/*" 
                              placeholder="User DP"
                              class="form-control form-control-alternative"
                              multiple="multiple"
                              @change="bannerupload($event,'add','file1')"
                            />
                          <base-progress type="success" :value="banneruploadPercentage" label="File uploaded"></base-progress>
                           <div class="row gallery">
                            <div class="position-relative col-lg-6" v-for="(gallery,index) in galleryarr" :key="'pb'+index" >
                            <base-button
                              type="danger"
                              size="sm"
                              class="position-absolute btn-icon-only"
                              @click="deletegallaryitem(gallery,index)"
                              style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                              round
                              >
                              <i class="fas fa-times"></i>
                            </base-button>
                            <div>
                             <img :src="gallery" class="img-fluid rounded shadow mt-2 p-2">
                            </div>
                          </div>
                         </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  
  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import TagsInput from '@/components/Inputs/TagsInput'
  import { Select, Option } from 'element-ui'
  import _ from 'lodash'
  import { VueEditor } from "vue2-editor";
  import { MultiSelect } from 'vue-search-select'
  import 'vue-search-select/dist/VueSearchSelect.css'
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
      TagsInput,
      DropzoneFileUpload,
      [Select.name]: Select,
      [Option.name]: Option,
      MultiSelect,
      VueEditor
    },
    data() {
      return {
        inputs: {
          file: []
        },
        searchText: '', // If value is falsy, reset searchText & searchItem
        items: [],
        items_error:false,
        options: [],
        lastSelectItem: {},
        subcategoryData:[],
        pricetypeerror:false,
        categoryData:[],
        variationData:[],
        subcategoryproductData:[],
        storeData:[],
        galleryarr:[],
        brandData:[],
        banneruploadPercentage:0,
        createproduct:false,
        createcategory:false,
        editcategory:false,
        editproduct:false,
        uploadPercentage:0,
        item_price:[{item_id : '',item_name : '',unit : '',quantity : "",availability_now : 0,marked_price : "",price : "",item_discount:"",is_featured : false,sequenced_at : 0,status: "approved",visibility : "visible",serves:"",size:"",is_shape:false,shape:[{"title":'Standard',"price":''},{"title":'Heart',"price":''}]}],
        customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"]],
        productmodal:{
          item_id : '',
          item_name : '',
          item_tagline : '',
          item_short_description : '',
          item_long_description : '',
          item_type : '',
          is_featured :false,
          sequenced_at :'',
          status:'approved',
          price_type_title:"",
          message_on_item:false,
          picture_on_item:false,
          visibility :'', 
          item_category :'',
          item_subcategory : '',
          item_variation : '',
          item_brand : '',
          item_sp : '',
          tax : "0",
          item_mp : '',
          item_discount : '',
          minimum_quantity : '',
          item_ratings : '',
          item_theme_color : '',
          item_tags : [],
          item_main_img : '',
          item_secondary_img : '',
          item_thumbnail : '',
          store : [],
          number_available :'',
          meta_title : '',
          meta_description : '',
          meta_keyword : '',
          og_title : '',
          og_type : '',
          og_image : '',
          og_description : '',
          store:[],
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    validations: {
      productmodal: {
        item_name: {
          required
        },
        item_category:{
          required
        },
        item_main_img:{
          required
        },
      },

    },
    mounted () {
      const querystring = require('querystring');
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_category',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.categoryData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_brand',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.brandData=response.data.details;
        }
            
      })

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_store',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
          this.storeData=response.data.details;
          this.productmodal.store=this.storeData[0].store_id;
          for (var i = 0; i < this.storeData.length; i++) {
            this.options.push({ value: this.storeData[i].store_id, text: this.storeData[i].store_name });
          }
          
        }
            
      })


    },
    methods: {
      onSelect (items, lastSelectItem) {
        this.items = items
        this.lastSelectItem = lastSelectItem
      },
      // deselect option
      reset () {
        this.items = [] // reset
      },
      selectFromParentComponent () {
        this.items = _.unionWith(this.items, [this.options[0]], _.isEqual)
      },
      deleteproducttype(index){
        //this.deleteitem_price.push(this.item_price[index]);
        this.item_price.splice(index,1);
      },
      hadleimage: function(event,type) {
          // Reference to the DOM input element
          this.uploadPercentage =0;
          var input = event.target;
          // Ensure that you have a file before attempting to read it
          if(input.files[0].size < 5000000){

          const fd= new FormData();
          fd.append('image',input.files[0],input.files[0].name);
          axios
          .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
          .then(response => {
            console.log(response);
            if(type=='thumbnail'){
              this.productmodal.item_thumbnail=response.data.imageUrl;
              console.log(this.productmodal.item_thumbnail);
            }else if(type=='main_img'){
              this.productmodal.item_main_img=response.data.imageUrl;
              console.log(this.productmodal.item_main_img);
            }else if(type=='secondary_img'){
              this.productmodal.item_secondary_img=response.data.imageUrl;
              console.log(this.productmodal.item_secondary_img);
            }else if(type=='og_image'){
              this.productmodal.og_image=response.data.imageUrl;
              console.log(this.productmodal.og_image);
            }
          })
          }else{

          swal({
            title:'File is too large!',
            text: 'Your file size is larger than 5 MB. Please upload images which are less than 5 MB.',
            icon:'warning',
           }).then((result) => {
             
          })
          }
      },
        async bannerupload(event,type,uploatype) {
              // Reference to the DOM input element
                //console.log(event);
                // document.getElementById("editassignmentbtn").classList.add('d-none');
                // document.getElementById("editaddassignmentinvalid").classList.remove('d-none');
               this.banneruploadPercentage =0;
                if(uploatype=='file1'){
                  for( var i = 0; i < this.$refs.file1.files.length; i++ ){
                      let file = this.$refs.file1.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }
                if(uploatype=='file3'){
                  for( var i = 0; i < this.$refs.file3.files.length; i++ ){
                      let file = this.$refs.file3.files[i];
                      //console.log(file);
                      await this.upload(file,type);
                      
                      
                  }
                }
        },
        upload(file,type){
          //console.log(file);
          let fd = new FormData();
          fd.append('image',file,file.name);
          axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.banneruploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.banneruploadPercentage)}.bind(this)}).then(response => {
            //console.log(response);
            this.galleryarr.push(response.data.imageUrl);
            //console.log(this.imagearr);
            // if(type=='add'){
            //   this.categorymodal.banner_primary=this.imagearr;
            //   //console.log(this.assignmentmodel.assignment_file);
            // }
            // else{
            //   this.categorymodal.banner_secondary=this.imagearr;
            // }
            //console.log();
         })

        },
      getsubcategory(){
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_inventory_subcategory',{ "details" :{inventory_category_oid:this.productmodal.item_category}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.subcategoryData=response.data.details;
        }else{
          this.subcategoryData=[];
        }
            
      })
      },
      additem(){
        this.item_price.push({item_id : '',item_name : '',unit : '',quantity : "",availability_now : 0,marked_price : "",price : "",item_discount:"",is_featured : false,sequenced_at : 0,status: "approved",visibility : "visible",serves:"",size:"",is_shape:false,shape:[{"title":'Standard',"price":''},{"title":'Heart',"price":''}]})
      },
      getvariation(){
       axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_variation',{ "details" :{variation_subcategory_oid:this.productmodal.item_subcategory}},this.auth1)
      .then(response => {
        console.log(response);
          if(response.data.details){
          this.variationData=response.data.details;
          }else{
            this.variationData=[];
          }  
        })
      },
      overflow_change() {
        document.getElementsByClassName("overflow_visible")[0].classList.add('overflow-x-visible');
        //document.getElementById("updatebtn").classList.add('btn-success');
      },
      deletemainimage(index){
        this.productmodal.item_main_img='';
        this.uploadPercentage =0;
      },
      deletegallaryitem(gallery,index){
        this.galleryarr.splice(index,1);
      },
      deleteogimage(index){
        this.productmodal.og_image='';
        this.uploadPercentage =0;
      },
      saveproduct() {
           let pricevaluevalidation=1;
            this.items_error=false;
            console.log(this.items.length)
           if(this.items.length==0){
               this.items_error=true;
           }
           for (var l = 0; l < this.item_price.length; l++) {
             document.getElementById('pricetypeerror'+l).classList.add('d-none');
            let price=parseFloat(this.item_price[l].price);
            this.item_price[l].availability_now=parseFloat(this.item_price[l].availability_now);
            if(price<= 0){
                pricevaluevalidation=0;
                 document.getElementById('pricetypeerror'+l).classList.remove('d-none');
                console.log("price less than 0")
              }
              
              let market_price=parseFloat(this.item_price[l].marked_price);
            if(market_price!=0 && price > market_price){
                console.log("price greater than mp")
                pricevaluevalidation=0;
                 document.getElementById('pricetypeerror'+l).classList.remove('d-none');
              }
           }
           if(this.$v.productmodal.$error===false){
            if(this.item_price[0].price && this.item_price[0].unit && this.item_price[0].quantity && pricevaluevalidation){
              this.pricetypeerror=false;

            
           const querystring = require('querystring');
           console.log(this.productmodal);
           let details={ item_name : this.productmodal.item_name,item_tagline:this.productmodal.item_tagline,item_short_description:this.productmodal.item_short_description,item_long_description:this.productmodal.item_long_description,item_category:this.productmodal.item_category,is_featured:this.productmodal.is_featured.toString(),tax:this.productmodal.tax,item_main_img:this.productmodal.item_main_img,item_secondary_img:this.productmodal.item_secondary_img,item_thumbnail:this.productmodal.item_main_img,status:this.productmodal.status,price_type_title:this.productmodal.price_type_title,message_on_item:this.productmodal.message_on_item,picture_on_item:this.productmodal.picture_on_item,meta_title:this.productmodal.meta_title,meta_description:this.productmodal.meta_description,meta_keyword:this.productmodal.meta_keyword,og_type:this.productmodal.og_type,og_title:this.productmodal.og_title,og_description:this.productmodal.og_description,og_image:this.productmodal.og_image,item_flavour:this.productmodal.item_flavour,item_flavour_type:this.productmodal.item_flavour_type};
           if(this.productmodal.item_subcategory){
              details.item_subcategory=this.productmodal.item_subcategory;
           }
           if(this.productmodal.item_brand){
              details.item_brand=this.productmodal.item_brand;
           }
           if(this.productmodal.item_variation){
              details.item_variation=this.productmodal.item_variation;
           }
           if (this.item_price[0].price){
               details.item_sp=this.item_price[0].price;
           }
           if (this.item_price[0].marked_price){
               details.item_mp=this.item_price[0].marked_price;
           }
           if (this.item_price[0].marked_price && this.item_price[0].price){
               details.item_discount=((this.item_price[0].marked_price-this.item_price[0].price)*100/this.item_price[0].marked_price).toFixed(2);
           }
           if(this.galleryarr.length==0){
            this.galleryarr[0]=this.productmodal.item_main_img;
           }
           for (var z = 0; z < this.items.length; z++) {
             details.store=this.items[z].value;
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_item',querystring.stringify(details),this.auth)
          .then(response => {
            console.log(response)

              if(this.item_price[0].price){

                axios
                  .post(process.env.VUE_APP_API_ENDPOINT+'create_price_type_list',{item_id:response.data.details.item_id,item_name:this.productmodal.item_name,store:this.productmodal.store,item_price: this.item_price},this.auth1)
                  .then(response1 => {
                    console.log(response1); 
                  })

              }
            for (var k = 0; k < this.galleryarr.length; k++) {
              if(this.galleryarr[k].gallery_id){
                }else{

                  axios
                  .post(process.env.VUE_APP_API_ENDPOINT+'create_item_gallery',{item_id:response.data.details.item_id,item_img:this.galleryarr[k],item_name:this.productmodal.item_name},this.auth1)
                  .then(response1 => {
                    console.log(response1);
                      // if(response.data.details){
                      // }   
                    })

              }
            }
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updateproductbtn").classList.remove('btn-primary');
            document.getElementById("updateproductbtn").classList.add('btn-success');
            document.getElementById("updateproductbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updateproductbtn").classList.remove('btn-success');
            document.getElementById("updateproductbtn").classList.add('btn-primary');
            document.getElementById("updateproductbtn").innerHTML="Update";
             window.location.reload();
           }, 2000);

          })

         }

          }else{
              this.pricetypeerror=true;
          }

         }

        },
      deleteproduct(row) {
      console.log(row);
      const querystring = require('querystring');
        swal({
            title: 'Are you sure to delete?',
            text: "This process is not reversible.",
            icon: 'warning',
            buttons: {cancel: true,confirm: true,}
        }).then((result) => { // <--
          if (result) {
            console.log('result cloud',result)
            axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_item',querystring.stringify({item_id :row.item_id,product_oid:row._id}),this.auth)
          .then(response => {
            console.log(response);
            swal({
              title:'Deleted!',
              text: 'Your product has been deleted.',
              icon:'success',
             }).then((result) => {
              console.log(result)
              window.location.reload()
            })
            })
           }
        });
      },

    },
  };
</script>
<style></style>
