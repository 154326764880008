<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Manage content of Pre-primary Page
                  </h3>
                </div>
                <div class="col text-right">
                  
                </div>
              </div>
            </div>
            <div class="card-body border-top">  
               <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Overview and activities
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input label="Overview Heading" v-model="model.overview_heading" placeholder="Overview Heading" input-classes="" />
                      <textarea class="form-control mb-2" rows="5" placeholder="Overview Description" v-model="model.overview_description"></textarea>
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">Overview Image</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'overview_image')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="model.overview_image">
                        <img :src="model.overview_image" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">Overview Image 2</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'overview_image2')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="model.overview_image2">
                        <img :src="model.overview_image2" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <base-input label="Programs Heading" v-model="model.programs_heading" placeholder="Programs Heading" input-classes="" />
                      <base-input label="Activities Heading" v-model="model.activities_heading" placeholder="Activities Heading" input-classes="" />
                    </div>
                  </div>
                  <div class="row px-3">
                  <div
                    class="col-md-3"
                    v-for="(activity, index) in model.activities"
                    :key="index"
                  >
                    <base-input
                      :label="'activities ' + (index + 1) + ' name'"
                      v-model="activity.name"
                      placeholder="Appears in pre primary section"
                      input-classes=""
                    />
                    <label class="form-control-label"
                      >activity {{ index + 1 }} image</label
                    >
                    <input
                      id="file"
                      ref="myFiles"
                      type="file"
                      aria-describedby="addon-right addon-left"
                      alternative=""
                      class="form-control form-control-alternative"
                      name="img"
                      @change="hadleimage($event, 'activity'+index)"
                    />
                    <base-progress
                      type="success"
                      v-model="uploadPercentage"
                      label="File uploaded"
                    ></base-progress>
                    <div v-if="activity.image">
                      <img
                        :src="activity.image"
                        class="img-fluid rounded shadow mt-2 card-body"
                      />
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Admission and fee details
                </div>
  

                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input label="Title" v-model="model.admission_title" placeholder="Title" input-classes="" />
                      <textarea class="form-control mb-2" rows="5" placeholder="Description" v-model="model.admission_description"></textarea>
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">Image</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'admission_image')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="model.admission_image">
                        <img :src="model.admission_image" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Gallery
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-12 col-12 mb-3 px-2">
                      <template>
                        <div :id="'app1'">
                          <div class="images-wrapper row mx-0">
                            <div class="image-wrapper position-relative" v-for="(image, imageIndex) in this.model.gallary" :key="imageIndex">
                              <base-button type="danger" size="sm" @click="deletefile(imageIndex)" class="position-absolute right-0 mr--2 mt--2 btn-round"><i class="fas fa-trash"></i></base-button>
                              <div
                                class="gallery_image"
                                @click="index = imageIndex"
                                :style="{ backgroundImage: 'url(' + image + ')' }"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="col-md-12 px-4">
                      <form>
                        <div class="custom-file">
                            <input type="file" ref="filec" inputClasses="custom-file-input" id="customFileLang" multiple="multiple" @change="handlemultipleimage($event,'gallery','filec')"></input>
                            <label class="custom-file-label" for="customFileLang">Upload photos</label>
                        </div>
                        <!-- <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress> -->
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-12 mb-3 px-2">
                      <template>
                        <div :id="'app2'">
                          <div class="row mx-0 mt-3">
                            <div class="image-wrapper position-relative" v-for="(video, imageIndex) in this.model.videogallary" :key="imageIndex">
                              <base-button type="danger" size="sm" @click="deletevideofile(imageIndex)" class="position-absolute right-0 mr--2 mt--2 btn-round"><i class="fas fa-trash"></i></base-button>
                              <video width="320" height="240" controls class="img-fluid">
                                <source :src="video" type="video/mp4">
                                <!-- <source src="movie.ogg" type="video/ogg"> -->
                              </video>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="col-md-12 px-4">
                      <form>
                        <div class="custom-file">
                            <input type="file" ref="filed" inputClasses="custom-file-input" id="customFileLangvideo" accept="video/*"  multiple="multiple" @change="handlemultipleimage($event,'videogallery','filed')"></input>
                            <label class="custom-file-label" for="customFileLangvideo">Upload video</label>
                        </div>
                        <base-progress type="success" :value="multipleuploadPercentage" label="Upload Progress"></base-progress>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="row">
                  <div class="col-lg-12">
                    <base-button
                      @click="savecontent()"
                      id="savecontent"
                      type="primary"
                      class="float-center"
                      block
                      size="lg"
                    >
                      <span>Save Content</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow" :class="''">
              <div class="card-header border-bottom" :class="''">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0" :class="''">Program</h3>
                  </div>
                  <div class="col text-right">
                    <base-button type="primary" @click="addprogrampopup"
                      ><i class="fas fa-plus"></i> Add new
                      program</base-button
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-lg-4"
                    v-for="(program, index) in programData"
                    :key="program._id"
                  >
                    <!-- Basic with list group -->
                    <div class="card border shadow-sm">
                      <!-- List group -->
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item small">
                         <img :src="program.image1" class="img-fluid rounded shadow mt-2 card-body">
                        </li>
                        <li class="list-group-item small">
                          {{ program.name }}
                        </li>
                        <li class="list-group-item small">
                          {{ program.short_description }}
                        </li>
                      </ul>
                      <!-- Card body -->
                      <div class="card-body p-3">
                        <!-- <p class="card-text mb-4" v-html="post.blog_body"></p> -->
                        <div class="">
                          <div class="float-left">
                            <!-- <router-link to="#" class="btn btn-primary btn-sm">View</router-link> -->
                          </div>
                          <div class="float-right">
                            <base-button
                              type="primary"
                              size="sm"
                              @click="editprogrampopup(program)"
                              >Edit</base-button
                            >
                            <base-button
                              type="danger"
                              size="sm"
                              @click="deleteprogram(program)"
                              >Delete</base-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- add program popup -->
      <modal :show.sync="addprogram" modalClasses="modal-lg">
        <p id="modal-title-default" slot="header" class="modal-title">
          <span>Add program</span>
        </p>
        <template>
          <form @submit.prevent>
            <div class="row mt--3">
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">name</label>
                <base-input
                  v-model="programmodel.name"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Heading</label>
                <base-input
                  v-model="programmodel.heading"
                  alternative=""
                  input-classes="form-control-alternative"
                />
  
              </div>
              <div class="col-lg-12 mb-2">
                <div class="col-lg-6">
                      <label class="form-control-label">Image 1</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'image1')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="programmodel.image1">
                        <img :src="programmodel.image1" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <label class="form-control-label"> Image 2</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'image2')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="programmodel.image2">
                        <img :src="programmodel.image2" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                    </div>
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Short description</label>
                <base-input
                  v-model="programmodel.short_description"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">linkId</label>
                <base-input
                  v-model="programmodel.linkId"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Description</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="programmodel.description"
                />
              </div>
            </div>
          </form>
        </template>

        <template slot="footer">
          <base-button
            @click="saveprogram()"
            type="success"
            id="createprogrambtn"
            class="float-center"
          >
            Save program
          </base-button>
          <base-button
            type="link"
            class="ml-auto"
            @click="addprogram = false"
          >
            <span>Close</span>
          </base-button>
        </template>
      </modal>
      <!-- edit program popup -->
      <modal :show.sync="editprogram" modalClasses="modal-lg">
        <p id="modal-title-default" slot="header" class="modal-title">
          <span>Edit program</span>
        </p>
        <template>
          <form @submit.prevent>
            <div class="row mt--3">
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">name</label>
                <base-input
                  v-model="editprogrammodal.name"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
               <div class="col-lg-12 mb-2">
                <label class="form-control-label">Heading</label>
                <base-input
                  v-model="editprogrammodal.heading"
                  alternative=""
                  input-classes="form-control-alternative"
                />
  
              </div>
              <div class="col-lg-12 mb-2">
                <div class="col-lg-6">
                      <label class="form-control-label">Image 1</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'editimage1')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="editprogrammodal.image1">
                        <img :src="editprogrammodal.image1" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <label class="form-control-label"> Image 2</label>
                      <input
                        id="file"
                        ref="myFiles"
                        type="file"
                        aria-describedby="addon-right addon-left"
                        alternative=""
                        class="form-control form-control-alternative"
                        name="img"
                        @change="hadleimage($event,'editimage2')"
                      >
                      <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                      <div v-if="editprogrammodal.image2">
                        <img :src="editprogrammodal.image2" class="img-fluid rounded shadow mt-2 card-body">
                      </div>
                    </div>
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Short description</label>
                <base-input
                  v-model="editprogrammodal.short_description"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">linkId</label>
                <base-input
                  v-model="editprogrammodal.linkId"
                  alternative=""
                  input-classes="form-control-alternative"
                />
                <!-- <div
                  v-if="$v.model.blog_title.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
                </div> -->
              </div>
              <div class="col-lg-12 mb-2">
                <label class="form-control-label">Description</label>
                <vue-editor
                  :editorToolbar="customToolbar"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="editprogrammodal.description"
                />
              </div>
            </div>
          </form>
        </template>

        <template slot="footer">
          <base-button
            @click="updateprogram()"
            type="success"
            id="updateprogrambtn"
            class="float-center"
          >
            Update program
          </base-button>
          <base-button
            type="link"
            class="ml-auto"
            @click="editprogram = false"
          >
            <span>Close</span>
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
  import CoolLightBox from 'vue-cool-lightbox';
  import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      CoolLightBox,
      Tabs,
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard,
      VueEditor
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addboardmember: false,
        datanotfound:false,
        editboardmember: false,
        editprogram: false,
        addprogram: false,
        addstore: false,
        editstore: false,
        addregion: false,
        editregion: false,
        addarea: false,
        editarea: false,
        datanotfound:false,
        uploadPercentage:0,
        multipleuploadPercentage:0,
        pagepermission:{},
        programData:{},
        storeData:[],
        regionData:[],
        areaData:[],
        allregionData :[],
        selectstore:'',
        selectpin:'',
      programmodel: {
        program_id: "",
        name: "",
        heading:"",
        short_decription: "",
        image1: "",
        image2: "",
        description: "",
        linkId: "",
        status: "approved",
      },
      editprogrammodal: {
        program_id: "",
        name: "",
        heading:"",
        short_decription: "",
        image1: "",
        image2: "",
        description: "",
        linkId: "",
        status: "approved",
      },
        customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link", "image", "video"],
          ["clean"]],
        model:{
          preprimary_id:'',
          admission_image:'',
          admission_title:'',
          admission_description:'',
          gallary:[],
          videogallary:[],
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        items: [
        '/img/theme/team-1.jpg',
        '/img/theme/team-2.jpg',
        '/img/theme/team-3.jpg',
        '/img/theme/team-4.jpg',
        '/img/theme/team-5.jpg',
        '/img/theme/team-1.jpg',
        ],
      };
    },
    validations: {
      // model: {
      //   store_name:{
      //     required
      //   },
      //   store_img:{
      //     required
      //   },
      //   shipping_charge:{
      //     required
      //   },
      // }

    },
    mounted() {
      
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_pre_primary',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.model=response.data.details[0];
        if(response.data.details[0].videogallary){
          this.model.videogallary=response.data.details[0].videogallary;
        }else{
          this.model.videogallary=[];
        }
        console.log(this.model)
        }
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_program',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.programData=response.data.details;
        console.log(this.programData)
        }

            
      })
    },
    methods: {

       async handlemultipleimage(event,type,uploatype) {
            // Reference to the DOM input element

              // document.getElementById("assignmentbtn").classList.add('d-none');
              // document.getElementById("addassignmentinvalid").classList.remove('d-none');
              // console.log(event);
              this.multipleuploadPercentage=0;
              // if(uploatype=='file'){
              //   for( var i = 0; i < this.$refs.file.files.length; i++ ){
              //       let file = this.$refs.file.files[i];
              //       console.log(file);
              //       await this.upload(file,type);
                    
                    
              //   }
              // }
              // if(uploatype=='fileb'){
              //   for( var i = 0; i < this.$refs.fileb.files.length; i++ ){
              //       let file = this.$refs.fileb.files[i];
              //       console.log(file);
              //       await this.upload(file,type);
                    
                    
              //   }
              // }
              if(uploatype=='filec'){
                for( var i = 0; i < this.$refs.filec.files.length; i++ ){
                    let file = this.$refs.filec.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }

              }
              if(uploatype=='filed'){
                for( var i = 0; i < this.$refs.filed.files.length; i++ ){
                    let file = this.$refs.filed.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }

              }

      },
      upload(file,type){
        // console.log(file);
        let fd = new FormData();
        fd.append('image',file,file.name);
        axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.multipleuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.multipleuploadPercentage)}.bind(this)}).then(response => {
          // console.log(response);
          if(type=='add'){
            this.eventmodel.images.push(response.data.imageUrl);
          }
          if(type=='edit'){
          this.editeventmodel.images.push(response.data.imageUrl);

          }
          if(type=='gallery'){
          this.model.gallary.push(response.data.imageUrl);

          }
          if(type=='videogallery'){
          this.model.videogallary.push(response.data.imageUrl);

          }
          
          // console.log(this.imagearr);
          
       })

      },

      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='admission_image'){
                this.model.admission_image=response.data.imageUrl;
                console.log(this.model.hero_banner);
              }if(type=='image_1'){
                this.model.image_1=response.data.imageUrl;
                console.log(this.model.image_1);
              }if(type=='image_2'){
                this.model.image_2=response.data.imageUrl;
                console.log(this.model.image_2);
              }if(type=='block_1_icon'){
                this.model.block_1_icon=response.data.imageUrl;
                console.log(this.model.block_1_icon);
              }if(type=='block_2_icon'){
                this.model.block_2_icon=response.data.imageUrl;
                console.log(this.model.block_2_icon);
              }if(type=='block_3_icon'){
                this.model.block_3_icon=response.data.imageUrl;
                console.log(this.model.block_3_icon);
              }
              if(type=='overview_image'){
                this.model.overview_image=response.data.imageUrl;
                console.log(this.model.overview_image);
              }
              if(type=='overview_image2'){
                this.model.overview_image2=response.data.imageUrl;
                console.log(this.model.overview_image2);
              }
              
              if(type=='activity0'){
                this.model.activities[0].image=response.data.imageUrl;
                console.log(this.model.activities[0].image);
              }
              if(type=='activity1'){
                this.model.activities[1].image=response.data.imageUrl;
                console.log(this.model.activities[1].image);
              }
              if(type=='activity2'){
                this.model.activities[2].image=response.data.imageUrl;
                console.log(this.model.activities[2].image);
              }
              if(type=='activity3'){
                this.model.activities[3].image=response.data.imageUrl;
                console.log(this.model.activities[3].image);
              }
              if(type=='activity4'){
                this.model.activities[4].image=response.data.imageUrl;
                console.log(this.model.activities[4].image);
              }
              if(type=='activity5'){
                this.model.activities[5].image=response.data.imageUrl;
                console.log(this.model.activities[5].image);
              }
              if(type=='activity6'){
                this.model.activities[6].image=response.data.imageUrl;
                console.log(this.model.activities[6].image);
              }
              if(type=='activity7'){
                this.model.activities[7].image=response.data.imageUrl;
                console.log(this.model.activities[7].image);
              }
              if(type=='activity8'){
                this.model.activities[8].image=response.data.imageUrl;
                console.log(this.model.activities[8].image);
              }
              if(type=='activity9'){
                this.model.activities[9].image=response.data.imageUrl;
                console.log(this.model.activities[9].image);
              }
              
              if(type=='image1'){
                this.programmodel.image1=response.data.imageUrl;
                console.log(this.programmodel.image1);
              }
              if(type=='image2'){
                this.programmodel.image2=response.data.imageUrl;
                console.log(this.programmodel.image2);
              }
              if(type=='editimage1'){
                this.editprogrammodal.image1=response.data.imageUrl;
                console.log(this.editprogrammodal.image1);
              }
              if(type=='editimage2'){
                this.editprogrammodal.image2=response.data.imageUrl;
                console.log(this.editprogrammodal.image2);
              }
              
              //else{
              //   this.storemodal.store_img=response.data.imageUrl;
              //   console.log(this.storemodal.store_img);
              // }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }
      },
      savecontent(){
        const querystring = require('querystring');
        if(!this.model.preprimary_id){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_pre_primary',this.model,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontent").classList.remove('btn-primary');
            document.getElementById("savecontent").classList.add('btn-success');
            document.getElementById("savecontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savecontent").classList.remove('btn-success');
            document.getElementById("savecontent").classList.add('btn-primary');
            document.getElementById("savecontent").innerHTML="Update";
            window.location.reload()

          }, 2000);


          })
        }else{

          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_pre_primary',this.model,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontent").classList.remove('btn-primary');
            document.getElementById("savecontent").classList.add('btn-success');
            document.getElementById("savecontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savecontent").classList.remove('btn-success');
            document.getElementById("savecontent").classList.add('btn-primary');
            document.getElementById("savecontent").innerHTML="Update";
            window.location.reload()
            
          }, 2000);


          })

        }
      },
      addprogrampopup() {
      this.addprogram = true;
    },
    saveprogram() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "create_program",
          this.programmodel,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("createprogrambtn")
            .classList.remove("btn-primary");
          document
            .getElementById("createprogrambtn")
            .classList.add("btn-success");
          document.getElementById("createprogrambtn").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("createprogrambtn")
              .classList.remove("btn-success");
            document
              .getElementById("createprogrambtn")
              .classList.add("btn-primary");
            document.getElementById("createprogrambtn").innerHTML = "Save";
            window.location.reload();
          }, 2000);

          //
        });
    },
    editprogrampopup(row) {
      console.log(row);
      this.editprogrammodal = row;
      this.editprogram = true;
    },
    updateprogram() {
      const querystring = require("querystring");
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "update_program",
          this.editprogrammodal,
          this.auth1
        )
        .then((response) => {
          console.log(response);
          this.message = response.data.message;
          document
            .getElementById("updateprogrambtn")
            .classList.remove("btn-primary");
          document
            .getElementById("updateprogrambtn")
            .classList.add("btn-success");
          document.getElementById("updateprogrambtn").innerHTML = "Updated";
          setTimeout(function () {
            document
              .getElementById("updateprogrambtn")
              .classList.remove("btn-success");
            document
              .getElementById("updateprogrambtn")
              .classList.add("btn-primary");
            document.getElementById("updateprogrambtn").innerHTML = "Update";
            window.location.reload();
          }, 2000);
        });
    },
     handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      this.uploadPercentage = 0;
      if (file.size < 5000000) {
        var formData = new FormData();
        formData.append("image", file);

        axios
          .post(process.env.VUE_APP_API_ENDPOINT + "image-upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              console.log(this.uploadPercentage);
            }.bind(this),
          })
          .then((response) => {
            console.log(response);
            let url = response.data.imageUrl; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal({
          title: "File to large!",
          text: "Your file is larger than 5 MB. Please upload images which are less than 5 MB.",
          icon: "warning",
        }).then((result) => {});
      }
    },
    deleteprogram(row) {
      console.log(row);
      const querystring = require("querystring");
      swal({
        title: "Are you sure?",
        text: "wan't to delete",
        icon: "warning",
        buttons: { cancel: true, confirm: true },
      }).then((result) => {
        // <--
        if (result) {
          console.log("result cloud", result);
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT + "delete_program",
              {
                program_id: row.program_id,
              },
              this.auth1
            )
            .then((response) => {
              console.log(response);
              swal({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              }).then((result) => {
                console.log(result);
                window.location.reload();
              });
            });
        }
      });
    },
      addboardmemberpopup(row){
        console.log(row);
        this.addboardmember=true;
      },
      editboardmemberpopup(row){
        this.editboardmembermodal=row;
        console.log(this.editboardmembermodal);
        this.editboardmember=true;
        // }
      },
      deletefile(imageIndex){
        this.model.gallary.splice(imageIndex,1);
      },
  },
    
};
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
