<template>
  <div>
    <div class="container-fluid mt-3">
      <div class="row mb-5">
        <div class="col">
          <div class="card shadow" :class="''">
            <div class="card-header border-0" :class="''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="''">
                    Contact details content
                  </h3>
                </div>
                <div class="col text-right">
                  
                </div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="card border shadow-sm">
                <div class="card-header bg-secondary">
                  Contact details content
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-3">
                      <label class="form-control-label">Address</label>
                      <textarea class="form-control mb-2" rows="5" placeholder="School address" v-model="contactusData.address"></textarea>
                    </div>
                    <div class="col-lg-3">
                      <label class="form-control-label">Phone No.</label>
                      <textarea class="form-control mb-2" rows="5" placeholder="Phone number" v-model="contactusData.phone"></textarea>
                    </div>
                    <div class="col-lg-3">
                      <label class="form-control-label">Email</label>
                      <textarea class="form-control mb-2" rows="5" placeholder="Email address" v-model="contactusData.email"></textarea>
                    </div>
                    <div class="col-lg-3">
                      <label class="form-control-label">Contact Person</label>
                      <textarea class="form-control mb-2" rows="5" placeholder="Contact Person" v-model="contactusData.person"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border shadow-sm">
                <div class="row">
                  <div class="col-lg-12">
                    
                  </div>
                  <div class="col-lg-12">
                    <base-button
                      @click="savecontent()"
                      id="savecontactcontent"
                      type="primary"
                      class="float-center"
                      block
                      size="lg"
                    >
                      <span>Save Content</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  import { Tabs } from '@/components';
  import { BasePagination } from '@/components/Tabs/Tabs';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { required, minLength, between } from 'vuelidate/lib/validators';
  import VueSwal from 'vue-swal';
  import axios from "axios";
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      // ActivityFeed,
      // Tabs,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // StatsCard,
      // VueEditor
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        addboardmember: false,
        // datanotfound:false,
        editboardmember: false,
        addstore: false,
        editstore: false,
        addregion: false,
        editregion: false,
        addarea: false,
        editarea: false,
        datanotfound:false,
        uploadPercentage:0,
        multipleuploadPercentage:0,
        pagepermission:{},
        board_of_governorData:[],
        editboardmembermodal:{},
        regionData:[],
        areaData:[],
        affiliationsData :[],
        selectstore:'',
        selectpin:'',
        customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ["link", "image", "video"],
          ["clean"]],
        contactusData:{
          contactus_id:'',
          address:'',
          phone:'',
          email:'',
          person:'',
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
        auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      };
    },
    validations: {
      // model: {
      //   store_name:{
      //     required
      //   },
      //   store_img:{
      //     required
      //   },
      //   shipping_charge:{
      //     required
      //   },
      // }

    },
    mounted() {

      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_contactus',{ "details" :{}},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.contactusData=response.data.details[0];
        }
      })

    },
    methods: {

      async handlemultipleimage(event,type,uploatype) {
            // Reference to the DOM input element

              // document.getElementById("assignmentbtn").classList.add('d-none');
              // document.getElementById("addassignmentinvalid").classList.remove('d-none');
              // console.log(event);
              this.multipleuploadPercentage=0;
              if(uploatype=='file'){
                for( var i = 0; i < this.$refs.file.files.length; i++ ){
                    let file = this.$refs.file.files[i];
                    console.log(file);
                    await this.upload(file,type);
                    
                    
                }
              }
              // if(uploatype=='fileb'){
              //   for( var i = 0; i < this.$refs.fileb.files.length; i++ ){
              //       let file = this.$refs.fileb.files[i];
              //       console.log(file);
              //       await this.upload(file,type);
                    
                    
              //   }
              // }

      },
      upload(file,type){
        // console.log(file);
        let fd = new FormData();
        fd.append('image',file,file.name);
        axios.post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.multipleuploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.multipleuploadPercentage)}.bind(this)}).then(response => {
          // console.log(response);
          this.contactusData.images.push(response.data.imageUrl);
          // console.log(this.imagearr);
          
       })

      },
      deletefile(index,type){
          if(type=='add'){
            this.contactusData.images.splice(index,1);
          }
      },
      hadleimage: function(event,type) {
            // Reference to the DOM input element
            this.uploadPercentage =0;
            var input = event.target;
            // Ensure that you have a file before attempting to read it
            if(input.files[0].size < 5000000){

            const fd= new FormData();
            fd.append('image',input.files[0],input.files[0].name);
            axios
            .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
            .then(response => {
              console.log(response);
              if(type=='addboardmember'){
                this.model.image=response.data.imageUrl;
                console.log(this.model.image);
              }if(type=='editboardmember'){
                this.editboardmembermodal.image=response.data.imageUrl;
                console.log(this.editboardmembermodal.image);
              }if(type=='logo'){
                this.affiliationdata.logo=response.data.imageUrl;
                console.log(this.affiliationdata.logo);
              }if(type=='social_responsibility'){
                this.contactusData.images=response.data.imageUrl;
                console.log(this.contactusData.images);
              }if(type=='block_2_icon'){
                this.model.block_2_icon=response.data.imageUrl;
                console.log(this.model.block_2_icon);
              }if(type=='block_3_icon'){
                this.model.block_3_icon=response.data.imageUrl;
                console.log(this.model.block_3_icon);
              }
              //else{
              //   this.storemodal.store_img=response.data.imageUrl;
              //   console.log(this.storemodal.store_img);
              // }
                
            })

            }else{

            swal({
              title:'File too large!',
              text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
              icon:'warning',
             }).then((result) => {
               
            })

          }
      },
      deleteimage(row,type){
        if(type='add'){
         this.model.image='';
        }
        if(type='edit'){
          this.editmodel.image='';
        }
      },
      savecontent(){
        const querystring = require('querystring');
           if(!this.contactusData.contactus_id){
           axios.post(process.env.VUE_APP_API_ENDPOINT+'create_contactus',this.contactusData,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontactcontent").classList.remove('btn-primary');
            document.getElementById("savecontactcontent").classList.add('btn-success');
            document.getElementById("savecontactcontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savecontactcontent").classList.remove('btn-success');
            document.getElementById("savecontactcontent").classList.add('btn-primary');
            document.getElementById("savecontactcontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

            //

          })
        }else{

          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_contactus',this.contactusData,this.auth1)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("savecontactcontent").classList.remove('btn-primary');
            document.getElementById("savecontactcontent").classList.add('btn-success');
            document.getElementById("savecontactcontent").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("savecontactcontent").classList.remove('btn-success');
            document.getElementById("savecontactcontent").classList.add('btn-primary');
            document.getElementById("savecontactcontent").innerHTML="Update";
            window.location.reload()
          }, 2000);

          })

        }
      },
      addboardmemberpopup(row){
        console.log(row);
        this.addboardmember=true;
      },
      editboardmemberpopup(row){
        this.editboardmembermodal=row;
        console.log(this.editboardmembermodal);
        this.editboardmember=true;
        // }
      },
      deleteboardmember(row){
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure?',
              text: "wan't to delete",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_board_of_governor',querystring.stringify({board_of_governor_id :row.board_of_governor_id}),this.auth)
            .then(response => {
                let _this=this;
                console.log(response);
                swal({
                  title:'Deleted!',
                  text: 'Member has been deleted.',
                  icon:'success',
                 }).then((result) => {
                  console.log(result)
                  _this.getalldata();
                })
              })
             }
          });
      },
      deleteaffiliation(row){
        console.log(row);
        const querystring = require('querystring');
          swal({
              title: 'Are you sure to delete?',
              text: "This action is irreversible.",
              icon: 'warning',
              buttons: {cancel: true,confirm: true,}
          }).then((result) => { // <--
            if (result) {
              console.log('result cloud',result)
              axios.post(process.env.VUE_APP_API_ENDPOINT+'delete_affiliation',querystring.stringify({affiliation_id :row.affiliation_id}),this.auth)
            .then(response => {
                let _this=this;
                console.log(response);
                swal({
                  title:'Deleted!',
                  text: 'Affiliation logo has been deleted.',
                  icon:'success',
                 }).then((result) => {
                  console.log(result)
                  // get updated list
                  axios
                  .post(process.env.VUE_APP_API_ENDPOINT+'read_affiliation',{ "details" :{}},this.auth1)
                  .then(response => {
                    console.log(response);
                    if(response.data.details){
                    this.affiliationsData=response.data.details;
                    }   
                  })
                })
              })
             }
          });
      },
  },
    
};
</script>
<style>
  .search-input .form-group {
    display: inline-block;
    margin-bottom: 0px;
  }
</style>
