<template>
  <div>
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="h3 mb-0" v-if="model.user_class">{{model.user_name}} - {{model.user_class.class_name}}</h5>
                  </div>
                  <div class="col text-right">
                    <base-button type="primary" @click="editdetails(model)"><i class="fas fa-edit"></i> Edit Details</base-button>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="col-lg-12 mt-3">
                  <div class="row align-items-center pb-3">
                    <div class="col-sm-12">
                      <div class="row mb-5">
                        <div class="col">
                          <div class="card shadow" :class="''">
                            <div class="card-header border-0" :class="''">
                              <div class="row align-items-center">
                                <div class="col">
                                  <p class="h3 mb-0 font-weight-bold">
                                    <i class="fas fa-user mr-2"></i> Student Details
                                  </p>
                                </div>
                                <div class="col text-right">
                                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="row justify-content-around">
                                  <div class="col-lg-12 mb-3">
                                    <div class="row">
                                      <div class="col-lg-2">
                                        <img alt="Image placeholder" v-if="model.user_dp" :src="model.user_dp" class="rounded-circle img-thumbnail img-fluid rounded">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="mr-3 align-items-center">
                                    <p class="small mb-0 mt-2"><i class="fas fa-font"></i> Name</p>
                                    <p class="h3 mb-1 font-weight-500">{{model.user_name}}</p>
                                  </div>
                                  <div class="mr-3 align-items-center mt-3" v-if="model.user_phone">
                                    <p class="small mb-0 mt-2"><i class="fas fa-phone"></i> Phone</p>
                                    <p class="h3 mb-1 mt-1 font-weight-500">{{model.user_phone}}</p>
                                  </div>
                                  <div class="mr-3 align-items-center mt-3">
                                    <p class="small mb-0 mt-2"><i class="fas fa-envelope"></i> Email</p>
                                    <p class="h3 mb-1 font-weight-400">{{model.user_email}}</p>
                                  </div>
                                  <div class="mr-3 align-items-center mt-3">
                                    <p class="small mb-0 mt-2"><i class="fas fa-venus-mars"></i> Gender</p>
                                    <p class="h3 mb-1 font-weight-400">{{model.user_gender}}</p>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <div class="mr-3 align-items-center mt-3">
                                    <p class="small mb-0 mt-2"><i class="fas fa-male"></i> Father's Name</p>
                                    <p class="h3 mb-1 font-weight-500">{{model.father_name}}</p>
                                  </div>
                                  <div class="mr-3 align-items-center mt-3" >
                                    <p class="small mb-0 mt-2"><i class="fas fa-female"></i> Mother's Name</p>
                                    <p class="h3 mb-1 mt-1 font-weight-500">{{model.mother_name}}</p>
                                  </div>
                                  <div class="mr-3 align-items-center mt-3" v-if="model.user_address">
                                    <p class="small mb-0 mt-2"><i class="fas fa-map-marker"></i> Address</p>
                                    <p class="h3 mb-1 font-weight-400">{{model.user_address}}</p>
                                  </div>
                                  <div class="mr-3 align-items-center mt-3" v-if="model.user_dob">
                                    <p class="small mb-0 mt-2"><i class="fas fa-birthday-cake"></i> Date of birth</p>
                                    <p class="h3 mb-1 font-weight-400">{{format_datetime(model.user_dob)}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="editdetailspopup" bodyClasses="pt-0">
      <p
        id="modal-title-default"
        slot="header"
        class="modal-title"
      >
        <span>Edit Student Details</span>
      </p>
      <template>
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-12">
                <base-input label="User Name" placeholder="User Name" v-model="editmodel.user_name" input-classes="" />
                <div
                  v-if="$v.editmodel.user_name.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <div class="col-lg-12">
                <base-input label="Admission number" placeholder="Admission number" v-model="editmodel.admission_no" input-classes="" />
                 <div
                  v-if="$v.editmodel.admission_no.$error"
                  class="error h5 text-danger mt--4 pl-1">
                  Field is required.
                 </div>
            </div>
            <div class="col-lg-12">
                <base-input label="User Email" placeholder="User Email" v-model="editmodel.user_email" input-classes="" />
                <div
                  v-if="$v.editmodel.user_email.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input label="User Phone" placeholder="User Phone" v-model="editmodel.user_phone" input-classes="" />
                <div
                  v-if="$v.editmodel.user_phone.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input type="date" label="DOB" placeholder="DOB" v-model="editmodel.user_dob" input-classes="" />
                <div
                  v-if="$v.editmodel.user_dob.$error"
                  class="error h5 text-danger mt--4 pl-1"
                >
                  Field is required.
                </div>
            </div>
            <div class="col-lg-12">
                <base-input label="Father's Name" placeholder="Father's Name" v-model="editmodel.father_name" input-classes="" />
            </div>
            <div class="col-lg-12">
                <base-input label="Mother's Name" placeholder="Mother's Name" v-model="editmodel.mother_name" input-classes="" />
            </div>
            
            <div class="col-lg-12">
              <label class="form-control-label">Profile Picture</label>
              <input
                  id="file"
                  ref="myFiles"
                  type="file"
                  aria-describedby="addon-right addon-left"
                  alternative=""
                  class="form-control form-control-alternative"
                  name="img"
                  @change="hadleimage($event,'add')"
                >
                <base-progress type="success" v-model="uploadPercentage" label="File uploaded"></base-progress>
                <div class="position-relative" v-if="editmodel.user_dp">
                  <base-button
                    type="danger"
                    size="sm"
                    class="position-absolute btn-icon-only"
                    @click="deleteogimage(editmodel)"
                    style="right: 0px;top: -5px;font-size: x-small;width: 1.3rem; height: 1.3rem;"
                    round
                    >
                    <i class="fas fa-times"></i>
                </base-button>
                  <img :src="editmodel.user_dp" style="max-width: 100px" class="img-fluid rounded shadow mt-2 card-body">
                </div>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">User Gender</label>
                <select v-model="editmodel.user_gender"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
               </select>
            </div>
            <div class="col-lg-12">
              <label class="form-control-label">User Class</label>
              <select class="form-control" v-model="editmodel.user_class">
                <option value="">User Class</option>
                <option v-for="classes in classesData" :key="classes._id" :value="classes._id">{{classes.class_name}}</option>
              </select>
              <div
                  v-if="$v.editmodel.user_class.$error"
                  class="error h5 text-danger mt-1 pl-1"
                >
                  Field is required.
              </div>
            </div>
            <!-- <div class="col-lg-12">
                <base-input label="Password" placeholder="Password" v-model="editmodel.user_password" input-classes="" />
            </div> -->
            <!-- <div class="col-lg-12">
                <base-input label="Confirm Password" placeholder="Confirm Password" v-model="editmodel.cuser_password" input-classes="" />
            </div> -->
            <div class="col-lg-12">
               <label class="form-control-label">Status</label>
                <select v-model="editmodel.user_status"  aria-describedby="addon-right addon-left" class="form-control" autocomplete="off">
                    <option value="approved">active</option>
                    <option value="disapproved">inactive</option>
               </select>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">Result</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editmodel.result_allowed">
                    <option>enable</option>
                    <option>disable</option>
               </select>
            </div>
            <div class="col-lg-12">
               <label class="form-control-label">Tc</label>
                <select  aria-describedby="addon-right addon-left" class="form-control " autocomplete="off" v-model="editmodel.tc_allowed">
                    <option>enable</option>
                    <option>disable</option>
               </select>
            </div>
            <!-- <div class="col-lg-12">
                <span v-if="passworderror" style="color: red">{{passworderror}}</span>
            </div> -->
          </div>
        </form>
      </template>

      <template slot="footer">
        <base-button
          @click="updatestudent()"
          type="success"
          class="float-center"
          id="updatestudentbtn"
        >
          <span>Save Details</span>
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="editdetailspopup = false"
        >
          <span>Close</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  // import LineChart from '@/components/Charts/LineChart';
  // import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  // import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  // import StatsCard from '@/components/Cards/StatsCard';
  import VueSwal from 'vue-swal';
  import moment from 'moment';
  import axios from "axios";
  import { required, minLength, between } from 'vuelidate/lib/validators';
  
  // Lists
  // import ActivityFeed from './ActivityFeed';
  // import TaskList from './TaskList';
  // import UserList from './UserList';
  // import ProgressTrackList from './ProgressTrackList';

  // // Tables
  // import LightTable from './LightTable';
  // import SocialTrafficTable from './SocialTrafficTable';
  // import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      BaseProgress,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },

    data() {
      return {
        inputs: {
          file: []
        },
        userData:[],
        orderData:[],
        classesData:[],
        offset:0,
        uploadPercentage:0,
        editproduct:false,
        loadmorefound:1,
        user_type:localStorage.getItem('user_type'),
        editdetailspopup:false,
        model:{
          updated_at: '',
          user_dp: '',
          user_email: '',
          user_fcm: '',
          user_fid: '',
          user_gid: '',
          user_id: '',
          user_class:'',
          user_name: '',
          user_permissions: '',
          user_phone: '',
          user_status: '',
        },
        editmodel:{
          user_id:'',
          user_name:'',
          admission_no:'',
          user_dob:'',
          father_name:'',
          mother_name:'',
          result_allowed:'',
          tc_allowed:'',
          user_email:'',
          user_password :'',
          user_dp:'',
          user_status:'approved',
          user_phone:'',
          user_gender:'male',
          user_class :'',
          verified :true,
        },
        checkboxes: {
          unchecked: false,
          checked: true,
          uncheckedDisabled: false,
          checkedDisabled: true
        },
        radios: {
          radio1: 'checked',
          radio2: 'checked'
        },
        auth : {
          headers: { 'Content-Type':'application/x-www-form-urlencoded',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      auth1 : { headers: { 'Content-Type':'application/json',
          'Authorization':'caption#' + localStorage.getItem('accessToken'),
          '_id':localStorage.getItem('_id')}
        },
      }
    },
    validations: {
      editmodel: {
        user_name:{
          required
        },
        admission_no:{
          required
        },
        user_dob:{
          required
        },
        user_email:{
          required
        },
        user_phone:{
          required
        },
        user_class:{
          required
        },
      },

    },
    mounted () {

      const querystring = require('querystring');
      console.log(this.auth);
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_class',{ "details" :{},"offset":"0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
         this.classesData=response.data.details;
        }
            
      })
      axios
      .post(process.env.VUE_APP_API_ENDPOINT+'read_student',{ "details" :{"_id":this.$route.params.id},"offset":"0"},this.auth1)
      .then(response => {
        console.log(response);
        if(response.data.details){
        this.model=response.data.details[0];
        }
            
      })

    },
    methods: {
      editdetails(row){
        this.editdetailspopup=true;
        this.editmodel =row;
        if(row.user_dob){
        let date=row.user_dob.split('T');
        this.editmodel.user_dob=date[0];
        }
        this.editmodel.user_id =row.user_id;
        this.editmodel.user_name =row.user_name;
        this.editmodel.user_email =row.user_email;
        this.editmodel.user_status =row.user_status;
        this.editmodel.user_phone =row.user_phone;
        this.editmodel.user_gender =row.user_gender;
        this.editmodel.user_address =row.user_address;
        this.editmodel.father_name =row.father_name;
        this.editmodel.mother_name =row.mother_name;
        this.editmodel.user_dob =row.user_dob;
        this.editmodel.tc_allowed =row.tc_allowed;
        this.editmodel.result_allowed =row.result_allowed;

        this.editmodel.user_dob =row.user_dob;
        if(row.user_class){
          this.editmodel.user_class =row.user_class._id;
        }
        
      },
     hadleimage: function(event,type) {
              // Reference to the DOM input element
              this.uploadPercentage =0;
              var input = event.target;
              // Ensure that you have a file before attempting to read it
              if(input.files[0].size < 5000000){

              const fd= new FormData();
              fd.append('image',input.files[0],input.files[0].name);
              axios
              .post(process.env.VUE_APP_API_ENDPOINT+'image-upload',fd,{ headers: {'Content-Type': 'multipart/form-data'},onUploadProgress: function( progressEvent ) { this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) ); console.log(this.uploadPercentage)}.bind(this)})
              .then(response => {
                console.log(response);
                if(type=='add'){
                  this.subjectmodal.subject_image=response.data.imageUrl;
                  console.log(this.subjectmodal.subject_image);
                }if(type=='edit'){
                  this.editmodel.user_dp=response.data.imageUrl;
                  console.log(this.editmodel.user_dp);
                }
                  
              })

              }else{

              swal({
                title:'File too large!',
                text: 'Your file is larger than 5 MB. Please upload images which are less than 5 MB.',
                icon:'warning',
               }).then((result) => {
                 
              })

            }

      },
      updatestudent(){
          const querystring = require('querystring');
          if(this.$v.editmodel.$error===false){
          axios.post(process.env.VUE_APP_API_ENDPOINT+'update_student',querystring.stringify({user_id:this.editmodel.user_id,user_name : this.editmodel.user_name,user_phone : this.editmodel.user_phone,user_email : this.editmodel.user_email,admission_no : this.editmodel.admission_no,user_gender : this.editmodel.user_gender,user_class : this.editmodel.user_class,user_dp : this.editmodel.user_dp,user_dob : this.editmodel.user_dob,father_name : this.editmodel.father_name,mother_name : this.editmodel.mother_name,result_allowed : this.editmodel.result_allowed,tc_allowed : this.editmodel.tc_allowed,user_status : this.editmodel.user_status}),this.auth)
          .then(response => {
            console.log(response);
            this.message=response.data.message;
            document.getElementById("updatestudentbtn").classList.remove('btn-primary');
            document.getElementById("updatestudentbtn").classList.add('btn-success');
            document.getElementById("updatestudentbtn").innerHTML='Updated';
            setTimeout(function(){
            document.getElementById("updatestudentbtn").classList.remove('btn-success');
            document.getElementById("updatestudentbtn").classList.add('btn-primary');
            document.getElementById("updatestudentbtn").innerHTML="Update";
            window.location.reload()
          }, 2000);
          })
         }
         // }else{
         //    this.passworderror="Confirm Password not match";
         // }
        },
        format_datetime(value){
          if(value) {
            return moment(new Date(value)).format("LLL")
          }
        },
      },
  };
</script>
<style></style>
