<template>
  <div class="container-fluid h-100vh">
    <div class="row justify-content-center h-100vh">
      <div class="col-md-4">
        <div class="header mt-6">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="header-body text-center mb-4">
                  <img src="img/brand/green.png" class="img-fluid w-auto" style="max-height: 80px;">
                  <!-- <p class="text-lead my-3">If you face any issues please reach out to us on Whatsapp/Call at 9999999999.</p>  -->              
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
              <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div> -->
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="card bg-secondary border-0 mb-0">
              <!-- <div class="card-header bg-transparent pb-5">
                <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
                <div class="btn-wrapper text-center">
                  <a href="#" class="btn btn-neutral btn-icon">
                    <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                    <span class="btn-inner--text">Github</span>
                  </a>
                  <a href="#" class="btn btn-neutral btn-icon">
                    <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                    <span class="btn-inner--text">Google</span>
                  </a>
                </div>
              </div> -->
              <div class="card-body px-lg-5 py-lg-5">
                <h1 class="mb-4 mt--2 h3 text-center">Welcome to APS</h1>
                <div class="text-center text-muted mb-2">
                  <!-- <small>Sign in with credentials</small> -->
                </div>
                <div class="text-center">
                  <badge
                    v-if="loginError"
                    type="danger"
                    class="text-center mb-2"
                  >
                    {{ loginError }}
                  </badge>
                </div>
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                  <form role="form" @submit.prevent="handleSubmit(loginSubmit)">
                    <base-input alternative
                                class="mb-3"
                                name="Email"
                                :rules="{required: true, email: true}"
                                prepend-icon="ni ni-email-83"
                                placeholder="Email"
                                v-model="email">
                    </base-input>

                    <base-input alternative
                                class="mb-3"
                                name="Password"
                                :rules="{required: true, min: 6}"
                                prepend-icon="ni ni-lock-circle-open"
                                type="password"
                                placeholder="Password"
                                v-model="password">
                    </base-input>

                    <base-checkbox v-model="rememberMe">Remember me</base-checkbox>
                    <div class="text-center">
                      <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <!-- <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link> -->
              </div>
              <div class="col-6 text-right">
                <router-link to="/forgot" class="text-light"><small>Forgot password?</small></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 bg-gradient-success">
        <div class="mt-6 w-50 mx-auto">
          <img src="/img/theme/school_bul.jpeg" class="mx-auto text-center d-block img-fluid rounded p-1 bg-white">
        </div>
        <div class="px-5">
          <p class="mb-0 pt-5 h2 text-white text-center">"Alpine Public School, Khurja is a Co-educational, English Medium school modeled upon the best educational practices. The school aims to give our children the best education, with the right mix of values, to sustain them for their future lives."</p>
          <footer class="blockquote-footer mt-3 text-white text-center">
            <!-- <cite title="Source Title">Used by 20 CA firms across India.</cite> -->
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  export default {
    layout: 'AuthLayout',
    data() {
      return {
        email: '',
        password: '',
        rememberMe:false,
      }
    },
    computed: {
      ...mapState([
        'loggingIn',
        'loginError',
        'accessToken'
      ])
    },
    methods: {
      ...mapActions([
        'doLogin'
      ]),
      loginSubmit() {
        this.doLogin({
          user_email: this.email,
          user_password: this.password
        });
      }
    }
  }
</script>
<style>
</style>
